.inspiracao-frame1171277072 {
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.inspiracao-inspirese {
  gap: 16px;
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.inspiracao-botao-exemplos {
  gap: 4px;
  cursor: pointer;
  display: flex;
  padding: 8px 12px 8px 16px;
  align-items: center;
  border-radius: 8px 8px 0 0;
  justify-content: center;
  background-color: var(--dl-color-maincolors_gray-bg);
}
.inspiracao-text {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 14px;
  align-self: center;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 100%;
  font-stretch: normal;
  text-decoration: none;
}
.inspiracao-arrowicon {
  width: 14px;
  height: 14px;
}
.inspiracao-lampicon {
  width: 14px;
  height: 14px;
}
.inspiracao-text-proibido-links {
  color: rgba(125, 138, 152, 1);
  flex-grow: 1;
  font-size: 12px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.inspiracao-exemplos {
  display: flex;
  padding: 24px;
  overflow: hidden;
  align-self: stretch;
  align-items: flex-start;
  border-radius: 0 8px 8px;
  flex-direction: column;
  background-color: var(--dl-color-default-f0f4f6);
}
.inspiracao-topico {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.inspiracao-text3 {
  color: rgb(125, 138, 152);
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.inspiracao-text4 {
  color: rgba(125, 138, 152, 1);
  width: 100%;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.inspiracao-topico1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.inspiracao-text5 {
  color: rgb(125, 138, 152);
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.inspiracao-text6 {
  color: rgba(125, 138, 152, 1);
  width: 100%;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.inspiracao-topico2 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.inspiracao-text7 {
  color: rgb(125, 138, 152);
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.inspiracao-text8 {
  color: rgba(125, 138, 152, 1);
  width: 100%;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}





@media(max-width: 767px) {
  .inspiracao-text3 {
    font-size: 15px;
  }
  .inspiracao-text4 {
    font-size: 15px;
  }
  .inspiracao-text5 {
    font-size: 15px;
  }
  .inspiracao-text6 {
    font-size: 15px;
  }
  .inspiracao-text7 {
    font-size: 15px;
  }
  .inspiracao-text8 {
    font-size: 15px;
  }
}
@media(max-width: 479px) {
  .inspiracao-text3 {
    font-size: 14px;
  }
  .inspiracao-text4 {
    font-size: 14px;
  }
  .inspiracao-text5 {
    font-size: 14px;
  }
  .inspiracao-text6 {
    font-size: 14px;
  }
  .inspiracao-text7 {
    font-size: 14px;
  }
  .inspiracao-text8 {
    font-size: 14px;
  }
}
