.container {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
  background-color: rgba(240, 244, 246, 1);
}
.container1 {
  width: 100%;
  display: flex;
  padding: 0px;
  align-items: center;
  flex-direction: column;
}
.container1 > img {
  width: 100%;
  padding-top: 32px;
  padding-left: 16px;
  padding-right: 16px;
  margin: 0 auto;
}
.busca-personal {
  width: 100%;
  display: flex;
  padding: 32px 16px;
  padding-top: 16px;
  /* overflow: hidden; */
  flex-wrap: wrap;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: center;
}
.contrate {
  gap: 24px;
  width: 100%;
  display: flex;
  max-width: 1320px;
  align-items: center;
  flex-shrink: 0;
  padding-top: 52px;
  padding-left: 24px;
  border-radius: 16px;
  padding-right: 24px;
  flex-direction: column;
  padding-bottom: 52px;
  justify-content: center;
  background-color: var(--dl-color-default-padro);
}
.text {
  color: var(--dl-color-default-ffffff);
  width: 100%;
  height: auto;
  font-size: 32px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: center;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text001 {
  color: var(--dl-color-default-11592b);
  width: 100%;
  height: auto;
  font-size: 24px;
  align-self: stretch;
  font-style: Regular;
  text-align: center;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-bottom: 24px;
  text-decoration: none;
}
.categorias {
  gap: 16px;
  width: 100%;
  display: flex;
  padding: 52px 16px;
  overflow: hidden;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
}
.categorias1 {
  gap: 32px;
  width: 100%;
  display: flex;
  max-width: 1320px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
}
.text002 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  font-size: 24px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: center;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.conteudo {
  gap: 16px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  max-width: 1320px;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
}
.categoria {
  gap: 24px;
  width: 100%;
  height: 200px;
  display: flex;
  padding: 16px 24px 16px 16px;
  flex-grow: 1;
  max-width: 640px;
  align-items: flex-start;
  border-color: rgba(236, 239, 244, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 20px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
  cursor: pointer;
}

.categoria:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.imagemcategoria {
  width: 128px;
  height: 128px;
  align-self: center;
}
.texts {
  gap: 8px;
  display: flex;
  padding: 8px 0;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.text004 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  font-size: 20px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  font-stretch: normal;
  text-decoration: none;
}
.text005 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  font-stretch: normal;
  text-decoration: none;
}
.text006 {
  color: rgba(125, 138, 152, 1);
  height: auto;
  font-size: 14px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.categoria01 {
  gap: 24px;
  width: 100%;
  height: 200px;
  display: flex;
  padding: 16px 24px 16px 16px;
  flex-grow: 1;
  max-width: 640px;
  align-items: flex-start;
  border-color: rgba(236, 239, 244, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 20px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
  cursor: pointer;
}
.imagemcategoria01 {
  width: 128px;
  height: 128px;
  align-self: center;
}
.texts01 {
  gap: 16px;
  display: flex;
  padding: 8px 0;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.text007 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  font-size: 20px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  font-stretch: normal;
  text-decoration: none;
}
.text009 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  font-stretch: normal;
  text-decoration: none;
}
.text011 {
  color: rgba(125, 138, 152, 1);
  height: auto;
  font-size: 14px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.categoria02 {
  gap: 24px;
  width: 100%;
  height: 200px;
  display: flex;
  padding: 16px 24px 16px 16px;
  flex-grow: 1;
  max-width: 640px;
  align-items: flex-start;
  border-color: rgba(236, 239, 244, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 20px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
  cursor: pointer;
}
.imagemcategoria02 {
  width: 128px;
  height: 128px;
  align-self: center;
}
.texts02 {
  gap: 16px;
  display: flex;
  padding: 8px 0;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.text013 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  font-size: 20px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  font-stretch: normal;
  text-decoration: none;
}
.text015 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  font-stretch: normal;
  text-decoration: none;
}
.text017 {
  color: rgba(125, 138, 152, 1);
  height: auto;
  font-size: 14px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.categoria03 {
  gap: 24px;
  width: 100%;
  height: 200px;
  display: flex;
  padding: 16px 24px 16px 16px;
  flex-grow: 1;
  max-width: 640px;
  align-items: flex-start;
  border-color: rgba(236, 239, 244, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 20px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
  cursor: pointer;
}
.imagemcategoria03 {
  width: 128px;
  height: 128px;
  align-self: center;
}
.texts03 {
  gap: 16px;
  display: flex;
  padding: 8px 0;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.text019 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  font-size: 20px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  font-stretch: normal;
  text-decoration: none;
}
.text021 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  font-stretch: normal;
  text-decoration: none;
}
.text023 {
  color: rgba(125, 138, 152, 1);
  height: auto;
  font-size: 14px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.categoria04 {
  gap: 24px;
  width: 100%;
  height: 200px;
  display: flex;
  padding: 16px 24px 16px 16px;
  flex-grow: 1;
  max-width: 640px;
  align-items: flex-start;
  border-color: rgba(236, 239, 244, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 20px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
  cursor: pointer;
}
.imagemcategoria04 {
  width: 128px;
  height: 128px;
  align-self: center;
}
.texts04 {
  gap: 16px;
  display: flex;
  padding: 8px 0;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.text025 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  font-size: 20px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  font-stretch: normal;
  text-decoration: none;
}
.text027 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  font-stretch: normal;
  text-decoration: none;
}
.text029 {
  color: rgba(125, 138, 152, 1);
  height: auto;
  font-size: 14px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.categoria05 {
  gap: 24px;
  width: 100%;
  height: 200px;
  display: flex;
  padding: 16px 24px 16px 16px;
  flex-grow: 1;
  max-width: 640px;
  align-items: flex-start;
  border-color: rgba(236, 239, 244, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 20px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
  cursor: pointer;
}
.imagemcategoria05 {
  width: 128px;
  height: 128px;
  align-self: center;
}
.texts05 {
  gap: 16px;
  display: flex;
  padding: 8px 0;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.text031 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  font-size: 20px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  font-stretch: normal;
  text-decoration: none;
}
.text033 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  font-stretch: normal;
  text-decoration: none;
}
.text035 {
  color: rgba(125, 138, 152, 1);
  height: auto;
  font-size: 14px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.categoria06 {
  gap: 24px;
  width: 100%;
  height: 200px;
  display: flex;
  padding: 16px 24px 16px 16px;
  flex-grow: 1;
  max-width: 640px;
  align-items: flex-start;
  border-color: rgba(236, 239, 244, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 20px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
  cursor: pointer;
}
.imagemcategoria06 {
  width: 128px;
  height: 128px;
  align-self: center;
}
.texts06 {
  gap: 16px;
  display: flex;
  padding: 8px 0;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.text037 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  font-size: 20px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  font-stretch: normal;
  text-decoration: none;
}
.text039 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  font-stretch: normal;
  text-decoration: none;
}
.text041 {
  color: rgba(125, 138, 152, 1);
  height: auto;
  font-size: 14px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.categoria07 {
  gap: 24px;
  width: 100%;
  height: 200px;
  display: flex;
  padding: 16px 24px 16px 16px;
  flex-grow: 1;
  max-width: 640px;
  align-items: flex-start;
  border-color: rgba(236, 239, 244, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 20px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
  cursor: pointer;
}
.imagemcategoria07 {
  width: 128px;
  height: 128px;
  align-self: center;
}
.texts07 {
  gap: 16px;
  display: flex;
  padding: 8px 0;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.text043 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  font-size: 20px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  font-stretch: normal;
  text-decoration: none;
}
.text045 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  font-stretch: normal;
  text-decoration: none;
}
.text047 {
  color: rgba(125, 138, 152, 1);
  height: auto;
  font-size: 14px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.categoria08 {
  gap: 24px;
  width: 100%;
  height: 200px;
  display: flex;
  padding: 16px 24px 16px 16px;
  flex-grow: 1;
  max-width: 640px;
  align-items: flex-start;
  border-color: rgba(236, 239, 244, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 20px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
  cursor: pointer;
}
.imagemcategoria08 {
  width: 128px;
  height: 128px;
  align-self: center;
}
.texts08 {
  gap: 16px;
  display: flex;
  padding: 8px 0;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.text049 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  font-size: 20px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  font-stretch: normal;
  text-decoration: none;
}
.text051 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  font-stretch: normal;
  text-decoration: none;
}
.text053 {
  color: rgba(125, 138, 152, 1);
  height: auto;
  font-size: 14px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.categoria09 {
  gap: 24px;
  width: 100%;
  height: 200px;
  display: flex;
  padding: 16px 24px 16px 16px;
  flex-grow: 1;
  max-width: 640px;
  align-items: flex-start;
  border-color: rgba(236, 239, 244, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 20px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
  cursor: pointer;
}
.imagemcategoria09 {
  width: 128px;
  height: 128px;
  align-self: center;
}
.texts09 {
  gap: 16px;
  display: flex;
  padding: 8px 0;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.text055 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  font-size: 20px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  font-stretch: normal;
  text-decoration: none;
}
.text057 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  font-stretch: normal;
  text-decoration: none;
}
.text059 {
  color: rgba(125, 138, 152, 1);
  height: auto;
  font-size: 14px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.voce-sabia {
  gap: 32px;
  width: 100%;
  display: flex;
  padding: 52px 16px;
  overflow: hidden;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.text061 {
  color: rgba(45, 62, 80, 1);
  width: 100%;
  height: auto;
  flex-grow: 1;
  font-size: 32px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: center;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.conteudos {
  gap: 16px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  max-width: 1320px;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
}
.info {
  gap: 16px;
  width: 100%;
  display: flex;
  padding: 24px;
  overflow: hidden;
  max-width: 652px;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(222, 228, 230, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
  background-color: var(--dl-color-default-ffffff);
}
.text062 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 20px;
  align-self: stretch;
  font-style: Bold;
  text-align: left;
  font-family: Poppins;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text064 {
  color: rgba(125, 138, 152, 1);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.info1 {
  gap: 16px;
  width: 100%;
  display: flex;
  padding: 24px;
  overflow: hidden;
  max-width: 652px;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(222, 228, 230, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
  background-color: var(--dl-color-default-ffffff);
}
.text066 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 20px;
  align-self: stretch;
  font-style: Bold;
  text-align: left;
  font-family: Poppins;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text068 {
  color: rgba(125, 138, 152, 1);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.info2 {
  gap: 16px;
  width: 100%;
  display: flex;
  padding: 24px;
  overflow: hidden;
  max-width: 652px;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(222, 228, 230, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
  background-color: var(--dl-color-default-ffffff);
}
.text070 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 20px;
  align-self: stretch;
  font-style: Bold;
  text-align: left;
  font-family: Poppins;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text072 {
  color: rgba(125, 138, 152, 1);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.info3 {
  gap: 16px;
  width: 100%;
  display: flex;
  padding: 24px;
  overflow: hidden;
  max-width: 652px;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(222, 228, 230, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
  background-color: var(--dl-color-default-ffffff);
}
.text074 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 20px;
  align-self: stretch;
  font-style: Bold;
  text-align: left;
  font-family: Poppins;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text076 {
  color: rgba(125, 138, 152, 1);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.buscas-populares {
  width: 100%;
  display: flex;
  padding: 52px 16px;
  overflow: hidden;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.conteudo1 {
  gap: 32px;
  width: 100%;
  display: flex;
  max-width: 1098px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-default-ffffff);
}
.text077 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  font-size: 32px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: center;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.box {
  gap: 16px;
  width: 100%;
  /* width: fit-content; */
  height: 198px;
  display: flex;
  padding: 24px;
  overflow: hidden;
  /* overflow-y: scroll; */
  flex-grow: 1;
  max-width: 1098px;
  flex-shrink: 0;
  border-radius: 16px;
  flex-wrap: wrap;
  background-color: rgba(240, 244, 246, 1);
}
.texts10 {
  gap: 5px;  
  /* height: 100%; */
  width: 100%;
  padding-right: 8px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* overflow: hidden;
  overflow-y: auto; */
  flex-shrink: 0;
  height: 150px;
  /* display: contents; */
  overflow: hidden;
  overflow-y: scroll;
}
.texts10::-webkit-scrollbar{
  width: 4px;
  background-color: hidden;
}
.texts10::-webkit-scrollbar-thumb {
  width: 4px;
  background: white;
  border-radius: 100px;
}
.text079 {
  color: rgba(125, 138, 152, 1);
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text116 {
  color: rgb(125, 138, 152);
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text152 {
  color: rgba(125, 138, 152, 1);
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.mundo-personal {
  gap: 32px;
  width: 100%;
  display: flex;
  padding: 52px 16px;
  overflow: hidden;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.text189 {
  color: rgb(45, 62, 80);
  width: 100%;
  height: auto;
  font-size: 32px;
  font-style: SemiBold;
  text-align: center;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.conteudo2 {
  gap: 16px;
  width: 100%;
  display: flex;
  max-width: 1118px;
  overflow-x: auto;
  align-items: center;
  flex-shrink: 0;
  justify-content: flex-start;
}
.box1 {
  width: 360px;
  display: flex;
  align-self: stretch;
  box-sizing: content-box;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(216, 223, 232, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
}
.video {
  gap: 10px;
  height: 202px;
  display: flex;
  padding: 16px;
  align-self: stretch;
  align-items: flex-end;
  flex-shrink: 0;
  border-radius: 16px 16px 0 0;
  flex-direction: column;
  background-size: cover;
  background-image: url("/pastedimage-7e9-300h.png");
}
.playvideo {
  width: 29px;
  cursor: pointer;
  height: 29px;
}
.text190 {
  gap: 16px;
  display: flex;
  padding: 24px;
  overflow: hidden;
  align-self: stretch;
  align-items: center;
  border-radius: 0 0 16px 16px;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.text191 {
  color: rgb(45, 62, 80);
  height: auto;
  font-size: 20px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.botaovermais {
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: flex-end;
}
.buttons {
  gap: 4px;
  cursor: pointer;
  display: flex;
  padding: 8px 12px 8px 16px;
  align-items: center;
  border-radius: 4px;
  justify-content: flex-end;
}
.text194 {
  color: var(--dl-color-default-padro);
  height: auto;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: 100%;
  font-stretch: normal;
  text-decoration: none;
}
.iconvermais {
  width: 12px;
  height: 12px;
}
.box2 {
  width: 360px;
  display: flex;
  align-self: stretch;
  box-sizing: content-box;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(216, 223, 232, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
}
.video1 {
  gap: 10px;
  height: 202px;
  display: flex;
  padding: 16px;
  align-self: stretch;
  align-items: flex-end;
  flex-shrink: 0;
  border-radius: 16px 16px 0 0;
  flex-direction: column;
  background-size: cover;
  background-image: url("/pastedimage-w6a8-300h.png");
}
.playvideo1 {
  width: 29px;
  cursor: pointer;
  height: 29px;
}
.text196 {
  gap: 16px;
  display: flex;
  padding: 24px;
  overflow: hidden;
  align-self: stretch;
  align-items: center;
  border-radius: 0 0 16px 16px;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.text197 {
  color: rgb(45, 62, 80);
  height: auto;
  font-size: 20px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.botaovermais1 {
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: flex-end;
}
.buttons1 {
  gap: 4px;
  cursor: pointer;
  display: flex;
  padding: 8px 12px 8px 16px;
  align-items: center;
  border-radius: 4px;
  justify-content: flex-end;
}
.text200 {
  color: var(--dl-color-default-padro);
  height: auto;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: 100%;
  font-stretch: normal;
  text-decoration: none;
}
.iconvermais1 {
  width: 12px;
  height: 12px;
}
.box3 {
  width: 360px;
  display: flex;
  align-self: stretch;
  box-sizing: content-box;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(216, 223, 232, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
}
.video2 {
  gap: 10px;
  height: 202px;
  display: flex;
  padding: 16px;
  align-self: stretch;
  align-items: flex-end;
  flex-shrink: 0;
  border-radius: 16px 16px 0 0;
  flex-direction: column;
  background-size: cover;
  background-image: url("/pastedimage-u1w-300h.png");
}
.playvideo2 {
  width: 29px;
  cursor: pointer;
  height: 29px;
}
.text202 {
  gap: 16px;
  display: flex;
  padding: 24px;
  overflow: hidden;
  align-self: stretch;
  align-items: center;
  border-radius: 0 0 16px 16px;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.text203 {
  color: rgb(45, 62, 80);
  height: auto;
  font-size: 20px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.botaovermais2 {
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: flex-end;
}
.buttons2 {
  gap: 4px;
  cursor: pointer;
  display: flex;
  padding: 8px 12px 8px 16px;
  align-items: center;
  border-radius: 4px;
  justify-content: flex-end;
}
.text206 {
  color: var(--dl-color-default-padro);
  height: auto;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: 100%;
  font-stretch: normal;
  text-decoration: none;
}
.iconvermais2 {
  width: 12px;
  height: 12px;
}
.botao-mais-conteudos {
  gap: 8px;
  cursor: pointer;
  display: flex;
  padding: 12px 20px 12px 24px;
  align-items: center;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-colorgreybg);
}
.text208 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 100%;
  font-stretch: normal;
  text-decoration: none;
}
.iconvermais3 {
  width: 14px;
  height: 14px;
}
@media (max-width: 991px) {
  .contrate {
    padding-left: 16px;
    padding-right: 16px;
  }
  .categoria {
    height: 100%;
    max-width: 100%;
  }
  .categoria01 {
    height: 100%;
    max-width: 100%;
  }
  .categoria02 {
    height: 100%;
    max-width: 100%;
  }
  .categoria03 {
    height: 100%;
    max-width: 100%;
  }
  .categoria04 {
    height: 100%;
    max-width: 100%;
  }
  .categoria05 {
    height: 100%;
    max-width: 100%;
  }
  .categoria06 {
    height: 100%;
    max-width: 100%;
  }
  .categoria07 {
    height: 100%;
    max-width: 100%;
  }
  .categoria08 {
    height: 100%;
    max-width: 100%;
  }
  .categoria09 {
    height: 100%;
    max-width: 100%;
  }
  .conteudos {
    gap: 8px;
  }
  .info {
    max-width: 100%;
  }
  .info1 {
    max-width: 100%;
  }
  .info2 {
    max-width: 100%;
  }
  .info3 {
    max-width: 100%;
  }
  .conteudo2 {
    align-self: center;
    justify-content: flex-start;
  }
  .texts10 {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 767px) {
  .contrate {
    padding-top: 40px;
    padding-right: 10px;
    padding-bottom: 40px;
  }
  .text {
    font-size: 26px;
  }
  .text001 {
    font-size: 18px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .categorias {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .categorias1 {
    gap: 24px;
  }
  .text002 {
    font-size: 22px;
  }
  .conteudo {
    gap: 8px;
  }
  .text004 {
    font-size: 18px;
  }
  .text005 {
    font-size: 15px;
  }
  .text006 {
    color: rgb(125, 138, 152);
    font-size: 13px;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
  }
  .text007 {
    font-size: 18px;
  }
  .text009 {
    font-size: 15px;
  }
  .text011 {
    font-size: 13px;
  }
  .text013 {
    font-size: 18px;
  }
  .text015 {
    font-size: 15px;
  }
  .text017 {
    font-size: 13px;
  }
  .text019 {
    font-size: 18px;
  }
  .text021 {
    font-size: 15px;
  }
  .text023 {
    font-size: 13px;
  }
  .text025 {
    font-size: 18px;
  }
  .text027 {
    font-size: 15px;
  }
  .text029 {
    font-size: 13px;
  }
  .text031 {
    font-size: 18px;
  }
  .text033 {
    font-size: 15px;
  }
  .text035 {
    font-size: 13px;
  }
  .text037 {
    font-size: 18px;
  }
  .text039 {
    font-size: 15px;
  }
  .text041 {
    font-size: 13px;
  }
  .text043 {
    font-size: 18px;
  }
  .text045 {
    font-size: 15px;
  }
  .text047 {
    font-size: 13px;
  }
  .text049 {
    font-size: 18px;
  }
  .text051 {
    font-size: 15px;
  }
  .text053 {
    font-size: 13px;
  }
  .text055 {
    font-size: 18px;
  }
  .text057 {
    font-size: 15px;
  }
  .text059 {
    font-size: 13px;
  }
  .voce-sabia {
    gap: 24px;
    padding-top: 48px;
    padding-bottom: 32px;
  }
  .text061 {
    color: rgb(45, 62, 80);
    font-size: 26px;
    font-family: Poppins;
    font-weight: 600;
    line-height: normal;
    text-decoration: none;
  }
  .text062 {
    font-size: 17px;
  }
  .text064 {
    font-size: 15px;
  }
  .text066 {
    font-size: 17px;
  }
  .text068 {
    font-size: 15px;
  }
  .text070 {
    font-size: 17px;
  }
  .text072 {
    font-size: 15px;
  }
  .text074 {
    font-size: 17px;
  }
  .text076 {
    font-size: 15px;
  }
  .buscas-populares {
    padding-top: 24px;
    padding-bottom: 32px;
  }
  .conteudo1 {
    gap: 24px;
  }
  .text077 {
    font-size: 26px;
  }
  .text079 {
    font-size: 15px;
  }
  .text116 {
    font-size: 15px;
  }
  .text152 {
    font-size: 15px;
  }
  .mundo-personal {
    gap: 24px;
    padding-top: 24px;
    padding-bottom: 48px;
  }
  .text189 {
    font-size: 26px;
  }
  .box1 {
    width: 287px;
  }
  .text191 {
    font-size: 17px;
  }
  .box2 {
    width: 287px;
  }
  .text197 {
    color: rgb(45, 62, 80);
    font-size: 17px;
    font-family: Poppins;
    font-weight: 600;
    line-height: normal;
    text-decoration: none;
  }
  .box3 {
    width: 287px;
  }
  .text203 {
    font-size: 17px;
  }
  
}
@media (max-width: 615px) {
  .texts10 {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media (max-width: 479px) {
  .text {
    color: var(--dl-color-default-ffffff);
    font-size: 20px;
    font-family: Poppins;
    font-weight: 600;
    line-height: normal;
    text-decoration: none;
  }
  .text001 {
    font-size: 16px;
  }
  .text002 {
    font-size: 20px;
  }
  .imagemcategoria {
    display: none;
  }
  .text004 {
    color: rgb(45, 62, 80);
    font-size: 16px;
    font-family: Poppins;
    font-weight: 600;
    line-height: normal;
    text-decoration: none;
  }
  .text005 {
    color: var(--dl-color-default-2d3e50);
    font-size: 14px;
  }
  .text006 {
    color: rgb(125, 138, 152);
    font-size: 12px;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
  }
  .imagemcategoria01 {
    display: none;
  }
  .text007 {
    font-size: 16px;
  }
  .text009 {
    font-size: 14px;
  }
  .text011 {
    font-size: 12px;
  }
  .imagemcategoria02 {
    display: none;
  }
  .text013 {
    font-size: 16px;
  }
  .text015 {
    font-size: 14px;
  }
  .text017 {
    font-size: 12px;
  }
  .imagemcategoria03 {
    display: none;
  }
  .text019 {
    font-size: 16px;
  }
  .text021 {
    font-size: 14px;
  }
  .text023 {
    font-size: 12px;
  }
  .imagemcategoria04 {
    display: none;
  }
  .text025 {
    font-size: 16px;
  }
  .text027 {
    font-size: 14px;
  }
  .text029 {
    font-size: 12px;
  }
  .imagemcategoria05 {
    display: none;
  }
  .text031 {
    font-size: 16px;
  }
  .text033 {
    font-size: 14px;
  }
  .text035 {
    font-size: 12px;
  }
  .imagemcategoria06 {
    display: none;
  }
  .text037 {
    font-size: 16px;
  }
  .text039 {
    font-size: 14px;
  }
  .text041 {
    font-size: 12px;
  }
  .imagemcategoria07 {
    display: none;
  }
  .text043 {
    font-size: 16px;
  }
  .text045 {
    font-size: 14px;
  }
  .text047 {
    font-size: 12px;
  }
  .imagemcategoria08 {
    display: none;
  }
  .text049 {
    font-size: 16px;
  }
  .text051 {
    font-size: 14px;
  }
  .text053 {
    font-size: 12px;
  }
  .imagemcategoria09 {
    display: none;
  }
  .text055 {
    font-size: 16px;
  }
  .text057 {
    font-size: 14px;
  }
  .text059 {
    font-size: 12px;
  }
  .text061 {
    font-size: 20px;
  }
  .text062 {
    font-size: 14px;
  }
  .text064 {
    font-size: 14px;
  }
  .text066 {
    font-size: 14px;
  }
  .text068 {
    font-size: 14px;
  }
  .text070 {
    font-size: 14px;
  }
  .text072 {
    font-size: 14px;
  }
  .text074 {
    font-size: 14px;
  }
  .text076 {
    color: rgb(125, 138, 152);
    font-size: 14px;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
  }
  .text077 {
    font-size: 20px;
  }
  .texts10 {
    grid-template-columns: repeat(1, 1fr);
    max-height: 280px;
  }
  .text079 {
    font-size: 14px;
  }
  .text116 {
    font-size: 14px;
  }
  .text152 {
    font-size: 14px;
  }
  .text189 {
    font-size: 20px;
  }
  .text191 {
    font-size: 14px;
  }
  .text197 {
    font-size: 14px;
  }
  .text203 {
    font-size: 14px;
  }
}
.btn-banner-sorteio {
  padding: 32px 16px;
  overflow: hidden;
  max-width: 100%;
}
.btn-banner-sorteio div {
  display: flex;
  justify-content: center;
  overflow: hidden;
  border-radius: 12px;
  max-width: 1320px;
}
.btn-banner-sorteio img {
  min-width: 648px;
}