.anunciante {
    gap: 24px;
    width: 100%;
    display: flex;
    padding: 24px;
    position: relative;
    flex-wrap: wrap;
    align-self: stretch;
    box-shadow: 4px 8px 30px 0px rgba(0, 0, 0, 0.09);
    align-items: center;
    flex-shrink: 0;
    border-color: #DFE4E7;
    border-width: 1px;
    border-radius: 16px;
  }
  .foto-plano {
    gap: 16px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .image {
    width: 124px;
    height: 124px;
    object-fit: cover;
    border-radius: var(--dl-radius-radius-round);
  }
  .infos-usuario {
    gap: 24px;
    width: 100%;
    display: flex;
    overflow: hidden;
    flex-grow: 1;
    max-width: 377px;
    align-self: stretch;
    align-items: flex-start;
    border-color: #F0F4F6;
    border-width: 1px;
    padding-right: 24px;
    flex-direction: column;
    justify-content: space-between;
    border-top-width: 0px;
    border-left-width: 0px;
    border-bottom-width: 0px;
  }
  .container {
    gap: 14px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .text-nome {
    color: rgba(45, 62, 80, 1);
    width: fit-content;
    font-size: 20px;
    font-style: SemiBold;
    text-align: left;
    font-family: Poppins;
    font-weight: 600;
    line-height: 1;
  }
  .email {
    gap: 8px;
    width: 100%;
    display: flex;
    align-items: center;
  }
  .emailicon {
    width: 15px;
  }
  .text03 {
    color: rgb(125, 138, 152);
    font-size: 14px;
    font-style: Regular;
    text-align: left;
    font-family: Poppins;
    font-weight: 400;
    line-height: 1;
    font-stretch: normal;
    overflow-wrap: break-word;
  }
  .telefone {
    gap: 8px;
    width: 100%;
    display: flex;
    align-items: center;
  }
  .phoneicon {
    width: 13px;
  }
  .text04 {
    color: rgb(125, 138, 152);
    font-size: 14px;
    font-style: Regular;
    text-align: left;
    font-family: "Poppins";
    font-weight: 400;
    line-height: 1;
    font-stretch: normal;
    overflow-wrap: break-word;
  }
  .text-data-entrada {
    color: #7D8A98;
    font-size: 14px;
    font-style: Regular;
    text-align: left;
    font-family: Poppins;
    font-weight: 400;
    line-height: 1;
    font-stretch: normal;
    text-decoration: none;
  }
  .container1 {
    width: 100%;
    display: flex;
    align-items: flex-start;
  }
  .status-usuario {
    gap: 8px;
    width: 100%;
    display: flex;
    min-width: 180px;
    align-self: stretch;
    align-items: center;
    padding-top: 8px;
    border-color: #DFE4E7;
    border-width: 1px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 0;
  }
  .iconinativo {
    width: 16px;
    display: none;
    object-fit: cover;
  }
  .iconcloqueado {
    width: 16px;
    display: none;
    object-fit: cover;
  }
  .iconchecked {
    width: 16px;
    object-fit: cover;
  }
  .text08 {
    color: #2D3E50;
    font-size: 14px;
    font-family: "Poppins";
  }
  .botao-ver-perfil {
    gap: 8px;
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: 12px;
    cursor: pointer;
    padding-left: 24px;
    padding-right: 20px;
    padding-bottom: 12px;
    justify-content: center;
    background-color: #F0F4F6;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .text09 {
    color: #2D3E50;
    font-size: 16px;
    font-family: "Poppins";
    font-weight: 500;
  }
  .iconarrow {
    width: 16px;
    object-fit: cover;
  }
  .infos-anuncio {
    gap: 24px;
    display: flex;
    overflow: hidden;
    flex-grow: 1;
    align-self: stretch;
    flex-direction: column;
  }
  .text-nome1 {
    color: #7d8a98;
    width: fit-content;
    font-size: 16px;
    font-style: SemiBold;
    text-align: left;
    font-family: "Poppins";
    font-weight: 600;
    line-height: 1;
  }
  .container2 {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    height: 50%;
    flex-direction: row;
  }
  .impressoes {
    gap: 16px;
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    max-width: 98px;
    align-items: flex-start;
    flex-direction: column;
  }
  .text10 {
    color: #7D8A98;
    font-size: 16px;
    font-family: "Poppins";
    line-height: 1;
  }
  .qntd {
    color: #2D3E50;
    font-size: 18px;
    font-family: "Poppins";
    font-weight: 600;
    line-height: 1;
  }
  .divisor {
    width: 1px;
    height: 100%;
    display: flex;
    align-items: flex-start;
    background-color: #F0F4F6;
  }
  .cliques {
    gap: 16px;
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    max-width: 98px;
    align-items: flex-start;
    flex-direction: column;
  }
  .text11 {
    color: rgb(125, 138, 152);
    font-size: 16px;
    font-family: "Poppins";
    line-height: 1;
  }
  .qntd1 {
    color: #2D3E50;
    font-size: 18px;
    font-family: "Poppins";
    font-weight: 600;
    line-height: 1;
  }
  .divisor1 {
    width: 1px;
    height: 100%;
    display: flex;
    align-items: flex-start;
    background-color: #F0F4F6;
  }
  .conversoes {
    gap: 16px;
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    max-width: 98px;
    align-items: flex-start;
    flex-direction: column;
  }
  .text12 {
    color: rgb(125, 138, 152);
    font-size: 16px;
    font-family: "Poppins";
    line-height: 1;
  }
  .qntds {
    gap: 8px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .qntd2 {
    color: #2D3E50;
    font-size: 18px;
    font-family: "Poppins";
    font-weight: 600;
    line-height: 1;
  }
  .qntd3 {
    color: #2D3E50;
    font-size: 18px;
    font-family: "Poppins";
    font-weight: 600;
    line-height: 1;
  }
  .container3 {
    width: 100%;
    display: flex;
    align-items: flex-end;
  }
  .status-anuncio {
    gap: 8px;
    width: 100%;
    display: flex;
    max-width: 180px;
    align-self: stretch;
    align-items: center;
    padding-top: 8px;
    border-color: #DFE4E7;
    border-width: 1px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 8px;
    justify-content: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 0;
  }
  .iconcloqueado1 {
    width: 16px;
    object-fit: cover;
  }
  .iconchecked1 {
    width: 16px;
    object-fit: cover;
  }
  .text13 {
    color: rgb(45, 62, 80);
    font-size: 14px;
    font-family: "Poppins";
    white-space: nowrap;
  }
  .botao-ver-anuncio {
    gap: 8px;
    width: 100%;
    display: flex;
    max-width: 242px;
    align-items: center;
    padding-top: 12px;
    cursor: pointer;
    padding-left: 24px;
    padding-right: 20px;
    padding-bottom: 12px;
    justify-content: center;
    background-color: #FCA600;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .text14 {
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-family: "Poppins";
    font-weight: 500;
  }
  .iconarrow1 {
    width: 16px;
    object-fit: cover;
  }
  
  
  
  @media(max-width: 1250px) {
    .anunciante {
      flex-direction: column;
    }
    .infos-usuario {
      max-width: 353px;
      align-self: center;
      border-width: 0px;
      padding-right: 0px;
    }
    .container {
      align-items: center;
    }
    .email {
      justify-content: center;
    }
    .telefone {
      justify-content: center;
    }
    .infos-anuncio {
      width: 100%;
      display: flex;
      position: relative;
      justify-content: space-between;
      flex-direction: column;
      align-self: center;
      align-items: stretch;
    }
    .container2 {
      width: 100%;
      justify-content: center;
    }
    .impressoes {
      align-items: center;
    }
    .cliques {
      align-items: center;
    }
    .conversoes {
      align-items: center;
    }
    .container3 {
      justify-content: center;
    }
  }
  @media(max-width: 767px) {
    .infos-usuario {
      width: 100%;
    }
    .text-nome {
      font-size: 19px;
      text-align: center;
    }
    .email {
      align-self: center;
      justify-content: center;
    }
    .text03 {
      width: fit-content;
    }
    .telefone {
      align-self: center;
      justify-content: center;
    }
    .text04 {
      width: fit-content;
    }
    .text-data-entrada {
      align-self: stretch;
      text-align: center;
    }
    .text09 {
      font-size: 15px;
    }
    .infos-anuncio {
      width: 100%;
    }
    .text-nome1 {
      font-size: 15px;
      text-align: center;
    }
    .text14 {
      font-size: 15px;
    }
  }
  @media(max-width: 479px) {

    .infos-usuario {
      max-width: 100%;
    }
    .text-nome {
      font-size: 18px;
    }
    .status-usuario {
      width: auto;
      min-width: auto;
    }
    .text08 {
      display: none;
    }
    .text09 {
      font-size: 14px;
    }
    .container2 {
      flex-wrap: wrap;
      justify-content: center;
    }
    .status-anuncio {
      width: auto;
      max-width: auto;
    }
    .text13 {
      display: none;
    }
    .botao-ver-anuncio {
      max-width: 100%;
    }
    .text14 {
      font-size: 14px;
    }
  }
  