.container {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.container1 {
  gap: 24px;
  width: 100%;
  display: flex;
  max-width: 1352px;
  align-self: center;
  align-items: flex-start;
  flex-shrink: 0;
  padding-top: 24px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 24px;
  justify-content: space-between;
}
.container2 {
  gap: 16px;
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  padding-top: 0px;
  padding-left: 16px;
  padding-right: 16px;
  flex-direction: column;
  padding-bottom: 92px;
  justify-content: center;
}
.cabecalho {
  gap: 10px;
  width: 100%;
  display: flex;
  padding: 0px 16px;
  overflow: hidden;
  flex-grow: 1;
  flex-wrap: wrap;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
}
.text {
  color: var(--dl-color-default-2d3e50);
  flex-grow: 1;
  font-size: 24px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  padding-top: 8px;
  font-stretch: normal;
  padding-bottom: 8px;
  text-decoration: none;
}
.botoes {
  gap: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.navlink {
  display: contents;
}
.botao-planos {
  gap: 8px;
  cursor: pointer;
  height: 40px;
  display: flex;
  padding: 8px 16px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-default-d8ffe6);
}
.text003 {
  color: var(--dl-color-default-2d3e50);
  font-size: 16px;
  font-style: Medium;
  text-align: center;
  font-family: Poppins;
  font-weight: 400;
  line-height: 100%;
  font-stretch: normal;
  text-decoration: none;
}
.text005 {
  color: #e98c00;
}
.crownicon {
  width: 24px;
  height: 24px;
}
.botao-visao-aluno {
  gap: 8px;
  cursor: pointer;
  display: flex;
  padding: 12px 20px 12px 24px;
  align-items: center;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-colorgreybg);
}
.text006 {
  color: var(--dl-color-default-2d3e50);
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 100%;
  font-stretch: normal;
  text-decoration: none;
}
.viewicon {
  width: 16px;
  height: 16px;
}
.anuncio-status {
  gap: 8px;
  cursor: pointer;
  height: 40px;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-f0f4f6);
}
.text008 {
  color: var(--dl-color-default-2d3e50);
  font-size: 16px;
  font-style: Regular;
  text-align: right;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.analiseicon {
  width: 16px;
  height: 16px;
  display: none;
}
.conteudo {
  gap: 40px;
  width: 100%;
  display: flex;
  max-width: 874px;
  align-self: center;
  align-items: flex-start;
  padding-top: 24px;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: column;
  padding-bottom: 0px;
  justify-content: center;
}
.perfil {
  gap: 36px;
  width: 100%;
  display: flex;
  padding: 40px;
  box-shadow: 2px 9px 40px 0px rgba(0, 0, 0, 0.09000000357627869);
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(222, 228, 230, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
  background-color: var(--dl-color-default-ffffff);
}
.text010 {
  color: var(--dl-color-default-padro);
  width: 100%;
  font-size: 24px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  font-stretch: normal;
  text-decoration: none;
}
.foto-perfil {
  gap: 10px;
  width: 209px;
  height: 209px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-self: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 200px;
  flex-direction: column;
  justify-content: flex-end;
}
.foto-galeria {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}
.sombra {
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  margin: auto;
  display: flex;
  position: absolute;
  min-height: 100px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 4%,
    rgba(0, 0, 0, 0.43) 100%
  );
}
.imgpersonal {
  width: 100%;
  height: 100%;
  cursor: pointer;
  object-fit: cover;
}
.editaricon {
  left: 0px;
  right: 0px;
  width: 32px;
  bottom: 024px;
  cursor: pointer;
  height: 32px;
  margin: auto;
  position: absolute;
}
.nome-denominacao {
  gap: 24px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.denominacao {
  gap: 10px;
  width: 100%;
  display: flex;
  max-width: 224px;
  align-self: flex-start;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.text012 {
  color: rgba(45, 62, 80, 1);
  width: 100%;
  font-size: 16px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.container_cidade_uf {
  display: flex;
  gap: 16px;
  width: 100%;
}
.container_cidade_uf [class^="Mui"]:hover,
.container_cidade_uf [class*="JoyAutocomplete"] {
  --Input-focusedHighlight: unset !important;
}
.dropdown_uf_container {
  gap: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  max-width: 170px;
}
.dropdown_uf {
  gap: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  outline: none;
  padding: 8px 16px !important;
  overflow: hidden;
  align-self: stretch;
  max-height: 40px;
  align-items: center;
  flex-shrink: 0;
  font-family: Poppins;
  border-color: rgba(216, 223, 232, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
  padding: 10px 16px;
}
.placeholder_uf {
  color: var(--dl-input-placeholder-color) !important;
}
.dropdown {
  gap: 10px;
  width: 100%;
  display: flex;
  padding: 10px 16px;
  overflow: hidden;
  align-self: stretch;
  max-height: 40px;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(216, 223, 232, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
}
.text014 {
  color: rgba(125, 138, 152, 1);
  width: 100%;
  flex-grow: 1;
  font-size: 16px;
  min-width: fit-content;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  font-stretch: normal;
  text-decoration: none;
}
.arrowicon {
  width: 24px;
  height: 24px;
}
.genero {
  gap: 10px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.text016 {
  color: rgba(45, 62, 80, 1);
  width: 100%;
  height: 100%;
  font-size: 16px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.opcoes {
  gap: 16px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.masculino {
  gap: 8px;
  display: flex;
  padding: 8px 12px;
  overflow: hidden;
  align-items: center;
  border-radius: 8px;
  background-color: var(--dl-color-default-f0f4f6);
  cursor: pointer;
}
.text018 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.feminino {
  gap: 8px;
  display: flex;
  padding: 8px 12px;
  overflow: hidden;
  align-items: center;
  border-radius: 8px;
  background-color: var(--dl-color-default-f0f4f6);
  cursor: pointer;
}
.text020 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.outro {
  gap: 8px;
  display: flex;
  padding: 8px 12px;
  overflow: hidden;
  align-items: center;
  border-radius: 8px;
  background-color: var(--dl-color-default-f0f4f6);
  cursor: pointer;
}
.text022 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.descrio {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.biografia {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.qualificacoes {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.redes-sociais {
  gap: 10px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}

.social-premium {
  gap: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  outline: none;
  padding: 10px 16px;
  overflow: hidden;
  align-self: stretch;
  max-height: 40px;
  align-items: center;
  flex-shrink: 0;
  font-family: Poppins;
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  border-color: rgba(232, 139, 0, 1);
  background-color: var(--dl-color-default-d8ffe6);
}

.input {
  gap: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  outline: none;
  padding: 10px 16px;
  overflow: hidden;
  align-self: stretch;
  max-height: 40px;
  align-items: center;
  flex-shrink: 0;
  font-family: Poppins;
  border-color: rgba(216, 223, 232, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
}

.text024 {
  color: rgba(45, 62, 80, 1);
  width: 100%;
  font-size: 16px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.inputs {
  gap: 10px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.insta {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  position: relative;
}
.social-icon {
  position: absolute;
  right: 0;
  margin-right: 20px;
  width: 24px;
  height: 24px;
}
.instaimg {
  width: 32px;
  height: 32px;
}
.face {
  gap: 8px;
  width: 100%;
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.faceimg {
  width: 32px;
  height: 32px;
}
.tiktok {
  gap: 8px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  position: relative;
}
.ttkimg {
  width: 32px;
  height: 32px;
}
.linked-in {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  position: relative;
}
.linkedinimg {
  width: 32px;
  height: 32px;
  border-radius: 35px;
}
.rede {
  gap: 8px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  position: relative;
  flex-shrink: 0;
}
.redeimg {
  width: 32px;
  height: 32px;
}
.servicos {
  gap: 36px;
  width: 100%;
  display: flex;
  box-shadow: 2px 9px 40px 0px rgba(0, 0, 0, 0.09000000357627869);
  align-items: flex-start;
  flex-shrink: 0;
  padding-top: 32px;
  border-color: rgba(222, 228, 230, 1);
  border-style: solid;
  border-width: 1px;
  padding-left: 32px;
  border-radius: 16px;
  padding-right: 32px;
  flex-direction: column;
  padding-bottom: 40px;
  background-color: var(--dl-color-default-ffffff);
}
.text026 {
  color: var(--dl-color-default-padro);
  width: 100%;
  font-size: 24px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  font-stretch: normal;
  text-decoration: none;
}
.categorias {
  gap: 16px;
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
  padding-bottom: 24px;
}
.cabecalho01 {
  gap: 10px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.titulo {
  gap: 10px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.text028 {
  color: rgba(45, 62, 80, 1);
  width: 100%;
  height: 100%;
  font-size: 16px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text-plano-free {
  color: var(--dl-color-default-7d8a98);
  width: 100%;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.navlink1 {
  display: contents;
}
.botao-planos1 {
  gap: 10px;
  cursor: pointer;
  display: flex;
  padding: 8px 16px;
  overflow: hidden;
  align-items: center;
  border-radius: 8px;
  text-decoration: none;
  background-color: var(--dl-color-default-d8ffe6);
}
.text031 {
  color: var(--dl-color-default-07a741);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.crownicon1 {
  width: 24px;
  height: 24px;
}
.opcoes1 {
  gap: 8px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
}
.opcao {
  gap: 16px;
  width: 100%;
  display: flex;
  padding: 24px;
  flex-grow: 1;
  max-width: 400px;
  align-self: stretch;
  align-items: flex-start;
  border-color: rgba(236, 239, 244, 1);
  border-width: 1px;
  border-radius: 20px;
  flex-direction: column;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 1);
}
.fullWidth {
  width: 100%;
}
.cabecalho02 {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.text033 {
  color: rgba(45, 62, 80, 1);
  width: 100%;
  flex-grow: 1;
  font-size: 18px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text034 {
  color: rgba(125, 138, 152, 1);
  width: 100%;
  font-size: 14px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.opcao01 {
  gap: 16px;
  width: 100%;
  display: flex;
  padding: 24px;
  flex-grow: 1;
  max-width: 400px;
  align-self: stretch;
  align-items: flex-start;
  border-color: rgba(236, 239, 244, 1);
  border-width: 1px;
  border-radius: 20px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}
.cabecalho03 {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.text036 {
  color: rgb(45, 62, 80);
  width: 100%;
  flex-grow: 1;
  font-size: 18px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text039 {
  color: rgb(125, 138, 152);
  width: 100%;
  font-size: 14px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.opcao02 {
  gap: 16px;
  width: 100%;
  display: flex;
  padding: 24px;
  flex-grow: 1;
  max-width: 400px;
  align-self: stretch;
  align-items: flex-start;
  border-color: rgba(236, 239, 244, 1);
  border-width: 1px;
  border-radius: 20px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}
.cabecalho04 {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.text040 {
  color: rgb(45, 62, 80);
  width: 100%;
  flex-grow: 1;
  font-size: 18px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text043 {
  color: rgb(125, 138, 152);
  width: 100%;
  font-size: 14px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.opcao03 {
  gap: 16px;
  width: 100%;
  display: flex;
  padding: 24px;
  flex-grow: 1;
  max-width: 400px;
  align-self: stretch;
  align-items: flex-start;
  border-color: rgba(236, 239, 244, 1);
  border-width: 1px;
  border-radius: 20px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}
.cabecalho05 {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.text044 {
  color: rgb(45, 62, 80);
  width: 100%;
  flex-grow: 1;
  font-size: 18px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text045 {
  color: rgb(125, 138, 152);
  width: 100%;
  font-size: 14px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.opcao04 {
  gap: 16px;
  width: 100%;
  display: flex;
  padding: 24px;
  flex-grow: 1;
  max-width: 400px;
  align-self: stretch;
  align-items: flex-start;
  border-color: rgba(236, 239, 244, 1);
  border-width: 1px;
  border-radius: 20px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}
.cabecalho06 {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.text046 {
  color: rgb(45, 62, 80);
  width: 100%;
  flex-grow: 1;
  font-size: 18px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text047 {
  color: rgb(125, 138, 152);
  width: 100%;
  font-size: 14px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.opcao05 {
  gap: 16px;
  width: 100%;
  display: flex;
  padding: 24px;
  flex-grow: 1;
  max-width: 400px;
  align-self: stretch;
  align-items: flex-start;
  border-color: rgba(236, 239, 244, 1);
  border-width: 1px;
  border-radius: 20px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}
.cabecalho07 {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.text048 {
  color: rgb(45, 62, 80);
  width: 100%;
  flex-grow: 1;
  font-size: 18px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text049 {
  color: rgb(125, 138, 152);
  width: 100%;
  font-size: 14px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.opcao06 {
  gap: 16px;
  width: 100%;
  display: flex;
  padding: 24px;
  flex-grow: 1;
  max-width: 400px;
  align-self: stretch;
  align-items: flex-start;
  border-color: rgba(236, 239, 244, 1);
  border-width: 1px;
  border-radius: 20px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}
.cabecalho08 {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.text050 {
  color: rgb(45, 62, 80);
  width: 100%;
  flex-grow: 1;
  font-size: 18px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text051 {
  color: rgb(125, 138, 152);
  width: 100%;
  font-size: 14px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.opcao07 {
  gap: 16px;
  width: 100%;
  display: flex;
  padding: 24px;
  flex-grow: 1;
  max-width: 400px;
  align-self: stretch;
  align-items: flex-start;
  border-color: rgba(236, 239, 244, 1);
  border-width: 1px;
  border-radius: 20px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}
.cabecalho09 {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.text052 {
  color: rgb(45, 62, 80);
  width: 100%;
  flex-grow: 1;
  font-size: 18px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text053 {
  color: rgb(125, 138, 152);
  width: 100%;
  font-size: 14px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.opcao08 {
  gap: 16px;
  width: 100%;
  display: flex;
  padding: 24px;
  flex-grow: 1;
  max-width: 400px;
  align-self: stretch;
  align-items: flex-start;
  border-color: rgba(236, 239, 244, 1);
  border-width: 1px;
  border-radius: 20px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}
.cabecalho10 {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.text054 {
  color: rgb(45, 62, 80);
  width: 100%;
  flex-grow: 1;
  font-size: 18px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text055 {
  color: rgb(125, 138, 152);
  width: 100%;
  font-size: 14px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.opcao09 {
  gap: 16px;
  width: 100%;
  display: flex;
  padding: 24px;
  flex-grow: 1;
  max-width: 400px;
  align-self: stretch;
  align-items: flex-start;
  border-color: rgba(236, 239, 244, 1);
  border-width: 1px;
  border-radius: 20px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}
.cabecalho11 {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.text056 {
  color: rgb(45, 62, 80);
  width: 100%;
  flex-grow: 1;
  font-size: 18px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text057 {
  color: rgb(125, 138, 152);
  width: 100%;
  font-size: 14px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.opcao-elite {
  gap: 16px;
  width: 100%;
  display: flex;
  padding: 24px;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  border-color: rgba(251, 166, 0, 1);
  border-width: 1px;
  border-radius: 20px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
  cursor: pointer;
}
.opcao-elite-off-border {
  gap: 16px;
  width: 100%;
  display: flex;
  padding: 24px;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  border-color: rgba(236, 239, 244, 1);
  border-width: 1px;
  border-radius: 20px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
  cursor: pointer;
}
.cabecalho12 {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.text058 {
  color: rgb(45, 62, 80);
  width: 100%;
  flex-grow: 1;
  font-size: 18px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.navlink2 {
  display: contents;
}
.crownicon2 {
  width: 24px;
  cursor: pointer;
  height: 24px;
  text-decoration: none;
}
.text059 {
  color: rgb(125, 138, 152);
  width: 100%;
  font-size: 14px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.metodos {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.atendimento {
  gap: 36px;
  width: 100%;
  display: flex;
  padding: 32px 32px 40px;
  box-shadow: 2px 9px 40px 0px rgba(0, 0, 0, 0.09000000357627869);
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(222, 228, 230, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
  background-color: var(--dl-color-default-ffffff);
}
.text060 {
  color: var(--dl-color-default-padro);
  width: 100%;
  font-size: 24px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  font-stretch: normal;
  text-decoration: none;
}
.tipo-atendimento {
  gap: 10px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.text062 {
  color: rgba(45, 62, 80, 1);
  width: 100%;
  height: 100%;
  font-size: 16px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.opcoes2 {
  gap: 16px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.presencial {
  gap: 8px;
  display: flex;
  padding: 8px 12px;
  overflow: hidden;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  background-color: var(--dl-color-default-f0f4f6);
}
.text064 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.online {
  gap: 8px;
  display: flex;
  padding: 8px 12px;
  overflow: hidden;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  background-color: #fff5db;
}
.text066 {
  color: rgb(45, 62, 80);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.navlink3 {
  display: contents;
}
.crownicon3 {
  width: 24px;
  cursor: pointer;
  height: 24px;
  text-decoration: none;
}
.genero-alunos {
  gap: 10px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.text067 {
  color: rgb(45, 62, 80);
  width: 100%;
  height: 100%;
  font-size: 16px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.opcoes3 {
  gap: 16px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.homens {
  gap: 8px;
  display: flex;
  padding: 8px 12px;
  overflow: hidden;
  align-items: center;
  border-radius: 8px;
  background-color: var(--dl-color-default-f0f4f6);
  cursor: pointer;
}
.text068 {
  color: rgb(45, 62, 80);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.mulheres {
  gap: 8px;
  display: flex;
  padding: 8px 12px;
  overflow: hidden;
  align-items: center;
  border-radius: 8px;
  background-color: var(--dl-color-default-f0f4f6);
  cursor: pointer;
}
.text071 {
  color: rgb(45, 62, 80);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.idade-alunos {
  gap: 10px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.text074 {
  color: rgb(45, 62, 80);
  width: 100%;
  height: 100%;
  font-size: 16px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.opcoes4 {
  gap: 16px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.criancas {
  gap: 8px;
  display: flex;
  padding: 8px 12px;
  overflow: hidden;
  align-items: center;
  border-radius: 8px;
  background-color: var(--dl-color-default-f0f4f6);
  cursor: pointer;
}
.text075 {
  color: rgb(45, 62, 80);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.adultos {
  gap: 8px;
  display: flex;
  padding: 8px 12px;
  overflow: hidden;
  align-items: center;
  border-radius: 8px;
  background-color: var(--dl-color-default-f0f4f6);
  cursor: pointer;
}
.text078 {
  color: rgb(45, 62, 80);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.idosos {
  gap: 8px;
  display: flex;
  padding: 8px 12px;
  overflow: hidden;
  align-items: center;
  border-radius: 8px;
  background-color: var(--dl-color-default-f0f4f6);
  cursor: pointer;
}
.text081 {
  color: rgb(45, 62, 80);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.nivel-alunos {
  gap: 10px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.text084 {
  color: rgb(45, 62, 80);
  width: 100%;
  height: 100%;
  font-size: 16px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.opcoes5 {
  gap: 16px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.iniciante {
  gap: 8px;
  display: flex;
  padding: 8px 12px;
  overflow: hidden;
  align-items: center;
  border-radius: 8px;
  background-color: var(--dl-color-default-f0f4f6);
  cursor: pointer;
}
.text085 {
  color: rgb(45, 62, 80);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.intermediario {
  gap: 8px;
  display: flex;
  padding: 8px 12px;
  overflow: hidden;
  align-items: center;
  border-radius: 8px;
  background-color: var(--dl-color-default-f0f4f6);
  cursor: pointer;
}
.text088 {
  color: rgb(45, 62, 80);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.avancado {
  gap: 8px;
  display: flex;
  padding: 8px 12px;
  overflow: hidden;
  align-items: center;
  border-radius: 8px;
  background-color: var(--dl-color-default-f0f4f6);
  cursor: pointer;
}
.text091 {
  color: rgb(45, 62, 80);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.periodo-dia-semana {
  gap: 10px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.text094 {
  color: rgb(45, 62, 80);
  width: 100%;
  height: 100%;
  font-size: 16px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.opcoes6 {
  gap: 16px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.manha {
  gap: 8px;
  display: flex;
  padding: 8px 12px;
  overflow: hidden;
  align-items: center;
  border-radius: 8px;
  background-color: var(--dl-color-default-f0f4f6);
  cursor: pointer;
}
.text095 {
  color: rgb(45, 62, 80);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.tarde {
  gap: 8px;
  display: flex;
  padding: 8px 12px;
  overflow: hidden;
  align-items: center;
  border-radius: 8px;
  background-color: var(--dl-color-default-f0f4f6);
  cursor: pointer;
}
.text098 {
  color: rgb(45, 62, 80);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.noite {
  gap: 8px;
  display: flex;
  padding: 8px 12px;
  overflow: hidden;
  align-items: center;
  border-radius: 8px;
  background-color: var(--dl-color-default-f0f4f6);
  cursor: pointer;
}
.text101 {
  color: rgb(45, 62, 80);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.separador {
  flex: 0 0 auto;
  width: 1px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  background-color: #dfe4e7;
}
.segunda {
  gap: 8px;
  display: flex;
  padding: 8px 12px;
  overflow: hidden;
  align-items: center;
  border-radius: 8px;
  background-color: var(--dl-color-default-f0f4f6);
  cursor: pointer;
}
.text104 {
  color: rgb(45, 62, 80);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.terca {
  gap: 8px;
  display: flex;
  padding: 8px 12px;
  overflow: hidden;
  align-items: center;
  border-radius: 8px;
  background-color: var(--dl-color-default-f0f4f6);
  cursor: pointer;
}
.text107 {
  color: rgb(45, 62, 80);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.quarta {
  gap: 8px;
  display: flex;
  padding: 8px 12px;
  overflow: hidden;
  align-items: center;
  border-radius: 8px;
  background-color: var(--dl-color-default-f0f4f6);
  cursor: pointer;
}
.text110 {
  color: rgb(45, 62, 80);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.quinta {
  gap: 8px;
  display: flex;
  padding: 8px 12px;
  overflow: hidden;
  align-items: center;
  border-radius: 8px;
  background-color: var(--dl-color-default-f0f4f6);
  cursor: pointer;
}
.text113 {
  color: rgb(45, 62, 80);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.sexta {
  gap: 8px;
  display: flex;
  padding: 8px 12px;
  overflow: hidden;
  align-items: center;
  border-radius: 8px;
  background-color: var(--dl-color-default-f0f4f6);
  cursor: pointer;
}
.text116 {
  color: rgb(45, 62, 80);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.sabado {
  gap: 8px;
  display: flex;
  padding: 8px 12px;
  overflow: hidden;
  align-items: center;
  border-radius: 8px;
  background-color: var(--dl-color-default-f0f4f6);
  cursor: pointer;
}
.text119 {
  color: rgb(45, 62, 80);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.domingo {
  gap: 8px;
  display: flex;
  padding: 8px 12px;
  overflow: hidden;
  align-items: center;
  border-radius: 8px;
  background-color: var(--dl-color-default-f0f4f6);
  cursor: pointer;
}
.text122 {
  color: rgb(45, 62, 80);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.onde-atendo {
  gap: 10px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.text125 {
  color: rgb(45, 62, 80);
  width: 100%;
  height: 100%;
  font-size: 16px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.opcoes7 {
  gap: 16px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.local-publico {
  gap: 8px;
  display: flex;
  padding: 8px 12px;
  overflow: hidden;
  align-items: center;
  border-radius: 8px;
  background-color: var(--dl-color-default-f0f4f6);
  cursor: pointer;
}
.text126 {
  color: rgb(45, 62, 80);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.casa-aluno {
  gap: 8px;
  display: flex;
  padding: 8px 12px;
  overflow: hidden;
  align-items: center;
  border-radius: 8px;
  background-color: var(--dl-color-default-f0f4f6);
  cursor: pointer;
}
.text129 {
  color: rgb(45, 62, 80);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.academias {
  gap: 8px;
  display: flex;
  padding: 8px 12px;
  overflow: hidden;
  align-items: center;
  border-radius: 8px;
  background-color: var(--dl-color-default-f0f4f6);
  cursor: pointer;
}
.text132 {
  color: rgb(45, 62, 80);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.local-proprio {
  gap: 8px;
  display: flex;
  padding: 8px 12px;
  overflow: hidden;
  align-items: center;
  border-radius: 8px;
  background-color: var(--dl-color-default-f0f4f6);
  cursor: pointer;
}
.text135 {
  color: rgb(45, 62, 80);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.idiomas {
  gap: 10px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.idiomas-adicionados {
  gap: 8px;
  display: flex;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.idioma {
  gap: 8px;
  height: 30px;
  display: flex;
  padding: 0 16px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(216, 223, 231, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 24px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.text138 {
  color: var(--dl-color-default-2d3e50);
  width: 100%;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
  cursor: pointer;
}
.removericon {
  width: 12px;
  cursor: pointer;
  height: 12px;
}
.idioma1 {
  gap: 8px;
  height: 30px;
  display: flex;
  padding: 0 16px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(216, 223, 231, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 24px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.text140 {
  color: var(--dl-color-default-2d3e50);
  width: 100%;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.removericon1 {
  width: 12px;
  cursor: pointer;
  height: 12px;
}
.idioma2 {
  gap: 8px;
  height: 30px;
  display: flex;
  padding: 0 16px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(216, 223, 231, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 24px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.text142 {
  color: var(--dl-color-default-2d3e50);
  width: 100%;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.removericon2 {
  width: 12px;
  cursor: pointer;
  height: 12px;
}
.idioma3 {
  gap: 8px;
  height: 30px;
  display: flex;
  padding: 0 16px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(216, 223, 231, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 24px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.text144 {
  color: var(--dl-color-default-2d3e50);
  width: 100%;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.removericon3 {
  width: 12px;
  cursor: pointer;
  height: 12px;
}
.planos {
  gap: 36px;
  width: 100%;
  display: flex;
  padding: 32px 32px 40px;
  box-shadow: 2px 9px 40px 0px rgba(0, 0, 0, 0.09000000357627869);
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(222, 228, 230, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
  background-color: var(--dl-color-default-ffffff);
}
.text146 {
  color: var(--dl-color-default-padro);
  width: 100%;
  font-size: 24px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  font-stretch: normal;
  text-decoration: none;
}
.valores {
  gap: 16px;
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.infos-pagamento {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.valor-hora {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  flex-direction: column;
  flex-shrink: 0;
}

.text148 {
  color: rgb(45, 62, 80);
  font-size: 16px;
  align-self: flex-start;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.pricing-container {
  display: flex;
  gap: 15px;
  flex-direction: row;
}
@media (max-width: 700px) {
  .pricing-container {
    flex-direction: column;
  } 
}
.presencial1 {
  width: 100%;
  min-width: 330px;
  gap: 8px;
  display: flex;
  padding: 8px 8px 8px 16px;
  flex-grow: 1;
  align-items: center;
  border-radius: 8px;
  justify-content: space-between;
  background-color: var(--dl-color-default-f0f4f6);
}
.text150 {
  color: var(--dl-color-default-7d8a98);
  width: 100%;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.online1 {
  min-width: 330px;
  gap: 8px;
  display: flex;
  padding: 8px 8px 8px 16px;
  width: 100%;
  align-items: center;
  border-radius: 8px;
  background-color: var(--dl-color-default-d8ffe6);
  justify-content: space-between;
}
.text152 {
  color: var(--dl-color-default-7d8a98);
  width: 100%;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.container3 {
  gap: 8px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navlink4 {
  display: contents;
}
.crownicon4 {
  width: 24px;
  height: 24px;
  text-decoration: none;
}
.midia {
  gap: 36px;
  width: 100%;
  display: flex;
  padding: 32px 32px 40px;
  box-shadow: 2px 9px 40px 0px rgba(0, 0, 0, 0.09000000357627869);
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(222, 228, 230, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
  background-color: var(--dl-color-default-ffffff);
}
.cabecalho13 {
  gap: 24px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: space-between;
}
.text154 {
  color: var(--dl-color-default-padro);
  font-size: 24px;
  align-self: center;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  font-stretch: normal;
  text-decoration: none;
}
.navlink5 {
  display: contents;
}
.botao-planos2 {
  gap: 10px;
  width: 100%;
  cursor: icon;
  display: flex;
  padding: 8px 16px;
  overflow: hidden;
  max-width: fit-content;
  align-items: center;
  border-radius: 8px;
  text-decoration: none;
  background-color: var(--dl-color-default-d8ffe6);
}
.text155 {
  color: var(--dl-color-default-07a741);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  font-stretch: normal;
  text-decoration: none;
}
.crownicon5 {
  width: 24px;
  height: 24px;
}
.enviar-video {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.botao-enviar {
  gap: 8px;
  cursor: pointer;
  display: flex;
  padding: 12px 20px 12px 24px;
  align-items: center;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-colorgreybg);
}
.text156 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 100%;
  font-stretch: normal;
  text-decoration: none;
}
.arrowicon1 {
  width: 16px;
  height: 16px;
}
.plusicon {
  width: 16px;
  height: 16px;
  display: none;
}
.text-config {
  color: rgba(125, 138, 152, 1);
  width: 100%;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.video-enviado {
  gap: 10px;
  width: 48%;
  display: flex;
  overflow: hidden;
  position: relative;
  max-width: 443px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  justify-content: center;
}
.video-enviado video {
  width: 100%;
  border-radius: 8px;
  height: auto;
}
.playicon {
  top: 0px;
  left: 0px;
  right: 0px;
  width: 32px;
  bottom: 0px;
  cursor: pointer;
  height: 32px;
  margin: auto;
  position: absolute;
  box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.09000000357627869);
}
.videoimg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.botao-deletar {
  gap: 5px;
  top: 8px;
  right: 8px;
  width: 40px;
  cursor: pointer;
  height: 40px;
  display: flex;
  padding: 8px 16px;
  z-index: 1;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-colorgreybg);
}
.trashicon {
  width: 16px;
  height: 16px;
}
.enviar-foto {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.botao-enviar1 {
  gap: 8px;
  cursor: pointer;
  display: flex;
  padding: 12px 20px 12px 24px;
  align-items: center;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-colorgreybg);
  cursor: pointer;
}
.text158 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 100%;
  font-stretch: normal;
  text-decoration: none;
}
.arrowicon2 {
  width: 16px;
  height: 16px;
}
.plusicon1 {
  width: 16px;
  height: 16px;
  display: none;
}
.text-config1 {
  color: rgba(125, 138, 152, 1);
  width: 100%;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.fotos-enviadas,
.videos-enviados {
  gap: 10px;
  width: 100%;
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-shrink: 0;
  background-color: rgba(255, 255, 255, 1);
}
.foto {
  gap: 10px;
  width: 194px;
  height: 194px;
  display: flex;
  padding: 8px;
  overflow: hidden;
  align-items: flex-end;
  flex-shrink: 0;
  border-radius: 8px;
  position: relative;
  flex-direction: column;
  background-size: cover;
}
.botao-deletar1 {
  gap: 5px;
  width: 40px;
  cursor: pointer;
  height: 40px;
  display: flex;
  padding: 8px 16px;
  z-index: 1;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  justify-content: center;
  position: absolute;
  background-color: var(--dl-color-default-colorgreybg);
  right: 16px;
  top: 16px;
  background-image: url("/external/vectori378-pdeb.svg");
  background-repeat: no-repeat;
  background-position: center;
}
.foto1 {
  gap: 10px;
  width: 194px;
  height: 194px;
  display: flex;
  padding: 8px;
  overflow: hidden;
  align-items: flex-end;
  flex-shrink: 0;
  border-radius: 8px;
  flex-direction: column;
  background-color: var(--dl-color-default-dfe4e7);
}
.botao-deletar2 {
  gap: 5px;
  width: 40px;
  cursor: pointer;
  height: 40px;
  display: flex;
  padding: 8px 16px;
  z-index: 1;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-colorgreybg);
}
.vector1 {
  width: 16px;
  height: 16px;
}
.foto2 {
  gap: 10px;
  width: 194px;
  height: 194px;
  display: flex;
  padding: 8px;
  overflow: hidden;
  align-items: flex-end;
  flex-shrink: 0;
  border-radius: 8px;
  flex-direction: column;
  background-color: var(--dl-color-default-dfe4e7);
}
.botao-deletar3 {
  gap: 5px;
  width: 40px;
  cursor: pointer;
  height: 40px;
  display: flex;
  padding: 8px 16px;
  z-index: 1;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-colorgreybg);
}
.vector2 {
  width: 16px;
  height: 16px;
}
.foto3 {
  gap: 10px;
  width: 194px;
  height: 194px;
  display: flex;
  padding: 8px;
  overflow: hidden;
  align-items: flex-end;
  flex-shrink: 0;
  border-radius: 8px;
  flex-direction: column;
  background-color: var(--dl-color-default-dfe4e7);
}
.botao-deletar4 {
  gap: 5px;
  width: 40px;
  cursor: pointer;
  height: 40px;
  display: flex;
  padding: 8px 16px;
  z-index: 1;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-colorgreybg);
}
.vector3 {
  width: 16px;
  height: 16px;
}
.botao-publicar {
  gap: 8px;
  cursor: pointer;
  display: flex;
  padding: 16px 24px 16px 32px;
  align-self: center;
  align-items: center;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-padro);
}
.text160 {
  color: var(--dl-color-default-white);
  font-size: 20px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 100%;
  font-stretch: normal;
  text-decoration: none;
}
.arrowicon3 {
  width: 20px;
  height: 20px;
}
@media (max-width: 991px) {
  .container1 {
    padding-bottom: 16px;
  }
  .container2 {
    gap: 24px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 108px;
  }
  .cabecalho {
    padding: 0px;
    grid-row-gap: 24px;
  }
  .text {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .conteudo {
    gap: 24px;
    max-width: 100%;
    border-color: #dfe4e7;
    border-width: 1px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
  }
  .perfil {
    padding: 0px;
    box-shadow: none;
    border-width: 0px;
  }
  .foto-perfil {
    width: 182px;
    cursor: pointer;
    height: 182px;
  }
  .editaricon {
    width: 30px;
    bottom: 020px;
    height: 30px;
  }
  .nome-denominacao {
    flex-direction: column;
  }
  .denominacao {
    max-width: 100%;
  }
  .servicos {
    box-shadow: none;
    padding-top: 24px;
    border-width: 0px;
    padding-left: 0px;
    border-radius: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    border-top-width: 1px;
  }
  .opcao {
    max-width: 100%;
  }
  .opcao01 {
    max-width: 100%;
  }
  .opcao02 {
    max-width: 100%;
  }
  .opcao03 {
    max-width: 100%;
  }
  .opcao04 {
    max-width: 100%;
  }
  .opcao05 {
    max-width: 100%;
  }
  .opcao06 {
    max-width: 100%;
  }
  .opcao07 {
    max-width: 100%;
  }
  .opcao08 {
    max-width: 100%;
  }
  .opcao09 {
    max-width: 100%;
  }
  .atendimento {
    box-shadow: none;
    padding-top: 24px;
    padding-left: 0px;
    border-radius: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
  }
  .planos {
    box-shadow: none;
    padding-top: 24px;
    padding-left: 0px;
    border-radius: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
  }
  .valor-hora {
    flex-wrap: wrap;
  }
  /* .presencial1 {
    width: 100%;
  } */
  .text150 {
    max-width: fit-content;
    text-align: center;
  }
  .online1 {
    width: 100%;
  }
  .text152 {
    max-width: fit-content;
    text-align: center;
  }
  .midia {
    box-shadow: none;
    padding-top: 24px;
    padding-left: 0px;
    border-radius: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
  }
  .text155 {
    color: var(--dl-color-default-07a741);
    font-size: 16px;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
  }
  .foto {
    width: 182px;
    height: 182px;
  }
  .foto1 {
    width: 182px;
    height: 182px;
  }
  .foto2 {
    width: 182px;
    height: 182px;
  }
  .foto3 {
    width: 182px;
    height: 182px;
  }
}
@media (max-width: 767px) {
  .cabecalho {
    align-self: center;
    flex-direction: column;
  }
  .text {
    font-size: 22px;
    align-self: flex-start;
  }
  .text001 {
    font-size: 22px;
  }
  .botoes {
    width: 100%;
    flex-direction: column;
  }
  .botao-planos {
    width: 100%;
  }
  .botao-visao-aluno {
    width: 100%;
  }
  .anuncio-status {
    width: 100%;
  }
  .text010 {
    font-size: 22px;
  }
  .foto-perfil {
    width: 155px;
    height: 155px;
  }
  .editaricon {
    width: 27px;
    bottom: 016px;
    height: 27px;
  }
  .text012 {
    font-size: 15px;
  }
  .text014 {
    font-size: 15px;
  }
  .text016 {
    font-size: 15px;
  }
  .text018 {
    font-size: 15px;
  }
  .text020 {
    font-size: 15px;
  }
  .text022 {
    font-size: 15px;
  }
  .text024 {
    font-size: 15px;
  }
  .instaimg {
    width: 28px;
    height: 28px;
  }
  .faceimg {
    width: 28px;
    height: 28px;
  }
  .ttkimg {
    width: 28px;
    height: 28px;
  }
  .linkedinimg {
    width: 28px;
    height: 28px;
  }
  .redeimg {
    width: 28px;
    height: 28px;
  }
  .text026 {
    font-size: 22px;
  }
  .text028 {
    font-size: 15px;
  }
  .text-plano-free {
    font-size: 13px;
  }
  .text033 {
    font-size: 17px;
  }
  .text036 {
    font-size: 17px;
  }
  .text040 {
    font-size: 17px;
  }
  .text044 {
    font-size: 17px;
  }
  .text046 {
    font-size: 17px;
  }
  .text048 {
    font-size: 17px;
  }
  .text050 {
    font-size: 17px;
  }
  .text052 {
    font-size: 17px;
  }
  .text054 {
    font-size: 17px;
  }
  .text056 {
    font-size: 17px;
  }
  .text058 {
    font-size: 17px;
  }
  .text060 {
    font-size: 22px;
  }
  .text062 {
    font-size: 15px;
  }
  .opcoes2 {
    gap: 12px;
  }
  .text064 {
    font-size: 15px;
  }
  .text066 {
    font-size: 15px;
  }
  .text067 {
    font-size: 15px;
  }
  .opcoes3 {
    gap: 12px;
  }
  .text068 {
    font-size: 15px;
  }
  .text071 {
    font-size: 15px;
  }
  .text074 {
    font-size: 15px;
  }
  .opcoes4 {
    gap: 12px;
  }
  .text075 {
    font-size: 15px;
  }
  .text078 {
    font-size: 15px;
  }
  .text081 {
    font-size: 15px;
  }
  .text084 {
    font-size: 15px;
  }
  .opcoes5 {
    gap: 12px;
  }
  .text085 {
    font-size: 15px;
  }
  .text088 {
    font-size: 15px;
  }
  .text091 {
    font-size: 15px;
  }
  .text094 {
    font-size: 15px;
  }
  .opcoes6 {
    gap: 12px;
  }
  .text095 {
    font-size: 15px;
  }
  .text098 {
    font-size: 15px;
  }
  .text101 {
    font-size: 15px;
  }
  .separador {
    width: 100%;
    height: 1px;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .text104 {
    font-size: 15px;
  }
  .text107 {
    font-size: 15px;
  }
  .text110 {
    font-size: 15px;
  }
  .text113 {
    font-size: 15px;
  }
  .text116 {
    font-size: 15px;
  }
  .text119 {
    font-size: 15px;
  }
  .text122 {
    font-size: 15px;
  }
  .text125 {
    font-size: 15px;
  }
  .opcoes7 {
    gap: 12px;
  }
  .text126 {
    font-size: 15px;
  }
  .text129 {
    font-size: 15px;
  }
  .text132 {
    font-size: 15px;
  }
  .text135 {
    font-size: 15px;
  }
  .text146 {
    font-size: 22px;
  }
  .text148 {
    font-size: 15px;
  }
  .text150 {
    font-size: 15px;
  }
  .text152 {
    font-size: 15px;
  }
  .text154 {
    font-size: 22px;
  }
  .arrowicon1 {
    display: none;
  }
  .plusicon {
    display: flex;
  }
  .text-config {
    font-size: 15px;
  }
  .arrowicon2 {
    display: none;
  }
  .plusicon1 {
    display: flex;
  }
  .text-config1 {
    font-size: 15px;
  }
  .foto {
    width: 170px;
    height: 170px;
  }
  .foto1 {
    width: 170px;
    height: 170px;
  }
  .foto2 {
    width: 170px;
    height: 170px;
  }
  .foto3 {
    width: 170px;
    height: 170px;
  }
  .text160 {
    font-size: 18px;
  }
  .arrowicon3 {
    width: 18px;
    height: 18px;
  }
}
@media (max-width: 479px) {
  .text {
    font-size: 20px;
  }
  .text001 {
    font-size: 20px;
  }
  .botao-planos {
    height: auto;
  }
  .text010 {
    font-size: 20px;
  }
  .foto-perfil {
    width: 128px;
    height: 128px;
  }
  .editaricon {
    width: 24px;
    bottom: 012px;
    height: 24px;
  }
  .text012 {
    font-size: 14px;
  }
  .text014 {
    font-size: 14px;
  }
  .text016 {
    font-size: 14px;
  }
  .text018 {
    font-size: 14px;
  }
  .text020 {
    font-size: 14px;
  }
  .text022 {
    font-size: 14px;
  }
  .text024 {
    font-size: 14px;
  }
  .text026 {
    font-size: 20px;
  }
  .titulo {
    width: 100%;
  }
  .text028 {
    font-size: 14px;
  }
  .text-plano-free {
    font-size: 12px;
  }
  .text033 {
    font-size: 16px;
  }
  .text036 {
    font-size: 16px;
  }
  .text040 {
    font-size: 16px;
  }
  .text044 {
    font-size: 16px;
  }
  .text046 {
    font-size: 16px;
  }
  .text048 {
    font-size: 16px;
  }
  .text050 {
    font-size: 16px;
  }
  .text052 {
    font-size: 16px;
  }
  .text054 {
    font-size: 16px;
  }
  .text056 {
    font-size: 16px;
  }
  .text058 {
    font-size: 16px;
  }
  .text060 {
    font-size: 20px;
  }
  .text062 {
    font-size: 14px;
  }
  .opcoes2 {
    gap: 8px;
  }
  .text064 {
    font-size: 14px;
  }
  .text066 {
    font-size: 14px;
  }
  .text067 {
    font-size: 14px;
  }
  .opcoes3 {
    gap: 8px;
  }
  .text068 {
    font-size: 14px;
  }
  .text071 {
    font-size: 14px;
  }
  .text074 {
    font-size: 14px;
  }
  .opcoes4 {
    gap: 8px;
  }
  .text075 {
    font-size: 14px;
  }
  .text078 {
    font-size: 14px;
  }
  .text081 {
    font-size: 14px;
  }
  .text084 {
    font-size: 14px;
  }
  .opcoes5 {
    gap: 8px;
  }
  .text085 {
    font-size: 14px;
  }
  .text088 {
    font-size: 14px;
  }
  .text091 {
    font-size: 14px;
  }
  .text094 {
    font-size: 14px;
  }
  .opcoes6 {
    gap: 8px;
  }
  .text095 {
    font-size: 14px;
  }
  .text098 {
    font-size: 14px;
  }
  .text101 {
    font-size: 14px;
  }
  .text104 {
    font-size: 14px;
  }
  .text107 {
    font-size: 14px;
  }
  .text110 {
    font-size: 14px;
  }
  .text113 {
    font-size: 14px;
  }
  .text116 {
    font-size: 14px;
  }
  .text119 {
    font-size: 14px;
  }
  .text122 {
    font-size: 14px;
  }
  .text125 {
    font-size: 14px;
  }
  .opcoes7 {
    gap: 8px;
  }
  .text126 {
    font-size: 14px;
  }
  .text129 {
    font-size: 14px;
  }
  .text132 {
    font-size: 14px;
  }
  .text135 {
    font-size: 14px;
  }
  .text146 {
    font-size: 20px;
  }
  .text148 {
    font-size: 14px;
  }
  .text150 {
    font-size: 14px;
  }
  .text152 {
    font-size: 14px;
    text-align: center;
  }
  .text154 {
    font-size: 20px;
  }
  .text-config {
    font-size: 14px;
  }
  .text-config1 {
    font-size: 14px;
  }
  .foto {
    width: 158px;
    height: 158px;
  }
  .foto1 {
    width: 158px;
    height: 158px;
  }
  .foto2 {
    width: 158px;
    height: 158px;
  }
  .foto3 {
    width: 158px;
    height: 158px;
  }
}

.btn-info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.btn-info p {
  font-size: 14px;
  color: #afafaf;
}

.link-perfil-container {
  gap: 8px;
  width: 100%;
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}

.togle-disabled {
  opacity: 50%;
  cursor: auto;
}
