.denunciar {
  width: 100%;
  border: none;
  height: 100%;
  display: flex;
  padding: 16px;
  z-index: 9999999999999999999999999999999999999999999999999999999999999;
  overflow: hidden;
  position: fixed;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-color: rgba(0, 0, 0, 0.41);
}
.container {
  gap: 32px;
  top: 50%;
  left: 50%;
  transform:translate(-50%,-50%);
  width: 100%;
  height: auto;
  display: flex;
  padding: 31px 32px 48px;
  position: absolute;
  max-width: 556px;
  align-self: center;
  box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.07999999821186066) ;
  align-items: center;
  border-color: rgba(216, 223, 232, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.cabecalho {
  gap: 24px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.text {
  color: rgba(45, 62, 80, 1);
  height: auto;
  flex-grow: 1;
  font-size: 20px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.fecharicon {
  width: 32px;
  cursor:pointer;
  height: 32px;
}
.motivo {
  gap: 10px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.text2 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text-area {
  gap: 10px;
  display: flex;
  padding: 16px;
  overflow: hidden;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(216, 223, 232, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
}
.textarea1 {
  color: rgba(125, 138, 152, 1);
  height: 114px;
  resize: none;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.botao-denunciar {
  gap: 5px;
  display: flex;
  padding: 12px 24px;
  align-items: center;
  cursor:pointer;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-padro);
}
.text4 {
  color: var(--dl-color-default-white);
  height: auto;
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 100%;
  font-stretch: normal;
  text-decoration: none;
}
.root-class-name {
  top: 0px;
  left: 0px;
  display: none;
  position: absolute;
}
@media(max-width: 767px) {
  .textarea1 {
    font-size: 15px;
  }
}
@media(max-width: 479px) {
  .textarea1 {
    font-size: 14px;
  }
}
