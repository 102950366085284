.container {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
  background-color: rgba(240, 244, 246, 1);
}
.container1 {
  width: 100%;
  height: auto;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: 24px;
  padding-left: 16px;
  padding-right: 16px;
  flex-direction: column;
  padding-bottom: 108px;
}
.conteudo {
  gap: 32px;
  width: 100%;
  display: flex;
  padding: 52px 32px;
  overflow: hidden;
  max-width: 1320px;
  align-self: center;
  align-items: center;
  border-radius: 8px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}
.text {
  color: rgba(45, 62, 80, 1);
  width: 100%;
  height: auto;
  font-size: 32px;
  font-style: SemiBold;
  text-align: center;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.listafaqs {
  gap: 16px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.pergunta {
  gap: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 24px;
  overflow: hidden;
  max-width: 596px;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(222, 228, 230, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--dl-color-default-ffffff);
}
.text02 {
  color: var(--dl-color-default-2d3e50);
  width: 100%;
  font-size: 20px;
  align-self: stretch;
  font-style: Bold;
  text-align: left;
  font-family: Poppins;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text04 {
  color: rgba(125, 138, 152, 1);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.pergunta01 {
  gap: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 24px;
  overflow: hidden;
  max-width: 596px;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(222, 228, 230, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--dl-color-default-ffffff);
}
.text06 {
  color: var(--dl-color-default-2d3e50);
  width: 100%;
  font-size: 20px;
  align-self: stretch;
  font-style: Bold;
  text-align: left;
  font-family: Poppins;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text08 {
  color: rgba(125, 138, 152, 1);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.pergunta02 {
  gap: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 24px;
  overflow: hidden;
  max-width: 596px;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(222, 228, 230, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--dl-color-default-ffffff);
}
.text10 {
  color: var(--dl-color-default-2d3e50);
  width: 100%;
  font-size: 20px;
  align-self: stretch;
  font-style: Bold;
  text-align: left;
  font-family: Poppins;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text12 {
  color: rgba(125, 138, 152, 1);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.pergunta03 {
  gap: 16px;
  width: 100%;
  display: flex;
  padding: 24px;
  overflow: hidden;
  max-width: 596px;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(222, 228, 230, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--dl-color-default-ffffff);
}
.text14 {
  color: var(--dl-color-default-2d3e50);
  width: 100%;
  font-size: 20px;
  align-self: stretch;
  font-style: Bold;
  text-align: left;
  font-family: Poppins;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text16 {
  color: rgba(125, 138, 152, 1);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.pergunta04 {
  gap: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 24px;
  overflow: hidden;
  max-width: 596px;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(222, 228, 230, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--dl-color-default-ffffff);
}
.text18 {
  color: var(--dl-color-default-2d3e50);
  width: 100%;
  font-size: 20px;
  align-self: stretch;
  font-style: Bold;
  text-align: left;
  font-family: Poppins;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text20 {
  color: rgba(125, 138, 152, 1);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.pergunta05 {
  gap: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 24px;
  overflow: hidden;
  max-width: 596px;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(222, 228, 230, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--dl-color-default-ffffff);
}
.text22 {
  color: var(--dl-color-default-2d3e50);
  width: 100%;
  font-size: 20px;
  align-self: stretch;
  font-style: Bold;
  text-align: left;
  font-family: Poppins;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text24 {
  color: rgba(125, 138, 152, 1);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.pergunta06 {
  gap: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 24px;
  overflow: hidden;
  max-width: 596px;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(222, 228, 230, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--dl-color-default-ffffff);
}
.text26 {
  color: var(--dl-color-default-2d3e50);
  width: 100%;
  font-size: 20px;
  align-self: stretch;
  font-style: Bold;
  text-align: left;
  font-family: Poppins;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text28 {
  color: rgba(125, 138, 152, 1);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.pergunta07 {
  gap: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 24px;
  overflow: hidden;
  max-width: 596px;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(222, 228, 230, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--dl-color-default-ffffff);
}
.text30 {
  color: var(--dl-color-default-2d3e50);
  width: 100%;
  font-size: 20px;
  align-self: stretch;
  font-style: Bold;
  text-align: left;
  font-family: Poppins;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text32 {
  color: rgba(125, 138, 152, 1);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.pergunta08 {
  gap: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 24px;
  overflow: hidden;
  max-width: 596px;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(222, 228, 230, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--dl-color-default-ffffff);
}
.text34 {
  color: var(--dl-color-default-2d3e50);
  width: 100%;
  font-size: 20px;
  align-self: stretch;
  font-style: Bold;
  text-align: left;
  font-family: Poppins;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text36 {
  color: rgba(125, 138, 152, 1);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.pergunta09 {
  gap: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 24px;
  overflow: hidden;
  max-width: 596px;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(222, 228, 230, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--dl-color-default-ffffff);
}
.text38 {
  color: var(--dl-color-default-2d3e50);
  width: 100%;
  font-size: 20px;
  align-self: stretch;
  font-style: Bold;
  text-align: left;
  font-family: Poppins;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text40 {
  color: rgba(125, 138, 152, 1);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.pergunta10 {
  gap: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 24px;
  overflow: hidden;
  max-width: 596px;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(222, 228, 230, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--dl-color-default-ffffff);
}
.text42 {
  color: var(--dl-color-default-2d3e50);
  width: 100%;
  font-size: 20px;
  align-self: stretch;
  font-style: Bold;
  text-align: left;
  font-family: Poppins;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text44 {
  color: rgba(125, 138, 152, 1);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.pergunta11 {
  gap: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 24px;
  overflow: hidden;
  max-width: 596px;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(222, 228, 230, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--dl-color-default-ffffff);
}
.text46 {
  color: var(--dl-color-default-2d3e50);
  width: 100%;
  font-size: 20px;
  align-self: stretch;
  font-style: Bold;
  text-align: left;
  font-family: Poppins;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text48 {
  color: rgba(125, 138, 152, 1);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.pergunta12 {
  gap: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 24px;
  overflow: hidden;
  max-width: 596px;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(222, 228, 230, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--dl-color-default-ffffff);
}
.text50 {
  color: var(--dl-color-default-2d3e50);
  width: 100%;
  font-size: 20px;
  align-self: stretch;
  font-style: Bold;
  text-align: left;
  font-family: Poppins;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text52 {
  color: rgba(125, 138, 152, 1);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.pergunta13 {
  gap: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 24px;
  overflow: hidden;
  max-width: 596px;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(222, 228, 230, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--dl-color-default-ffffff);
}
.text54 {
  color: var(--dl-color-default-2d3e50);
  width: 100%;
  font-size: 20px;
  align-self: stretch;
  font-style: Bold;
  text-align: left;
  font-family: Poppins;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text56 {
  color: rgba(125, 138, 152, 1);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
@media(max-width: 991px) {
  .container1 {
    padding-bottom: 80px;
  }
  .conteudo {
    gap: 24px;
    padding: 0px;
    background-color: transparent;
  }
  .text {
    font-size: 28px;
  }
  .pergunta {
    max-width: 100%;
  }
  .pergunta01 {
    max-width: 100%;
  }
  .pergunta02 {
    max-width: 100%;
  }
  .pergunta03 {
    max-width: 100%;
  }
  .pergunta04 {
    max-width: 100%;
  }
  .pergunta05 {
    max-width: 100%;
  }
  .pergunta06 {
    max-width: 100%;
  }
  .pergunta07 {
    max-width: 100%;
  }
  .pergunta08 {
    max-width: 100%;
  }
  .pergunta09 {
    max-width: 100%;
  }
  .pergunta10 {
    max-width: 100%;
  }
  .pergunta11 {
    max-width: 100%;
  }
  .pergunta12 {
    max-width: 100%;
  }
  .pergunta13 {
    max-width: 100%;
  }
}
@media(max-width: 767px) {
  .text {
    font-size: 24px;
  }
  .text02 {
    font-size: 18px;
  }
  .text04 {
    font-size: 15px;
  }
  .text06 {
    font-size: 18px;
  }
  .text08 {
    font-size: 15px;
  }
  .text10 {
    font-size: 18px;
  }
  .text12 {
    font-size: 15px;
  }
  .text14 {
    font-size: 18px;
  }
  .text16 {
    font-size: 15px;
  }
  .text18 {
    font-size: 18px;
  }
  .text20 {
    font-size: 15px;
  }
  .text22 {
    font-size: 18px;
  }
  .text24 {
    font-size: 15px;
  }
  .text26 {
    font-size: 18px;
  }
  .text28 {
    font-size: 15px;
  }
  .text30 {
    font-size: 18px;
  }
  .text32 {
    font-size: 15px;
  }
  .text34 {
    font-size: 18px;
  }
  .text36 {
    font-size: 15px;
  }
  .text38 {
    font-size: 18px;
  }
  .text40 {
    font-size: 15px;
  }
  .text42 {
    font-size: 18px;
  }
  .text44 {
    font-size: 15px;
  }
  .text46 {
    font-size: 18px;
  }
  .text48 {
    font-size: 15px;
  }
  .text50 {
    font-size: 18px;
  }
  .text52 {
    font-size: 15px;
  }
  .text54 {
    font-size: 18px;
  }
  .text56 {
    font-size: 15px;
  }
}
@media(max-width: 479px) {
  .text {
    font-size: 20px;
  }
  .text02 {
    font-size: 16px;
  }
  .text04 {
    font-size: 14px;
  }
  .text06 {
    font-size: 16px;
  }
  .text08 {
    font-size: 14px;
  }
  .text10 {
    font-size: 16px;
  }
  .text12 {
    font-size: 14px;
  }
  .text14 {
    font-size: 16px;
  }
  .text16 {
    font-size: 14px;
  }
  .text18 {
    font-size: 16px;
  }
  .text20 {
    font-size: 14px;
  }
  .text22 {
    font-size: 16px;
  }
  .text24 {
    font-size: 14px;
  }
  .text26 {
    font-size: 16px;
  }
  .text28 {
    font-size: 14px;
  }
  .text30 {
    font-size: 16px;
  }
  .text32 {
    font-size: 14px;
  }
  .text34 {
    font-size: 16px;
  }
  .text36 {
    font-size: 14px;
  }
  .text38 {
    font-size: 16px;
  }
  .text40 {
    font-size: 14px;
  }
  .text42 {
    font-size: 16px;
  }
  .text44 {
    font-size: 14px;
  }
  .text46 {
    font-size: 16px;
  }
  .text48 {
    font-size: 14px;
  }
  .text50 {
    font-size: 16px;
  }
  .text52 {
    font-size: 14px;
  }
  .text54 {
    font-size: 16px;
  }
  .text56 {
    font-size: 14px;
  }
}
