.container {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
  background-color: rgba(240, 244, 246, 1);
}
.container1 {
  gap: 24px;
  width: 100%;
  height: auto;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  padding-top: 24px;
  padding-left: 16px;
  padding-right: 16px;
  flex-direction: column;
  padding-bottom: 108px;
}
.conteudo {
  gap: 52px;
  width: 100%;
  display: flex;
  padding: 52px 32px;
  flex-wrap: wrap;
  max-width: 1320px;
  align-self: center;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: center;
}
.contato {
  gap: 24px;
  flex: 1;
  width: 100%;
  display: flex;
  padding: 52px;
  box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.09000000357627869);
  align-items: center;
  flex-shrink: 0;
  border-radius: 16px;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-default-ffffff);
}
.text {
  color: rgba(45, 62, 80, 1);
  width: 100%;
  font-size: 32px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: center;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.emailicon {
  width: 50px;
  height: 50px;
}
.text2 {
  color: var(--dl-color-default-7d8a98);
  width: 100%;
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: center;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.contatoemail {
  display: flex;
  padding: 16px;
  align-items: center;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-f0f4f6);
}
.text4 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: center;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.email {
  gap: 24px;
  flex: 2;
  width: 100%;
  display: flex;
  padding: 32px;
  overflow: hidden;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(216, 223, 232, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}
.text6 {
  color: var(--dl-color-default-7d8a98);
  width: 100%;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Inter;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.inputs {
  gap: 24px;
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.botao-enviar {
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: flex-end;
}
.buttons {
  gap: 5px;
  width: 146px;
  display: flex;
  padding: 12px 24px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-padro);
  cursor: pointer;
}
.text8 {
  color: var(--dl-color-default-white);
  height: auto;
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 100%;
  font-stretch: normal;
  text-decoration: none;
}
@media (max-width: 991px) {
  .container1 {
    padding-bottom: 80px;
  }
  .conteudo {
    gap: 24px;
    padding: 0px;
    flex-direction: column;
  }
  .contato {
    padding-top: 40px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 40px;
  }
  .text {
    font-size: 28px;
  }
  .email {
    padding: 24px;
  }
  .text8 {
    color: var(--dl-color-default-white);
    font-size: 16px;
    font-family: Poppins;
    font-weight: 500;
    line-height: 100%;
    text-decoration: none;
  }
}
@media (max-width: 767px) {
  .text {
    font-size: 24px;
  }
  .text2 {
    font-size: 15px;
  }
  .text4 {
    font-size: 15px;
  }
  .text6 {
    font-size: 15px;
  }
}
@media (max-width: 479px) {
  .text {
    font-size: 20px;
  }
  .text2 {
    font-size: 14px;
  }
  .text4 {
    font-size: 14px;
  }
  .text6 {
    font-size: 14px;
  }
}
