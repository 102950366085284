.anuncio-aprovar {
  gap: 40px;
  width: 100%;
  height: auto;
  display: flex;
  padding: 48px;
  overflow: hidden;
  align-self: stretch;
  box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.09000000357627869);
  align-items: center;
  border-radius: 8px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}
.info-pessoais {
  gap: 24px;
  width: 100%;
  display: flex;
  padding: 24px 0;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 8px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.container3 {
  gap: 24px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.column {
  gap: 24px;
  display: flex;
  align-items: center;
  border-radius: 16px;
  flex-direction: column;
}
.container4 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.foto-perfil5 {
  width: 262px;
  height: 262px;
  display: flex;
  overflow: hidden;
  position: relative;
  box-sizing: content-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(251, 166, 0, 1);
  border-style: solid;
  border-width: 5px;
  border-radius: 200px;
}
.imgpersonal {
  width: 100%;
  height: 100%;
}
.cidade {
  gap: 8px;
  height: 32px;
  display: flex;
  padding: 0 16px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-radius: 24px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.localicon {
  width: 14px;
  height: 14px;
}
.text046 {
  color: var(--dl-color-default-2d3e50);
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.numero {
  gap: 8px;
  height: 32px;
  display: flex;
  padding: 0 16px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-radius: 24px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.telefoneicon {
  width: 14px;
  height: 14px;
}
.numero1 {
  color: var(--dl-color-default-2d3e50);
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.column1 {
  gap: 32px;
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  padding-top: 0px;
  padding-left: 24px;
  border-radius: 16px;
  padding-right: 24px;
  flex-direction: column;
  padding-bottom: 0px;
}
.infos5 {
  gap: 32px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.nome {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.text-denominacao {
  color: var(--dl-color-default-2d3e50);
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: 130%;
  font-stretch: normal;
  text-decoration: none;
}
.text-nome5 {
  color: var(--dl-color-default-2d3e50);
  flex-grow: 1;
  font-size: 24px;
  font-style: normal;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: 130%;
  font-stretch: normal;
  text-decoration: none;
}
.text-descricao5 {
  color: var(--dl-color-default-7d8a98);
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.atendimento {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.text054 {
  color: rgba(45, 62, 80, 1);
  font-size: 18px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.infos6 {
  gap: 8px;
  display: flex;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.frame1171276959 {
  gap: 8px;
  height: 32px;
  display: flex;
  padding: 0 16px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-radius: 24px;
  justify-content: center;
  background-color: var(--dl-color-default-f0f4f6);
}
.text056 {
  color: var(--dl-color-default-2d3e50);
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.frame1171277046 {
  gap: 8px;
  height: 32px;
  display: flex;
  padding: 0 16px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-radius: 24px;
  justify-content: center;
  background-color: var(--dl-color-default-f0f4f6);
}
.text058 {
  color: var(--dl-color-default-2d3e50);
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.frame1171277047 {
  gap: 8px;
  height: 32px;
  display: flex;
  padding: 0 16px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-radius: 24px;
  justify-content: center;
  background-color: var(--dl-color-default-f0f4f6);
}
.text060 {
  color: var(--dl-color-default-2d3e50);
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.frame1171277048 {
  gap: 8px;
  height: 32px;
  display: flex;
  padding: 0 16px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-radius: 24px;
  justify-content: center;
  background-color: var(--dl-color-default-f0f4f6);
}
.text062 {
  color: var(--dl-color-default-2d3e50);
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.frame1171277045 {
  gap: 8px;
  height: 32px;
  display: flex;
  padding: 0 16px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-radius: 24px;
  justify-content: center;
  background-color: var(--dl-color-default-f0f4f6);
}
.text064 {
  color: var(--dl-color-default-2d3e50);
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.frame1171277049 {
  gap: 8px;
  height: 32px;
  display: flex;
  padding: 0 16px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-radius: 24px;
  justify-content: center;
  background-color: var(--dl-color-default-f0f4f6);
}
.text066 {
  color: var(--dl-color-default-2d3e50);
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.frame1171277050 {
  gap: 8px;
  height: 32px;
  display: flex;
  padding: 0 16px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-radius: 24px;
  justify-content: center;
  background-color: var(--dl-color-default-f0f4f6);
}
.text068 {
  color: var(--dl-color-default-2d3e50);
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.linguagem {
  gap: 8px;
  height: 32px;
  display: flex;
  padding: 0 16px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(216, 223, 231, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 24px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.idiomaicon {
  width: 18px;
  height: 18px;
}
.text070 {
  color: var(--dl-color-default-2d3e50);
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.redes-sociais {
  gap: 10px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.text072 {
  color: rgba(45, 62, 80, 1);
  width: 100%;
  font-size: 20px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.inputs {
  gap: 10px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.insta {
  gap: 8px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.instaimg {
  width: 32px;
  height: 32px;
}
.face {
  gap: 8px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.faceimg {
  width: 32px;
  height: 32px;
}
.tiktok {
  gap: 8px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.ttkimg {
  width: 32px;
  height: 32px;
}
.linked-in {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.linkedinimg {
  width: 32px;
  height: 32px;
  border-radius: 35px;
}
.rede {
  gap: 8px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.redeimg {
  width: 32px;
  height: 32px;
}
.objetivos {
  gap: 24px;
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.text073 {
  color: rgba(45, 62, 80, 1);
  width: 100%;
  font-size: 20px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.container5 {
  gap: 8px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.objetivo {
  gap: 16px;
  width: 100%;
  display: flex;
  padding: 24px;
  max-width: 370px;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(222, 228, 230, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
  justify-content: flex-start;
}
.text075 {
  color: rgba(45, 62, 80, 1);
  font-size: 16px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.objetivo1 {
  gap: 16px;
  width: 100%;
  display: flex;
  padding: 24px;
  max-width: 370px;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(222, 228, 230, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
  justify-content: flex-start;
}
.text077 {
  color: rgba(45, 62, 80, 1);
  font-size: 16px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.objetivo2 {
  gap: 16px;
  width: 100%;
  display: flex;
  padding: 24px;
  max-width: 370px;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(222, 228, 230, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
  justify-content: flex-start;
}
.text079 {
  color: rgba(45, 62, 80, 1);
  font-size: 16px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.objetivo3 {
  gap: 16px;
  width: 100%;
  display: flex;
  padding: 24px;
  max-width: 370px;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(222, 228, 230, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
  justify-content: flex-start;
}
.text081 {
  color: rgba(45, 62, 80, 1);
  font-size: 16px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.objetivo-extra {
  gap: 16px;
  width: 100%;
  display: flex;
  padding: 24px;
  max-width: 370px;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(251, 166, 0, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
  justify-content: flex-start;
}
.text083 {
  color: rgba(45, 62, 80, 1);
  font-size: 16px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.infos-sobre {
  gap: 72px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  align-items: flex-start;
  padding-top: 24px;
  flex-direction: row;
  padding-bottom: 24px;
  grid-column-gap: 48px;
}
.sobre {
  gap: 24px;
  width: 100%;
  display: flex;
  flex-grow: 1;
  max-width: 438px;
  align-items: flex-start;
  border-radius: 8px;
  flex-direction: column;
}
.text085 {
  color: rgba(45, 62, 80, 1);
  width: 100%;
  font-size: 20px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text087 {
  color: rgba(125, 138, 152, 1);
  width: 100%;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.metodos {
  gap: 24px;
  width: 100%;
  display: flex;
  flex-grow: 1;
  max-width: 438px;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.text089 {
  color: rgba(45, 62, 80, 1);
  width: 100%;
  font-size: 20px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text091 {
  color: rgba(125, 138, 152, 1);
  width: 100%;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.qualificacoes {
  gap: 24px;
  width: 100%;
  display: flex;
  flex-grow: 1;
  max-width: 438px;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.text093 {
  color: rgba(45, 62, 80, 1);
  width: 100%;
  font-size: 20px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text095 {
  color: rgba(125, 138, 152, 1);
  width: 100%;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.planos {
  gap: 24px;
  width: 100%;
  display: flex;
  flex-grow: 1;
  max-width: 438px;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.text104 {
  color: rgba(45, 62, 80, 1);
  width: 100%;
  font-size: 20px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text106 {
  color: rgba(125, 138, 152, 1);
  width: 100%;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.online-presencial {
  gap: 8px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.online {
  gap: 8px;
  height: 40px;
  display: flex;
  padding: 0 16px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(216, 223, 231, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 24px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.text108 {
  color: var(--dl-color-default-2d3e50);
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text109 {
  font-weight: 400;
}
.text110 {
  font-style: normal;
  font-weight: 600;
}
.presencial {
  gap: 8px;
  height: 40px;
  display: flex;
  padding: 0 16px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(216, 223, 231, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 24px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.text111 {
  color: var(--dl-color-default-2d3e50);
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text112 {
  font-weight: 400;
}
.text113 {
  font-style: normal;
  font-weight: 600;
}
.midia {
  gap: 24px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.text114 {
  color: rgba(45, 62, 80, 1);
  width: 100%;
  font-size: 20px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.lista1 {
  gap: 10px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  background-color: rgba(255, 255, 255, 1);
}
.midia1 {
  gap: 10px;
  width: 443px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  justify-content: center;
}
.playicon {
  top: 0px;
  left: 0px;
  right: 0px;
  width: 32px;
  bottom: 0px;
  cursor: pointer;
  height: 32px;
  margin: auto;
  position: absolute;
  box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.09000000357627869);
}
.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.midia2 {
  width: 248px;
  height: 248px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 8px;
  background-size: cover;
}
.midia3 {
  width: 248px;
  height: 248px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 8px;
  background-size: cover;
}
.midia4 {
  width: 248px;
  height: 248px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 8px;
  background-size: cover;
}
.midia5 {
  width: 248px;
  height: 248px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 8px;
  background-size: cover;
}
.midia6 {
  width: 248px;
  height: 248px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 8px;
  background-size: cover;
}
.midia7 {
  width: 248px;
  height: 248px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 8px;
  background-size: cover;
}
.palavras-chave {
  gap: 24px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 24px;
}
.text116 {
  color: rgb(45, 62, 80);
  width: 100%;
  font-size: 20px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.lista2 {
  gap: 8px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.tag {
  gap: 8px;
  height: 30px;
  display: flex;
  padding: 0 12px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(216, 223, 231, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 24px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.text117 {
  color: rgba(125, 138, 152, 1);
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.tag01 {
  gap: 8px;
  height: 30px;
  display: flex;
  padding: 0 12px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(216, 223, 231, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 24px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.text118 {
  color: rgba(125, 138, 152, 1);
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.tag02 {
  gap: 8px;
  height: 30px;
  display: flex;
  padding: 0 12px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(216, 223, 231, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 24px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.text120 {
  color: rgba(125, 138, 152, 1);
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.tag03 {
  gap: 8px;
  height: 30px;
  display: flex;
  padding: 0 12px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(216, 223, 231, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 24px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.text122 {
  color: rgba(125, 138, 152, 1);
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.tag04 {
  gap: 8px;
  height: 30px;
  display: flex;
  padding: 0 12px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(216, 223, 231, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 24px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.text124 {
  color: rgba(125, 138, 152, 1);
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.tag05 {
  gap: 8px;
  height: 30px;
  display: flex;
  padding: 0 12px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(216, 223, 231, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 24px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.text126 {
  color: rgba(125, 138, 152, 1);
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.tag06 {
  gap: 8px;
  height: 30px;
  display: flex;
  padding: 0 12px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(216, 223, 231, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 24px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.text128 {
  color: rgba(125, 138, 152, 1);
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.tag07 {
  gap: 8px;
  height: 30px;
  display: flex;
  padding: 0 12px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(216, 223, 231, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 24px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.text130 {
  color: rgba(125, 138, 152, 1);
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.tag08 {
  gap: 8px;
  height: 30px;
  display: flex;
  padding: 0 12px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(216, 223, 231, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 24px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.text132 {
  color: rgba(125, 138, 152, 1);
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.tag09 {
  gap: 8px;
  height: 30px;
  display: flex;
  padding: 0 12px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(216, 223, 231, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 24px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.text134 {
  color: rgba(125, 138, 152, 1);
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.tag10 {
  gap: 8px;
  height: 30px;
  display: flex;
  padding: 0 12px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(216, 223, 231, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 24px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.text136 {
  color: rgba(125, 138, 152, 1);
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

@media (max-width: 991px) {
  .container1 {
    padding-bottom: 16px;
  }
  .container2 {
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 108px;
  }
  .lista {
    gap: 24px;
  }
  .anuncio-novo {
    padding-top: 0px;
    padding-left: 0px;
    border-radius: 0px;
    padding-right: 0px;
    padding-bottom: 24px;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .foto-perfil {
    width: 120px;
    height: 120px;
  }
  .anuncio-novo1 {
    padding-top: 0px;
    padding-left: 0px;
    border-radius: 0px;
    padding-right: 0px;
    padding-bottom: 24px;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .foto-perfil1 {
    width: 120px;
    height: 120px;
  }
  .anuncio-novo2 {
    padding-top: 0px;
    padding-left: 0px;
    border-radius: 0px;
    padding-right: 0px;
    padding-bottom: 24px;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .foto-perfil2 {
    width: 120px;
    height: 120px;
  }
  .anuncio-novo3 {
    padding-top: 0px;
    padding-left: 0px;
    border-radius: 0px;
    padding-right: 0px;
    padding-bottom: 24px;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .foto-perfil3 {
    width: 120px;
    height: 120px;
  }
  .anuncio-novo4 {
    padding-top: 0px;
    padding-left: 0px;
    border-radius: 0px;
    padding-right: 0px;
    padding-bottom: 24px;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .foto-perfil4 {
    width: 120px;
    height: 120px;
  }
  .anuncio-aprovar {
    padding: 0px;
    box-shadow: none;
  }
  .info-pessoais {
    position: relative;
    padding-top: 0px;
  }
  .container3 {
    align-items: center;
    flex-direction: column;
  }
  .column {
    gap: 16px;
    width: 100%;
    position: relative;
  }
  .numero {
    display: none;
  }
  .column1 {
    gap: 24px;
    padding: 0px;
  }
  .infos5 {
    gap: 16px;
    border-color: var(--dl-color-default-f0f4f6);
    border-width: 1px;
    padding-bottom: 24px;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .nome {
    align-self: center;
  }
  .text-denominacao {
    align-self: center;
  }
  .text-nome5 {
    align-self: center;
  }
  .text-descricao5 {
    text-align: center;
  }
  .infos-sobre {
    gap: 48px;
  }
  .midia1 {
    width: 402px;
  }
  .midia2 {
    width: 225px;
    height: 225px;
  }
  .midia3 {
    width: 225px;
    height: 225px;
  }
  .midia4 {
    width: 225px;
    height: 225px;
  }
  .midia5 {
    width: 225px;
    height: 225px;
  }
  .midia6 {
    width: 225px;
    height: 225px;
  }
  .midia7 {
    width: 225px;
    height: 225px;
  }
}
@media (max-width: 767px) {
  .cabecalho {
    gap: 16px;
    flex-direction: column;
  }
  .titulo {
    width: 100%;
  }
  .text {
    font-size: 22px;
  }
  .textqntd {
    font-size: 15px;
  }
  .botoes-anuncio-unico {
    width: 100%;
    flex-direction: column;
  }
  .plano {
    width: 100%;
  }
  .text002 {
    font-size: 15px;
  }
  .botao-visao-aluno {
    width: 100%;
  }
  .botao-reprovar {
    width: 100%;
  }
  .botao-aprovar {
    width: 100%;
  }
  .anuncio-novo {
    gap: 16px;
    flex-wrap: wrap;
  }
  .foto-perfil {
    width: 112px;
    height: 112px;
  }
  .infos {
    gap: 16px;
    width: 100%;
    align-items: center;
  }
  .text-nome {
    font-size: 18px;
    text-align: center;
  }
  .text-descricao {
    font-size: 15px;
    text-align: center;
  }
  .botoes {
    gap: 24px;
    width: 100%;
  }
  .botao-tipo {
    width: fit-content;
    align-self: center;
  }
  .text011 {
    font-size: 15px;
  }
  .botao-ver {
    width: 100%;
  }
  .text013 {
    font-size: 15px;
  }
  .anuncio-novo1 {
    gap: 16px;
    flex-wrap: wrap;
  }
  .foto-perfil1 {
    width: 112px;
    height: 112px;
  }
  .infos1 {
    gap: 16px;
    width: 100%;
    align-items: center;
  }
  .text-nome1 {
    font-size: 18px;
    text-align: center;
  }
  .text-descricao1 {
    font-size: 15px;
    text-align: center;
  }
  .botoes1 {
    gap: 24px;
    width: 100%;
  }
  .botao-tipo1 {
    width: fit-content;
    align-self: center;
  }
  .text017 {
    font-size: 15px;
  }
  .botao-ver1 {
    width: 100%;
  }
  .text020 {
    font-size: 15px;
  }
  .anuncio-novo2 {
    gap: 16px;
    flex-wrap: wrap;
  }
  .foto-perfil2 {
    width: 112px;
    height: 112px;
  }
  .infos2 {
    gap: 16px;
    width: 100%;
    align-items: center;
  }
  .text-nome2 {
    font-size: 18px;
    text-align: center;
  }
  .text-descricao2 {
    font-size: 15px;
    text-align: center;
  }
  .botoes2 {
    gap: 24px;
    width: 100%;
  }
  .botao-tipo2 {
    width: fit-content;
    align-self: center;
  }
  .text024 {
    font-size: 15px;
  }
  .botao-ver2 {
    width: 100%;
  }
  .text026 {
    font-size: 15px;
  }
  .anuncio-novo3 {
    gap: 16px;
    flex-wrap: wrap;
  }
  .foto-perfil3 {
    width: 112px;
    height: 112px;
  }
  .infos3 {
    gap: 16px;
    width: 100%;
    align-items: center;
  }
  .text-nome3 {
    font-size: 18px;
    text-align: center;
  }
  .text-descricao3 {
    font-size: 15px;
    text-align: center;
  }
  .botoes3 {
    gap: 24px;
    width: 100%;
  }
  .botao-tipo3 {
    width: fit-content;
    align-self: center;
  }
  .text030 {
    font-size: 15px;
  }
  .botao-ver3 {
    width: 100%;
  }
  .text032 {
    font-size: 15px;
  }
  .anuncio-novo4 {
    gap: 16px;
    flex-wrap: wrap;
  }
  .foto-perfil4 {
    width: 112px;
    height: 112px;
  }
  .infos4 {
    gap: 16px;
    width: 100%;
    align-items: center;
  }
  .text-nome4 {
    font-size: 18px;
    text-align: center;
  }
  .text-descricao4 {
    font-size: 15px;
    text-align: center;
  }
  .botoes4 {
    gap: 24px;
    width: 100%;
  }
  .botao-tipo4 {
    width: fit-content;
    align-self: center;
  }
  .text036 {
    font-size: 15px;
  }
  .botao-ver4 {
    width: 100%;
  }
  .text038 {
    font-size: 15px;
  }
  .foto-perfil5 {
    width: 200px;
    height: 200px;
  }
  .text046 {
    font-size: 15px;
  }
  .text-descricao5 {
    font-size: 15px;
  }
  .text056 {
    font-size: 15px;
  }
  .text058 {
    font-size: 15px;
  }
  .text060 {
    font-size: 15px;
  }
  .text062 {
    font-size: 15px;
  }
  .text064 {
    font-size: 15px;
  }
  .text066 {
    font-size: 15px;
  }
  .text068 {
    font-size: 15px;
  }
  .text070 {
    font-size: 15px;
  }
  .text072 {
    font-size: 19px;
  }
  .instaimg {
    width: 28px;
    height: 28px;
  }
  .faceimg {
    width: 28px;
    height: 28px;
  }
  .ttkimg {
    width: 28px;
    height: 28px;
  }
  .linkedinimg {
    width: 28px;
    height: 28px;
  }
  .redeimg {
    width: 28px;
    height: 28px;
  }
  .text073 {
    font-size: 19px;
  }
  .text085 {
    font-size: 19px;
  }
  .text087 {
    font-size: 15px;
  }
  .text089 {
    font-size: 19px;
  }
  .text093 {
    font-size: 19px;
  }
  .text104 {
    font-size: 19px;
  }
  .text108 {
    font-size: 15px;
  }
  .text111 {
    font-size: 15px;
  }
  .text114 {
    font-size: 19px;
  }
  .midia1 {
    width: 361px;
  }
  .midia2 {
    width: 202px;
    height: 202px;
  }
  .midia3 {
    width: 202px;
    height: 202px;
  }
  .midia4 {
    width: 202px;
    height: 202px;
  }
  .midia5 {
    width: 202px;
    height: 202px;
  }
  .midia6 {
    width: 202px;
    height: 202px;
  }
  .midia7 {
    width: 202px;
    height: 202px;
  }
  .text116 {
    font-size: 19px;
  }
  .lista2 {
    align-items: flex-start;
    flex-direction: column;
    padding-bottom: 24px;
  }
  .text117 {
    color: rgb(125, 138, 152);
    font-size: 13px;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
  }
  .text118 {
    font-size: 13px;
  }
  .text120 {
    font-size: 13px;
  }
  .text122 {
    font-size: 13px;
  }
  .text124 {
    font-size: 13px;
  }
  .text126 {
    font-size: 13px;
  }
  .text128 {
    font-size: 13px;
  }
  .text130 {
    font-size: 13px;
  }
  .text132 {
    font-size: 13px;
  }
  .text134 {
    font-size: 13px;
  }
  .text136 {
    font-size: 13px;
  }
}
@media (max-width: 479px) {
  .text {
    font-size: 20px;
  }
  .textqntd {
    font-size: 14px;
  }
  .pesoaquecimentoicon {
    width: 23px;
  }
  .text-nome {
    font-size: 16px;
  }
  .text-descricao {
    font-size: 14px;
  }
  .text011 {
    font-size: 14px;
  }
  .text013 {
    font-size: 14px;
  }
  .arrowicon {
    width: 14px;
    height: 14px;
  }
  .text-nome1 {
    font-size: 16px;
  }
  .text-descricao1 {
    font-size: 14px;
  }
  .text017 {
    font-size: 14px;
  }
  .text020 {
    font-size: 14px;
  }
  .arrowicon1 {
    width: 14px;
    height: 14px;
  }
  .text-nome2 {
    font-size: 16px;
  }
  .text-descricao2 {
    font-size: 14px;
  }
  .text024 {
    font-size: 14px;
  }
  .text026 {
    font-size: 14px;
  }
  .arrowicon2 {
    width: 14px;
    height: 14px;
  }
  .text-nome3 {
    font-size: 16px;
  }
  .text-descricao3 {
    font-size: 14px;
  }
  .text030 {
    font-size: 14px;
  }
  .text032 {
    font-size: 14px;
  }
  .arrowicon3 {
    width: 14px;
    height: 14px;
  }
  .text-nome4 {
    font-size: 16px;
  }
  .text-descricao4 {
    font-size: 14px;
  }
  .text036 {
    font-size: 14px;
  }
  .text038 {
    font-size: 14px;
  }
  .arrowicon4 {
    width: 14px;
    height: 14px;
  }
  .foto-perfil5 {
    width: 142px;
    height: 142px;
  }
  .text046 {
    font-size: 14px;
  }
  .text-descricao5 {
    font-size: 14px;
  }
  .text056 {
    font-size: 14px;
  }
  .text058 {
    font-size: 14px;
  }
  .text060 {
    font-size: 14px;
  }
  .text062 {
    font-size: 14px;
  }
  .text064 {
    font-size: 14px;
  }
  .text066 {
    font-size: 14px;
  }
  .text068 {
    font-size: 14px;
  }
  .text070 {
    font-size: 14px;
  }
  .text072 {
    font-size: 18px;
  }
  .text073 {
    font-size: 18px;
  }
  .text085 {
    font-size: 18px;
  }
  .text087 {
    font-size: 14px;
  }
  .text089 {
    font-size: 18px;
  }
  .text093 {
    font-size: 18px;
  }
  .text104 {
    font-size: 18px;
  }
  .text108 {
    font-size: 14px;
  }
  .text111 {
    font-size: 14px;
  }
  .text114 {
    font-size: 18px;
  }
  .midia1 {
    width: 320px;
  }
  .midia2 {
    width: 179px;
    height: 179px;
  }
  .midia3 {
    width: 179px;
    height: 179px;
  }
  .midia4 {
    width: 179px;
    height: 179px;
  }
  .midia5 {
    width: 179px;
    height: 179px;
  }
  .midia6 {
    width: 179px;
    height: 179px;
  }
  .midia7 {
    width: 179px;
    height: 179px;
  }
  .text116 {
    font-size: 18px;
  }
  .text117 {
    font-size: 12px;
  }
  .text118 {
    font-size: 12px;
  }
  .text120 {
    font-size: 12px;
  }
  .text122 {
    font-size: 12px;
  }
  .text124 {
    font-size: 12px;
  }
  .text126 {
    font-size: 12px;
  }
  .text128 {
    font-size: 12px;
  }
  .text130 {
    font-size: 12px;
  }
  .text132 {
    font-size: 12px;
  }
  .text134 {
    font-size: 12px;
  }
  .text136 {
    font-size: 12px;
  }
}

.foto-galeria {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}
.foto {
  gap: 10px;
  width: 194px;
  height: 194px;
  display: flex;
  padding: 8px;
  overflow: hidden;
  align-items: flex-end;
  flex-shrink: 0;
  border-radius: 8px;
  position: relative;
  flex-direction: column;
  background-size: cover;
}

.text045 {
  color: rgb(125, 138, 152);
  font-size: 16px;
  font-style: Regular;
  text-align: center;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.objetivos .text045 {
  text-align: initial;
}

.cabecalho {
  gap: 8px;
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: #dee4e6;
  border-width: 1px;
  padding-bottom: 16px;
  justify-content: space-between;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.titulo {
  gap: 8px;
  display: flex;
  align-self: center;
  align-items: center;
}

.botoes-anuncio-unico {
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.text {
  color: rgb(45, 62, 80);
  font-size: 24px;
  align-self: center;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  font-stretch: normal;
  text-decoration: none;
}
.plano {
  gap: 8px;
  width: 180px;
  height: 100%;
  display: flex;
  padding: 8px 16px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  background-color: rgb(125, 138, 152, 0.2);
  border-style: solid;
  border: 1px solid #7d8a98;
  border-width: 1px;
  border-radius: 8px;
  justify-content: center;
}

.plano-elite {
  gap: 8px;
  width: 180px;
  height: 31px;
  display: flex;
  padding: 8px 16px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  justify-content: center;
  border-color: rgba(232, 139, 0, 1);
  background-color: var(--dl-color-default-d8ffe6);
}
.pesoaquecimentoicon {
  width: 26px;
}
.text002 {
  color: var(--dl-color-default-07a741);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  font-stretch: normal;
  text-decoration: none;
}
.botao-visao-aluno {
  gap: 8px;
  cursor: pointer;
  display: flex;
  padding: 12px 20px 12px 24px;
  align-items: center;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-colorgreybg);
}
.text003 {
  color: var(--dl-color-default-2d3e50);
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 100%;
  font-stretch: normal;
  text-decoration: none;
}
.viewicon {
  width: 16px;
  height: 16px;
}
.botao-reprovar {
  gap: 8px;
  cursor: pointer;
  display: flex;
  padding: 12px 20px 12px 24px;
  align-items: center;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-colorgreybg);
}
.text005 {
  color: var(--dl-color-default-2d3e50);
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 100%;
  font-stretch: normal;
  text-decoration: none;
}
.recusadoicon {
  width: 16px;
  height: 16px;
}
.botao-aprovar {
  gap: 8px;
  cursor: pointer;
  display: flex;
  padding: 12px 20px 12px 24px;
  align-items: center;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-colorgreybg);
}
.text007 {
  color: var(--dl-color-default-2d3e50);
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 100%;
  font-stretch: normal;
  text-decoration: none;
}
.aprovadoicon {
  width: 16px;
  height: 16px;
}
.novos-anuncios {
  gap: 16px;
  width: 100%;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.novos-anuncios1 {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
