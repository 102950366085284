.container {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.container01 {
  gap: 24px;
  width: 100%;
  display: flex;
  max-width: 1352px;
  align-self: center;
  align-items: flex-start;
  flex-shrink: 0;
  padding-top: 24px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 24px;
  justify-content: space-between;
}
.container02 {
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: 116px;
  justify-content: center;
}
.planos {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  padding-left: 16px;
  padding-right: 16px;
  flex-direction: column;
}
.cabecalho {
  width: 100%;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  max-height: 40px;
  align-items: center;
  flex-shrink: 0;
  padding-top: 8px;
  padding-left: 0px;
  border-radius: 8px;
  padding-right: 0px;
  padding-bottom: 8px;
}
.text {
  color: var(--dl-color-default-2d3e50);
  width: 100%;
  flex-grow: 1;
  font-size: 24px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  padding-left: 16px;
  padding-right: 16px;
  text-decoration: none;
}
.text001 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  display: none;
  font-size: 16;
  align-self: stretch;
  font-style: Medium;
  text-align: center;
  font-family: Poppins;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text002 {
  color: var(--dl-color-default-2d3e50);
}
.text003 {
  font-weight: 500;
}
.text004 {
  color: var(--dl-color-default-padro);
  font-weight: 600;
}
.pag-mobile {
  width: 100%;
  display: none;
  align-self: center;
  align-items: center;
  padding-top: 24px;
  border-color: #F0F4F6;
  border-width: 1px;
  flex-direction: row;
  justify-content: center;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.planos1 {
  gap: 8px;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.plano-free {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.nao-atual {
  width: 14px;
  height: 14px;
}
.atual {
  width: 14px;
  height: 14px;
}
.linha {
  align-self: center;
}
.plano-aquecimento {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.nao-atual1 {
  width: 14px;
  height: 14px;
}
.atual1 {
  width: 14px;
  height: 14px;
}
.linha1 {
  align-self: center;
}
.plano-elite {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.nao-atual2 {
  width: 14px;
  height: 14px;
}
.atual2 {
  width: 14px;
  height: 14px;
}
.opcoes {
  gap: 16px;
  height: auto;
  /* overflow: scroll; */
  display: flex;
  margin-right: 200px;
  margin: auto;
  width: calc(100vw - 322px);
}
.plano-card {
  flex: 1;
  /* width: 100%; */
  width: 82vw;
  display: flex;
  min-width: 280px;
  max-width: 364px;
  align-self: stretch;
  box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.07999999821186066) ;
  align-items: center;
  flex-shrink: 0;
  border-color: #D8DFE8;
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
  justify-content: flex-start;
  order: 1
}
.cabecalho1 {
  gap: 16px;
  width: 100%;
  display: flex;
  padding: 16px 32px;
  align-self: stretch;
  min-height: 110px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 16px 16px 0 0;
  background-color: var(--dl-color-default-f0f4f6);
}
.pesoicon {
  width: 28px;
  height: 10px;
}
.text005 {
  color: rgb(45, 62, 80);
  height: auto;
  font-size: 24px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.pacote {
  gap: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 32px 24px 40px;
  overflow: hidden;
  align-self: stretch;
  align-items: center;
  border-radius: 0 0 16px 16px;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 1);
}
.container03 {
  gap: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.topico {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: #F0F4F6;
  border-width: 1px;
  padding-bottom: 16px;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.aprovado {
  width: 16px;
  height: 16px;
}
.text006 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text007 {
  font-weight: 400;
}
.topico01 {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: #F0F4F6;
  border-width: 1px;
  padding-bottom: 16px;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.recusado {
  width: 16px;
  height: 16px;
}
.text009 {
  color: var(--dl-color-default-7d8a98);
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.topico02 {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: #F0F4F6;
  border-width: 1px;
  padding-bottom: 16px;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.aprovado01 {
  width: 16px;
  height: 16px;
}
.text011 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.topico03 {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: #F0F4F6;
  border-width: 1px;
  padding-bottom: 16px;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.recusado1 {
  width: 16px;
  height: 16px;
}
.text013 {
  color: var(--dl-color-default-7d8a98);
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.topico04 {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: #F0F4F6;
  border-width: 1px;
  padding-bottom: 16px;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.aprovado02 {
  width: 16px;
  height: 16px;
}
.text015 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text016 {
  font-weight: 400;
}
.text017 {
  font-weight: 600;
}
.topico05 {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: #F0F4F6;
  border-width: 1px;
  padding-bottom: 16px;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.aprovado03 {
  width: 16px;
  height: 16px;
}
.text019 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text020 {
  font-weight: 600;
}
.topico06 {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: #F0F4F6;
  border-width: 1px;
  padding-bottom: 16px;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.recusado2 {
  width: 16px;
  height: 16px;
}
.text022 {
  color: var(--dl-color-default-7d8a98);
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.topico07 {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: #F0F4F6;
  border-width: 1px;
  padding-bottom: 16px;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.aprovado04 {
  width: 16px;
  height: 16px;
}
.text024 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text025 {
  font-weight: 400;
}
.text026 {
  font-weight: 600;
}
.topico08 {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: #F0F4F6;
  border-width: 1px;
  padding-bottom: 16px;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.recusado3 {
  width: 16px;
  height: 16px;
}
.text028 {
  color: var(--dl-color-default-7d8a98);
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.topico09 {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: #F0F4F6;
  border-width: 1px;
  padding-bottom: 16px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.recusado4 {
  width: 16px;
  height: 16px;
}
.text030 {
  color: var(--dl-color-default-7d8a98);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.appleicon {
  width: 20px;
  height: 20px;
}
.topico10 {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: #F0F4F6;
  border-width: 1px;
  padding-bottom: 16px;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.recusado5 {
  width: 16px;
  height: 16px;
}
.text032 {
  color: var(--dl-color-default-7d8a98);
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.valor {
  gap: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.text034 {
  color: rgba(45, 62, 80, 1);
  width: 100%;
  height: auto;
  font-size: 32px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  padding-left: 16px;
  padding-right: 16px;
  text-decoration: none;
}
.botao-escolher {
  gap: 5px;
  cursor: pointer;
  display: flex;
  padding: 12px 24px;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-padro);
}
.text036 {
  color: var(--dl-color-default-white);
  height: auto;
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 100%;
  font-stretch: normal;
  text-decoration: none;
}
.plano-aquecimento1 {
  flex: 1;
  width: 100%;
  display: flex;
  max-width: 364px;
  align-self: stretch;
  box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.07999999821186066) ;
  box-sizing: content-box;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(216, 223, 232, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
  order: 2
}
.cabecalho2 {
  gap: 16px;
  width: 100%;
  display: flex;
  padding: 16px 32px;
  align-self: stretch;
  min-height: 110px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 16px 16px 0 0;
  padding-right: 16px 32px;
  background-color: var(--dl-color-default-f0f4f6);
}
.pesoicon1 {
  width: 24px;
  height: 20px;
}
.text038 {
  color: rgba(45, 62, 80, 1);
  font-size: 24px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.pacote1 {
  gap: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 32px 24px 40px;
  overflow: hidden;
  align-self: stretch;
  align-items: flex-start;
  border-radius: 0 0 16px 16px;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 1);
}
.container04 {
  gap: 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.topico11 {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: #F0F4F6;
  border-width: 1px;
  padding-bottom: 16px;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.aprovado05 {
  width: 16px;
  height: 16px;
}
.text040 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text041 {
  font-weight: 400;
}
.topico12 {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: #F0F4F6;
  border-width: 1px;
  padding-bottom: 16px;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.aprovado06 {
  width: 16px;
  height: 16px;
}
.text043 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.topico13 {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: #F0F4F6;
  border-width: 1px;
  padding-bottom: 16px;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.aprovado07 {
  width: 16px;
  height: 16px;
}
.text045 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.topico14 {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: #F0F4F6;
  border-width: 1px;
  padding-bottom: 16px;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.aprovado08 {
  width: 16px;
  height: 16px;
}
.text047 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.topico15 {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: #F0F4F6;
  border-width: 1px;
  padding-bottom: 16px;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.aprovado09 {
  width: 16px;
  height: 16px;
}
.text049 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text050 {
  font-weight: 400;
}
.text051 {
  font-weight: 600;
}
.topico16 {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: #F0F4F6;
  border-width: 1px;
  padding-bottom: 16px;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.aprovado10 {
  width: 16px;
  height: 16px;
}
.text053 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text054 {
  font-weight: 400;
}
.text055 {
  font-weight: 600;
}
.topico17 {
  gap: 16px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: #F0F4F6;
  border-width: 1px;
  padding-bottom: 16px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.vector {
  width: 16px;
  height: 16px;
}
.text057 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.redes {
  gap: 8px;
  display: flex;
  align-items: flex-start;
}
.tiktok {
  width: 24px;
  height: 24px;
}
.linkedin {
  width: 24px;
  height: 24px;
  border-radius: 35px;
}
.rede {
  width: 24px;
  height: 24px;
}
.topico18 {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: #F0F4F6;
  border-width: 1px;
  padding-bottom: 16px;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.aprovado11 {
  width: 16px;
  height: 16px;
}
.text059 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text060 {
  font-weight: 400;
}
.text061 {
  font-weight: 600;
}
.text062 {
  font-weight: 400;
}
.text063 {
  font-weight: 600;
}
.topico19 {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: #F0F4F6;
  border-width: 1px;
  padding-bottom: 16px;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.recusado6 {
  width: 16px;
  height: 16px;
}
.text065 {
  color: var(--dl-color-default-7d8a98);
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.topico20 {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: #F0F4F6;
  border-width: 1px;
  padding-bottom: 16px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.recusado7 {
  width: 16px;
  height: 16px;
}
.text067 {
  color: var(--dl-color-default-7d8a98);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.appleicon1 {
  width: 20px;
  height: 20px;
}
.topico21 {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: #F0F4F6;
  border-width: 1px;
  padding-bottom: 16px;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.recusado8 {
  width: 16px;
  height: 16px;
}
.text069 {
  color: var(--dl-color-default-7d8a98);
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.valor1 {
  gap: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.preco {
  gap: 8px;
  display: flex;
  padding: 0 16px;
  position: relative;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.valor-anterior {
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.text071 {
  color: var(--dl-color-default-7d8a98);
  height: auto;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text072 {
  text-decoration: line-through;
  color: #FCA600;
}
.risco {
  top: 0px;
  left: 4px;
  width: 60px;
  bottom: 0px;
  height: 1px;
  margin: auto;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: var(--dl-color-default-7d8a98);
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
  justify-content: center;
}
.text074 {
  color: rgb(45, 62, 80);
  height: auto;
  font-size: 32px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text075 {
  color: #2d3e50;
  font-weight: 600;
}
.text076 {
  color: var(--dl-color-default-7d8a98);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
.botao-escolher1 {
  gap: 5px;
  cursor: pointer;
  display: flex;
  padding: 12px 24px;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-padro);
}
.text077 {
  color: var(--dl-color-default-white);
  height: auto;
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 100%;
  font-stretch: normal;
  text-decoration: none;
}
.plano-elite1 {
  flex: 1;
  width: 100%;
  display: flex;
  max-width: 364px;
  align-self: stretch;
  box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.07999999821186066) ;
  box-sizing: content-box;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(216, 223, 232, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
  order: 3;
}
.cabecalho3 {
  gap: 16px;
  width: 100%;
  display: flex;
  padding: 16px 32px;
  align-self: stretch;
  min-height: 110px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 16px 16px 0 0;
  background-color: var(--dl-color-default-f0f4f6);
}
.pesoicon2 {
  width: 30px;
  height: 20px;
}
.text079 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  font-size: 24px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.pacote2 {
  gap: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 32px 24px 40px;
  overflow: hidden;
  align-self: stretch;
  align-items: flex-start;
  border-radius: 0 0 16px 16px;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 1);
}
.container05 {
  gap: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.topico22 {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: #F0F4F6;
  border-width: 1px;
  padding-bottom: 16px;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.aprovado12 {
  width: 16px;
  height: 16px;
}
.text081 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text082 {
  font-weight: 400;
}
.topico23 {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: #F0F4F6;
  border-width: 1px;
  padding-bottom: 16px;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.aprovado13 {
  width: 16px;
  height: 16px;
}
.text084 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.topico24 {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: #F0F4F6;
  border-width: 1px;
  padding-bottom: 16px;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.aprovado14 {
  width: 16px;
  height: 16px;
}
.text086 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.topico25 {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: #F0F4F6;
  border-width: 1px;
  padding-bottom: 16px;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.aprovado15 {
  width: 16px;
  height: 16px;
}
.text088 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.topico26 {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: #F0F4F6;
  border-width: 1px;
  padding-bottom: 16px;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.aprovado16 {
  width: 16px;
  height: 16px;
}
.text090 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text091 {
  font-weight: 400;
}
.text092 {
  font-weight: 600;
}
.topico27 {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: #F0F4F6;
  border-width: 1px;
  padding-bottom: 16px;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.aprovado17 {
  width: 16px;
  height: 16px;
}
.text094 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text095 {
  font-weight: 400;
}
.text096 {
  font-weight: 600;
}
.topico28 {
  gap: 16px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: #F0F4F6;
  border-width: 1px;
  padding-bottom: 16px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.vector1 {
  width: 16px;
  height: 16px;
}
.text098 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.redes1 {
  gap: 8px;
  display: flex;
  align-items: flex-start;
}
.insta {
  width: 24px;
  height: 24px;
}
.face {
  width: 24px;
  height: 24px;
}
.tiktok1 {
  width: 24px;
  height: 24px;
}
.linkedin1 {
  width: 24px;
  height: 24px;
  border-radius: 35px;
}
.rede1 {
  width: 24px;
  height: 24px;
}
.topico29 {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: #F0F4F6;
  border-width: 1px;
  padding-bottom: 16px;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.aprovado18 {
  width: 16px;
  height: 16px;
}
.text100 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text101 {
  font-weight: 400;
}
.text102 {
  font-weight: 600;
}
.text103 {
  font-weight: 400;
}
.text104 {
  font-weight: 600;
}
.topico30 {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: #F0F4F6;
  border-width: 1px;
  padding-bottom: 16px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.aprovado19 {
  width: 16px;
  height: 16px;
}
.frame1171277085 {
  gap: 8px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.text106 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.frame1171277054 {
  gap: 4px;
  height: 24px;
  display: flex;
  padding: 8px 10px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-radius: 6px;
  justify-content: center;
  background-color: var(--dl-color-default-padro);
}
.text108 {
  color: var(--dl-color-default-ffffff);
  height: auto;
  font-size: 14px;
  font-style: SemiBold;
  text-align: center;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.topico31 {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: #F0F4F6;
  border-width: 1px;
  padding-bottom: 16px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.aprovado20 {
  width: 16px;
  height: 16px;
}
.text110 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.appleicon2 {
  width: 20px;
  height: 20px;
}
.topico32 {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: #F0F4F6;
  border-width: 1px;
  padding-bottom: 16px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.aprovado21 {
  width: 16px;
  height: 16px;
}
.text112 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.buttons {
  gap: 4px;
  flex: 1;
  display: flex;
  padding: 8px 12px 8px 16px;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  justify-content: flex-start;
  cursor: pointer;
}
.text114 {
  color: var(--dl-color-default-07a741);
  height: auto;
  font-size: 14px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 100%;
  font-stretch: normal;
  text-decoration: none;
}
.vuesaxlineararrowcircleright {
  width: 14px;
  height: 14px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}
.vuesaxlineararrowcircleright1 {
  top: 0px;
  left: 0px;
  width: 14px;
  height: 14px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.arrowcircleright {
  top: 0px;
  left: 0px;
  width: 14px;
  height: 14px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.vector2 {
  top: 1.1666666269302368px;
  left: 1.1666666269302368px;
  width: 12px;
  height: 12px;
  position: absolute;
}
.vector3 {
  top: 4.940834045410156px;
  left: 6.264994144439697px;
  width: 2px;
  height: 4px;
  position: absolute;
}
.vector4 {
  top: 14px;
  left: 14px;
  width: 14px;
  height: 14px;
  opacity: 0.00;
  position: absolute;
}
.valor2 {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.preco1 {
  gap: 8px;
  display: flex;
  padding: 0 16px;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.valor-anterior1 {
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.text116 {
  color: var(--dl-color-default-7d8a98);
  height: auto;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.risco1 {
  top: 0px;
  left: 4px;
  width: 60px;
  bottom: 0px;
  height: 1px;
  margin: auto;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: var(--dl-color-default-7d8a98);
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
  justify-content: center;
}
.text119 {
  color: rgb(45, 62, 80);
  height: auto;
  font-size: 32px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text120 {
  color: #2d3e50;
  font-weight: 600;
}
.text121 {
  color: var(--dl-color-default-7d8a98);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
.buttons1 {
  gap: 5px;
  cursor: pointer;
  display: flex;
  padding: 12px 24px;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-padro);
}
.text122 {
  color: var(--dl-color-default-white);
  height: auto;
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 100%;
  font-stretch: normal;
  text-decoration: none;
}
.container-checkout {
  gap: 32px;
  width: 100%;
  max-width: 1352px;
  align-self: center;
  align-items: flex-start;
  flex-shrink: 0;
  padding-top: 52px;
  padding-left: 16px;
  padding-right: 16px;
  flex-direction: column;
  padding-bottom: 112px;
  justify-content: center;
}
.text124 {
  color: rgba(45, 62, 80, 1);
  width: 100%;
  font-size: 32px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: center;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.ciclo {
  gap: 32px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.text126 {
  color: rgba(45, 62, 80, 1);
  width: 100%;
  font-size: 24px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: center;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.opcoes1 {
  gap: 16px;
  width: 100%;
  display: flex;
  max-width: 780px;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.anual {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.09000000357627869) ;
  align-items: flex-start;
  padding-top: 24px;
  border-color: rgba(222, 228, 230, 1);
  border-style: solid;
  border-width: 1px;
  padding-left: 24px;
  border-radius: 16px;
  padding-right: 24px;
  flex-direction: column;
  padding-bottom: 32px;
  background-color: var(--dl-color-default-ffffff);
  cursor: pointer;
}
.titulo {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.text128 {
  color: rgba(45, 62, 80, 1);
  flex-grow: 1;
  font-size: 20px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.economize {
  gap: 10px;
  display: flex;
  padding: 8px 10px;
  overflow: hidden;
  align-items: center;
  border-radius: 4px;
  background-color: rgba(39, 204, 99, 1);
}
.text130 {
  color: rgb(255, 255, 255);
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text132 {
  font-style: normal;
  font-weight: 700;
}
.text133 {
  color: rgb(45, 62, 80);
  height: auto;
  font-size: 24px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  padding-bottom: 16px;
  text-decoration: none;
}
.container-text-promocional {
  gap: 8px;
  display: flex;
  font-weight: 400;
  font-size: 16px;
}
.text-preco-cinza-cortado {
  color: var(--dl-color-default-7d8a98);
  text-decoration: line-through;
}
.text-promocao-lancamento {
  color: var(--dl-color-green-promotion);
}
.text135 {
  color: #7d8a98;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
.formas-pagamento {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.cartao {
  flex: 1;
  width: 100%;
  display: flex;
  padding: 16px 24px;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(222, 228, 230, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
  cursor: pointer;
}
.container06 {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.cardicon {
  width: 24px;
  height: 18px;
}
.text137 {
  color: rgba(45, 62, 80, 1);
  width: 100%;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.pix {
  flex: 1;
  width: 100%;
  display: flex;
  padding: 16px 24px;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(222, 228, 230, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
  cursor: pointer;
}
.container07 {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.pixicon {
  width: 20px;
  height: 20px;
}
.text139 {
  color: rgba(45, 62, 80, 1);
  width: 100%;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.semestral {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.09000000357627869) ;
  align-items: flex-start;
  padding-top: 24px;
  border-color: rgba(222, 228, 230, 1);
  border-style: solid;
  border-width: 1px;
  padding-left: 24px;
  border-radius: 16px;
  padding-right: 24px;
  flex-direction: column;
  padding-bottom: 32px;
  background-color: var(--dl-color-default-ffffff);
  cursor: pointer;
}
.titulo1 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.text141 {
  color: rgba(45, 62, 80, 1);
  flex-grow: 1;
  font-size: 20px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.frame1171277086 {
  gap: 10px;
  display: flex;
  padding: 8px 10px;
  overflow: hidden;
  align-items: center;
  border-radius: 4px;
  background-color: rgba(39, 204, 99, 1);
}
.text143 {
  color: rgb(255, 255, 255);
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text144 {
  font-weight: 400;
}
.text145 {
  font-style: normal;
  font-weight: 700;
}
.text146 {
  color: rgb(45, 62, 80);
  height: auto;
  font-size: 24px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  padding-bottom: 16px;
  text-decoration: none;
}
.text148 {
  color: #7d8a98;
  font-size: 16px;
  font-weight: 400;
}
.formas-pagamento1 {
  gap: 16px;
  width: 100%;
  display: none;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.cartao1 {
  flex: 1;
  width: 100%;
  display: flex;
  padding: 16px 24px;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(222, 228, 230, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
  cursor: pointer;
}
.container08 {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.cardicon1 {
  width: 24px;
  height: 18px;
}
.text150 {
  color: rgba(45, 62, 80, 1);
  width: 100%;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.pix1 {
  flex: 1;
  width: 100%;
  display: flex;
  padding: 16px 24px;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(222, 228, 230, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
}
.container09 {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.pixicon1 {
  width: 20px;
  height: 20px;
}
.text152 {
  color: rgba(45, 62, 80, 1);
  width: 100%;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.mensal {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.09000000357627869) ;
  align-items: flex-start;
  padding-top: 24px;
  border-color: rgba(222, 228, 230, 1);
  border-style: solid;
  border-width: 1px;
  padding-left: 24px;
  border-radius: 16px;
  padding-right: 24px;
  flex-direction: column;
  padding-bottom: 32px;
  background-color: var(--dl-color-default-ffffff);
  cursor: pointer;
}
.titulo2 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.text154 {
  color: rgba(45, 62, 80, 1);
  flex-grow: 1;
  font-size: 20px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text156 {
  color: rgb(45, 62, 80);
  height: auto;
  font-size: 24px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  padding-bottom: 16px;
  text-decoration: none;
}
.text158 {
  color: #7d8a98;
  font-size: 16px;
  font-weight: 400;
}
.formas-pagamento2 {
  gap: 16px;
  width: 100%;
  display: none;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.cartao2 {
  flex: 1;
  width: 100%;
  display: flex;
  padding: 16px 24px;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(222, 228, 230, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
}
.container10 {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.cardicon2 {
  width: 24px;
  height: 18px;
}
.text159 {
  color: rgba(45, 62, 80, 1);
  width: 100%;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.pix2 {
  flex: 1;
  width: 100%;
  display: flex;
  padding: 16px 24px;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(222, 228, 230, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
}
.container11 {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.pixicon2 {
  width: 20px;
  height: 20px;
}
.text161 {
  color: rgba(45, 62, 80, 1);
  width: 100%;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.botao-cupom {
  gap: 8px;
  display: flex;
  padding: 12px 20px 12px 24px;
  align-items: center;
  border-radius: 8px;
  justify-content: center;
}
.text163 {
  color: var(--dl-color-default-07a741);
  cursor: pointer;
  height: auto;
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 100%;
  font-stretch: normal;
  text-decoration: none;
}
.arrowicon {
  width: 16px;
  cursor: pointer;
  height: 16px;
}
.botao-contratar {
  gap: 5px;
  width: 206px;
  cursor: pointer;
  display: flex;
  padding: 12px 24px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-padro);
}
.text165 {
  color: var(--dl-color-default-white);
  height: auto;
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 100%;
  font-stretch: normal;
  text-decoration: none;
}
@media(max-width: 991px) {
  .container01 {
    padding-bottom: 16px;
  }
  .container02 {
    padding-bottom: 124px;
  }
  .botao-escolher{
    max-width: 80%;
  }
  .planos {
    gap: 24px;
    align-items: center;
    border-color: #F0F4F6;
    border-width: 1px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 24px;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .cabecalho {
    gap: 24px;
    align-self: center;
    max-height: 100%;
    padding-top: 0px;
    flex-direction: column;
    padding-bottom: 0px;
    justify-content: center;
  }
  .text {
    text-align: center;
    padding-left: 0px;
    padding-right: 0px;
  }
  .text001 {
    display: flex;
    justify-content: center;
  }
  .pag-mobile {
    display: flex;
  }
  .opcoes {
    width: 100%;
  }
  /* .plano-free1 {
    flex: 0 0 auto;
    max-width: 94%;
  }
  .plano-aquecimento1 {
    flex: 0 0 auto;
    max-width: 94%;
  }
  .plano-elite1 {
    flex: 0 0 auto;
    max-width: 94%;
  } */
  .container-checkout {
    gap: 24px;
    padding-top: 24px;
    padding-bottom: 124px;
  }
  .text124 {
    font-size: 28px;
  }
  .opcoes1 {
    gap: 8px;
  }
  .titulo {
    gap: 8px;
  }
  .text133 {
    padding-bottom: 0px;
  }
  .formas-pagamento {
    padding-top: 16px;
  }
  .titulo1 {
    gap: 8px;
  }
  .text146 {
    padding-bottom: 0px;
  }
  .text148 {
    font-style: normal;
  }
  .formas-pagamento1 {
    padding-top: 16px;
  }
  .titulo2 {
    gap: 8px;
  }
  .text156 {
    padding-bottom: 0px;
  }
  .text158 {
    color: #7d8a98;
    font-size: 16px;
    font-style: inherit;
    font-weight: 400;
  }
  .formas-pagamento2 {
    padding-top: 16px;
  }
}
@media(max-width: 767px) {
  .text {
    font-size: 22px;
  }
  .text001 {
    font-size: 15px;
  }
  .text005 {
    font-size: 22px;
  }
  .aprovado {
    width: 15px;
    height: 15px;
  }
  .text006 {
    font-size: 15px;
  }
  .recusado {
    width: 15px;
    height: 15px;
  }
  .text009 {
    font-size: 15px;
  }
  .aprovado01 {
    width: 15px;
    height: 15px;
  }
  .text011 {
    font-size: 15px;
  }
  .recusado1 {
    width: 15px;
    height: 15px;
  }
  .text013 {
    font-size: 15px;
  }
  .aprovado02 {
    width: 15px;
    height: 15px;
  }
  .text015 {
    font-size: 15px;
  }
  .aprovado03 {
    width: 15px;
    height: 15px;
  }
  .text019 {
    font-size: 15px;
  }
  .recusado2 {
    width: 15px;
    height: 15px;
  }
  .text022 {
    font-size: 15px;
  }
  .aprovado04 {
    width: 15px;
    height: 15px;
  }
  .text024 {
    font-size: 15px;
  }
  .recusado3 {
    width: 15px;
    height: 15px;
  }
  .text028 {
    font-size: 15px;
  }
  .recusado4 {
    width: 15px;
    height: 15px;
  }
  .text030 {
    font-size: 15px;
  }
  .recusado5 {
    width: 15px;
    height: 15px;
  }
  .text032 {
    font-size: 15px;
  }
  .text038 {
    font-size: 22px;
  }
  .aprovado05 {
    width: 15px;
    height: 15px;
  }
  .text040 {
    font-size: 15px;
  }
  .aprovado06 {
    width: 15px;
    height: 15px;
  }
  .text043 {
    font-size: 15px;
  }
  .aprovado07 {
    width: 15px;
    height: 15px;
  }
  .text045 {
    font-size: 15px;
  }
  .aprovado08 {
    width: 15px;
    height: 15px;
  }
  .text047 {
    font-size: 15px;
  }
  .aprovado09 {
    width: 15px;
    height: 15px;
  }
  .text049 {
    font-size: 15px;
  }
  .aprovado10 {
    width: 15px;
    height: 15px;
  }
  .text053 {
    font-size: 15px;
  }
  .text057 {
    font-size: 15px;
  }
  .tiktok {
    width: 22px;
    height: 22px;
  }
  .linkedin {
    width: 22px;
    height: 22px;
  }
  .rede {
    width: 22px;
    height: 22px;
  }
  .aprovado11 {
    width: 15px;
    height: 15px;
  }
  .text059 {
    font-size: 15px;
  }
  .recusado6 {
    width: 15px;
    height: 15px;
  }
  .text065 {
    font-size: 15px;
  }
  .recusado7 {
    width: 15px;
    height: 15px;
  }
  .text067 {
    font-size: 15px;
  }
  .recusado8 {
    width: 15px;
    height: 15px;
  }
  .text069 {
    font-size: 15px;
  }
  .text071 {
    font-size: 15px;
  }
  .text079 {
    font-size: 22px;
  }
  .aprovado12 {
    width: 15px;
    height: 15px;
  }
  .text081 {
    font-size: 15px;
  }
  .aprovado13 {
    width: 15px;
    height: 15px;
  }
  .text084 {
    font-size: 15px;
  }
  .aprovado14 {
    width: 15px;
    height: 15px;
  }
  .text086 {
    font-size: 15px;
  }
  .aprovado15 {
    width: 15px;
    height: 15px;
  }
  .text088 {
    font-size: 15px;
  }
  .aprovado16 {
    width: 15px;
    height: 15px;
  }
  .text090 {
    font-size: 15px;
  }
  .aprovado17 {
    width: 15px;
    height: 15px;
  }
  .text094 {
    font-size: 15px;
  }
  .vector1 {
    width: 15px;
    height: 15px;
  }
  .text098 {
    font-size: 15px;
  }
  .insta {
    width: 22px;
    height: 22px;
  }
  .face {
    width: 22px;
    height: 22px;
  }
  .tiktok1 {
    width: 22px;
    height: 22px;
  }
  .linkedin1 {
    width: 22px;
    height: 22px;
  }
  .rede1 {
    width: 22px;
    height: 22px;
  }
  .aprovado18 {
    width: 15px;
    height: 15px;
  }
  .text100 {
    font-size: 15px;
  }
  .aprovado19 {
    width: 15px;
    height: 15px;
  }
  .text106 {
    font-size: 15px;
  }
  .aprovado20 {
    width: 15px;
    height: 15px;
  }
  .text110 {
    font-size: 15px;
  }
  .aprovado21 {
    width: 15px;
    height: 15px;
  }
  .text112 {
    font-size: 15px;
  }
  .text116 {
    font-size: 15px;
  }
  .text124 {
    font-size: 24px;
  }
  .text126 {
    font-size: 22px;
  }
  .text128 {
    font-size: 19px;
  }
  .text130 {
    font-size: 15px;
  }
  .text133 {
    font-size: 22px;
  }
  .text135 {
    color: #7d8a98;
    font-size: 15px;
    font-style: inherit;
    font-weight: 400;
  }
  .text137 {
    font-size: 15px;
  }
  .text139 {
    font-size: 15px;
  }
  .text141 {
    font-size: 19px;
  }
  .text143 {
    font-size: 15px;
  }
  .text146 {
    font-size: 22px;
  }
  .text147 {
    color: #2d3e50;
    font-weight: 600;
  }
  .text148 {
    color: #7d8a98;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
  }
  .text150 {
    font-size: 15px;
  }
  .text152 {
    font-size: 15px;
  }
  .text154 {
    font-size: 19px;
  }
  .text156 {
    font-size: 22px;
  }
  .text158 {
    color: #7d8a98;
    font-size: 15px;
    font-style: inherit;
    font-weight: 400;
  }
  .text159 {
    font-size: 15px;
  }
  .text161 {
    font-size: 15px;
  }
  .text163 {
    font-size: 15px;
  }
  .arrowicon {
    width: 15px;
    height: 15px;
  }
}
@media(max-width: 479px) {
  .text {
    font-size: 20px;
  }
  .text001 {
    font-size: 14px;
  }
  .pesoicon {
    display: none;
  }
  .text005 {
    font-size: 20px;
  }
  .aprovado {
    width: 14px;
    height: 14px;
  }
  .text006 {
    font-size: 14px;
  }
  .recusado {
    width: 14px;
    height: 14px;
  }
  .text009 {
    font-size: 14px;
  }
  .aprovado01 {
    width: 14px;
    height: 14px;
  }
  .text011 {
    font-size: 14px;
  }
  .recusado1 {
    width: 14px;
    height: 14px;
  }
  .text013 {
    font-size: 14px;
  }
  .aprovado02 {
    width: 14px;
    height: 14px;
  }
  .text015 {
    font-size: 14px;
  }
  .aprovado03 {
    width: 14px;
    height: 14px;
  }
  .text019 {
    font-size: 14px;
  }
  .recusado2 {
    width: 14px;
    height: 14px;
  }
  .text022 {
    font-size: 14px;
  }
  .aprovado04 {
    width: 14px;
    height: 14px;
  }
  .text024 {
    font-size: 14px;
  }
  .recusado3 {
    width: 14px;
    height: 14px;
  }
  .text028 {
    font-size: 14px;
  }
  .recusado4 {
    width: 14px;
    height: 14px;
  }
  .text030 {
    font-size: 14px;
  }
  .appleicon {
    display: none;
  }
  .recusado5 {
    width: 14px;
    height: 14px;
  }
  .text032 {
    font-size: 14px;
  }
  .pesoicon1 {
    display: none;
  }
  .text038 {
    font-size: 20px;
  }
  .aprovado05 {
    width: 14px;
    height: 14px;
  }
  .text040 {
    font-size: 14px;
  }
  .aprovado06 {
    width: 14px;
    height: 14px;
  }
  .text043 {
    font-size: 14px;
  }
  .aprovado07 {
    width: 14px;
    height: 14px;
  }
  .text045 {
    font-size: 14px;
  }
  .aprovado08 {
    width: 14px;
    height: 14px;
  }
  .text047 {
    font-size: 14px;
  }
  .aprovado09 {
    width: 14px;
    height: 14px;
  }
  .text049 {
    font-size: 14px;
  }
  .aprovado10 {
    width: 14px;
    height: 14px;
  }
  .text053 {
    font-size: 14px;
  }
  .text057 {
    font-size: 14px;
  }
  .tiktok {
    width: 20px;
    height: 20px;
  }
  .linkedin {
    width: 20px;
    height: 20px;
  }
  .rede {
    width: 20px;
    height: 20px;
  }
  .aprovado11 {
    width: 14px;
    height: 14px;
  }
  .text059 {
    font-size: 14px;
  }
  .recusado6 {
    width: 14px;
    height: 14px;
  }
  .text065 {
    font-size: 14px;
  }
  .recusado7 {
    width: 14px;
    height: 14px;
  }
  .text067 {
    font-size: 14px;
  }
  .appleicon1 {
    display: none;
  }
  .recusado8 {
    width: 14px;
    height: 14px;
  }
  .text069 {
    font-size: 14px;
  }
  .text071 {
    font-size: 14px;
  }
  .text074 {
    color: rgb(45, 62, 80);
    font-size: 32px;
    font-family: Poppins;
    font-weight: 600;
    line-height: normal;
    text-decoration: none;
  }
  .text075 {
    color: #2d3e50;
    font-weight: 600;
  }
  .text076 {
    font-size: 14px;
  }
  .pesoicon2 {
    display: none;
  }
  .text079 {
    font-size: 20px;
  }
  .aprovado12 {
    width: 14px;
    height: 14px;
  }
  .text081 {
    font-size: 14px;
  }
  .aprovado13 {
    width: 14px;
    height: 14px;
  }
  .text084 {
    font-size: 14px;
  }
  .aprovado14 {
    width: 14px;
    height: 14px;
  }
  .text086 {
    font-size: 14px;
  }
  .aprovado15 {
    width: 14px;
    height: 14px;
  }
  .text088 {
    font-size: 14px;
  }
  .aprovado16 {
    width: 14px;
    height: 14px;
  }
  .text090 {
    font-size: 14px;
  }
  .aprovado17 {
    width: 14px;
    height: 14px;
  }
  .text094 {
    font-size: 14px;
  }
  .vector1 {
    width: 14px;
    height: 14px;
  }
  .text098 {
    font-size: 14px;
  }
  .insta {
    width: 20px;
    height: 20px;
  }
  .face {
    width: 20px;
    height: 20px;
  }
  .tiktok1 {
    width: 20px;
    height: 20px;
  }
  .linkedin1 {
    width: 20px;
    height: 20px;
  }
  .rede1 {
    width: 20px;
    height: 20px;
  }
  .aprovado18 {
    width: 14px;
    height: 14px;
  }
  .text100 {
    font-size: 14px;
  }
  .aprovado19 {
    width: 14px;
    height: 14px;
  }
  .text106 {
    font-size: 14px;
  }
  .text108 {
    font-size: 13px;
  }
  .aprovado20 {
    width: 14px;
    height: 14px;
  }
  .text110 {
    font-size: 14px;
  }
  .appleicon2 {
    display: none;
  }
  .aprovado21 {
    width: 14px;
    height: 14px;
  }
  .text112 {
    font-size: 14px;
  }
  .text116 {
    font-size: 14px;
  }
  .text124 {
    font-size: 20px;
  }
  .text126 {
    font-size: 20px;
  }
  .text128 {
    font-size: 18px;
  }
  .text130 {
    font-size: 14px;
  }
  .text133 {
    font-size: 20px;
  }
  .text134 {
    color: #2d3e50;
    font-weight: 600;
  }
  .text135 {
    color: #7d8a98;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
  .formas-pagamento {
    flex-direction: column;
  }
  .text137 {
    font-size: 14px;
  }
  .text139 {
    font-size: 14px;
  }
  .text141 {
    font-size: 18px;
  }
  .text143 {
    font-size: 14px;
  }
  .text146 {
    font-size: 20px;
  }
  .text147 {
    color: #2d3e50;
    font-weight: 600;
  }
  .text148 {
    color: #7d8a98;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
  .formas-pagamento1 {
    flex-direction: column;
  }
  .text150 {
    font-size: 14px;
  }
  .text152 {
    font-size: 14px;
  }
  .text154 {
    font-size: 18px;
  }
  .text156 {
    font-size: 20px;
  }
  .text158 {
    color: #7d8a98;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
  .formas-pagamento2 {
    flex-direction: column;
  }
  .text159 {
    font-size: 14px;
  }
  .text161 {
    font-size: 14px;
  }
  .text163 {
    font-size: 14px;
  }
  .arrowicon {
    width: 14px;
    height: 14px;
  }
}

.plano-escolhido {
  background:var(--dl-color-default-f0f4f6);
  cursor:initial;
}

.plano-escolhido .text036 {
  color:#b2b2b2;
}

.plans-container {
  max-width: 100%;  
  overflow-y: visible;
  padding-bottom: 16px;
  /* scroll-snap-type: x mandatory; */
}
@media (max-width: 1610px) {
  .plans-container {
    overflow-x: auto;
  }
}

/* .plans-container > div {
  scroll-snap-align: start;
	scroll-snap-stop: always;
} */