.pagarcomcarto {
    width: 100%;
    height: 100vh;
    display: flex;
    padding: 16px;
    top: 0;
    left: 0;
    overflow: hidden;
    position: fixed;
    align-items: center;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.61);
    z-index: 99999999999;
  }
  .container {
    gap: 24px;
    width: 100%;
    display: flex;
    padding: 16px 16px 24px;
    overflow: hidden;
    position: relative;
    max-width: 328px;
    box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.07999999821186066);
    align-items: center;
    flex-shrink: 0;
    border-radius: 8px;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(255, 255, 255, 1);
  }
  .titulo {
    gap: 14px;
    width: 100%;
    display: flex;
    align-self: stretch;
    align-items: flex-start;
    flex-shrink: 0;
  }
  .text {
    color: rgb(45, 62, 80);
    width: 100%;
    flex-grow: 1;
    font-size: 16px;
    font-style: SemiBold;
    text-align: left;
    font-family: "Poppins";
    font-weight: 600;
    font-stretch: normal;
    text-decoration: none;
  }
  .closeicon {
    width: 15px;
    cursor: pointer;
    height: 15px;
    align-self: center;
  }
  .form {
    gap: 24px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .botao-comprar {
    gap: 5px;
    cursor: pointer;
    display: flex;
    padding: 12px 24px;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
    border-radius: 40px;
    justify-content: center;
    background-color: var(--dl-color-default-padro);
  }
  .botao-comprar-grey {
    gap: 5px;
    cursor: pointer;
    display: flex;
    padding: 12px 24px;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
    border-radius: 40px;
    justify-content: center;
    background-color: #7D8A98;
  }
  .text1 {
    color: var(--dl-color-default-white);
    height: auto;
    font-size: 16px;
    font-style: SemiBold;
    text-align: left;
    font-family: "Poppins";
    font-weight: 600;
    line-height: 100%;
    font-stretch: normal;
    text-decoration: none;
  }
  