.adm-filtros-botoes {
  gap: 8px;
  width: 100%;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}
.adm-filtros-busca {
  gap: 16px;
  width: 100%;
  display: flex;
  padding: 8px 16px 8px 24px;
  overflow: hidden;
  max-width: 316px;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(222, 228, 230, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  background-color: var(--dl-color-default-ffffff);
}
.adm-filtros-textinput {
  color: rgba(125, 138, 152, 1);
  width: 100%;
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  font-stretch: normal;
  text-decoration: none;
}
.adm-filtros-lupa {
  width: 19px;
  height: 19px;
}
.adm-filtros-botoes1 {
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.adm-filtros-botao-ordenar {
  gap: 8px;
  cursor: pointer;
  display: flex;
  padding: 12px 20px 12px 24px;
  align-items: center;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-colorgreybg);
}
.adm-filtros-text {
  color: var(--dl-color-default-2d3e50);
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 100%;
  font-stretch: normal;
  text-decoration: none;
}
.adm-filtros-ordenaricon {
  width: 16px;
  height: 16px;
}
.adm-filtros-botao-filtrar {
  gap: 8px;
  cursor: pointer;
  display: flex;
  padding: 12px 20px 12px 24px;
  align-items: center;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-colorgreybg);
}
.adm-filtros-text2 {
  color: var(--dl-color-default-2d3e50);
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 100%;
  font-stretch: normal;
  text-decoration: none;
}
.adm-filtros-filtrar {
  width: 16px;
  height: 16px;
}
.adm-filtros-root-class-name {
  width: fit-content;
  display: none;
}
.adm-filtros-root-class-name1 {
  width: 100%;
}
.adm-filtros-root-class-name2 {
  width: 100%;
}
@media(max-width: 767px) {
  .adm-filtros-botoes {
    width: 100%;
    flex-direction: column;
  }
  .adm-filtros-busca {
    max-width: 100%;
  }
  .adm-filtros-textinput {
    font-size: 15px;
  }
  .adm-filtros-lupa {
    width: 18px;
    height: 18px;
  }
  .adm-filtros-botoes1 {
    width: 100%;
  }
  .adm-filtros-botao-ordenar {
    width: 100%;
  }
  .adm-filtros-text {
    font-size: 15px;
  }
  .adm-filtros-ordenaricon {
    width: 15px;
    height: 15px;
  }
  .adm-filtros-botao-filtrar {
    width: 100%;
  }
  .adm-filtros-text2 {
    font-size: 15px;
  }
  .adm-filtros-filtrar {
    width: 15px;
    height: 15px;
  }
  .adm-filtros-root-class-name {
    width: 100%;
  }
  .adm-filtros-root-class-name1 {
    width: 100%;
    align-self: center;
  }
  .adm-filtros-root-class-name2 {
    width: 100%;
    align-self: center;
  }
}
@media(max-width: 479px) {
  .adm-filtros-textinput {
    font-size: 14px;
  }
  .adm-filtros-lupa {
    width: 17px;
    height: 17px;
  }
  .adm-filtros-text {
    font-size: 14px;
  }
  .adm-filtros-ordenaricon {
    width: 14px;
    height: 14px;
  }
  .adm-filtros-text2 {
    font-size: 14px;
  }
  .adm-filtros-filtrar {
    width: 14px;
    height: 14px;
  }
}
