.container {
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
  background-color: rgba(240, 244, 246, 1);
}
.container1 {
  gap: 24px;
  width: 100%;
  display: flex;
  max-width: 1352px;
  
  align-self: center;
  align-items: center;
  flex-shrink: 0;
  padding-top: 24px;
  padding-left: 16px;
  padding-right: 16px;
  flex-direction: column;
  padding-bottom: 108px;
  justify-content: space-between;
}
.cabecalho {
  width: 100%;
  display: flex;
  padding: 7px 0;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
}
.logo {
  width: 228px;
}
.avaliar {
  gap: 32px;
  width: 100%;
  display: flex;
  padding: 32px 32px 48px;
  overflow: hidden;
  max-width: 502px;
  box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.07999999821186066) ;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(216, 223, 232, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}
.container2 {
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.text {
  color: rgba(45, 62, 80, 1);
  height: auto;
  flex-grow: 1;
  font-size: 20px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  font-stretch: normal;
  text-decoration: none;
}
.info-personal {
  gap: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.foto-perfil {
  width: 88px;
  height: 88px;
  display: flex;
  overflow: hidden;
  position: relative;
  box-sizing: content-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(251, 166, 0, 1);
  border-style: solid;
  border-width: 5px;
  border-radius: 200px;
}
.imgpersonal {
  width: 100%;
  height: 100%;
}
.text2 {
  color: var(--dl-color-default-2d3e50);
  width: 100%;
  height: auto;
  font-size: 16px;
  font-style: SemiBold;
  text-align: center;
  font-family: Poppins;
  font-weight: 600;
  font-stretch: normal;
  text-decoration: none;
}
.estrelas-avaliar {
  gap: 8px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: center;
}
.umaestrela {
  display: flex;
  align-items: center;
  justify-content: center;
}
.starempty {
  width: 24px;
  height: 24px;
}
.staryellow {
  width: 24px;
  height: 24px;
}
.duasestrelas {
  display: flex;
  align-items: center;
  justify-content: center;
}
.starempty1 {
  width: 24px;
  height: 24px;
}
.staryellow1 {
  width: 24px;
  height: 24px;
}
.tresestrelas {
  display: flex;
  align-items: center;
  justify-content: center;
}
.starempty2 {
  width: 24px;
  height: 24px;
}
.staryellow2 {
  width: 24px;
  height: 24px;
}
.quatroestrelas {
  display: flex;
  align-items: center;
  justify-content: center;
}
.starempty3 {
  width: 24px;
  height: 24px;
}
.staryellow3 {
  width: 24px;
  height: 24px;
}
.cincoestrelas {
  display: flex;
  align-items: center;
  justify-content: center;
}
.starempty4 {
  width: 24px;
  height: 24px;
}
.staryellow4 {
  width: 24px;
  height: 24px;
}
.botao-publicar {
  display: flex;
  padding: 12px 24px;
  align-items: center;
  border-radius: 8px;
  cursor:pointer;
  justify-content: center;
  background-color: var(--dl-color-default-padro);
}
.text4 {
  color: var(--dl-color-default-white);
  height: auto;
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 100%;
  font-stretch: normal;
  text-decoration: none;
}
@media(max-width: 991px) {
  .logo {
    width: 204px;
  }
  .avaliar {
    padding-top: 24px;
    padding-left: 23px;
    padding-right: 23px;
    padding-bottom: 32px;
  }
}
@media(max-width: 767px) {
  .logo {
    width: 180px;
  }
  .avaliar {
    gap: 24px;
  }
  .text {
    font-size: 18px;
  }
  .text4 {
    font-size: 15px;
  }
}
@media(max-width: 479px) {
  .logo {
    width: 156px;
  }
  .text {
    font-size: 16px;
  }
  .text4 {
    font-size: 14px;
  }
}

.form {
  width:100%;
  display:flex;
  flex-direction:column;
  gap:15px;
}