
.anuncio-novo {
  gap: 24px;
  width: 100%;
  display: flex;
  padding: 24px;
  overflow: hidden;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(222, 228, 230, 1);
  border-style: solid;
  box-shadow: 4px 8px 30px 0px rgba(0, 0, 0, 0.09);
  border-width: 1px;
  border-radius: 16px;
  justify-content: center;
}
.foto-perfil {
  width: 128px;
  height: 128px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: var(--dl-radius-radius-round);
  background-size: cover;
  background-image: url("/pastedimage-thz-200h.png");
  object-fit: cover;
}
.infos {
  gap: 24px;
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.text-nome {
  color: var(--dl-color-default-2d3e50);
  width: 100%;
  font-size: 20px;
  align-self: stretch;
  font-style: Bold;
  text-align: left;
  font-family: Poppins;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text-descricao {
  color: var(--dl-color-default-7d8a98);
  width: 100%;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.botoes {
  gap: 16px;
  display: flex;
  align-self: flex-start;
  align-items: flex-end;
  flex-shrink: 0;
  flex-direction: column;
}
.botao-tipo {
  gap: 8px;
  display: flex;
  padding: 8px 20px 8px 24px;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(222, 228, 230, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-ffffff);
}
.text011 {
  color: var(--dl-color-default-2d3e50);
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 100%;
  font-stretch: normal;
  text-decoration: none;
}
.botao-ver {
  gap: 8px;
  cursor: pointer;
  display: flex;
  padding: 12px 20px 12px 24px;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-padro);
}
.text013 {
  color: #ffffff;
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 100%;
  font-stretch: normal;
  text-decoration: none;
}
.arrowicon {
  width: 15px;
  height: 15px;
}


.status {
  gap: 8px;
  width: 100%;
  display: flex;
  min-width: 180px;
  align-self: stretch;
  justify-content: center;
  align-items: center;
  padding-top: 8px;
  border-color: #DFE4E7;
  border-width: 1px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 8px;
  border-radius: 8px;
}

.plano {
  gap: 8px;
  width: 100%;
  height: 31px;
  display: flex;
  padding: 8px 16px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  background-color: rgb(125, 138, 152, 0.2);
  border-style: solid;
  border: 1px solid #7D8A98;
  border-width: 1px;
  border-radius: 8px;
  justify-content: center;
}

.plano-elite{
  gap: 8px;
  width: 100%;
  height: 31px;
  display: flex;
  padding: 8px 16px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  justify-content: center;
  border-color: rgba(232, 139, 0, 1);
  background-color: var(--dl-color-default-d8ffe6);
}

.foto-plano {
  gap: 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.pesoeliteicon {
  width: 27px;
}
.text04 {
  color: var(--dl-color-default-07a741);
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  font-stretch: normal;
}

.botoes{
  display: flex;
  flex-direction: column;
  gap: 10px;
}