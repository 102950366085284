.container {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.container1 {
  width: 100%;
  display: flex;
  align-self: center;
  max-width: 1352px;
  align-items: flex-start;
  flex-shrink: 0;
  padding-top: 24px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 24px;
  justify-content: flex-start;
  min-height:100vh;
}
.container2 {
  width: 100%;
  display: flex;
  max-width: 1456px;
  align-items: flex-start;
  justify-content: center;
}
.container3 {
  gap: 16px;
  width: 100%;
  display: flex;
  flex-grow: 1;
  max-width: 1052px;
  align-items: center;
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  flex-direction: column;
  padding-bottom: 92px;
  justify-content: center;
}
.cabecalho {
  gap: 8px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: #DEE4E6;
  border-width: 1px;
  padding-bottom: 16px;
  justify-content: space-between;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.text {
  color: rgb(45, 62, 80);
  font-size: 24px;
  align-self: center;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  font-stretch: normal;
  text-decoration: none;
}
.denuncias {
  gap: 16px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-radius: 16px;
  flex-direction: column;
  justify-content: center;
}
.denuncia {
  width: 100%;
  display: flex;
  align-self: stretch;
  box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.09000000357627869) ;
  align-items: flex-end;
  border-radius: 16px;
  flex-direction: column;
}
.motivo {
  gap: 10px;
  width: 100%;
  display: flex;
  padding: 24px;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(222, 228, 230, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 0 0 8px 8px;
  flex-direction: column;
  justify-content: flex-end;
  background-color: var(--dl-color-default-colorgreybg);
}
.text03 {
  color: rgba(45, 62, 80, 1);
  width: 100%;
  font-size: 16px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: 1;
  font-stretch: normal;
  text-decoration: none;
}
.text05 {
  color: rgba(125, 138, 152, 1);
  width: 100%;
  padding: 16px;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: 24px;
  border-color: #D8DFE8;
  border-width: 1px;
  font-stretch: normal;
  border-radius: 8px;
  text-decoration: none;
  background-color: #ffffff;
}
.botao-ignorar {
  cursor: pointer;
  display: flex;
  padding: 12px 20px 12px 24px;
  align-items: center;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-padro);
}
.text07 {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  font-family: "Poppins";
  font-weight: 500;
  line-height: 100%;
  font-stretch: normal;
  text-decoration: none;
}
.denuncia1 {
  width: 100%;
  display: flex;
  align-self: stretch;
  box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.09000000357627869) ;
  align-items: flex-end;
  border-radius: 16px;
  flex-direction: column;
}
.motivo1 {
  gap: 10px;
  width: 100%;
  display: flex;
  padding: 24px;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(222, 228, 230, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 0 0 8px 8px;
  flex-direction: column;
  justify-content: flex-end;
  background-color: var(--dl-color-default-colorgreybg);
}
.text08 {
  color: rgba(45, 62, 80, 1);
  width: 100%;
  font-size: 16px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: 1;
  font-stretch: normal;
  text-decoration: none;
}
.text10 {
  color: rgba(125, 138, 152, 1);
  width: 100%;
  padding: 16px;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: 24px;
  border-color: #D8DFE8;
  border-width: 1px;
  font-stretch: normal;
  border-radius: 8px;
  text-decoration: none;
  background-color: #ffffff;
}
.botao-ignorar1 {
  cursor: pointer;
  display: flex;
  padding: 12px 20px 12px 24px;
  align-items: center;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-padro);
}
.text12 {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  font-family: "Poppins";
  font-weight: 500;
  line-height: 100%;
  font-stretch: normal;
  text-decoration: none;
}
.denuncia2 {
  width: 100%;
  display: flex;
  align-self: stretch;
  box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.09000000357627869) ;
  align-items: flex-end;
  border-radius: 16px;
  flex-direction: column;
}
.motivo2 {
  gap: 10px;
  width: 100%;
  display: flex;
  padding: 24px;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(222, 228, 230, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 0 0 8px 8px;
  flex-direction: column;
  justify-content: flex-end;
  background-color: var(--dl-color-default-colorgreybg);
}
.text13 {
  color: rgba(45, 62, 80, 1);
  width: 100%;
  font-size: 16px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: 1;
  font-stretch: normal;
  text-decoration: none;
}
.text15 {
  color: rgba(125, 138, 152, 1);
  width: 100%;
  padding: 16px;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: 24px;
  border-color: #D8DFE8;
  border-width: 1px;
  font-stretch: normal;
  border-radius: 8px;
  text-decoration: none;
  background-color: #ffffff;
}
.botao-ignorar2 {
  cursor: pointer;
  display: flex;
  padding: 12px 20px 12px 24px;
  align-items: center;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-padro);
}
.text17 {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  font-family: "Poppins";
  font-weight: 500;
  line-height: 100%;
  font-stretch: normal;
  text-decoration: none;
}
.textqntd {
  color: var(--dl-color-default-7d8a98);
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text {
  color: rgba(45, 62, 80, 1);
  font-size: 24px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  font-stretch: normal;
  text-decoration: none;
}
.titulo {
  gap: 8px;
  display: flex;
  align-self: center;
  align-items: center;
}
.paginacao {
  gap: 4px;
  width: 100%;
  display: flex;
  padding: 16px 0 0;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
}
.prev {
  height: 30px;
  display: none;
  padding: 10px;
  overflow: hidden;
  min-width: 30px;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(216, 223, 232, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.text18 {
  color: rgb(125, 138, 152);
  font-size: 16px;
  font-style: Regular;
  text-align: center;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.page-atual {
  height: 30px;
  display: flex;
  padding: 10px;
  overflow: hidden;
  min-width: 30px;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(251, 166, 0, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
  cursor: pointer;
}
.text19 {
  color: var(--dl-color-default-padro);
  font-size: 16px;
  font-style: Regular;
  text-align: center;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.page {
  height: 30px;
  display: flex;
  padding: 10px;
  overflow: hidden;
  min-width: 30px;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(216, 223, 232, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
  cursor: pointer;
}
.text20 {
  color: rgba(125, 138, 152, 1);
  font-size: 16px;
  font-style: Regular;
  text-align: center;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.page1 {
  height: 30px;
  display: flex;
  padding: 10px;
  overflow: hidden;
  min-width: 30px;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(216, 223, 232, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.text21 {
  color: rgb(125, 138, 152);
  font-size: 16px;
  font-style: Regular;
  text-align: center;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.page2 {
  height: 30px;
  display: flex;
  padding: 10px;
  overflow: hidden;
  min-width: 30px;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(216, 223, 232, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.text22 {
  color: rgb(125, 138, 152);
  font-size: 16px;
  font-style: Regular;
  text-align: center;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.next {
  height: 30px;
  display: flex;
  padding: 10px;
  overflow: hidden;
  min-width: 30px;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(216, 223, 232, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.text23 {
  color: rgb(125, 138, 152);
  font-size: 16px;
  font-style: Regular;
  text-align: center;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
@media(max-width: 991px) {
  .container3 {
    padding-bottom: 108px;
  }
  .denuncias {
    gap: 24px;
  }
}
@media(max-width: 767px) {
  .cabecalho {
    gap: 16px;
    flex-direction: column;
  }
  .text {
    font-size: 22px;
  }
  .text01 {
    font-size: 22px;
  }
  .text03 {
    font-size: 15px;
  }
  .text05 {
    font-size: 15px;
  }
  .text07 {
    font-size: 15px;
  }
  .text08 {
    font-size: 15px;
  }
  .text10 {
    font-size: 15px;
  }
  .text12 {
    font-size: 15px;
  }
  .text13 {
    font-size: 15px;
  }
  .text15 {
    font-size: 15px;
  }
  .text17 {
    font-size: 15px;
  }
}
@media(max-width: 479px) {
  .text {
    font-size: 20px;
  }
  .text01 {
    font-size: 20px;
  }
  .text03 {
    font-size: 14px;
  }
  .text05 {
    font-size: 14px;
  }
  .text07 {
    font-size: 14px;
  }
  .text08 {
    font-size: 14px;
  }
  .text10 {
    font-size: 14px;
  }
  .text12 {
    font-size: 14px;
  }
  .text13 {
    font-size: 14px;
  }
  .text15 {
    font-size: 14px;
  }
  .text17 {
    font-size: 14px;
  }
}

.novos-anuncios {
  gap: 16px;
  width: 100%;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}