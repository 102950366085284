.container {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.container1 {
  gap: 24px;
  width: 100%;
  display: flex;
  max-width: 1352px;
  align-self: center;
  align-items: flex-start;
  flex-shrink: 0;
  padding-top: 24px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 24px;
  justify-content: space-between;
}
.container2 {
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: 116px;
  justify-content: center;
}
.minha-conta {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  padding-left: 16px;
  padding-right: 16px;
  flex-direction: column;
}
.cabecalho {
  width: 100%;
  display: flex;
  flex-grow: 1;
  max-height: 40px;
  align-items: center;
  flex-shrink: 0;
  padding-top: 8px;
  padding-left: 0px;
  border-radius: 8px;
  padding-right: 0px;
  padding-bottom: 8px;
}
.text {
  color: var(--dl-color-default-2d3e50);
  width: 100%;
  flex-grow: 1;
  font-size: 24px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  padding-left: 16px;
  padding-right: 16px;
  text-decoration: none;
}

.header {
  gap: 24px;
  width: 100%;
  display: flex;
  padding: 32px;
  overflow: hidden;
  align-self: stretch;
  align-items: flex-start;
  border-color: rgba(216, 223, 232, 1);
  border-style: solid;
  border-width: 1px;
  justify-content: space-between;
  border-radius: 16px;
  background-color: rgba(255, 255, 255, 1);
}
.text12 {
  color: rgb(125, 138, 152);
  font-size: 16px;
  font-family: "Poppins";
  line-height: 1;
}
.image {
  width: 124px;
  height: 124px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}

.meus-dados {
  gap: 24px;
  width: 100%;
  display: flex;
  padding: 32px;
  overflow: hidden;
  align-self: stretch;
  align-items: flex-start;
  border-color: rgba(216, 223, 232, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}
.text01 {
  color: rgba(45, 62, 80, 1);
  width: 100%;
  height: auto;
  flex-grow: 1;
  font-size: 18px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.inputs {
  gap: 24px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: row;
}
.coluna {
  gap: 24px;
  flex: 3;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.coluna1 {
  gap: 24px;
  flex: 5;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.endereco-numero {
  gap: 16px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
}
.cidade-uf {
  gap: 16px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
}
.botao-salvar {
  gap: 5px;
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: 12px 24px;
  max-width: 128px;
  align-self: flex-end;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-padro);
}
.text03 {
  color: var(--dl-color-default-white);
  height: auto;
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 100%;
  font-stretch: normal;
  text-decoration: none;
}
.verificacao {
  gap: 24px;
  display: flex;
  padding: 32px;
  overflow: hidden;
  align-self: stretch;
  align-items: flex-start;
  border-color: rgba(216, 223, 232, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}
.text05 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  flex-grow: 1;
  font-size: 18px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.verificar-telefone {
  gap: 24px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.botao-verificar {
  gap: 5px;
  cursor: pointer;
  display: flex;
  padding: 12px 24px;
  align-self: flex-end;
  align-items: center;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-padro);
}
.text07 {
  color: var(--dl-color-default-white);
  height: auto;
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 100%;
  font-stretch: normal;
  text-decoration: none;
}
.verificado {
  gap: 10px;
  height: 40px;
  overflow: hidden;
  align-self: flex-end;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  display: flex;
}
.text09 {
  color: rgba(39, 204, 99, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.aprovado {
  width: 18px;
  height: 18px;
}
.verificar-email {
  gap: 24px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.botao-verificar1 {
  gap: 5px;
  cursor: pointer;
  padding: 12px 24px;
  align-self: flex-end;
  align-items: center;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-padro);
}
.text11 {
  color: var(--dl-color-default-white);
  height: auto;
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 100%;
  font-stretch: normal;
  text-decoration: none;
}
.verificado1 {
  gap: 10px;
  height: 40px;
  display: flex;
  overflow: hidden;
  align-self: flex-end;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
}
.text13 {
  color: rgba(39, 204, 99, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.aprovado1 {
  width: 18px;
  height: 18px;
}
.botao-alterar-senha {
  gap: 8px;
  cursor: pointer;
  display: flex;
  padding: 12px 20px 12px 24px;
  align-items: center;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-colorgreybg);
}
.text15 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 100%;
  font-stretch: normal;
  text-decoration: none;
}
.chave {
  width: 16px;
  height: 16px;
}
@media (max-width: 991px) {
  .container1 {
    padding-bottom: 16px;
  }
  .container2 {
    padding-bottom: 124px;
  }
  .minha-conta {
    gap: 24px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .cabecalho {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .text {
    padding-left: 0px;
    padding-right: 0px;
  }
  .meus-dados {
    gap: 32px;
    padding-top: 24px;
    padding-left: 0px;
    border-radius: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
  }
  .botao-salvar {
    align-self: center;
  }
  .verificacao {
    padding-top: 24px;
    padding-left: 0px;
    border-radius: 0px;
    padding-right: 0px;
    padding-bottom: 24px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .verificar-telefone {
    flex-direction: column;
  }
  .verificar-email {
    flex-direction: column;
  }
  .botao-alterar-senha {
    width: 100%;
    align-self: center;
  }
}
@media (max-width: 767px) {
  .text {
    font-size: 22px;
  }
  .coluna {
    flex: 0 0 auto;
  }
  .coluna1 {
    flex: 0 0 auto;
  }
}
@media (max-width: 479px) {
  .text {
    font-size: 20px;
  }
}

.disabled {
  opacity: 0.8 !important;
  cursor: initial !important;
}

.botao-reprovar {
  gap: 8px;
  cursor: pointer;
  display: flex;
  padding: 12px 20px 12px 24px;
  align-items: center;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-colorgreybg);
}
