.container {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
  background-color: rgba(240, 244, 246, 1);
}

.container1 {
  gap: 24px;
  width: 100%;
  height: auto;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: 24px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 24px;
  flex-direction: column;
}

.container1-paginaExclusiva {
  background-color: rgba(255, 255, 255, 1);
}

.perfil-trainer {
  gap: 40px;
  width: 100%;
  display: flex;
  padding: 48px 96px;
  overflow: hidden;
  max-width: 1320px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}

.info-pessoais {
  gap: 24px;
  width: 100%;
  display: flex;
  padding: 24px 0;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 8px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}

.container2 {
  gap: 24px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.column {
  gap: 16px;
  display: flex;
  padding: 24px;
  align-items: center;
  border-radius: 16px;
  flex-direction: column;
}

.container3 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.foto-perfil {
  width: 262px;
  height: 262px;
  display: flex;
  overflow: hidden;
  position: relative;
  box-sizing: content-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(251, 166, 0, 1);
  border-style: solid;
  border-width: 5px;
  border-radius: 200px;
}

.imgpersonal {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.verificado-desk {
  gap: 8px;
  display: flex;
  align-items: center;
}

.verificadoicon {
  width: 24px;
  height: 24px;
}

.text {
  color: rgba(39, 204, 99, 1);
  height: auto;
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.nome-local-mobile {
  display: none;
  align-items: center;
  flex-direction: column;
}

.text-denominacao {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: 130%;
  font-stretch: normal;
  text-decoration: none;
}

.text-nome {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  flex-grow: 1;
  font-size: 24px;
  font-style: normal;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: 130%;
  font-stretch: normal;
  text-decoration: none;
}

.avaliacao-cidade {
  gap: 16px;
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
}

.avaliacao {
  gap: 8px;
  display: flex;
  padding: 16px;
  align-self: stretch;
  align-items: center;
  padding-top: 16px;
  cursor: pointer;
  border-radius: 8px;
  flex-direction: column;
  background-color: var(--dl-color-default-f0f4f6);
}

.nota {
  gap: 8px;
  display: flex;
  align-items: center;
}

.vector {
  width: 22px;
  height: 21px;
}

.text004 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 18px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.textquantidade {
  color: var(--dl-color-default-7d8a98);
  height: auto;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.cidade {
  gap: 8px;
  height: 32px;
  display: flex;
  padding: 0 16px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-radius: 24px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}

.localicon {
  width: 14px;
  height: 14px;
}

.text006 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.numero-desk {
  gap: 8px;
  height: 32px;
  display: flex;
  padding: 0 16px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-radius: 24px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}

.telefoneicon {
  width: 14px;
  height: 14px;
}

.numero {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.botaovertelefone {
  color: #E98C00;
  cursor: pointer;
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.container4 {
  top: 0px;
  left: 0px;
  width: 100%;
  display: none;
  position: absolute;
  align-items: center;
  flex-direction: column;
}

.redes-sociais-mobile {
  width: 100%;
  cursor: pointer;
  display: flex;
  max-width: 81px;
  align-self: flex-start;
}

.redes-abertas-mobile {
  width: 100%;
  display: flex;
  height: auto;
  align-items: center;
  transition: .35s ease-in-out;
}

.xfechar {
  width: 14px;
  cursor: pointer;
  height: 14px;
  opacity: 0;
  visibility: hidden;
  transition: .15s ease-in-out;
}

.rede {
  width: 24px;
  cursor: pointer;
  height: 24px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
  z-index: 4;
}

.ellipse218 {
  top: 0px;
  left: 0px;
  width: 24px;
  height: 24px;
  position: absolute;
}

.icons-boldnetwork {
  top: 4.96728515625px;
  left: 4.9697265625px;
  width: 14px;
  height: 14px;
  position: absolute;
}

.tiktok {
  width: 24px;
  cursor: pointer;
  height: 24px;
  z-index: 3;
  margin-left: -8px;
  transition: .35s ease-in-out;
}

.insta {
  width: 24px;
  cursor: pointer;
  height: 24px;
  z-index: 2;
  margin-left: -8px;
  transition: .35s ease-in-out;
}

.face {
  width: 24px;
  cursor: pointer;
  height: 24px;
  z-index: 1;
  margin-left: -8px;
  transition: .35s ease-in-out;
}

.linke-in {
  width: 24px;
  cursor: pointer;
  height: 24px;
  border-radius: 35px;
  z-index: 0;
  margin-left: -8px;
  transition: .35s ease-in-out;
}

.column1 {
  gap: 32px;
  display: flex;
  padding: 24px;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  border-radius: 16px;
  flex-direction: column;
}

.infos {
  gap: 32px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
}

.nome-valor {
  gap: 16px;
  display: flex;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: space-between;
}

.nome-desk {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.text-denominacao1 {
  color: var(--dl-color-default-2d3e50);
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: 130%;
  font-stretch: normal;
  text-decoration: none;
}

.text-nome1 {
  color: var(--dl-color-default-2d3e50);
  flex-grow: 1;
  font-size: 24px;
  font-style: normal;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: 130%;
  font-stretch: normal;
  text-decoration: none;
}

.valor {
  gap: 8px;
  height: 40px;
  display: flex;
  padding: 0 16px;
  overflow: hidden;
  align-self: center;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(255, 193, 75, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 24px;
  justify-content: center;
  background-color: var(--dl-color-default-d8ffe6);
}

.text012 {
  color: var(--dl-color-default-2d3e50);
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.text013 {
  font-weight: 400;
}

.text014 {
  font-style: normal;
}

.text-descricao {
  color: var(--dl-color-default-7d8a98);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.atendimento {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}

.text019 {
  color: rgba(45, 62, 80, 1);
  font-size: 18px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.infos1 {
  gap: 8px;
  display: flex;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}

.frame1171276959 {
  gap: 8px;
  height: 32px;
  display: flex;
  padding: 0 16px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-radius: 24px;
  justify-content: center;
  background-color: var(--dl-color-default-f0f4f6);
}

.text021 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.frame1171277046 {
  gap: 8px;
  height: 32px;
  display: flex;
  padding: 0 16px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-radius: 24px;
  justify-content: center;
  background-color: var(--dl-color-default-f0f4f6);
}

.text023 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.frame1171277047 {
  gap: 8px;
  height: 32px;
  display: flex;
  padding: 0 16px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-radius: 24px;
  justify-content: center;
  background-color: var(--dl-color-default-f0f4f6);
}

.text025 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.frame1171277048 {
  gap: 8px;
  height: 32px;
  display: flex;
  padding: 0 16px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-radius: 24px;
  justify-content: center;
  background-color: var(--dl-color-default-f0f4f6);
}

.text027 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.frame1171277045 {
  gap: 8px;
  height: 32px;
  display: flex;
  padding: 0 16px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-radius: 24px;
  justify-content: center;
  background-color: var(--dl-color-default-f0f4f6);
}

.text029 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.frame1171277049 {
  gap: 8px;
  height: 32px;
  display: flex;
  padding: 0 16px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-radius: 24px;
  justify-content: center;
  background-color: var(--dl-color-default-f0f4f6);
}

.text031 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.frame1171277050 {
  gap: 8px;
  height: 32px;
  display: flex;
  padding: 0 16px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-radius: 24px;
  justify-content: center;
  background-color: var(--dl-color-default-f0f4f6);
}

.text033 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.linguagem {
  gap: 8px;
  height: 32px;
  display: flex;
  padding: 0 16px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(216, 223, 231, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 24px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}

.idiomaicon {
  width: 18px;
  height: 18px;
}

.text035 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.contato {
  gap: 16px;
  display: flex;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}

.botao-wpp {
  gap: 8px;
  cursor: pointer;
  display: flex;
  padding: 16px 24px 16px 32px;
  align-items: center;
  border-radius: 8px;
  justify-content: center;
  background-color: #00cb66;
}

.text037 {
  color: var(--dl-color-default-white);
  height: auto;
  font-size: 20px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 100%;
  font-stretch: normal;
  text-decoration: none;
}

.whats-appicon {
  width: 20px;
  height: 20px;
}

.redes-sociais {
  gap: 16px;
  display: flex;
  align-items: center;
}

.insta1 {
  width: 24px;
  cursor: pointer;
  height: 24px;
}

.face1 {
  width: 24px;
  cursor: pointer;
  height: 24px;
}

.tiktok1 {
  width: 24px;
  cursor: pointer;
  height: 24px;
}

.linked-in {
  width: 24px;
  cursor: pointer;
  height: 24px;
  border-radius: 35px;
}

.rede1 {
  width: 24px;
  cursor: pointer;
  height: 24px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
}

.ellipse2181 {
  top: 0px;
  left: 0px;
  width: 24px;
  height: 24px;
  position: absolute;
}

.icons-boldnetwork1 {
  top: 4.96728515625px;
  left: 4.9697265625px;
  width: 14px;
  height: 14px;
  position: absolute;
}

.numero-mobile {
  gap: 8px;
  height: 32px;
  display: none;
  padding: 0 16px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-radius: 24px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}

.telefoneicon1 {
  width: 14px;
  height: 14px;
}

.numero1 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.botaovertelefone1 {
  color: #E98C00;
  cursor: pointer;
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.compartilhar-denunciar {
  gap: 16px;
  display: flex;
  padding: 24px 0;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}

.botao-compartilhar {
  gap: 5px;
  width: 30px;
  cursor: pointer;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-maincolors_gray-bg);
}

.compartilharicon {
  width: 14px;
  height: 14px;
}

.botao-denunciar {
  gap: 5px;
  width: 30px;
  cursor: pointer;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-maincolors_gray-bg);
}

.alertaicon {
  width: 14px;
  height: 14px;
}

.objetivos {
  gap: 24px;
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}

.text041 {
  color: rgba(45, 62, 80, 1);
  width: 100%;
  height: auto;
  font-size: 20px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.container5 {
  gap: 8px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}

.objetivo {
  gap: 12px;
  width: 100%;
  display: flex;
  padding: 24px;
  max-width: 370px;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(222, 228, 230, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
  justify-content: flex-start;
}

.text043 {
  color: rgba(45, 62, 80, 1);
  font-size: 16px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  font-stretch: normal;
  text-decoration: none;
}

.text045 {
  color: rgb(125, 138, 152);
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.objetivo1 {
  gap: 16px;
  width: 100%;
  display: flex;
  padding: 24px;
  max-width: 370px;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(222, 228, 230, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
  justify-content: flex-start;
}

.text046 {
  color: rgba(45, 62, 80, 1);
  font-size: 16px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.text048 {
  color: rgba(125, 138, 152, 1);
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.objetivo2 {
  gap: 16px;
  width: 100%;
  display: flex;
  padding: 24px;
  max-width: 370px;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(222, 228, 230, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
  justify-content: flex-start;
}

.text051 {
  color: rgba(45, 62, 80, 1);
  font-size: 16px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.text053 {
  color: rgb(125, 138, 152);
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.objetivo3 {
  gap: 16px;
  width: 100%;
  display: flex;
  padding: 24px;
  max-width: 370px;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(222, 228, 230, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
  justify-content: flex-start;
}

.text056 {
  color: rgba(45, 62, 80, 1);
  font-size: 16px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.text058 {
  color: rgb(125, 138, 152);
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.objetivo-extra {
  gap: 16px;
  width: 100%;
  display: flex;
  padding: 24px;
  max-width: 370px;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(251, 166, 0, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
  justify-content: flex-start;
}

.text061 {
  color: rgba(45, 62, 80, 1);
  font-size: 16px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.text063 {
  color: rgba(125, 138, 152, 1);
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.sobre {
  gap: 24px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  padding-top: 24px;
  flex-direction: column;
}

.container6 {
  gap: 72px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-self: center;
  align-items: flex-start;
  flex-direction: row;
  padding-bottom: 24px;
  grid-column-gap: 48px;
}

@media (max-width:991px) {
  .container6 {
    display: flex;
    flex-wrap: wrap;
  }
}

.sobre1 {
  gap: 24px;
  width: 100%;
  display: flex;
  flex-grow: 1;
  max-width: 540px;
  min-width: 350px;
  align-items: flex-start;
  border-radius: 8px;
  flex-direction: column;
}

@media (max-width:991px) {
  .sobre1 {
    max-width: unset;
  }
}

.text065 {
  color: rgba(45, 62, 80, 1);
  width: 100%;
  font-size: 20px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.text067 {
  color: rgba(125, 138, 152, 1);
  width: 100%;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.metodos {
  gap: 24px;
  width: 100%;
  display: flex;
  flex-grow: 1;
  max-width: 540px;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}

@media (max-width:991px) {
  .metodos {
    max-width: unset;
  }
}

.text069 {
  color: rgba(45, 62, 80, 1);
  width: 100%;
  font-size: 20px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.text071 {
  color: rgba(125, 138, 152, 1);
  width: 100%;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.qualificacoes {
  gap: 24px;
  width: 100%;
  display: flex;
  flex-grow: 1;
  max-width: 540px;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}

@media (max-width:991px) {
  .qualificacoes {
    max-width: unset;
  }
}

.text073 {
  color: rgba(45, 62, 80, 1);
  width: 100%;
  font-size: 20px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.text075 {
  color: rgba(125, 138, 152, 1);
  width: 100%;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.planos {
  gap: 24px;
  width: 100%;
  display: flex;
  flex-grow: 1;
  max-width: 540px;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}

@media (max-width:991px) {
  .planos {
    max-width: unset;
  }
}

.text084 {
  color: rgba(45, 62, 80, 1);
  width: 100%;
  font-size: 20px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.text086 {
  color: rgba(125, 138, 152, 1);
  width: 100%;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.online-presencial {
  gap: 8px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}

.online {
  gap: 8px;
  height: 40px;
  display: flex;
  padding: 0 16px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(216, 223, 231, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 24px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}

.text088 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.text089 {
  font-weight: 400;
}

.text090 {
  font-style: normal;
  font-weight: 600;
}

.presencial {
  gap: 8px;
  height: 40px;
  display: flex;
  padding: 0 16px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(216, 223, 231, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 24px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}

.text091 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.text092 {
  font-weight: 400;
}

.text093 {
  font-style: normal;
  font-weight: 600;
}

.text094 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  font-size: 20px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.midias {
  gap: 10px;
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: 24px;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.prev-carrossel {
  gap: 10px;
  top: 0px;
  left: -20px;
  width: 52px;
  bottom: 0px;
  cursor: pointer;
  height: 52px;
  margin: auto;
  display: flex;
  z-index: 1;
  overflow: hidden;
  position: absolute;
  box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.09000000357627869);
  align-items: center;
  flex-shrink: 0;
  border-radius: 52px;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8999999761581421);
}

.arrowicon {
  fill: var(--dl-color-default-2d3e50);
  width: 28px;
  height: 28px;
}

.carrossel {
  gap: 10px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  background-color: rgba(255, 255, 255, 1);
  transition: 0.3s ease-in-out;
}

.midia {
  gap: 10px;
  width: 443px;
  display: flex;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  justify-content: center;
}

.playicon {
  top: 0px;
  left: 0px;
  right: 0px;
  width: 32px;
  bottom: 0px;
  cursor: pointer;
  height: 32px;
  margin: auto;
  position: absolute;
  box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.09000000357627869);
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.midia1 {
  width: 248px;
  height: 248px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 8px;
  background-size: cover;
}

.midia2 {
  width: 248px;
  height: 248px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 8px;
  background-size: cover;
}

.midia3 {
  width: 248px;
  height: 248px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 8px;
  background-size: cover;
}

.midia4 {
  width: 248px;
  height: 248px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 8px;
  background-size: cover;
}

.midia5 {
  width: 248px;
  height: 248px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 8px;
  background-size: cover;
}

.midia6 {
  width: 248px;
  height: 248px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 8px;
  background-size: cover;
}

.next-carrossel {
  gap: 10px;
  top: 0px;
  right: -20px;
  width: 52px;
  bottom: 0px;
  cursor: pointer;
  height: 52px;
  margin: auto;
  display: flex;
  z-index: 1;
  overflow: hidden;
  position: absolute;
  box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.09000000357627869);
  align-items: center;
  flex-shrink: 0;
  border-radius: 52px;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8999999761581421);
}

.arrowicon1 {
  width: 24px;
  height: 24px;
}

.avaliacoes {
  gap: 24px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: 24px;
}

.avaliacao1 {
  gap: 24px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}

.text096 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  font-size: 20px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.nota1 {
  gap: 8px;
  display: flex;
  align-items: center;
  border-radius: 24px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}

.estrelas {
  gap: 8px;
  display: flex;
  align-items: center;
}

.vector1 {
  width: 18px;
  height: 18px;
}

.text098 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 18px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.textquantidade1 {
  color: var(--dl-color-default-7d8a98);
  height: auto;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.comentarios {
  gap: 10px;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1128px;
  align-items: flex-end;
  flex-shrink: 0;
  flex-direction: row;
}

.prev {
  gap: 10px;
  top: 0px;
  left: -20px;
  width: 52px;
  bottom: 0px;
  cursor: pointer;
  height: 52px;
  margin: auto;
  display: flex;
  z-index: 1;
  overflow: hidden;
  position: absolute;
  box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.09000000357627869);
  align-items: center;
  flex-shrink: 0;
  border-radius: 52px;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8999999761581421);
}

.arrowicon2 {
  fill: var(--dl-color-default-2d3e50);
  width: 28px;
  height: 28px;
}

.carrossel1 {
  gap: 16px;
  display: flex;
  overflow: hidden;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  background-color: rgba(255, 255, 255, 1);
  transition: 0.3s ease-in-out;
}

.comentario {
  gap: 16px;
  width: 100%;
  height: auto;
  display: flex;
  height: 195px;
  padding: 24px;
  overflow: hidden;
  max-width: 488px;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 16px;
  flex-direction: column;
  background-color: var(--dl-color-default-f0f4f6);
}

.info-user {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}

.foto-perfil1 {
  width: 52px;
  height: 52px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 200px;
}

.foto {
  width: 100%;
  height: 100%;
}

.text101 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  flex-grow: 1;
  font-size: 20px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.nota-dada {
  gap: 8px;
  display: flex;
  align-items: center;
}

.staricon {
  width: 14px;
  height: 14px;
}

.text103 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 16px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.text105 {
  color: rgb(125, 138, 152);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.comentario1 {
  gap: 16px;
  width: 100%;
  height: auto;
  display: flex;
  padding: 24px;
  overflow: hidden;
  max-width: 488px;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 16px;
  flex-direction: column;
  background-color: var(--dl-color-default-f0f4f6);
}

.info-user1 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}

.foto-perfil2 {
  width: 52px;
  height: 52px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 200px;
}

.foto1 {
  width: 100%;
  height: 100%;
}

.text106 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  flex-grow: 1;
  font-size: 20px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.nota-dada1 {
  gap: 8px;
  display: flex;
  align-items: center;
}

.staricon1 {
  width: 14px;
  height: 14px;
}

.text108 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 16px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.text110 {
  color: rgba(125, 138, 152, 1);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.comentario2 {
  gap: 16px;
  width: 100%;
  height: auto;
  display: flex;
  padding: 24px;
  overflow: hidden;
  max-width: 488px;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 16px;
  flex-direction: column;
  background-color: var(--dl-color-default-f0f4f6);
}

.info-user2 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}

.foto-perfil3 {
  width: 52px;
  height: 52px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 200px;
}

.foto2 {
  width: 100%;
  height: 100%;
}

.text112 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  flex-grow: 1;
  font-size: 20px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.nota-dada2 {
  gap: 8px;
  display: flex;
  align-items: center;
}

.staricon2 {
  width: 14px;
  height: 14px;
}

.text114 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 16px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.text116 {
  color: rgba(125, 138, 152, 1);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.next {
  gap: 10px;
  top: 0px;
  right: -20px;
  width: 52px;
  bottom: 0px;
  cursor: pointer;
  height: 52px;
  margin: auto;
  display: flex;
  z-index: 1;
  overflow: hidden;
  position: absolute;
  box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.09000000357627869);
  align-items: center;
  flex-shrink: 0;
  border-radius: 52px;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8999999761581421);
}

.arrowicon3 {
  width: 24px;
  height: 24px;
}

.text-palavraschave {
  color: rgba(45, 62, 80, 1);
  width: 100%;
  height: auto;
  font-size: 20px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.palavras-chave {
  gap: 8px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: #D8DFE8;
  border-width: 1px;
  padding-bottom: 48px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}

.palavras-chave-paginaExclusiva {
  border: none;
}

.tag {
  gap: 8px;
  height: 30px;
  display: flex;
  padding: 0 12px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(216, 223, 231, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 24px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}

.text119 {
  color: rgba(125, 138, 152, 1);
  height: auto;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.tag01 {
  gap: 8px;
  height: 30px;
  display: flex;
  padding: 0 12px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(216, 223, 231, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 24px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}

.text120 {
  color: rgba(125, 138, 152, 1);
  height: auto;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.tag02 {
  gap: 8px;
  height: 30px;
  display: flex;
  padding: 0 12px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(216, 223, 231, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 24px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}

.text122 {
  color: rgba(125, 138, 152, 1);
  height: auto;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.tag03 {
  gap: 8px;
  height: 30px;
  display: flex;
  padding: 0 12px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(216, 223, 231, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 24px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}

.text124 {
  color: rgba(125, 138, 152, 1);
  height: auto;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.tag04 {
  gap: 8px;
  height: 30px;
  display: flex;
  padding: 0 12px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(216, 223, 231, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 24px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}

.text126 {
  color: rgba(125, 138, 152, 1);
  height: auto;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.tag05 {
  gap: 8px;
  height: 30px;
  display: flex;
  padding: 0 12px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(216, 223, 231, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 24px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}

.text128 {
  color: rgba(125, 138, 152, 1);
  height: auto;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.tag06 {
  gap: 8px;
  height: 30px;
  display: flex;
  padding: 0 12px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(216, 223, 231, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 24px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}

.text130 {
  color: rgba(125, 138, 152, 1);
  height: auto;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.tag07 {
  gap: 8px;
  height: 30px;
  display: flex;
  padding: 0 12px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(216, 223, 231, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 24px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}

.text132 {
  color: rgba(125, 138, 152, 1);
  height: auto;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.tag08 {
  gap: 8px;
  height: 30px;
  display: flex;
  padding: 0 12px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(216, 223, 231, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 24px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}

.text134 {
  color: rgba(125, 138, 152, 1);
  height: auto;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.tag09 {
  gap: 8px;
  height: 30px;
  display: flex;
  padding: 0 12px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(216, 223, 231, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 24px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}

.text136 {
  color: rgba(125, 138, 152, 1);
  height: auto;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.tag10 {
  gap: 8px;
  height: 30px;
  display: flex;
  padding: 0 12px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(216, 223, 231, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 24px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}

.text138 {
  color: rgba(125, 138, 152, 1);
  height: auto;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.atencao {
  gap: 8px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}

.atencaoicon {
  width: 24px;
  height: 24px;
}

.text140 {
  color: rgba(45, 62, 80, 1);
  width: 476px;
  height: auto;
  font-size: 20px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.text142 {
  color: rgba(125, 138, 152, 1);
  width: 100%;
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.anuncios-no-elite {
  gap: 24px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.trainersindicados {
  gap: 24px;
  width: 100%;
  display: flex;
  max-width: 1320px;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}

.text144 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  font-size: 24px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.trainers {
  gap: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrowicon4 {
  width: 20px;
  height: 37px;
}

.carrossel2 {
  gap: 16px;
  width: 100%;
  display: flex;
  overflow: hidden;
  max-width: 1320px;
  align-items: center;
  justify-content: flex-start;
}

.trainer {
  gap: 24px;
  width: 100%;
  display: flex;
  padding: 24px;
  overflow: hidden;
  position: relative;
  max-width: 328px;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(251, 166, 0, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}

.destaque {
  top: 12px;
  left: 12px;
  display: flex;
  padding: 4px 10px;
  z-index: 1;
  overflow: hidden;
  position: absolute;
  align-items: center;
  border-radius: 6px;
  justify-content: center;
  background-color: var(--dl-color-default-padro);
}

.text146 {
  color: var(--dl-color-default-ffffff);
  font-size: 14px;
  font-style: SemiBold;
  text-align: center;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.verificado {
  gap: 4px;
  top: 12px;
  right: 012px;
  height: 25px;
  display: flex;
  z-index: 4;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  justify-content: center;
}

.text148 {
  color: rgba(39, 204, 99, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.registros {
  width: 17px;
  height: 17px;
}

.foto-perfil4 {
  width: 160px;
  height: 160px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 88px;
}

.foto3 {
  width: 100%;
  height: 100%;
}

.nome {
  color: var(--dl-color-default-2d3e50);
  width: 100%;
  height: auto;
  z-index: 1;
  font-size: 20px;
  align-self: center;
  font-style: SemiBold;
  text-align: center;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.descricao {
  color: var(--dl-color-default-7d8a98);
  height: auto;
  z-index: 2;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: center;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.extras {
  gap: 8px;
  display: flex;
  z-index: 3;
  align-self: center;
  align-items: center;
  flex-direction: column;
}

.infos2 {
  gap: 8px;
  display: flex;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: center;
}

.avaliacao2 {
  gap: 4px;
  flex: 1;
  width: 100%;
  height: 32px;
  display: flex;
  padding: 8px 10px;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-f0f4f6);
}

.estrelaicon {
  width: 14px;
  height: 13px;
}

.textnota {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 16px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.textqnt {
  color: var(--dl-color-default-7d8a98);
  height: auto;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.apoio-nutricional {
  gap: 4px;
  flex: 2;
  width: 100%;
  height: 32px;
  display: flex;
  font-size: 12px;
  padding: 8px 14px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-f0f4f6);
}

.appleicon {
  width: 14px;
  height: 14px;
}

.text152 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
}

.valor1 {
  height: 32px;
  display: flex;
  padding: 0 16px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(255, 193, 75, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 24px;
  justify-content: center;
  background-color: var(--dl-color-default-d8ffe6);
}

.text154 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: center;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.text156 {
  font-style: normal;
}

.text157 {
  font-style: normal;
  font-weight: 600;
}

.trainer1 {
  gap: 24px;
  width: 100%;
  display: flex;
  padding: 24px;
  overflow: hidden;
  position: relative;
  max-width: 328px;
  align-items: center;
  flex-shrink: 0;
  border-color: #DFE4E7;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}

.destaque1 {
  top: 12px;
  left: 12px;
  display: none;
  padding: 4px 10px;
  z-index: 1;
  overflow: hidden;
  position: absolute;
  align-items: center;
  border-radius: 6px;
  justify-content: center;
  background-color: var(--dl-color-default-padro);
}

.text158 {
  color: var(--dl-color-default-ffffff);
  font-size: 14px;
  font-style: SemiBold;
  text-align: center;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.verificado1 {
  gap: 4px;
  top: 12px;
  right: 012px;
  height: 25px;
  display: flex;
  z-index: 4;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  justify-content: center;
}

.text160 {
  color: rgba(39, 204, 99, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.registros1 {
  width: 17px;
  height: 17px;
}

.foto-perfil5 {
  width: 160px;
  height: 160px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 88px;
}

.foto4 {
  width: 100%;
  height: 100%;
}

.nome1 {
  color: var(--dl-color-default-2d3e50);
  width: 100%;
  height: auto;
  z-index: 1;
  font-size: 20px;
  align-self: center;
  font-style: SemiBold;
  text-align: center;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.descricao1 {
  color: var(--dl-color-default-7d8a98);
  height: auto;
  z-index: 2;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: center;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.extras1 {
  gap: 8px;
  display: flex;
  z-index: 3;
  align-self: center;
  align-items: center;
  flex-direction: column;
}

.infos3 {
  gap: 8px;
  display: flex;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: center;
}

.avaliacao3 {
  gap: 4px;
  flex: 1;
  width: 100%;
  height: 32px;
  display: flex;
  padding: 8px 10px;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-f0f4f6);
}

.estrelaicon1 {
  width: 14px;
  height: 13px;
}

.textnota1 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 16px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.textqnt1 {
  color: var(--dl-color-default-7d8a98);
  height: auto;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.apoio-nutricional1 {
  gap: 8px;
  flex: 2;
  width: 100%;
  height: 32px;
  display: none;
  padding: 8px 10px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-f0f4f6);
}

.appleicon1 {
  width: 18px;
  height: 18px;
}

.text164 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.valor2 {
  height: 32px;
  display: flex;
  padding: 0 16px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(255, 193, 75, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 24px;
  justify-content: center;
  background-color: var(--dl-color-default-d8ffe6);
}

.text166 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: center;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.text168 {
  font-style: normal;
}

.text169 {
  font-style: normal;
  font-weight: 600;
}

.trainer2 {
  gap: 24px;
  width: 100%;
  display: flex;
  padding: 24px;
  overflow: hidden;
  position: relative;
  max-width: 328px;
  align-items: center;
  flex-shrink: 0;
  border-color: #DFE4E7;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}

.destaque2 {
  top: 12px;
  left: 12px;
  display: none;
  padding: 4px 10px;
  z-index: 1;
  overflow: hidden;
  position: absolute;
  align-items: center;
  border-radius: 6px;
  justify-content: center;
  background-color: var(--dl-color-default-padro);
}

.text170 {
  color: var(--dl-color-default-ffffff);
  font-size: 14px;
  font-style: SemiBold;
  text-align: center;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.verificado2 {
  gap: 4px;
  top: 12px;
  right: 012px;
  height: 25px;
  display: flex;
  z-index: 4;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  justify-content: center;
}

.text172 {
  color: rgba(39, 204, 99, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.registros2 {
  width: 17px;
  height: 17px;
}

.foto-perfil6 {
  width: 160px;
  height: 160px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 88px;
}

.foto5 {
  width: 100%;
  height: 100%;
}

.nome2 {
  color: var(--dl-color-default-2d3e50);
  width: 100%;
  height: auto;
  z-index: 1;
  font-size: 20px;
  align-self: center;
  font-style: SemiBold;
  text-align: center;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.descricao2 {
  color: var(--dl-color-default-7d8a98);
  height: auto;
  z-index: 2;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: center;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.extras2 {
  gap: 8px;
  display: flex;
  z-index: 3;
  align-self: center;
  align-items: center;
  flex-direction: column;
}

.infos4 {
  gap: 8px;
  display: flex;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: center;
}

.avaliacao4 {
  gap: 4px;
  flex: 1;
  width: 100%;
  height: 32px;
  display: flex;
  padding: 8px 10px;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-f0f4f6);
}

.estrelaicon2 {
  width: 14px;
  height: 13px;
}

.textnota2 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 16px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.textqnt2 {
  color: var(--dl-color-default-7d8a98);
  height: auto;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.apoio-nutricional2 {
  gap: 8px;
  flex: 2;
  width: 100%;
  height: 32px;
  display: none;
  padding: 8px 10px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-f0f4f6);
}

.appleicon2 {
  width: 18px;
  height: 18px;
}

.text176 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.valor3 {
  height: 32px;
  display: flex;
  padding: 0 16px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(255, 193, 75, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 24px;
  justify-content: center;
  background-color: var(--dl-color-default-d8ffe6);
}

.text178 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: center;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.text180 {
  font-style: normal;
}

.text181 {
  font-style: normal;
  font-weight: 600;
}

.trainer3 {
  gap: 24px;
  width: 100%;
  display: flex;
  padding: 24px;
  overflow: hidden;
  position: relative;
  max-width: 328px;
  align-items: center;
  flex-shrink: 0;
  border-color: #DFE4E7;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}

.destaque3 {
  top: 12px;
  left: 12px;
  display: none;
  padding: 4px 10px;
  z-index: 1;
  overflow: hidden;
  position: absolute;
  align-items: center;
  border-radius: 6px;
  justify-content: center;
  background-color: var(--dl-color-default-padro);
}

.text182 {
  color: var(--dl-color-default-ffffff);
  font-size: 14px;
  font-style: SemiBold;
  text-align: center;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.verificado3 {
  gap: 4px;
  top: 12px;
  right: 012px;
  height: 25px;
  display: flex;
  z-index: 4;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  justify-content: center;
}

.text184 {
  color: rgba(39, 204, 99, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.registros3 {
  width: 17px;
  height: 17px;
}

.foto-perfil7 {
  width: 160px;
  height: 160px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 88px;
}

.foto6 {
  width: 100%;
  height: 100%;
}

.nome3 {
  color: var(--dl-color-default-2d3e50);
  width: 100%;
  height: auto;
  z-index: 1;
  font-size: 20px;
  align-self: center;
  font-style: SemiBold;
  text-align: center;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.descricao3 {
  color: var(--dl-color-default-7d8a98);
  height: auto;
  z-index: 2;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: center;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.extras3 {
  gap: 8px;
  display: flex;
  z-index: 3;
  align-self: center;
  align-items: center;
  flex-direction: column;
}

.infos5 {
  gap: 8px;
  display: flex;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: center;
}

.avaliacao5 {
  gap: 4px;
  flex: 1;
  width: 100%;
  height: 32px;
  display: flex;
  padding: 8px 10px;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-f0f4f6);
}

.estrelaicon3 {
  width: 14px;
  height: 13px;
}

.textnota3 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 16px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.textqnt3 {
  color: var(--dl-color-default-7d8a98);
  height: auto;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.apoio-nutricional3 {
  gap: 8px;
  flex: 2;
  width: 100%;
  height: 32px;
  display: none;
  padding: 8px 10px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-f0f4f6);
}

.appleicon3 {
  width: 18px;
  height: 18px;
}

.text188 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.valor4 {
  height: 32px;
  display: flex;
  padding: 0 16px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(255, 193, 75, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 24px;
  justify-content: center;
  background-color: var(--dl-color-default-d8ffe6);
}

.text190 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: center;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.text192 {
  font-style: normal;
}

.text193 {
  font-style: normal;
  font-weight: 600;
}

.trainer4 {
  gap: 24px;
  width: 100%;
  display: flex;
  padding: 24px;
  overflow: hidden;
  position: relative;
  max-width: 328px;
  align-items: center;
  flex-shrink: 0;
  border-color: #DFE4E7;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}

.destaque4 {
  top: 12px;
  left: 12px;
  display: none;
  padding: 4px 10px;
  z-index: 1;
  overflow: hidden;
  position: absolute;
  align-items: center;
  border-radius: 6px;
  justify-content: center;
  background-color: var(--dl-color-default-padro);
}

.text194 {
  color: var(--dl-color-default-ffffff);
  font-size: 14px;
  font-style: SemiBold;
  text-align: center;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.verificado4 {
  gap: 4px;
  top: 12px;
  right: 012px;
  height: 25px;
  display: flex;
  z-index: 4;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  justify-content: center;
}

.text196 {
  color: rgba(39, 204, 99, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.registros4 {
  width: 17px;
  height: 17px;
}

.foto-perfil8 {
  width: 160px;
  height: 160px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 88px;
}

.foto7 {
  width: 100%;
  height: 100%;
}

.nome4 {
  color: var(--dl-color-default-2d3e50);
  width: 100%;
  height: auto;
  z-index: 1;
  font-size: 20px;
  align-self: center;
  font-style: SemiBold;
  text-align: center;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.descricao4 {
  color: var(--dl-color-default-7d8a98);
  height: auto;
  z-index: 2;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: center;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.extras4 {
  gap: 8px;
  display: flex;
  z-index: 3;
  align-self: center;
  align-items: center;
  flex-direction: column;
}

.infos6 {
  gap: 8px;
  display: flex;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: center;
}

.avaliacao6 {
  gap: 4px;
  flex: 1;
  width: 100%;
  height: 32px;
  display: flex;
  padding: 8px 10px;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-f0f4f6);
}

.estrelaicon4 {
  width: 14px;
  height: 13px;
}

.textnota4 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 16px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.textqnt4 {
  color: var(--dl-color-default-7d8a98);
  height: auto;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.apoio-nutricional4 {
  gap: 8px;
  flex: 2;
  width: 100%;
  height: 32px;
  display: none;
  padding: 8px 10px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-f0f4f6);
}

.appleicon4 {
  width: 18px;
  height: 18px;
}

.text200 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.valor5 {
  height: 32px;
  display: flex;
  padding: 0 16px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(255, 193, 75, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 24px;
  justify-content: center;
  background-color: var(--dl-color-default-d8ffe6);
}

.text202 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: center;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.text204 {
  font-style: normal;
}

.text205 {
  font-style: normal;
  font-weight: 600;
}

.arrowicon5 {
  width: 20px;
  height: 37px;
}

@media(max-width: 991px) {
  .carrossel {
    overflow: auto;
  }

  .container1 {
    padding-bottom: 16px;
    background-color: #ffffff;
  }

  .perfil-trainer {
    gap: 24px;
    padding: 0px;
  }

  .info-pessoais {
    position: relative;
    padding-top: 0px;
  }

  .container2 {
    gap: 8px;
    align-items: center;
    flex-direction: column;
  }

  .column {
    gap: 24px;
    width: 100%;
    padding: 0px;
    position: relative;
  }

  .verificado-desk {
    display: none;
  }

  .nome-local-mobile {
    display: flex;
  }

  .avaliacao-cidade {
    gap: 8px;
  }

  .avaliacao {
    width: fit-content;
    align-self: center;
    padding-top: 10px;
    padding-left: 16px;
    padding-right: 16px;
    flex-direction: row;
    padding-bottom: 10px;
    justify-content: center;
  }

  .numero-desk {
    display: none;
  }

  .container4 {
    display: flex;
  }

  .column1 {
    gap: 24px;
    padding: 0px;
  }

  .infos {
    gap: 24px;
    border-color: var(--dl-color-default-f0f4f6);
    border-width: 1px;
    padding-bottom: 24px;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }

  .nome-valor {
    align-self: center;
  }

  .nome-desk {
    display: none;
  }

  .text-descricao {
    text-align: center;
  }

  .contato {
    gap: 24px;
    flex-direction: column;
    justify-content: center;
  }

  .botao-wpp {
    padding-left: 28px;
  }

  .redes-sociais {
    display: none;
  }

  .numero-mobile {
    display: flex;
  }

  .compartilhar-denunciar {
    top: 0px;
    right: 0px;
    position: absolute;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .container6 {
    gap: 48px;
  }

  .prev-carrossel {
    display: none;
  }

  .midia {
    width: 402px;
  }

  .midia1 {
    width: 225px;
    height: 225px;
  }

  .midia2 {
    width: 225px;
    height: 225px;
  }

  .midia3 {
    width: 225px;
    height: 225px;
  }

  .midia4 {
    width: 225px;
    height: 225px;
  }

  .midia5 {
    width: 225px;
    height: 225px;
  }

  .midia6 {
    width: 225px;
    height: 225px;
  }

  .next-carrossel {
    display: none;
  }

  .prev {
    display: none;
  }

  .comentario {
    max-width: 432px;
  }

  .comentario1 {
    max-width: 432px;
  }

  .comentario2 {
    max-width: 432px;
  }

  .next {
    display: none;
  }

  .text142 {
    border-top: 0px;
    border-left: 0px;
    border-color: #D8DFE8;
    border-right: 0px;
    border-width: 1px;
    padding-bottom: 24px;
  }

  .text144 {
    padding-top: 24px;
  }

  .arrowicon4 {
    display: none;
  }

  .carrossel2 {
    gap: 24px;
    flex-wrap: wrap;
    padding-bottom: 108px;
    justify-content: center;
  }

  .trainer {
    box-shadow: 4px 8px 30px 0px rgba(0, 0, 0, 0.09000000357627869);
  }

  .trainer1 {
    box-shadow: 4px 8px 30px 0px rgba(0, 0, 0, 0.09000000357627869);
  }

  .trainer2 {
    box-shadow: 4px 8px 30px 0px rgba(0, 0, 0, 0.09000000357627869);
  }

  .trainer3 {
    box-shadow: 4px 8px 30px 0px rgba(0, 0, 0, 0.09000000357627869);
  }

  .trainer4 {
    box-shadow: 4px 8px 30px 0px rgba(0, 0, 0, 0.09000000357627869);
  }

  .arrowicon5 {
    display: none;
  }
}

@media(max-width: 767px) {
  .foto-perfil {
    width: 200px;
    height: 200px;
  }

  .text-denominacao {
    font-size: 15px;
  }

  .vector {
    width: 18px;
    height: 17px;
  }

  .text004 {
    font-size: 16px;
  }

  .textquantidade {
    font-size: 13px;
  }

  .text006 {
    font-size: 15px;
  }

  .valor {
    height: 36px;
  }

  .text012 {
    font-size: 15px;
  }

  .text-descricao {
    font-size: 15px;
  }

  .text021 {
    font-size: 15px;
  }

  .text023 {
    font-size: 15px;
  }

  .text025 {
    font-size: 15px;
  }

  .text027 {
    font-size: 15px;
  }

  .text029 {
    font-size: 15px;
  }

  .text031 {
    font-size: 15px;
  }

  .text033 {
    font-size: 15px;
  }

  .text035 {
    font-size: 15px;
  }

  .botao-wpp {
    padding-top: 14px;
    padding-left: 26px;
    padding-right: 22px;
    padding-bottom: 14px;
  }

  .text037 {
    font-size: 18px;
  }

  .whats-appicon {
    width: 18px;
    height: 18px;
  }

  .numero1 {
    font-size: 15px;
  }

  .botaovertelefone1 {
    font-size: 15px;
  }

  .text041 {
    font-size: 19px;
  }

  .text045 {
    font-size: 15px;
  }

  .text048 {
    font-size: 15px;
  }

  .text053 {
    font-size: 15px;
  }

  .text058 {
    font-size: 15px;
  }

  .text063 {
    font-size: 15px;
  }

  .text065 {
    font-size: 19px;
  }

  .text067 {
    font-size: 15px;
  }

  .text088 {
    font-size: 15px;
  }

  .text091 {
    font-size: 15px;
  }

  .text094 {
    font-size: 19px;
  }

  .midia {
    width: 361px;
  }

  .midia1 {
    width: 202px;
    height: 202px;
  }

  .midia2 {
    width: 202px;
    height: 202px;
  }

  .midia3 {
    width: 202px;
    height: 202px;
  }

  .midia4 {
    width: 202px;
    height: 202px;
  }

  .midia5 {
    width: 202px;
    height: 202px;
  }

  .midia6 {
    width: 202px;
    height: 202px;
  }

  .avaliacao1 {
    gap: 0;
    justify-content: space-between;
  }

  .text096 {
    font-size: 19px;
  }

  .text098 {
    font-size: 17px;
  }

  .textquantidade1 {
    font-size: 13px;
  }

  .comentario {
    max-width: 376px;
  }

  .info-user {
    gap: 12px;
  }

  .text101 {
    font-size: 18px;
  }

  .text105 {
    font-size: 15px;
  }

  .comentario1 {
    max-width: 376px;
  }

  .info-user1 {
    gap: 12px;
  }

  .text106 {
    font-size: 18px;
  }

  .text110 {
    font-size: 15px;
  }

  .comentario2 {
    max-width: 376px;
  }

  .info-user2 {
    gap: 12px;
  }

  .text112 {
    font-size: 18px;
  }

  .text116 {
    font-size: 15px;
  }

  .text-palavraschave {
    font-size: 19px;
  }

  .palavras-chave {
    align-items: flex-start;
    flex-direction: column;
    padding-bottom: 24px;
  }

  .text119 {
    color: rgb(125, 138, 152);
    font-size: 13px;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
  }

  .text120 {
    font-size: 13px;
  }

  .text122 {
    font-size: 13px;
  }

  .text124 {
    font-size: 13px;
  }

  .text126 {
    font-size: 13px;
  }

  .text128 {
    font-size: 13px;
  }

  .text130 {
    font-size: 13px;
  }

  .text132 {
    font-size: 13px;
  }

  .text134 {
    font-size: 13px;
  }

  .text136 {
    font-size: 13px;
  }

  .text138 {
    font-size: 13px;
  }

  .text140 {
    font-size: 18px;
  }

  .text142 {
    font-size: 15px;
  }

  .text144 {
    font-size: 20px;
  }

  .text146 {
    font-size: 13px;
  }

  .foto-perfil4 {
    width: 150px;
    height: 150px;
  }

  .nome {
    font-size: 19px;
  }

  .descricao {
    font-size: 15px;
  }

  .textnota {
    font-size: 15px;
  }

  .textqnt {
    font-size: 13px;
  }

  .text154 {
    color: var(--dl-color-default-2d3e50);
    font-size: 15px;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
  }

  .text155 {
    font-weight: 400;
  }

  .text156 {
    font-style: normal;
    font-weight: 700;
  }

  .text157 {
    font-style: normal;
    font-weight: 700;
  }

  .text158 {
    font-size: 13px;
  }

  .foto-perfil5 {
    width: 150px;
    height: 150px;
  }

  .nome1 {
    font-size: 19px;
  }

  .descricao1 {
    font-size: 15px;
  }

  .textnota1 {
    font-size: 15px;
  }

  .textqnt1 {
    font-size: 13px;
  }

  .text166 {
    color: var(--dl-color-default-2d3e50);
    font-size: 15px;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
  }

  .text167 {
    font-weight: 400;
  }

  .text168 {
    font-style: normal;
    font-weight: 700;
  }

  .text169 {
    font-style: normal;
    font-weight: 700;
  }

  .text170 {
    font-size: 13px;
  }

  .foto-perfil6 {
    width: 150px;
    height: 150px;
  }

  .nome2 {
    font-size: 19px;
  }

  .descricao2 {
    font-size: 15px;
  }

  .textnota2 {
    font-size: 15px;
  }

  .textqnt2 {
    font-size: 13px;
  }

  .text178 {
    color: var(--dl-color-default-2d3e50);
    font-size: 15px;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
  }

  .text179 {
    font-weight: 400;
  }

  .text180 {
    font-style: normal;
    font-weight: 700;
  }

  .text181 {
    font-style: normal;
    font-weight: 700;
  }

  .text182 {
    font-size: 13px;
  }

  .foto-perfil7 {
    width: 150px;
    height: 150px;
  }

  .nome3 {
    font-size: 19px;
  }

  .descricao3 {
    font-size: 15px;
  }

  .textnota3 {
    font-size: 15px;
  }

  .textqnt3 {
    font-size: 13px;
  }

  .text190 {
    color: var(--dl-color-default-2d3e50);
    font-size: 15px;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
  }

  .text191 {
    font-weight: 400;
  }

  .text192 {
    font-style: normal;
    font-weight: 700;
  }

  .text193 {
    font-style: normal;
    font-weight: 700;
  }

  .text194 {
    font-size: 13px;
  }

  .foto-perfil8 {
    width: 150px;
    height: 150px;
  }

  .nome4 {
    font-size: 19px;
  }

  .descricao4 {
    font-size: 15px;
  }

  .textnota4 {
    font-size: 15px;
  }

  .textqnt4 {
    font-size: 13px;
  }

  .text202 {
    color: var(--dl-color-default-2d3e50);
    font-size: 15px;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
  }

  .text203 {
    font-weight: 400;
  }

  .text204 {
    font-style: normal;
    font-weight: 700;
  }

  .text205 {
    font-style: normal;
    font-weight: 700;
  }
}

@media(max-width: 479px) {
  .foto-perfil {
    width: 142px;
    height: 142px;
  }

  .text-denominacao {
    font-size: 14px;
  }

  .avaliacao {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .vector {
    width: 14px;
    height: 13px;
  }

  .text004 {
    font-size: 14px;
  }

  .textquantidade {
    font-size: 12px;
  }

  .text006 {
    font-size: 14px;
  }

  .valor {
    height: 32px;
  }

  .text012 {
    font-size: 14px;
  }

  .text-descricao {
    font-size: 14px;
  }

  .text021 {
    font-size: 14px;
  }

  .text023 {
    font-size: 14px;
  }

  .text025 {
    font-size: 14px;
  }

  .text027 {
    font-size: 14px;
  }

  .text029 {
    font-size: 14px;
  }

  .text031 {
    font-size: 14px;
  }

  .text033 {
    font-size: 14px;
  }

  .text035 {
    font-size: 14px;
  }

  .botao-wpp {
    padding-top: 12px;
    padding-left: 24px;
    padding-right: 20px;
    padding-bottom: 12px;
  }

  .text037 {
    font-size: 16px;
    font-weight: 500;
  }

  .whats-appicon {
    width: 16px;
    height: 16px;
  }

  .numero1 {
    font-size: 14px;
  }

  .botaovertelefone1 {
    font-size: 14px;
  }

  .text041 {
    font-size: 18px;
  }

  .text045 {
    font-size: 14px;
  }

  .text048 {
    color: rgb(125, 138, 152);
    font-size: 14px;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
  }

  .text053 {
    font-size: 14px;
  }

  .text058 {
    font-size: 14px;
  }

  .text063 {
    font-size: 14px;
  }

  .text065 {
    font-size: 18px;
  }

  .text067 {
    font-size: 14px;
  }

  .text088 {
    font-size: 14px;
  }

  .text091 {
    font-size: 14px;
  }

  .text094 {
    font-size: 18px;
  }

  .midia {
    width: 320px;
  }

  .midia1 {
    width: 179px;
    height: 179px;
  }

  .midia2 {
    width: 179px;
    height: 179px;
  }

  .midia3 {
    width: 179px;
    height: 179px;
  }

  .midia4 {
    width: 179px;
    height: 179px;
  }

  .midia5 {
    width: 179px;
    height: 179px;
  }

  .midia6 {
    width: 179px;
    height: 179px;
  }

  .text096 {
    font-size: 18px;
  }

  .text098 {
    font-size: 16px;
  }

  .textquantidade1 {
    font-size: 12px;
  }

  .comentario {
    max-width: 320px;
  }

  .info-user {
    gap: 8px;
  }

  .text101 {
    font-size: 16px;
  }

  .text105 {
    font-size: 14px;
  }

  .comentario1 {
    max-width: 320px;
  }

  .info-user1 {
    gap: 8px;
  }

  .text106 {
    font-size: 16px;
  }

  .text110 {
    font-size: 14px;
  }

  .comentario2 {
    max-width: 320px;
  }

  .info-user2 {
    gap: 8px;
  }

  .text112 {
    font-size: 16px;
  }

  .text116 {
    font-size: 14px;
  }

  .text-palavraschave {
    font-size: 18px;
  }

  .text119 {
    font-size: 12px;
  }

  .text120 {
    font-size: 12px;
  }

  .text122 {
    font-size: 12px;
  }

  .text124 {
    font-size: 12px;
  }

  .text126 {
    font-size: 12px;
  }

  .text128 {
    font-size: 12px;
  }

  .text130 {
    font-size: 12px;
  }

  .text132 {
    font-size: 12px;
  }

  .text134 {
    font-size: 12px;
  }

  .text136 {
    font-size: 12px;
  }

  .text138 {
    font-size: 12px;
  }

  .text140 {
    font-size: 16px;
  }

  .text142 {
    font-size: 14px;
  }

  .text144 {
    font-size: 16px;
  }

  .text146 {
    font-size: 12px;
  }

  .foto-perfil4 {
    width: 140px;
    height: 140px;
  }

  .nome {
    font-size: 18px;
  }

  .descricao {
    font-size: 14px;
  }

  .textnota {
    font-size: 14px;
  }

  .textqnt {
    font-size: 12px;
  }

  .text154 {
    font-size: 14px;
  }

  .text158 {
    font-size: 12px;
  }

  .foto-perfil5 {
    width: 140px;
    height: 140px;
  }

  .nome1 {
    font-size: 18px;
  }

  .descricao1 {
    font-size: 14px;
  }

  .textnota1 {
    font-size: 14px;
  }

  .textqnt1 {
    font-size: 12px;
  }

  .text166 {
    font-size: 14px;
  }

  .text170 {
    font-size: 12px;
  }

  .foto-perfil6 {
    width: 140px;
    height: 140px;
  }

  .nome2 {
    font-size: 18px;
  }

  .descricao2 {
    font-size: 14px;
  }

  .textnota2 {
    font-size: 14px;
  }

  .textqnt2 {
    font-size: 12px;
  }

  .text178 {
    font-size: 14px;
  }

  .text182 {
    font-size: 12px;
  }

  .foto-perfil7 {
    width: 140px;
    height: 140px;
  }

  .nome3 {
    font-size: 18px;
  }

  .descricao3 {
    font-size: 14px;
  }

  .textnota3 {
    font-size: 14px;
  }

  .textqnt3 {
    font-size: 12px;
  }

  .text190 {
    font-size: 14px;
  }

  .text194 {
    font-size: 12px;
  }

  .foto-perfil8 {
    width: 140px;
    height: 140px;
  }

  .nome4 {
    font-size: 18px;
  }

  .descricao4 {
    font-size: 14px;
  }

  .textnota4 {
    font-size: 14px;
  }

  .textqnt4 {
    font-size: 12px;
  }

  .text202 {
    font-size: 14px;
  }
}