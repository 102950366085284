.toggle-group1171276778 {
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.toggle-uncheck {
  width: 38px;
  cursor: pointer;
}
.toggle-check {
  width: 38px;
  cursor: pointer;
}
