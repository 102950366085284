.container {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.container1 {
  gap: 24px;
  width: 100%;
  display: flex;
  max-width: 1352px;
  align-self: center;
  align-items: flex-start;
  flex-shrink: 0;
  min-height: 80vh;
  padding-top: 24px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 24px;
  justify-content: space-between;
}
.container2 {
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  padding-bottom: 230px;
  justify-content: center;
}
.suporte {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  padding-left: 16px;
  padding-right: 16px;
  flex-direction: column;
}
.cabecalho {
  width: 100%;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  max-height: 40px;
  align-items: center;
  flex-shrink: 0;
  padding-top: 8px;
  padding-left: 0px;
  border-radius: 8px;
  padding-right: 0px;
  padding-bottom: 8px;
}
.text {
  color: var(--dl-color-default-2d3e50);
  width: 100%;
  flex-grow: 1;
  font-size: 24px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  padding-left: 16px;
  padding-right: 16px;
  text-decoration: none;
}
.infos {
  gap: 24px;
  width: 100%;
  display: flex;
  padding: 32px;
  overflow: hidden;
  align-self: stretch;
  align-items: flex-start;
  border-color: rgba(216, 223, 232, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}
.text1 {
  color: var(--dl-color-default-7d8a98);
  width: 100%;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Inter;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.enviar-email {
  gap: 24px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.inputs {
  gap: 24px;
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.botao-enviar {
  gap: 5px;
  min-width: 128px;
  max-width: 100%;
  display: flex;
  padding: 12px 24px;
  align-self: flex-end;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-padro);
  cursor: pointer;
}
.text2 {
  color: var(--dl-color-default-white);
  height: auto;
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 100%;
  font-stretch: normal;
  text-decoration: none;
}
@media (max-width: 991px) {
  .container1 {
    padding-bottom: 16px;
  }
  .container2 {
    padding-bottom: 108px;
  }
  .suporte {
    gap: 24px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .cabecalho {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .text {
    padding-left: 0px;
    padding-right: 0px;
  }
  .infos {
    padding-top: 24px;
    padding-left: 0px;
    border-radius: 0px;
    padding-right: 0px;
    padding-bottom: 24px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
  }
  .text1 {
    color: var(--dl-color-default-7d8a98);
    font-size: 16px;
    font-family: Inter;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
  }
  .botao-enviar {
    align-self: center;
  }
}
@media (max-width: 767px) {
  .text {
    font-size: 22px;
  }
  .text1 {
    font-size: 15px;
  }
}
@media (max-width: 479px) {
  .text {
    font-size: 20px;
  }
  .text1 {
    font-size: 14px;
  }
}

.botao-enviar1 {
  gap: 8px;
  cursor: pointer;
  display: flex;
  padding: 12px 20px 12px 24px;
  align-items: center;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-colorgreybg);
  cursor: pointer;
  width: fit-content;
  max-width: 100%;
}
.arrowicon2 {
  width: 16px;
  height: 16px;
}
.plusicon1 {
  width: 16px;
  height: 16px;
  display: none;
}
.text-config1 {
  color: rgba(125, 138, 152, 1);
  font-size: 14px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.btninfo {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.disabled {
  opacity: 0.8 !important;
  cursor: initial !important;
}
