.menu-lateral-desk {
  gap: 24px;
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  flex-grow: 1;
  max-width: 232px;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  padding-top: 0px;
  border-color: rgba(240, 244, 246, 1);
  border-style: solid;
  border-width: 0 1px 0 0;
  padding-left: 0px;
  padding-right: 24px;
  flex-direction: column;
  padding-bottom: 0px;
}
.navlink {
  display: contents;
  cursor:pointer;
}
.novos-anuncios {
  gap: 10px;
  display: flex;
  padding: 10px;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  padding-left: 10px;
  border-radius: 8px;
  text-decoration: none;
}
.plusicon {
  fill: #2D3E50;
  width: 19px;
  height: 19px;
}
.text {
  color:rgba(45, 62, 80, 1);
  height: auto;
  flex-grow: 1;
  font-size: 18px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.navlink1 {
  display: contents;
}
.anuncios {
  gap: 10px;
  display: flex;
  padding: 10px;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  text-decoration: none;
  background-color: rgba(255, 255, 255, 1);
}
.megaphoneicon {
  width: 19px;
  height: 19px;
}
.megaphonelaranja {
  width: 19px;
  height: 19px;
}
.text1 {
  color: rgb(45, 62, 80);
  height: auto;
  flex-grow: 1;
  font-size: 18px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.navlink2 {
  display: contents;
}
.usuarios {
  gap: 10px;
  display: flex;
  padding: 10px;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  text-decoration: none;
  background-color: rgba(255, 255, 255, 1);
}
.perfilicon {
  width: 19px;
  height: 19px;
}
.perfillaranja {
  width: 19px;
  height: 19px;
}
.text4 {
  color: rgb(45, 62, 80);
  height: auto;
  flex-grow: 1;
  font-size: 18px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.verificacao {
  gap: 10px;
  display: flex;
  padding: 10px;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
}
.checkicon {
  width: 19px;
  height: 19px;
}
.checklaranja {
  width: 19px;
  height: 19px;
}
.text5 {
  color: rgb(45, 62, 80);
  height: auto;
  flex-grow: 1;
  font-size: 18px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.navlink3 {
  display: contents;
}
.denuncias {
  gap: 10px;
  display: flex;
  padding: 10px;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  text-decoration: none;
  background-color: rgba(255, 255, 255, 1);
}
.attentionicon {
  width: 19px;
  height: 19px;
}
.attentionlaranja {
  width: 19px;
  height: 19px;
}
.text6 {
  color: rgb(45, 62, 80);
  height: auto;
  flex-grow: 1;
  font-size: 18px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.ativo {
  background-color: var(--dl-color-default-d8ffe6);
}

.ativo .text {
  color: #E88B00;
  font-weight: 600;
}

.ativo .plusicon {
  fill: #E88B00;
}

@media screen and (max-width: 991px) {
  .text {
    font-size:16px;
  }

  .menu-lateral-desk {
    gap:16px;
  }

  .plusicon, .megaphoneicon, .megaphonelaranja,
  .perfilicon, .perfillaranja,
  .checkicon, .checklaranja,
  .attentionicon, .attentionlaranja {
    width:17px;
    height:17px;
  }
}