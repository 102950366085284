.container-inaugural {
    width: 100%;
    height: auto;
    max-width:1320px;
    margin: 0 auto;
    padding-top:32px;
    display: flex;
    position: relative;
    align-items: flex-start;
    flex-direction: column;
  }
  .categorias {
    gap: 40px;
    width: 100%;
    height: auto;
    display: flex;
    padding: 0 52px;
    align-items: center;
    flex-shrink: 0;
    border-radius: 8px;
    justify-content: center;
    background-color: rgba(23, 25, 35, 1);
  }
  .trumpet-celebrations51 {
    width: 200px;
    height: 100px;
  }
  .lanc-banner-container{
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .text {
    color: var(--dl-color-default-padro);
    height: auto;
    font-size: 36px;
    font-style: italic;
    text-align: center;
    font-family: Poppins;
    font-weight: 800;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .bem-vindo-banner {
    font-size: 16px;
    line-height: 24px;
    font-family: Poppins;
    font-weight: 400;
    color: white;
  }
  .amarelo {
    color: var(--dl-color-default-padro);
  }
  .frame1171277155 {
    gap: 12px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
  }
  .text01 {
    color: var(--dl-color-default-ffffff);
    height: auto;
    font-size: 14px;
    font-style: Regular;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .text03 {
    color: var(--dl-color-default-7d8a98);
    height: auto;
    font-size: 14px;
    font-style: Regular;
    text-align: left;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .trumpet-celebrations52 {
    width: 200px;
    height: 100px;
  }
  .categorias1 {
    gap: 24px;
    width: 100%;
    display: none;
    padding: 8px;
    align-items: center;
    border-radius: 8px;
    justify-content: center;
    background-color: rgba(23, 25, 35, 1);
  }
  .trumpet-celebrations511 {
    width: 54px;
    height: auto;
    align-self: stretch;
    object-fit: cover;
    object-position: center;
  }
  .frame1171277157 {
    /* gap: 16px; */
    display: flex;
    padding: 8px 0;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .text05 {
    color: var(--dl-color-default-padro);
    height: auto;
    font-size: 20px;
    align-self: stretch;
    font-style: italic;
    text-align: center;
    font-family: Poppins;
    font-weight: 800;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .frame11712771551 {
    gap: 4px;
    display: flex;
    align-self: stretch;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
  .text06 {
    color: var(--dl-color-default-ffffff);
    height: auto;
    font-size: 14px;
    align-self: stretch;
    font-style: Regular;
    text-align: center;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .text08 {
    color: var(--dl-color-default-7d8a98);
    height: auto;
    font-size: 10px;
    align-self: stretch;
    font-style: Regular;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .trumpet-celebrations521 {
    width: 54px;
    height: auto;
    align-self: stretch;
    object-fit: cover;
    object-position: center;
  }
  @media(max-width: 1250px) {
    .trumpet-celebrations51 {
      width: 150px;
      height: 75px;
    }
    .text {
      color: var(--dl-color-default-padro);
      font-size: 36px;
      font-style: italic;
      font-family: Poppins;
      font-weight: 800;
      line-height: normal;
      text-decoration: none;
    }
    .frame1171277155 {
      gap: 6px;
    }
    .trumpet-celebrations52 {
      width: 150px;
      height: 75px;
    }
    .text05 {
      color: var(--dl-color-default-padro);
      font-size: 20px;
      font-style: italic;
      font-family: Poppins;
      font-weight: 800;
      line-height: normal;
      text-decoration: none;
    }
  }
  @media(max-width: 1093px) {
    .categorias {
      display: none;
    }
    .categorias1 {
      display: flex;
    }
    .text01 {     
      font-size: 11px;
    }
    .text05 {
      align-self: center;
    }
    .lanc-banner-container {      
      margin-top: unset;
      margin-bottom: unset;
    }
    .frame11712771551 {
      align-self: center;
    }
  }
  @media(max-width: 479px) {
    .categorias1 {
      gap: 16px;
    }
  }
  
  @media screen and (max-width:350px) {
    .container-inaugural img {
        width:auto;
        height:70px;
        align-self:center;
    }
  }

  @media screen and (max-width: 1320px) {
    .container-inaugural {
        padding-left:16px;
        padding-right:16px;
    }
  }