.container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.container1 {
  gap: 24px;
  width: 100%;
  display: flex;
  max-width: 1352px;
  align-self: center;
  align-items: flex-start;
  min-height: 100vh;
  flex-shrink: 0;
  padding-top: 24px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 24px;
  justify-content: space-between;
}
.container2 {
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  padding-bottom: 230px;
  justify-content: center;
}
.avaliacoes {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  padding-left: 16px;
  padding-right: 16px;
  flex-direction: column;
}
.cabecalho {
  width: 100%;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  max-height: 40px;
  align-items: center;
  flex-shrink: 0;
  padding-top: 8px;
  padding-left: 0px;
  border-radius: 8px;
  padding-right: 0px;
  padding-bottom: 8px;
}
.text {
  color: var(--dl-color-default-2d3e50);
  width: 100%;
  flex-grow: 1;
  font-size: 24px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  padding-left: 16px;
  padding-right: 16px;
  text-decoration: none;
}
.link-avaliar {
  gap: 24px;
  width: 100%;
  display: flex;
  padding: 32px;
  overflow: hidden;
  align-self: stretch;
  align-items: flex-start;
  border-color: rgba(216, 223, 232, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}
.text-titulo {
  color: rgba(45, 62, 80, 1);
  width: 100%;
  font-size: 20px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text02 {
  color: var(--dl-color-default-7d8a98);
  width: 100%;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: "Inter";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.gerar-link {
  gap: 16px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-shrink: 0;
}
.container3 {
  gap: 10px;
  width: 100%;
  display: flex;
  max-width: 539px;
  align-items: flex-end;
}
.botao-gerar {
  gap: 5px;
  width: fit-content;
  cursor: pointer;
  display: flex;
  padding: 12px 24px;
  min-width: fit-content;
  align-items: center;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-padro);
}
.text03 {
  color: var(--dl-color-default-white);
  height: auto;
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 100%;
  font-stretch: normal;
  text-decoration: none;
}
.container4 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}
.link {
  gap: 10px;
  width: 100%;
  height: 40px;
  display: flex;
  padding: 10px 16px;
  overflow: hidden;
  max-width: 539px;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(216, 223, 232, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
}
.text05 {
  color: rgb(125, 138, 152);
  width: 100%;
  overflow: hidden;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  white-space: nowrap;
  font-stretch: normal;
  text-overflow: ellipsis;
  text-decoration: none;
}
.botao-copiar {
  gap: 4px;
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: 8px 12px 8px 16px;
  max-width: fit-content;
  align-items: center;
  border-radius: 8px;
  justify-content: center;
}
.text06 {
  color: var(--dl-color-default-07a741);
  font-size: 14px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 100%;
  font-stretch: normal;
  text-decoration: none;
}
.copy {
  width: 14px;
  height: 14px;
}
.text08 {
  color: rgb(125, 138, 152);
  width: 100%;
  font-size: 16px;
  align-self: stretch;
  text-align: left;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.75;
  font-stretch: normal;
  text-decoration: none;
}
.text09 {
  color: #2d3e50;
  font-weight: 700;
}
.text10 {
  font-style: normal;
}
.text11 {
  font-size: 15px;
  line-height: 1.3;
}
.text12 {
  font-size: 15px;
  font-style: normal;
  line-height: 1.3;
}
.text13 {
  font-size: 15px;
  line-height: 1.3;
}
.text14 {
  font-size: 15px;
  font-style: normal;
  line-height: 1.3;
}
.lista-avaliacoes {
  gap: 24px;
  width: 100%;
  display: flex;
  padding: 32px;
  overflow: hidden;
  align-self: stretch;
  align-items: flex-start;
  border-color: rgba(216, 223, 232, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}
.cabecalho1 {
  gap: 24px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.text16 {
  color: rgba(45, 62, 80, 1);
  font-size: 20px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.nota {
  gap: 8px;
  display: flex;
  align-items: center;
  border-radius: 24px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.estrelas {
  gap: 8px;
  display: flex;
  align-items: center;
}
.staricon {
  width: 18px;
  height: 18px;
}
.text18 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 18px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.textqntd {
  color: var(--dl-color-default-7d8a98);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  font-stretch: normal;
  text-decoration: none;
}
.lista {
  gap: 16px;
  width: 100%;
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  background-color: rgba(255, 255, 255, 1);
}
.avaliacao {
  gap: 16px;
  width: 100%;
  display: flex;
  padding: 24px;
  height:210px;
  max-width: 470px;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 16px;
  flex-direction: column;
  background-color: var(--dl-color-default-f0f4f6);
}
.infouser {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.foto-perfil {
  width: 52px;
  height: 52px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 200px;
  background-size: cover;
  background-image: url("/pastedimage-8m5-200h.png");
}
.text-nome {
  color: rgba(45, 62, 80, 1);
  width: 100%;
  flex-grow: 1;
  font-size: 20px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  font-stretch: normal;
  text-decoration: none;
}
.nota1 {
  gap: 8px;
  display: flex;
  align-items: center;
}
.staricon1 {
  width: 14px;
  height: 14px;
}
.text21 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 16px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text23 {
  color: rgba(125, 138, 152, 1);
  width: 100%;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.avaliacao1 {
  gap: 16px;
  width: 100%;
  display: flex;
  padding: 24px;
  overflow: hidden;
  max-width: 470px;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 16px;
  flex-direction: column;
  background-color: var(--dl-color-default-f0f4f6);
}
.infouser1 {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.foto-perfil1 {
  width: 52px;
  height: 52px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 200px;
  background-size: cover;
  background-image: url("/pastedimage-8m5-200h.png");
}
.text-nome1 {
  color: rgba(45, 62, 80, 1);
  width: 100%;
  flex-grow: 1;
  font-size: 20px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  font-stretch: normal;
  text-decoration: none;
}
.nota2 {
  gap: 8px;
  display: flex;
  align-items: center;
}
.staricon2 {
  width: 14px;
  height: 14px;
}
.text26 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 16px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text28 {
  color: rgba(125, 138, 152, 1);
  width: 100%;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.avaliacao2 {
  gap: 16px;
  width: 100%;
  display: flex;
  padding: 24px;
  overflow: hidden;
  max-width: 470px;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 16px;
  flex-direction: column;
  background-color: var(--dl-color-default-f0f4f6);
}
.infouser2 {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.foto-perfil2 {
  width: 52px;
  height: 52px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 200px;
  background-size: cover;
  background-image: url("/pastedimage-8m5-200h.png");
}
.text-nome2 {
  color: rgba(45, 62, 80, 1);
  width: 100%;
  flex-grow: 1;
  font-size: 20px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  font-stretch: normal;
  text-decoration: none;
}
.nota3 {
  gap: 8px;
  display: flex;
  align-items: center;
}
.staricon3 {
  width: 14px;
  height: 14px;
}
.text31 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 16px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text33 {
  color: rgba(125, 138, 152, 1);
  width: 100%;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.avaliacao3 {
  gap: 16px;
  width: 100%;
  display: flex;
  padding: 24px;
  overflow: hidden;
  max-width: 470px;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 16px;
  flex-direction: column;
  background-color: var(--dl-color-default-f0f4f6);
}
.infouser3 {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.foto-perfil3 {
  width: 52px;
  height: 52px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 200px;
  background-size: cover;
  background-image: url("/pastedimage-8m5-200h.png");
}
.text-nome3 {
  color: rgba(45, 62, 80, 1);
  width: 100%;
  flex-grow: 1;
  font-size: 20px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  font-stretch: normal;
  text-decoration: none;
}
.nota4 {
  gap: 8px;
  display: flex;
  align-items: center;
}
.staricon4 {
  width: 14px;
  height: 14px;
}
.text36 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 16px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text38 {
  color: rgba(125, 138, 152, 1);
  width: 100%;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
@media(max-width: 991px) {
  .container1 {
    padding-bottom: 16px;
  }
  .container2 {
    padding-bottom: 108px;
  }
  .avaliacoes {
    gap: 24px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .cabecalho {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .text {
    padding-left: 0px;
    padding-right: 0px;
  }
  .link-avaliar {
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 16px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
  }
  .gerar-link {
    gap: 8px;
  }
  .lista-avaliacoes {
    padding-top: 16px;
    border-width: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 16px;
  }
  .lista {
    gap: 8px;
  }
  .avaliacao {
    max-width: 100%;
  }
  .avaliacao1 {
    max-width: 100%;
  }
  .avaliacao2 {
    max-width: 100%;
  }
  .avaliacao3 {
    max-width: 100%;
  }
}
@media(max-width: 767px) {
  .text {
    font-size: 22px;
  }
  .text-titulo {
    font-size: 18px;
  }
  .text02 {
    font-size: 15px;
  }
  .text08 {
    font-size: 15px;
  }
  .text16 {
    font-size: 18px;
  }
  .text18 {
    font-size: 17px;
  }
  .textqntd {
    font-size: 15px;
  }
  .foto-perfil {
    width: 50px;
    height: 50px;
  }
  .text-nome {
    font-size: 18px;
  }
  .text23 {
    font-size: 15px;
  }
  .foto-perfil1 {
    width: 50px;
    height: 50px;
  }
  .text-nome1 {
    font-size: 18px;
  }
  .text28 {
    font-size: 15px;
  }
  .foto-perfil2 {
    width: 50px;
    height: 50px;
  }
  .text-nome2 {
    font-size: 18px;
  }
  .text33 {
    font-size: 15px;
  }
  .foto-perfil3 {
    width: 50px;
    height: 50px;
  }
  .text-nome3 {
    font-size: 18px;
  }
  .text38 {
    font-size: 15px;
  }
}
@media(max-width: 479px) {
  .text {
    font-size: 20px;
  }
  .text-titulo {
    font-size: 16px;
  }
  .text02 {
    font-size: 14px;
  }
  .text08 {
    font-size: 14px;
  }
  .text16 {
    font-size: 16px;
  }
  .text18 {
    font-size: 16px;
  }
  .textqntd {
    font-size: 14px;
    text-align: center;
  }
  .foto-perfil {
    width: 48px;
    height: 48px;
  }
  .text-nome {
    font-size: 16px;
  }
  .text23 {
    font-size: 14px;
  }
  .foto-perfil1 {
    width: 48px;
    height: 48px;
  }
  .text-nome1 {
    font-size: 16px;
  }
  .text28 {
    font-size: 14px;
  }
  .foto-perfil2 {
    width: 48px;
    height: 48px;
  }
  .text-nome2 {
    font-size: 16px;
  }
  .text33 {
    font-size: 14px;
  }
  .foto-perfil3 {
    width: 48px;
    height: 48px;
  }
  .text-nome3 {
    font-size: 16px;
  }
  .text38 {
    font-size: 14px;
  }
}
