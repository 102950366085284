.not-found-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.not-found-container1 {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.not-found-text1 {
  color: var(--dl-color-default-padro);
  font-size: 252px;
  margin-top: -20px;
  font-weight: 900;
  margin-bottom: -20px;
  letter-spacing: -20px;
}
.not-found-container2 {
  width: 421px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.not-found-text2 {
  text-align: center;
  font-weight: 400;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom:3rem;
}
.text-logo {
  color: rgba(45, 62, 80, 1);
  height: auto;
  font-size: 40px;
  font-style: italic;
  text-align: left;
  font-family: Poppins;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text {
  color: var(--dl-color-default-2d3e50);
}
.text01 {
  color: var(--dl-color-default-padro);
  font-weight: 700;
}
.text02 {
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
}
