ul {
    margin-left: 1em;
}

li::marker {
    color: var(--dl-color-default-7d8a98);
}

.container-pagina {
    width: 100%;
    height: auto;
    display: flex;
    overflow: hidden;
    align-items: flex-start;
    flex-shrink: 0;
    flex-direction: column;
    padding: 24px 0px;
}

.corpo-pagina {
    height: auto;
    display: flex;
    align-self: center;
    align-items: flex-start;
    flex-direction: column;
    max-width: 1320px;
    gap: 32px;
    padding: 52px 110px;
}

.titulo-pagina {
    font-family: Poppins;
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;
    text-align: left;
    color: var(--dl-color-default-2d3e50);
}

.divisor-titulo-mobile {
    display: none;
}

.secao {
    gap: 32px;
    display: flex;
    align-self: stretch;
    align-items: flex-start;
    flex-direction: column;
    margin: 32px 0px;
}

.titulo-secao {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    text-align: left;
    color: var(--dl-color-default-2d3e50);
}

.text-cinza-claro {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: var(--dl-color-default-7d8a98);
}

.group-img-como-participar {
    display: flex;
    gap: 16px;
    width: fit-content;
    align-items: center;
}

.img-como-participar {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: fit-content;
}

.img-feed {
    width: 255px;
    height: 255px;
}

.img-stories {
    width: 143px;
    height: 255px;
}

.botao-baixar {
    width: 100%;
    height: 40px;
    padding: 12px 20px 12px 24px;
    gap: 8px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--dl-color-default-f0f4f6);
    cursor: pointer;
}

.botao-baixar:hover {
    background-image: linear-gradient(rgb(0 0 0/10%) 0 0);
}

.botao-baixar p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    color: var(--dl-color-default-2d3e50);
}

@media screen and (max-width: 768px) {
    .container-pagina {
        background-color: var(--dl-color-default-f0f4f6);
    }

    .corpo-pagina {
        width: 100%;
        padding: 24px 16px;
    }

    .titulo-pagina {
        font-size: 24px;
        line-height: 36px;
    }

    .divisor-titulo-mobile {
        display: block;
        background-color: white;
        height: 1px;
        width: 100%;
    }

    .secao {
        margin: 0px;
    }

    .titulo-secao {
        font-size: 16px;
        line-height: 24px;
    }

    .text-cinza-claro {
        font-size: 14px;
        line-height: 21px;
    }

    .img-feed {
        width: 190px;
        height: 190px;
    }

    .img-stories {
        width: 106px;
        height: 190px;
    }
}