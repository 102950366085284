.container {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
  background-color: rgba(240, 244, 246, 1);
}
.container1 {
  width: 100%;
  height: auto;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: 24px;
  padding-left: 16px;
  padding-right: 16px;
  flex-direction: column;
  padding-bottom: 108px;
}
.conteudo {
  gap: 32px;
  width: 100%;
  display: flex;
  overflow: hidden;
  max-width: 1320px;
  align-self: center;
  align-items: center;
  padding-top: 52px;
  padding-left: 110px;
  border-radius: 8px;
  padding-right: 110px;
  flex-direction: column;
  padding-bottom: 52px;
}
.text {
  color: rgb(45, 62, 80);
  width: 100%;
  height: auto;
  font-size: 32px;
  align-self: flex-start;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text03 {
  color: rgb(125, 138, 152);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.text03 p {
  margin-top:16px;
}

.text03 p:first-item {
  margin-top:0;
}
@media(max-width: 991px) {
  .container1 {
    padding-bottom: 80px;
  }
  .conteudo {
    gap: 24px;
    padding-top: 0px;
    border-color: #D8DFE8;
    border-width: 1px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 24px;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .text {
    font-size: 28px;
  }
}
@media(max-width: 767px) {
  .text {
    font-size: 24px;
  }
  .text03 {
    font-size: 15px;
  }
}
@media(max-width: 479px) {
  .text {
    font-size: 20px;
  }
  .text03 {
    font-size: 14px;
  }
}
