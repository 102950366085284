.rodape {
  gap: 24px;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 40px 24px;
  position: relative;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(23, 25, 35, 1);
}
.conteudo {
  gap: 48px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.opcoes {
  gap: 32px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  align-items: flex-start;
  flex-shrink: 0;
  grid-row-gap: 24px;
  justify-content: center;
}
.text {
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
  height: auto;
  font-size: 16px;
  font-style: SemiBold;
  text-align: center;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text02 {
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
  height: auto;
  font-size: 16px;
  font-style: SemiBold;
  text-align: center;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text04 {
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
  height: auto;
  font-size: 16px;
  font-style: SemiBold;
  text-align: center;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text06 {
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
  height: auto;
  font-size: 16px;
  font-style: SemiBold;
  text-align: center;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text08 {
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
  height: auto;
  font-size: 16px;
  font-style: SemiBold;
  text-align: center;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text10 {
  color: rgba(125, 138, 152, 1);
  width: 100%;
  font-size: 14px;
  align-self: center;
  font-style: Regular;
  text-align: center;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.root-class-name1 {
  margin-top: 84px;
}


















@media(max-width: 991px) {
  .root-class-name1 {
    margin-top: 0px;
  }
}
@media(max-width: 767px) {
  .rodape {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .conteudo {
    gap: 32px;
  }
  .opcoes {
    align-items: center;
    flex-direction: column;
  }
  .text10 {
    font-size: 13px;
  }
}
@media(max-width: 479px) {
  .text10 {
    font-size: 12px;
  }
}
