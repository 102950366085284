.container {
    width: 100%;
    height: auto;
    display: flex;
    overflow: hidden;
    align-items: center;
    flex-shrink: 0;
    flex-direction: column;
    background-color: #ffffff;
  }
  .container1 {
    gap: 32px;
    display: flex;
    padding: 52px 24px;
    overflow: hidden;
    align-self: stretch;
    align-items: center;
    flex-direction: column;
  }
  .bem-vindo {
    gap: 52px;
    width: 100%;
    display: flex;
    padding: 24px 0;
    overflow: hidden;
    max-width: 1320px;
    align-self: center;
    align-items: flex-start;
    flex-shrink: 0;
    justify-content: center;
  }
  .image-mobile {
    display: none;
    object-fit: cover;
    border-radius: 16px;
  }
  .video-mobile {
    display: none;
    object-fit: cover;
    border-radius: 16px;
  }
  .column {
    gap: 40px;
    display: flex;
    flex-grow: 1;
    align-self: stretch;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
  }
  .text-titulo {
    color: rgb(45, 62, 80);
    height: auto;
    font-size: 32px;
    align-self: stretch;
    font-style: SemiBold;
    text-align: left;
    font-family: "Poppins";
    font-weight: 600;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .text {
    color: #2d3e50;
    font-weight: 600;
  }
  .text001 {
    color: var(--dl-color-default-padro);
    font-style: italic;
    font-weight: 700;
  }
  .text002 {
    font-style: italic;
    font-weight: 700;
  }
  .text003 {
    font-weight: 700;
  }
  .text004 {
    color: rgb(125, 138, 152);
    height: auto;
    font-size: 16px;
    align-self: stretch;
    font-style: Regular;
    text-align: left;
    font-family: "Poppins";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .text005 {
    font-weight: 400;
  }
  .text006 {
    font-style: italic;
    font-weight: 700;
  }
  .text007 {
    color: var(--dl-color-default-7d8a98);
  }
  .botao-anunciar {
    display: flex;
    padding: 16px 32px;
    align-items: center;
    border-radius: 8px;
    cursor:pointer;
    justify-content: center;
    background-color: var(--dl-color-default-padro);
  }
  .text012 {
    color: var(--dl-color-default-white);
    height: auto;
    font-size: 20px;
    font-style: Medium;
    text-align: left;
    font-family: Poppins;
    font-weight: 500;
    line-height: 100%;
    font-stretch: normal;
    text-decoration: none;
  }
  .image-desk {
    height: 400px;
    object-fit: cover;
    border-radius: 16px;
  }
  .video-desk {
    height: 400px;
    min-width: 634px;
    flex-grow: 1;
    object-fit: cover;
    border-radius: 16px;
  }
  .motivos-anunciar {
    gap: 40px;
    width: 100%;
    display: flex;
    padding: 24px 0;
    max-width: 1320px;
    align-self: center;
    align-items: center;
    flex-direction: column;
  }
  .text-titulo1 {
    color: rgb(45, 62, 80);
    height: auto;
    font-size: 32px;
    align-self: stretch;
    font-style: SemiBold;
    text-align: center;
    font-family: "Poppins";
    font-weight: 600;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .text014 {
    color: #2d3e50;
    font-weight: 600;
  }
  .text015 {
    color: var(--dl-color-default-padro);
    font-style: italic;
    font-weight: 700;
  }
  .text016 {
    font-style: italic;
    font-weight: 700;
  }
  .text017 {
    font-weight: 700;
  }
  .motivos {
    gap: 32px;
    display: flex;
    align-self: stretch;
    align-items: stretch;
    flex-shrink: 0;
    justify-content: center;
  }
  .card {
    gap: 24px;
    flex: 1;
    display: flex;
    padding: 24px 24px 32px;
    overflow: hidden;
    flex-grow: 1;
    box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.09000000357627869) ;
    align-items: flex-start;
    flex-shrink: 0;
    border-radius: 16px;
    flex-direction: column;
    background-color: var(--dl-color-default-ffffff);
  }
  .icon {
    gap: 24px;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    border-radius: 16px;
    flex-direction: column;
    justify-content: center;
    background-color: var(--dl-color-default-d8ffe6);
  }
  .iconcommunity {
    width: 32px;
    object-fit: cover;
  }
  .text018 {
    color: var(--dl-color-default-2d3e50);
    font-size: 18px;
    align-self: stretch;
    font-style: normal;
    text-align: left;
    font-family: Poppins;
    font-weight: 600;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .card01 {
    gap: 24px;
    flex: 1;
    display: flex;
    padding: 24px 24px 32px;
    overflow: hidden;
    flex-grow: 1;
    align-self: stretch;
    box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.09000000357627869) ;
    align-items: flex-start;
    border-radius: 16px;
    flex-direction: column;
    background-color: var(--dl-color-default-ffffff);
  }
  .icon1 {
    gap: 24px;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    border-radius: 16px;
    flex-direction: column;
    justify-content: center;
    background-color: var(--dl-color-default-d8ffe6);
  }
  .iconstar {
    width: 32px;
    object-fit: cover;
  }
  .text020 {
    color: rgba(45, 62, 80, 1);
    height: auto;
    font-size: 18px;
    align-self: stretch;
    font-style: SemiBold;
    text-align: left;
    font-family: Poppins;
    font-weight: 600;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .text021 {
    color: var(--dl-color-default-2d3e50);
    text-decoration: NONE;
  }
  .text022 {
    color: var(--dl-color-default-padro);
    text-decoration: UNDERLINE;
  }
  .card02 {
    gap: 24px;
    flex: 1;
    display: flex;
    padding: 24px 24px 32px;
    overflow: hidden;
    flex-grow: 1;
    box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.09000000357627869) ;
    align-items: flex-start;
    flex-shrink: 0;
    border-radius: 16px;
    flex-direction: column;
    background-color: var(--dl-color-default-ffffff);
  }
  .icon2 {
    gap: 24px;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    border-radius: 16px;
    flex-direction: column;
    justify-content: center;
    background-color: var(--dl-color-default-d8ffe6);
  }
  .iconrocket {
    width: 32px;
    object-fit: cover;
  }
  .text024 {
    color: var(--dl-color-default-2d3e50);
    height: auto;
    font-size: 18px;
    align-self: stretch;
    font-style: SemiBold;
    text-align: left;
    font-family: Poppins;
    font-weight: 600;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .card03 {
    gap: 24px;
    flex: 1;
    display: flex;
    padding: 24px 24px 32px;
    overflow: hidden;
    flex-grow: 1;
    box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.09000000357627869) ;
    align-items: flex-start;
    flex-shrink: 0;
    border-radius: 16px;
    flex-direction: column;
    background-color: var(--dl-color-default-ffffff);
  }
  .icon3 {
    gap: 24px;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    border-radius: 16px;
    flex-direction: column;
    justify-content: center;
    background-color: var(--dl-color-default-d8ffe6);
  }
  .iconspeaker {
    width: 32px;
    object-fit: cover;
  }
  .text026 {
    color: var(--dl-color-default-2d3e50);
    height: auto;
    font-size: 18px;
    align-self: stretch;
    font-style: SemiBold;
    text-align: left;
    font-family: Poppins;
    font-weight: 600;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .nao-perca-tempo {
    gap: 40px;
    width: 100%;
    display: flex;
    padding: 24px 0;
    overflow: hidden;
    max-width: 1320px;
    align-self: center;
    align-items: center;
    flex-direction: column;
  }
  .text-titulo2 {
    color: rgba(45, 62, 80, 1);
    height: auto;
    font-size: 32px;
    align-self: center;
    font-style: SemiBold;
    text-align: center;
    font-family: Poppins;
    font-weight: 600;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .text029 {
    color: rgba(45, 62, 80, 1);
    font-size: 20px;
    max-width: 1098px;
    align-self: center;
    font-style: Regular;
    text-align: center;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .botao-anunciar1 {
    gap: 5px;
    display: flex;
    padding: 16px 32px;
    align-items: center;
    border-radius: 8px;
    justify-content: center;
    cursor:pointer;
    background-color: var(--dl-color-default-padro);
  }
  .text031 {
    color: var(--dl-color-default-white);
    height: auto;
    font-size: 20px;
    font-style: Medium;
    text-align: left;
    font-family: Poppins;
    font-weight: 500;
    line-height: 100%;
    font-stretch: normal;
    text-decoration: none;
  }
  .cards-vantagens {
    width: 100%;
    display: flex;
    padding: 24px 0;
    overflow: hidden;
    max-width: 1320px;
    align-self: center;
    align-items: center;
    flex-direction: column;
  }
  .cards {
    gap: 32px;
    display: flex;
    flex-wrap: wrap;
    align-self: stretch;
    align-items: flex-start;
    flex-shrink: 0;
    justify-content: center;
  }
  .card04 {
    gap: 24px;
    width: 644px;
    display: flex;
    padding: 24px;
    overflow: hidden;
    flex-grow: 1;
    align-items: flex-start;
    flex-shrink: 0;
    border-radius: 16px;
    flex-direction: column;
    background-color: var(--dl-color-default-ffffff);
  }
  .text033 {
    color: var(--dl-color-default-2d3e50);
    height: auto;
    font-size: 20px;
    align-self: stretch;
    font-style: SemiBold;
    text-align: left;
    font-family: Poppins;
    font-weight: 600;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .text035 {
    color: rgb(125, 138, 152);
    height: auto;
    font-size: 16px;
    align-self: stretch;
    font-style: Regular;
    text-align: left;
    font-family: "Poppins";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .text036 {
    font-weight: 400;
  }
  .text037 {
    font-style: italic;
    font-weight: 700;
  }
  .card05 {
    gap: 24px;
    width: 644px;
    display: flex;
    padding: 24px;
    overflow: hidden;
    flex-grow: 1;
    align-items: flex-start;
    flex-shrink: 0;
    border-radius: 16px;
    flex-direction: column;
    background-color: var(--dl-color-default-ffffff);
  }
  .text040 {
    color: var(--dl-color-default-2d3e50);
    height: auto;
    font-size: 20px;
    align-self: stretch;
    font-style: SemiBold;
    text-align: left;
    font-family: Poppins;
    font-weight: 600;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .text042 {
    color: rgba(125, 138, 152, 1);
    height: auto;
    font-size: 16px;
    align-self: stretch;
    font-style: Regular;
    text-align: left;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .card06 {
    gap: 24px;
    width: 644px;
    display: flex;
    padding: 24px;
    overflow: hidden;
    flex-grow: 1;
    align-items: flex-start;
    flex-shrink: 0;
    border-radius: 16px;
    flex-direction: column;
    background-color: var(--dl-color-default-ffffff);
  }
  .text044 {
    color: var(--dl-color-default-2d3e50);
    height: auto;
    font-size: 20px;
    align-self: stretch;
    font-style: SemiBold;
    text-align: left;
    font-family: Poppins;
    font-weight: 600;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .text046 {
    color: rgb(125, 138, 152);
    height: auto;
    font-size: 16px;
    align-self: stretch;
    font-style: Regular;
    text-align: left;
    font-family: "Poppins";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .text047 {
    font-weight: 400;
  }
  .text048 {
    font-style: italic;
    font-weight: 700;
  }
  .card07 {
    gap: 24px;
    width: 644px;
    display: flex;
    padding: 24px;
    overflow: hidden;
    flex-grow: 1;
    align-items: flex-start;
    flex-shrink: 0;
    border-radius: 16px;
    flex-direction: column;
    background-color: var(--dl-color-default-ffffff);
  }
  .text051 {
    color: var(--dl-color-default-2d3e50);
    height: auto;
    font-size: 20px;
    align-self: stretch;
    font-style: SemiBold;
    text-align: left;
    font-family: Poppins;
    font-weight: 600;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .text053 {
    color: rgba(125, 138, 152, 1);
    height: auto;
    font-size: 16px;
    align-self: stretch;
    font-style: Regular;
    text-align: left;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .banner-anunciar {
    gap: 40px;
    width: 100%;
    display: flex;
    overflow: hidden;
    max-width: 1320px;
    align-self: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 8px;
    justify-content: center;
    background-color: var(--dl-color-default-padro);
  }
  .img-phone {
    width: 240px;
    height: 226px;
  }
  .botao-anunciar2 {
    gap: 5px;
    width: fit-content;
    display: flex;
    padding: 16px 32px;
    box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.09000000357627869) ;
    align-items: center;
    border-radius: 8px;
    justify-content: center;
    background-color: var(--dl-color-default-colorgreybg);
  }
  .text055 {
    color: var(--dl-color-default-2d3e50);
    height: auto;
    font-size: 20px;
    font-style: Medium;
    text-align: left;
    font-family: Poppins;
    font-weight: 500;
    line-height: 100%;
    font-stretch: normal;
    text-decoration: none;
  }
  .nao-perca-tempo1 {
    gap: 40px;
    width: 100%;
    display: flex;
    padding: 24px 0;
    max-width: 1320px;
    align-self: center;
    align-items: center;
    flex-direction: column;
  }
  .text-titulo3 {
    color: rgba(45, 62, 80, 1);
    height: auto;
    font-size: 32px;
    align-self: stretch;
    font-style: SemiBold;
    text-align: center;
    font-family: Poppins;
    font-weight: 600;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .cards1 {
    gap: 32px;
    display: flex;
    flex-wrap: wrap;
    align-self: stretch;
    align-items: stretch;
    flex-shrink: 0;
    justify-content: center;
  }
  .card08 {
    gap: 24px;
    width: 644px;
    display: flex;
    padding: 24px;
    overflow: hidden;
    flex-grow: 1;
    box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.09000000357627869) ;
    align-items: flex-start;
    flex-shrink: 0;
    border-radius: 16px;
    flex-direction: column;
    background-color: var(--dl-color-default-ffffff);
  }
  .icon4 {
    gap: 24px;
    width: 52px;
    height: 52px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    border-radius: 16px;
    flex-direction: column;
    justify-content: center;
    background-color: var(--dl-color-default-d8ffe6);
  }
  .iconuser {
    width: 32px;
    object-fit: cover;
  }
  .text058 {
    color: var(--dl-color-default-2d3e50);
    height: auto;
    font-size: 20px;
    align-self: stretch;
    font-style: SemiBold;
    text-align: left;
    font-family: Poppins;
    font-weight: 600;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .text060 {
    color: rgba(125, 138, 152, 1);
    height: auto;
    font-size: 16px;
    align-self: stretch;
    font-style: Regular;
    text-align: left;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .card09 {
    gap: 24px;
    width: 644px;
    display: flex;
    padding: 24px;
    overflow: hidden;
    flex-grow: 1;
    box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.09000000357627869) ;
    align-items: flex-start;
    flex-shrink: 0;
    border-radius: 16px;
    flex-direction: column;
    background-color: var(--dl-color-default-ffffff);
  }
  .icon5 {
    gap: 24px;
    width: 52px;
    height: 52px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    border-radius: 16px;
    flex-direction: column;
    justify-content: center;
    background-color: var(--dl-color-default-d8ffe6);
  }
  .iconanalysis {
    width: 32px;
    object-fit: cover;
  }
  .text062 {
    color: var(--dl-color-default-2d3e50);
    height: auto;
    font-size: 20px;
    align-self: stretch;
    font-style: SemiBold;
    text-align: left;
    font-family: Poppins;
    font-weight: 600;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .text064 {
    color: rgba(125, 138, 152, 1);
    height: auto;
    font-size: 16px;
    align-self: stretch;
    font-style: Regular;
    text-align: left;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .card10 {
    gap: 24px;
    width: 644px;
    display: flex;
    padding: 24px;
    overflow: hidden;
    flex-grow: 1;
    box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.09000000357627869) ;
    align-items: flex-start;
    flex-shrink: 0;
    border-radius: 16px;
    flex-direction: column;
    background-color: var(--dl-color-default-ffffff);
  }
  .icon6 {
    gap: 24px;
    width: 52px;
    height: 52px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    border-radius: 16px;
    flex-direction: column;
    justify-content: center;
    background-color: var(--dl-color-default-d8ffe6);
  }
  .iconbook {
    width: 32px;
    object-fit: cover;
  }
  .text066 {
    color: var(--dl-color-default-2d3e50);
    height: auto;
    font-size: 20px;
    align-self: stretch;
    font-style: SemiBold;
    text-align: left;
    font-family: Poppins;
    font-weight: 600;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .text068 {
    color: rgb(125, 138, 152);
    height: auto;
    font-size: 16px;
    align-self: stretch;
    font-style: Regular;
    text-align: left;
    font-family: "Poppins";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .text069 {
    color: #7d8a98;
    font-weight: 400;
  }
  .text070 {
    color: var(--dl-color-default-padro);
    font-style: italic;
    font-weight: 700;
  }
  .text071 {
    color: var(--dl-color-default-2d3e50);
    font-style: italic;
    font-weight: 700;
  }
  .card11 {
    gap: 24px;
    width: 644px;
    display: flex;
    padding: 24px;
    overflow: hidden;
    flex-grow: 1;
    box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.09000000357627869) ;
    align-items: flex-start;
    flex-shrink: 0;
    border-radius: 16px;
    flex-direction: column;
    background-color: var(--dl-color-default-ffffff);
  }
  .icon7 {
    gap: 24px;
    width: 52px;
    height: 52px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    border-radius: 16px;
    flex-direction: column;
    justify-content: center;
    background-color: var(--dl-color-default-d8ffe6);
  }
  .iconsecurity {
    width: 32px;
    object-fit: cover;
  }
  .text073 {
    color: var(--dl-color-default-2d3e50);
    height: auto;
    font-size: 20px;
    align-self: stretch;
    font-style: SemiBold;
    text-align: left;
    font-family: Poppins;
    font-weight: 600;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .text075 {
    color: rgba(125, 138, 152, 1);
    height: auto;
    font-size: 16px;
    align-self: stretch;
    font-style: Regular;
    text-align: left;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .botao-comparar-planos {
    gap: 8px;
    display: flex;
    padding: 16px 24px 16px 32px;
    align-items: center;
    border-radius: 8px;
    justify-content: center;
    background-color: var(--dl-color-default-colorgreybg);
  }
  .text077 {
    color: rgb(45, 62, 80);
    height: auto;
    font-size: 20px;
    font-style: Medium;
    text-align: left;
    font-family: "Poppins";
    font-weight: 500;
    line-height: 24px;
    font-stretch: normal;
    text-decoration: none;
  }
  .text078 {
    color: var(--dl-color-default-2d3e50);
  }
  .text079 {
    color: #00cc66;
  }
  .text080 {
    color: var(--dl-color-default-2d3e50);
  }
  .text081 {
    color: #fca600;
  }
  .iconvermais {
    width: 20px;
    height: 20px;
  }
  .cadastre-se {
    width: 100%;
    display: flex;
    padding: 52px 24px;
    overflow: hidden;
    align-self: stretch;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: var(--dl-color-default-f0f4f6);
  }
  .container2 {
    gap: 40px;
    width: 100%;
    display: flex;
    padding: 24px 0;
    overflow: hidden;
    max-width: 1098px;
    align-self: center;
    align-items: center;
    flex-direction: column;
  }
  .text-titulo4 {
    color: rgb(45, 62, 80);
    width: 100%;
    font-size: 32px;
    font-style: SemiBold;
    text-align: center;
    font-family: "Poppins";
    font-weight: 600;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .text082 {
    color: #2d3e50;
    font-weight: 600;
  }
  .text083 {
    color: var(--dl-color-default-padro);
    font-style: italic;
    font-weight: 700;
  }
  .text084 {
    font-style: italic;
    font-weight: 700;
  }
  .text085 {
    font-weight: 700;
  }
  .text086 {
    color: rgb(45, 62, 80);
    width: 100%;
    font-size: 20px;
    align-self: center;
    font-style: SemiBold;
    text-align: center;
    font-family: "Poppins";
    font-weight: 600;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .text088 {
    font-style: normal;
    font-weight: 400;
  }
  .text089 {
    font-style: normal;
    font-weight: 400;
  }
  .text090 {
    font-style: normal;
    font-weight: 400;
  }
  .text091 {
    color: rgb(125, 138, 152);
    width: 100%;
    font-size: 16px;
    align-self: center;
    font-style: Regular;
    text-align: center;
    font-family: "Poppins";
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .text092 {
    font-weight: 400;
  }
  .text093 {
    font-style: italic;
    font-weight: 700;
  }
  .botao-anunciar3 {
    gap: 5px;
    display: flex;
    padding: 16px 32px;
    align-items: center;
    border-radius: 8px;
    justify-content: center;
    background-color: var(--dl-color-default-padro);
  }
  .text098 {
    color: var(--dl-color-default-white);
    height: auto;
    font-size: 20px;
    font-style: Medium;
    text-align: left;
    font-family: Poppins;
    font-weight: 500;
    line-height: 100%;
    font-stretch: normal;
    text-decoration: none;
  }
  @media(max-width: 1250px) {
    .bem-vindo {
      gap: 40px;
      flex-wrap: wrap;
      padding-top: 0px;
      padding-bottom: 0px;
    }
    .image-mobile {
      display: flex;
    }
    .video-mobile {
      display: flex;
      width: 634px !important;
      height: 400px !important;
    }
    .column {
      gap: 24px;
    }
    .text-titulo {
      font-size: 26px;
      text-align: center;
    }
    .botao-anunciar {
      width: 100%;
      padding-top: 14px;
      padding-left: 24px;
      padding-right: 24px;
      padding-bottom: 14px;
    }
    .text012 {
      font-size: 18px;
    }
    .image-desk {
      display: none;
    }
    .video-desk {
      display: none;
    }
    .motivos-anunciar {
      gap: 24px;
    }
    .text-titulo1 {
      font-size: 26px;
    }
    .motivos {
      gap: 16px;
    }
    .text-titulo2 {
      font-size: 26px;
    }
    .botao-anunciar1 {
      width: 100%;
      padding-top: 14px;
      padding-left: 24px;
      padding-right: 24px;
    }
    .text031 {
      font-size: 18px;
    }
    .cards {
      gap: 40px;
    }
    .card04 {
      width: 100%;
      padding: 0px;
    }
    .card05 {
      width: 100%;
      padding: 0px;
    }
    .card06 {
      width: 100%;
      padding: 0px;
    }
    .card07 {
      width: 100%;
      padding: 0px;
    }
    .botao-anunciar2 {
      padding-top: 14px;
      padding-left: 24px;
      padding-right: 24px;
      padding-bottom: 14px;
    }
    .text055 {
      font-size: 18px;
    }
    .text-titulo3 {
      font-size: 26px;
    }
    .cards1 {
      gap: 16px;
    }
    .card08 {
      width: 100%;
    }
    .card09 {
      width: 100%;
    }
    .card10 {
      width: 100%;
    }
    .card11 {
      width: 100%;
    }
    .botao-comparar-planos {
      padding-top: 14px;
      padding-bottom: 14px;
    }
    .text077 {
      font-size: 18px;
    }
    .text-titulo4 {
      font-size: 26px;
    }
    .text087 {
      font-weight: 600;
    }
    .text089 {
      font-style: normal;
      font-weight: 400;
    }
    .text090 {
      font-style: normal;
      font-weight: 400;
    }
    .botao-anunciar3 {
      width: 100%;
      padding-top: 14px;
      padding-left: 24px;
      padding-right: 24px;
      padding-bottom: 14px;
    }
    .text098 {
      font-size: 18px;
    }
  }
  @media(max-width: 991px) {
    .container1 {
      gap: 24px;
      padding-top: 24px;
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 24px;
    }
    .motivos {
      flex-wrap: wrap;
    }
    .card {
      gap: 16px;
      flex: 0 0 auto;
      width: 100%;
    }
    .card01 {
      gap: 16px;
      flex: 0 0 auto;
      width: 100%;
    }
    .card02 {
      gap: 16px;
      flex: 0 0 auto;
      width: 100%;
    }
    .card03 {
      gap: 16px;
      flex: 0 0 auto;
      width: 100%;
    }
    .banner-anunciar {
      position: relative;
    }
    .botao-anunciar2 {
      left: 24px;
      right: 24px;
      bottom: 24px;
      margin: auto;
      position: absolute;
    }
    .text055 {
      text-align: center;
    }
    .cadastre-se {
      padding-top: 24px;
      padding-bottom: 24px;
    }
  }
  @media(max-width: 767px) {
    .image-mobile {
      width: 328px;
      height: 200px;
    }
    .video-mobile {
      width: 328px !important;
      height: 200px !important;
    }
    .text-titulo {
      font-size: 20px;
    }
    .text004 {
      font-size: 14px;
    }
    .botao-anunciar {
      padding-top: 12px;
      padding-bottom: 12px;
    }
    .text012 {
      font-size: 16px;
    }
    .text-titulo1 {
      font-size: 20px;
    }
    .icon {
      width: 52px;
      height: 52px;
    }
    .iconcommunity {
      width: 27.73px;
    }
    .text018 {
      font-size: 16px;
    }
    .iconstar {
      width: 27.73px;
    }
    .text020 {
      font-size: 16px;
    }
    .iconrocket {
      width: 27.73px;
    }
    .text024 {
      font-size: 16px;
    }
    .iconspeaker {
      width: 27.73px;
    }
    .text026 {
      font-size: 16px;
    }
    .text-titulo2 {
      font-size: 20px;
    }
    .text029 {
      font-size: 14px;
    }
    .botao-anunciar1 {
      padding-top: 12px;
      padding-bottom: 12px;
    }
    .text031 {
      font-size: 16px;
    }
    .text033 {
      font-size: 16px;
    }
    .text035 {
      font-size: 14px;
    }
    .text040 {
      font-size: 16px;
    }
    .text042 {
      font-size: 14px;
    }
    .text044 {
      font-size: 16px;
    }
    .text046 {
      font-size: 14px;
    }
    .text051 {
      font-size: 16px;
    }
    .text053 {
      font-size: 14px;
    }
    .botao-anunciar2 {
      padding-top: 12px;
      padding-bottom: 12px;
    }
    .text055 {
      font-size: 16px;
    }
    .text-titulo3 {
      font-size: 20px;
    }
    .icon4 {
      width: 40px;
      height: 40px;
    }
    .iconuser {
      width: 24.62px;
    }
    .text058 {
      font-size: 16px;
    }
    .text060 {
      font-size: 14px;
    }
    .icon5 {
      width: 40px;
      height: 40px;
    }
    .iconanalysis {
      width: 24.62px;
    }
    .text062 {
      font-size: 16px;
    }
    .text064 {
      font-size: 14px;
    }
    .icon6 {
      width: 40px;
      height: 40px;
    }
    .iconbook {
      width: 24.62px;
    }
    .text066 {
      font-size: 16px;
    }
    .text068 {
      font-size: 14px;
    }
    .icon7 {
      width: 40px;
      height: 40px;
    }
    .iconsecurity {
      width: 24.62px;
    }
    .text073 {
      font-size: 16px;
    }
    .text075 {
      font-size: 14px;
    }
    .botao-comparar-planos {
      padding-top: 12px;
      padding-left: 24px;
      padding-right: 20px;
      padding-bottom: 12px;
    }
    .text077 {
      font-size: 16px;
    }
    .iconvermais {
      width: 16px;
      height: 16px;
    }
    .text-titulo4 {
      font-size: 20px;
    }
    .text086 {
      font-size: 16px;
    }
    .text091 {
      font-size: 14px;
    }
    .botao-anunciar3 {
      padding-top: 12px;
      padding-bottom: 12px;
    }
    .text098 {
      font-size: 16px;
    }
  }
  @media(max-width: 479px) {
    .image-mobile {
      width: 100%;
      max-width: 328px;
    }
    .video-mobile {
      width: 100% !important;
      max-width: 328px !important;
    }
  }
  