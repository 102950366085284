.frame1171277060 {
  gap: 16px;
  display: flex;
  padding: 8px 8px 8px 16px;
  position: relative;
  align-self: stretch;
  align-items: center;
  border-color: #DFE4E7;
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  flex-direction: row;
  justify-content: center;
  cursor:pointer;
}
.text {
  color: rgb(45, 62, 80);
  height: auto;
  flex-grow: 1;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
















@media(max-width: 991px) {
  .root-class-name8 {
    display: none;
  }
  .root-class-name9 {
    display: none;
  }
}
