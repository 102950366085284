.container {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.container1 {
  gap: 24px;
  width: 100%;
  display: flex;
  max-width: 1352px;
  align-self: center;
  align-items: flex-start;
  flex-shrink: 0;
  padding-top: 24px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 24px;
  justify-content: space-between;
}
.container2 {
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  padding-bottom: 280px;
  justify-content: center;
}
.estatisticas {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  padding-left: 16px;
  padding-right: 16px;
  flex-direction: column;
}
.cabecalho {
  gap: 10px;
  width: 100%;
  display: flex;
  flex-grow: 1;
  max-height: 40px;
  align-items: center;
  flex-shrink: 0;
  padding-top: 8px;
  padding-left: 0px;
  border-radius: 8px;
  padding-right: 0px;
  padding-bottom: 8px;
}
.text {
  color: var(--dl-color-default-2d3e50);
  width: 100%;
  flex-grow: 1;
  font-size: 24px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  padding-left: 16px;
  padding-right: 16px;
  text-decoration: none;
}
.mes-so-desk {
  gap: 10px;
  height: 40px;
  display: flex;
  padding: 10px 16px;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(216, 223, 232, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
  position:relative;
  cursor:pointer;
  justify-content: center;
}
.mes-so-desk > .input {
  display:flex;
  align-items:center;
  gap:8px;
  justify-content: center;
}
.text02 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.arrowicon {
  width: 20px;
  height: 11px;
}
.infos {
  gap: 16px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: row;
}
.impressoes-cliques {
  gap: 16px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.impressoes {
  gap: 12px;
  flex: 1;
  width: 100%;
  display: flex;
  padding: 32px;
  overflow: hidden;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(216, 223, 232, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 1);
}
.text04 {
  color: var(--dl-color-default-2d3e50);
  width: 100%;
  font-size: 18px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text-numero {
  color: var(--dl-color-default-2d3e50);
  width: 100%;
  flex-grow: 1;
  font-size: 24px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text06 {
  color: var(--dl-color-default-7d8a98);
  width: 100%;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.cliques {
  gap: 12px;
  flex: 1;
  width: 100%;
  display: flex;
  padding: 32px;
  overflow: hidden;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(216, 223, 232, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}
.text07 {
  color: var(--dl-color-default-2d3e50);
  width: 100%;
  font-size: 18px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text-numero1 {
  color: var(--dl-color-default-2d3e50);
  width: 100%;
  font-size: 24px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text10 {
  color: var(--dl-color-default-7d8a98);
  width: 100%;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.telefone-whats {
  gap: 16px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.telefone {
  gap: 12px;
  flex: 1;
  width: 100%;
  display: flex;
  padding: 32px;
  overflow: hidden;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(216, 223, 232, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}
.text12 {
  color: var(--dl-color-default-2d3e50);
  width: 100%;
  font-size: 18px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text14 {
  color: var(--dl-color-default-2d3e50);
  width: 100%;
  font-size: 24px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text16 {
  color: var(--dl-color-default-7d8a98);
  width: 100%;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.whatsapp {
  gap: 12px;
  flex: 1;
  width: 100%;
  display: flex;
  padding: 32px;
  overflow: hidden;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(216, 223, 232, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}
.text18 {
  color: var(--dl-color-default-2d3e50);
  width: 100%;
  font-size: 18px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text20 {
  color: var(--dl-color-default-2d3e50);
  width: 100%;
  font-size: 24px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text22 {
  color: var(--dl-color-default-7d8a98);
  width: 100%;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
@media(max-width: 991px) {
  .container1 {
    padding-bottom: 16px;
  }
  .container2 {
    padding-bottom: 108px;
  }
  .estatisticas {
    gap: 32px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .cabecalho {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .text {
    padding-left: 0px;
    padding-right: 0px;
  }
  .mes-so-desk {
    display: none;
  }
}
@media(max-width: 767px) {
  .impressoes-cliques {
    flex-direction: column;
  }
  .impressoes {
    padding: 28px;
  }
  .text04 {
    font-size: 17px;
  }
  .text-numero {
    font-size: 22px;
  }
  .text06 {
    font-size: 15px;
  }
  .cliques {
    padding: 28px;
  }
  .text07 {
    font-size: 17px;
  }
  .text-numero1 {
    font-size: 22px;
  }
  .text10 {
    font-size: 15px;
  }
  .telefone-whats {
    flex-direction: column;
  }
  .telefone {
    padding: 28px;
  }
  .text12 {
    font-size: 17px;
  }
  .text14 {
    font-size: 22px;
  }
  .text16 {
    font-size: 15px;
  }
  .whatsapp {
    padding: 28px;
  }
  .text18 {
    font-size: 17px;
  }
  .text20 {
    font-size: 22px;
  }
  .text22 {
    font-size: 15px;
  }
}
@media(max-width: 479px) {
  .impressoes {
    padding: 24px;
  }
  .text04 {
    font-size: 16px;
  }
  .text-numero {
    font-size: 20px;
  }
  .text06 {
    color: var(--dl-color-default-7d8a98);
    font-size: 14px;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
  }
  .cliques {
    padding: 24px;
  }
  .text07 {
    font-size: 16px;
  }
  .text-numero1 {
    font-size: 20px;
  }
  .text10 {
    font-size: 14px;
  }
  .telefone {
    padding: 24px;
  }
  .text12 {
    font-size: 16px;
  }
  .text14 {
    font-size: 20px;
  }
  .text16 {
    font-size: 14px;
  }
  .whatsapp {
    padding: 24px;
  }
  .text18 {
    font-size: 16px;
  }
  .text20 {
    font-size: 20px;
  }
  .text22 {
    font-size: 14px;
  }
}


.lista-meses {
  user-select: none;
  position: absolute;
  top: 100%;
  background:white;
  border:1px solid rgba(216, 223, 232, 1);
  border-top-width: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  left: 0;
  border-radius:0 0 8px 8px;
}

.lista-meses span {
  padding: .5rem;
  font-family:'Poppins',sans-serif;
  color:var(--dl-color-default-7d8a98);
  text-align:center;
}