.frame1171274941 {
  gap: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.text {
  color: rgba(45, 62, 80, 1);
  width: 100%;
  height: 100%;
  font-size: 16px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text-limite {
  color: rgba(125, 138, 152, 1);
  right: 16px;
  bottom: 10px;
  height: auto;
  margin: auto;
  position: absolute;
  font-size: 14px;
  font-style: Regular;
  text-align: right;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.input {
  gap: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  outline: none;
  padding: 10px 16px;
  overflow: hidden;
  align-self: stretch;
  max-height: 40px;
  align-items: center;
  flex-shrink: 0;
  font-family: Poppins;
  border-color: rgba(216, 223, 232, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
}
.input.erro {
  border-color:var(--dl-color-danger-500);
  color:var(--dl-color-danger-500);
}
.textarea {
  gap: 10px;
  width: 100%;
  height: 100%;
  resize: none;
  display: flex;
  outline: none;
  padding: 10px 16px;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  font-family: Poppins;
  border-color: rgba(216, 223, 232, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
}
.input-popup {
  gap: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  outline: none;
  padding: 10px 16px;
  overflow: hidden;
  font-size: 14px;
  align-self: stretch;
  max-height: 40px;
  align-items: center;
  flex-shrink: 0;
  font-family: Poppins;
  border-color: rgba(216, 223, 232, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
}
.input1 {
  gap: 10px;
  width: 100%;
  height: 40px;
  display: flex;
  outline: none;
  padding: 10px 56px 10px 16px;
  overflow: hidden;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  font-family: Poppins;
  border-color: rgba(216, 223, 232, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  background-color: var(--dl-color-default-d8ffe6);
}
.crownicon {
  right: 16px;
  width: 24px;
  bottom: 8px;
  height: 24px;
  margin: auto;
  position: absolute;
}
.navlink {
  display: contents;
}
.botao-planos {
  gap: 10px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  display: flex;
  padding: 8px 16px;
  overflow: hidden;
  position: absolute;
  max-width: fit-content;
  align-items: center;
  border-radius: 8px;
  text-decoration: none;
  background-color: var(--dl-color-default-d8ffe6);
}
.text2 {
  color: var(--dl-color-default-07a741);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.crownicon1 {
  width: 24px;
  height: 24px;
}
.text4 {
  color: var(--dl-color-danger-500);
  width: 100%;
  height: 100%;
  font-size: 14px;
  align-self: stretch;
  font-style: SemiBold;
  margin-top: -4px;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.eye-icon {
  position: absolute;
  right: 0;
  margin-right: 20px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}






.root-class-name6 {
  max-width: 300px;
  align-self: center;
}
.root-class-name7 {
  max-width: 300px;
  align-self: center;
}
.root-class-name8 {
  max-width: 300px;
  align-self: center;
}
.root-class-name9 {
  max-width: 300px;
  align-self: center;
}
.root-class-name10 {
  max-width: 300px;
  align-self: center;
}





.root-class-name16 {
  width: 100%;
}
.root-class-name17 {
  flex: 3;
}
.root-class-name18 {
  flex: 1;
  align-self: flex-end;
}
.root-class-name19 {
  flex: 3;
}
.root-class-name20 {
  flex: 1;
  align-self: flex-end;
}
.root-class-name21 {
  max-width: 640px;
}
.root-class-name22 {
  max-width: 640px;
}
.root-class-name23 {
  max-width: 640px;
}
.root-class-name24 {
  max-width: 640px;
}



.root-class-name28 {
  width: 100%;
}
.root-class-name29 {
  flex: 3;
}
.root-class-name30 {
  flex: 1;
  align-self: flex-end;
}
.root-class-name31 {
  flex: 3;
}
.root-class-name32 {
  flex: 1;
  align-self: flex-end;
}
.root-class-name33 {
  height: auto;
}
.root-class-name34 {
  height: auto;
}
.root-class-name35 {
  flex: 1;
  height: auto;
  min-width: 80px;
}
.root-class-name36 {
  flex: 2;
  height: auto;
}
.root-class-name37 {
  height: auto;
}
.root-class-name38 {
  flex: 1;
  height: auto;
  min-width: 80px;
}
.root-class-name39 {
  flex: 3;
  height: auto;
}
.root-class-name40 {
  height: auto;
}
.root-class-name41 {
  flex: 3;
  height: auto;
  min-width: 80px;
}
.root-class-name42 {
  flex: 1;
  height: auto;
}


.root-class-name45 {
  height: auto;
}
.root-class-name46 {
  max-width: 544px;
  align-self: flex-start;
}
.root-class-name47 {
  height: 100%;
}
.root-class-name48 {
  height: 100%;
}
.root-class-name49 {
  height: 100%;
}





.root-class-name55 {
  height: 100%;
}

.root-class-name57 {
  height: 100%;
}
.root-class-name58 {
  max-width: 98px;
  align-self: center;
}
.root-class-name59 {
  max-width: 98px;
  min-width: 98px;
  align-self: center;
}







@media(max-width: 991px) {
  .root-class-name21 {
    max-width: 100%;
  }
  .root-class-name22 {
    max-width: 100%;
  }
  .root-class-name23 {
    max-width: 100%;
  }
  .root-class-name24 {
    max-width: 100%;
  }
  .root-class-name46 {
    max-width: 100%;
  }
}
@media(max-width: 767px) {
  .text {
    font-size: 15px;
  }
  .text-limite {
    font-size: 13px;
  }
  .input {
    font-size: 15px;
  }
  .textarea {
    font-size: 15px;
  }
  .input1 {
    font-size: 15px;
  }
  .botao-planos {
    gap: 9px;
  }
  .text2 {
    font-size: 15px;
  }
  .text4 {
    font-size: 15px;
  }
  .root-class-name6 {
    max-width: 100%;
  }
  .root-class-name7 {
    max-width: 100%;
  }
  .root-class-name8 {
    max-width: 100%;
  }
  .root-class-name9 {
    max-width: 100%;
  }
  .root-class-name10 {
    max-width: 100%;
  }
}
@media(max-width: 479px) {
  .text {
    font-size: 14px;
  }
  .text-limite {
    font-size: 12px;
  }
  .input {
    font-size: 14px;
  }
  .textarea {
    font-size: 14px;
  }
  .input1 {
    font-size: 14px;
  }
  .botao-planos {
    gap: 8px;
  }
  .text2 {
    font-size: 14px;
  }
  .text4 {
    font-size: 14px;
  }
}

.disabled-input {
  background-color: var(--dl-color-gray-900);
}