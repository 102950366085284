.botao-ordenar-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 10px;
  justify-content: flex-end;
}
.botao-ordenar-botao-ordenar {
  gap: 8px;
  cursor: pointer;
  display: flex;
  padding: 12px 20px 12px 24px;
  align-items: center;
  border-radius: 8px;
  justify-content: center;
}
.botao-ordenar-text {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 100%;
  font-stretch: normal;
  text-decoration: none;
}
.botao-ordenar-ordenar {
  width: 16px;
  height: 16px;
}
.botao-ordenar-ordenar-aberto {
  gap: 16px;
  top: 48px;
  right: 0px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  align-items: flex-start;
  width: 100%;
  flex-shrink: 0;
  border-radius: 8px;
}
.botao-ordenar-data {
  gap: 10px;
  width: 100%;
  display: flex;
  flex-grow: 1;
  max-width: 336px;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.botao-ordenar-text02 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  font-size: 14px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.botao-ordenar-thq-dropdown {
  width: 100%;
  cursor: pointer;
  display: inline-block;
  position: relative;
  align-self: center;
  border-radius: var(--dl-radius-radius-radius2);
}
.botao-ordenar-dropdown-toggle {
  display: flex;
  padding: 10px 16px;
  overflow: hidden;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  border-color: rgba(216, 223, 232, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 1);
}
.botao-ordenar-text04 {
  color: rgb(125, 138, 152);
  height: auto;
  flex-grow: 1;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.botao-ordenar-arrowicon {
  width: 9px;
  height: 16px;
}
.botao-ordenar-dropdown-list {
  padding: 10px 16px;
  overflow: hidden;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  border-color: rgba(216, 223, 232, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 1);
}
.botao-ordenar-dropdown {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.botao-ordenar-dropdown-toggle1 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.botao-ordenar-dropdown-toggle1:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.botao-ordenar-text05 {
  color: rgb(125, 138, 152);
  height: auto;
  flex-grow: 1;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.botao-ordenar-dropdown1 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.botao-ordenar-dropdown-toggle2 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.botao-ordenar-dropdown-toggle2:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.botao-ordenar-text06 {
  color: rgb(125, 138, 152);
  height: auto;
  flex-grow: 1;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.botao-ordenar-dropdown2 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.botao-ordenar-dropdown-toggle3 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.botao-ordenar-dropdown-toggle3:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.botao-ordenar-text07 {
  color: rgb(125, 138, 152);
  height: auto;
  flex-grow: 1;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.botao-ordenar-acessos {
  gap: 10px;
  width: 100%;
  display: flex;
  flex-grow: 1;
  max-width: 336px;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.botao-ordenar-text08 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  font-size: 14px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.botao-ordenar-thq-dropdown1 {
  width: 100%;
  cursor: pointer;
  display: inline-block;
  position: relative;
  align-self: center;
  border-radius: var(--dl-radius-radius-radius2);
}
.botao-ordenar-dropdown-toggle4 {
  display: flex;
  padding: 10px 16px;
  overflow: hidden;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  border-color: rgba(216, 223, 232, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 1);
}
.botao-ordenar-text10 {
  color: rgb(125, 138, 152);
  height: auto;
  flex-grow: 1;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.botao-ordenar-arrowicon1 {
  width: 9px;
  height: 16px;
}
.botao-ordenar-dropdown-list1 {
  padding: 10px 16px;
  overflow: hidden;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  border-color: rgba(216, 223, 232, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 1);
}
.botao-ordenar-dropdown3 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.botao-ordenar-dropdown-toggle5 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.botao-ordenar-dropdown-toggle5:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.botao-ordenar-text11 {
  color: rgb(125, 138, 152);
  height: auto;
  flex-grow: 1;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.botao-ordenar-dropdown4 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.botao-ordenar-dropdown-toggle6 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.botao-ordenar-dropdown-toggle6:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.botao-ordenar-text12 {
  color: rgb(125, 138, 152);
  height: auto;
  flex-grow: 1;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.botao-ordenar-dropdown5 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.botao-ordenar-dropdown-toggle7 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.botao-ordenar-dropdown-toggle7:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.botao-ordenar-text13 {
  color: rgb(125, 138, 152);
  height: auto;
  flex-grow: 1;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.botao-ordenar-root-class-name {
  width: auto;
}
.botao-ordenar-root-class-name1 {
  height: auto;
  display: none;
  align-self: center;
}
@media(max-width: 991px) {
  .botao-ordenar-botao-ordenar {
    width: 100%;
    background-color: #ffffff;
  }
  .botao-ordenar-ordenar-aberto {
    max-width: 100%;
    flex-direction: column;
    background-color: #ffffff;
  }
  .botao-ordenar-data {
    max-width: 100%;
  }
  .botao-ordenar-acessos {
    max-width: 100%;
  }
  .botao-ordenar-root-class-name1 {
    width: 100%;
    display: flex;
  }
}
@media(max-width: 767px) {
  .botao-ordenar-text {
    font-size: 15px;
  }
  .botao-ordenar-ordenar {
    width: 15px;
    height: 15px;
  }
}
@media(max-width: 479px) {
  .botao-ordenar-text {
    font-size: 14px;
  }
  .botao-ordenar-ordenar {
    width: 14px;
    height: 14px;
  }
}
