.filtros {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
  width:21%;
}
.busca-mobile {
  gap: 8px;
  width: 100%;
  height: auto;
  display: none;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.botao-local {
  gap: 5px;
  width: 40px;
  height: 40px;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-ffffff);
}
.local {
  width: 16px;
  height: 16px;
}
.container {
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.input-busca {
  gap: 8px;
  height: 40px;
  display: flex;
  padding: 0 12px 0 16px;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(216, 223, 231, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-style);
}
.textinput {
  color: rgba(125, 138, 152, 1);
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.lupaicon {
  width: 18px;
  height: 18px;
}
.localizacao-atual {
  gap: 16px;
  width: 100%;
  cursor: pointer;
  height: auto;
  display: none;
  padding: 4px 8px 4px 16px;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(222, 228, 230, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
}
.elementos {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.text {
  color: rgba(45, 62, 80, 1);
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.localicon {
  width: 20px;
  height: 20px;
}
.brasil-todo {
  gap: 16px;
  width: 100%;
  cursor: pointer;
  height: auto;
  display: none;
  padding: 4px 8px 4px 16px;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(222, 228, 230, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
}
.elementos1 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.text02 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text-mobile {
  color: var(--dl-color-default-7d8a98);
  height: auto;
  display: none;
  font-size: 12px;
  align-self: center;
  font-style: Regular;
  text-align: center;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.filtrar-ordenar-mobile {
  gap: 16px;
  width: 100%;
  height: auto;
  display: none;
  align-items: flex-start;
  flex-shrink: 0;
}
.botao-filtrar {
  gap: 8px;
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: 12px 20px 12px 24px;
  align-items: center;
  border-radius: 8px;
  justify-content: center;
  background-color: #ffffff;
}
.text04 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 100%;
  font-stretch: normal;
  text-decoration: none;
}
.filtrar {
  width: 16px;
  height: 16px;
}
.filtros1 {
  gap: 32px;
  display: flex;
  overflow: hidden;
  background:white;
  position: relative;
  align-self: stretch;
  align-items: flex-start;
  padding-top: 16px;
  padding-left: 16px;
  border-radius: 8px;
  padding-right: 16px;
  flex-direction: column;
  padding-bottom: 40px;
}

.botao-ordenar-ordenar-aberto{
  width: 100%
}

.botao-ordenar-data {
  gap: 10px;
  width: 100%;
  display: flex;
  flex-grow: 1;
  max-width: 336px;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}

.botao-ordenar-acessos {
  gap: 10px;
  width: 100%;
  display: flex;
  flex-grow: 1;
  max-width: 336px;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}

.botao-ordenar-thq-dropdown{
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.botao-ordenar-dropdown-toggle1 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}

.filtrar-limpar {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.text07 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  flex-grow: 1;
  font-size: 20px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.buttons-limpar {
  gap: 5px;
  cursor: pointer;
  display: flex;
  padding: 8px;
  align-items: center;
  border-radius: 8px;
  justify-content: center;
}
.text09 {
  color: var(--dl-color-default-07a741);
  height: auto;
  font-size: 14px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 100%;
  font-stretch: normal;
  text-decoration: none;
}
.objetivo-modalidade {
  gap: 16px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.objetivo {
  gap: 16px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.text11 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.opcoes {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.vermaismenos {
  gap: 8px;
  display: flex;
  padding: 4px;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  justify-content: center;
}
.linha-mobile {
  width: 0px;
  height: 1px;
  display: none;
  flex-grow: 1;
  align-self: center;
  background-color: #D8DFE8;
}
.buttons {
  gap: 4px;
  display: flex;
  padding: 8px 12px 8px 16px;
  align-items: center;
  border-radius: 8px;
  justify-content: center;
}
.text13 {
  color: var(--dl-color-default-07a741);
  height: auto;
  font-size: 14px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 100%;
  font-stretch: normal;
  text-decoration: none;
}
.arrowmais {
  width: 14px;
  height: 14px;
}
.arrowmenos {
  width: 16px;
  height: 16px;
}
.linha-desk {
  width: 0px;
  height: 1px;
  flex-grow: 1;
  align-self: center;
  background-color: #D8DFE8;
}
.modalidade {
  gap: 16px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.text15 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.buscar-modalidade {
  gap: 4px;
  width: 100%;
  height: auto;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.buscar {
  gap: 16px;
  width: 100%;
  display: flex;
  padding: 4px 8px 4px 16px;
  flex-grow: 1;
  max-width: 204px;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(222, 228, 230, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
}
.box-input {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.textinput1 {
  color: rgba(45, 62, 80, 1);
  width: 100%;
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  background: none;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.botao-buscar {
  gap: 5px;
  width: 40px;
  cursor: pointer;
  height: 40px;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-padro);
}
.lupa {
  width: 16px;
  height: 16px;
}
.categorias {
  gap: 8px;
  width: 100%;
  height: auto;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.forca {
  gap: 16px;
  height: 40px;
  display: flex;
  padding: 4px 0;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
}
.elementos2 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.text17 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.mostrarmais {
  width: 24px;
  height: 24px;
}
.mostrarmenos {
  width: 24px;
  height: 24px;
  display: none;
}
.relaxamento {
  gap: 8px;
  height: 40px;
  display: flex;
  padding: 4px 0;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
}
.elementos3 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.text19 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.mostrarmais1 {
  width: 24px;
  height: 24px;
}
.mostrarmenos1 {
  width: 24px;
  height: 24px;
  display: none;
}
.opcoes-esportes {
  gap: 8px;
  width: 100%;
  height: auto;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.esportes {
  gap: 16px;
  height: 40px;
  display: flex;
  padding: 4px 0;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
}
.elementos4 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.text21 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.mostrarmais2 {
  width: 24px;
  height: 24px;
  display: none;
}
.mostrarmenos2 {
  width: 24px;
  height: 24px;
}
.vermaismenos1 {
  gap: 8px;
  display: flex;
  padding: 4px;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  justify-content: center;
}
.linha-mobile1 {
  width: 0px;
  height: 1px;
  display: none;
  flex-grow: 1;
  align-self: center;
  background-color: #D8DFE8;
}
.buttons1 {
  gap: 4px;
  display: flex;
  padding: 8px 12px 8px 16px;
  align-items: center;
  border-radius: 8px;
  justify-content: center;
}
.text23 {
  color: var(--dl-color-default-07a741);
  height: auto;
  font-size: 14px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 100%;
  font-stretch: normal;
  text-decoration: none;
}
.arrowmais1 {
  width: 14px;
  cursor: pointer;
  height: 14px;
}
.arrowmenos1 {
  width: 16px;
  height: 16px;
  display: none;
}
.linha-desk1 {
  width: 0px;
  height: 1px;
  flex-grow: 1;
  align-self: center;
  background-color: #D8DFE8;
}
.tipo-atendimento {
  gap: 16px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.text25 {
  color: rgb(45, 62, 80);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.opcoes1 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.valorhora {
  gap: 16px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.text28 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.busca {
  gap: 4px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.minimo {
  gap: 16px;
  width: 100px;
  display: flex;
  padding: 4px 8px 4px 16px;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(222, 228, 230, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
}
.box-input1 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.textinput2 {
  color: rgba(45, 62, 80, 1);
  width: 100%;
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  background: none;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.maximo {
  gap: 16px;
  width: 100px;
  display: flex;
  padding: 4px 8px 4px 16px;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(222, 228, 230, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
}
.box-input2 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.textinput3 {
  color: rgba(45, 62, 80, 1);
  width: 100%;
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  background: none;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.botao-buscar1 {
  gap: 5px;
  width: 40px;
  height: 40px;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-padro);
}
.lupa1 {
  width: 16px;
  height: 16px;
}
.periodo-atendimento {
  gap: 16px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.text30 {
  color: rgb(45, 62, 80);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.opcoes2 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.genero {
  gap: 16px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.text33 {
  color: rgb(45, 62, 80);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.opcoes3 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.onde-atende {
  gap: 16px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.text34 {
  color: rgb(45, 62, 80);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.opcoes4 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.nivel-aluno {
  gap: 16px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.text37 {
  color: rgb(45, 62, 80);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.opcoes5 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.idade-aluno {
  gap: 16px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.text40 {
  color: rgb(45, 62, 80);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.opcoes6 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.botao-filtrar-mobile {
  gap: 8px;
  color: transparent;
  width: 100%;
  height: auto;
  display: none;
  padding: 12px 20px 12px 24px;
  z-index: 1;
  align-items: center;
  flex-shrink: 0;
  border-width: 0px;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-padro);
}
.text43 {
  color: var(--dl-color-default-white);
  height: auto;
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 100%;
  font-stretch: normal;
  text-decoration: none;
}
.filtrar1 {
  width: 16px;
  height: 16px;
}

@media(max-width: 991px) {
  .filtros {
    gap: 24px;
    width: 100%;
  }
  .busca-mobile {
    display: flex;
  }
  .container {
    flex-direction: column;
  }
  .input-busca {
    width: 100%;
  }
  .localizacao-atual {
    height: 40px;
    background-color: #ffffff;
  }
  .brasil-todo {
    height: 40px;
    background-color: #ffffff;
  }
  .text02 {
    color: rgb(45, 62, 80);
    font-size: 16px;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
  }
  .text-mobile {
    display: flex;
  }
  .filtrar-ordenar-mobile {
    display: flex;
    align-self: center;
    justify-content: center;
  }
  .filtros1 {
    width: 100%;
    padding: 16px;
    z-index: 100;
    background-color: #ffffff;
  }
  .filtrar-limpar {
    display: none;
  }
  .linha-mobile {
    display: flex;
  }
  .linha-desk {
    display: none;
  }
  .modalidade {
    display: none;
  }
  .buscar {
    flex: 1;
    max-width: 100%;
  }
  .linha-mobile1 {
    display: flex;
  }
  .linha-desk1 {
    display: none;
  }
  .valorhora {
    display: none;
  }
  .periodo-atendimento {
    display: none;
  }
  .genero {
    display: none;
  }
  .onde-atende {
    display: none;
  }
  .nivel-aluno {
    display: none;
  }
  .idade-aluno {
    display: none;
  }
  .botao-filtrar-mobile {
    display: flex;
  }
}
@media(max-width: 767px) {
  .textinput {
    font-size: 15px;
  }
  .lupaicon {
    width: 17px;
    height: 17px;
  }
  .text04 {
    font-size: 15px;
  }
  .filtrar {
    width: 15px;
    height: 15px;
  }
  .objetivo {
    gap: 12px;
  }
  .text11 {
    font-size: 15px;
  }
  .modalidade {
    gap: 12px;
  }
  .text15 {
    font-size: 15px;
  }
  .textinput1 {
    font-size: 15px;
  }
  .lupa {
    width: 15px;
    height: 15px;
  }
  .text17 {
    font-size: 15px;
  }
  .mostrarmais {
    width: 22px;
    height: 22px;
  }
  .text19 {
    font-size: 15px;
  }
  .mostrarmais1 {
    width: 22px;
    height: 22px;
  }
  .text21 {
    font-size: 15px;
  }
  .mostrarmenos2 {
    width: 22px;
    height: 22px;
  }
  .tipo-atendimento {
    gap: 12px;
  }
  .text25 {
    font-size: 15px;
  }
  .valorhora {
    gap: 12px;
  }
  .text28 {
    font-size: 15px;
  }
  .textinput2 {
    font-size: 15px;
  }
  .textinput3 {
    font-size: 15px;
  }
  .lupa1 {
    width: 15px;
    height: 15px;
  }
  .periodo-atendimento {
    gap: 12px;
  }
  .text30 {
    font-size: 15px;
  }
  .genero {
    gap: 12px;
  }
  .text33 {
    font-size: 15px;
  }
  .onde-atende {
    gap: 12px;
  }
  .text34 {
    font-size: 15px;
  }
  .nivel-aluno {
    gap: 12px;
  }
  .text37 {
    font-size: 15px;
  }
  .idade-aluno {
    gap: 12px;
  }
  .text40 {
    font-size: 15px;
  }
  .text43 {
    font-size: 15px;
  }
  .filtrar1 {
    width: 15px;
    height: 15px;
  }
}
@media(max-width: 479px) {
  .textinput {
    font-size: 14px;
  }
  .lupaicon {
    width: 16px;
    height: 16px;
  }
  .text04 {
    font-size: 14px;
  }
  .filtrar {
    width: 14px;
    height: 14px;
  }
  .filtros1 {
    gap: 16px;
  }
  .text11 {
    font-size: 14px;
  }
  .text15 {
    font-size: 14px;
  }
  .textinput1 {
    font-size: 14px;
  }
  .lupa {
    width: 14px;
    height: 14px;
  }
  .text17 {
    font-size: 14px;
  }
  .mostrarmais {
    width: 20px;
    height: 20px;
  }
  .text19 {
    font-size: 14px;
  }
  .mostrarmais1 {
    width: 20px;
    height: 20px;
  }
  .text21 {
    font-size: 14px;
  }
  .mostrarmenos2 {
    width: 20px;
    height: 20px;
  }
  .text25 {
    font-size: 14px;
  }
  .text28 {
    font-size: 14px;
  }
  .textinput2 {
    font-size: 14px;
  }
  .textinput3 {
    font-size: 14px;
  }
  .lupa1 {
    width: 14px;
    height: 14px;
  }
  .text30 {
    font-size: 14px;
  }
  .text33 {
    font-size: 14px;
  }
  .text34 {
    font-size: 14px;
  }
  .text37 {
    font-size: 14px;
  }
  .text40 {
    font-size: 14px;
  }
  .text43 {
    font-size: 14px;
  }
  .filtrar1 {
    width: 14px;
    height: 14px;
  }
}
