:root {
  --dl-color-gray-500: #595959;
  --dl-color-gray-700: #999999;
  --dl-color-gray-900: #d9d9d9;
  --dl-size-size-large: 144px;
  --dl-size-size-small: 48px;
  --dl-color-danger-300: #a22020;
  --dl-color-danger-500: #bf2626;
  --dl-color-danger-700: #e14747;
  --dl-color-gray-black: #000000;
  --dl-color-gray-white: #ffffff;
  --dl-size-size-medium: 96px;
  --dl-size-size-xlarge: 192px;
  --dl-size-size-xsmall: 16px;
  --dl-space-space-unit: 16px;
  --dl-color-primary-100: #003eb3;
  --dl-color-primary-300: #0074f0;
  --dl-color-primary-500: #14a9ff;
  --dl-color-primary-700: #85dcff;
  --dl-color-success-300: #199033;
  --dl-color-success-500: #32a94c;
  --dl-color-success-700: #4cc366;
  --dl-size-size-xxlarge: 288px;
  --dl-size-size-maxwidth: 1400px;
  --dl-color-brand1-medium: rgba(14, 26, 71, 1);
  --dl-color-default-padro: rgba(251, 166, 0, 1);
  --dl-color-default-style: rgba(255, 255, 255, 1);
  --dl-color-default-white: rgba(255, 255, 255, 1);
  --dl-radius-radius-round: 50%;
  --dl-color-default-07a741: rgba(232, 139, 0, 1);
  --dl-color-default-11592b: rgba(136, 81, 0, 1);
  --dl-color-default-2d3e50: rgba(45, 62, 80, 1);
  --dl-color-default-7d8a98: rgba(125, 138, 152, 1);
  --dl-color-default-d8ffe6: rgba(255, 245, 219, 1);
  --dl-color-default-dfe4e7: rgba(222, 228, 230, 1);
  --dl-color-default-f0f4f6: rgba(240, 244, 246, 1);
  --dl-color-default-ffffff: rgba(255, 255, 255, 1);
  --dl-space-space-halfunit: 8px;
  --dl-space-space-sixunits: 96px;
  --dl-space-space-twounits: 32px;
  --dl-color-neutral_dark-20: rgba(133, 133, 133, 1);
  --dl-color-neutral_dark-30: rgba(53, 53, 53, 1);
  --dl-radius-radius-radius2: 2px;
  --dl-radius-radius-radius4: 4px;
  --dl-radius-radius-radius8: 8px;
  --dl-space-space-fiveunits: 80px;
  --dl-space-space-fourunits: 64px;
  --dl-color-neutral_light-10: rgba(250, 250, 251, 1);
  --dl-color-neutral_light-20: rgba(224, 224, 224, 1);
  --dl-space-space-threeunits: 48px;
  --dl-color-maincolors_gray-bg: rgba(240, 244, 246, 1);
  --dl-color-default-colorgreybg: rgba(240, 244, 246, 1);
  --dl-color-neutral_light-prime: rgba(255, 255, 255, 1);
  --dl-space-space-oneandhalfunits: 24px;
  --dl-input-placeholder-color:#C9D3DD;
  --dl-color-green-promotion: #00CC66;
  --dl-color-danger-light: #FFCCCC;
}
.button {
  color: var(--dl-color-gray-black);
  display: inline-block;
  padding: 0.5rem 1rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.input {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem 1rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.textarea {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.list {
  width: 100%;
  margin: 1em 0px 1em 0px;
  display: block;
  padding: 0px 0px 0px 1.5rem;
  list-style-type: none;
  list-style-position: outside;
}
.list-item {
  display: list-item;
}
.teleport-show {
  display: flex !important;
  transform: none !important;
}
.Content {
  font-size: 16px;
  font-family: Inter;
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.Heading {
  font-size: 32px;
  font-family: Inter;
  font-weight: 700;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
input {
  outline: none;
  color: var(--dl-color-default-2d3e50);
}
input::placeholder {
  color: var(--dl-input-placeholder-color);
}
.spinner {
  border: 4px solid rgba(255, 255, 255, 0.4);
  border-left-color: #fff;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.btn div {
  display: flex !important;
  gap: 8px !important;
  align-items: center !important;
}

.btn:disabled {
  opacity: 0.8 !important;
}

.Toastify__toast-container {
  z-index: 9999999;
}

.video-react .video-react-big-play-button {
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
}

img.logo {
  height: 48px;
  width: auto;
}

@media screen and (max-width: 500px) {
  img.logo {
    height: 42px;
  }
}

.dropdown-toggle {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding: 0px;
}

.dropdown-menu {
  display: none;
  z-index: 999;
  flex-direction: column;
  background-color: white;
  width: calc(100% + 2px);
  border-color: #d8dfe8;
  border-width: 0px 1px 1px 1px;
  border-radius: 0px 0px 8px 8px;
  background-color: rgba(255, 255, 255, 1);
  padding: 4px 16px;
  transform: translate3d(-1px, 32px, 0px) !important;
}

.dropdown-item:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.show {
  display: flex;
}

option {
  font-family: Poppins;
  color: var(--dl-color-default-2d3e50);
}