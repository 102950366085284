.container {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.container01 {
  width: 100%;
  max-width: 1352px;
  display: flex;
  align-self: center;
  align-items: flex-start;
  min-height:100vh;
  flex-shrink: 0;
  padding-top: 24px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 24px;
  justify-content: flex-start;
}
.container02 {
  width: 100%;
  display: flex;
  max-width: 1456px;
  align-items: flex-start;
  justify-content: center;
}
.anunciantes {
  gap: 16px;
  width: 100%;
  display: flex;
  flex-grow: 1;
  max-width: 1052px;
  align-items: center;
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  flex-direction: column;
  padding-bottom: 92px;
  justify-content: center;
}
.cabecalho {
  gap: 8px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
}
.titulo {
  gap: 8px;
  width: 100%;
  display: flex;
  align-self: flex-start;
  align-items: center;
  padding-top: 8px;
}
.text {
  color: rgb(45, 62, 80);
  font-size: 24px;
  font-style: SemiBold;
  text-align: left;
  font-family: "Poppins";
  font-weight: 600;
  font-stretch: normal;
  text-decoration: none;
}
.textqntd {
  color: var(--dl-color-default-7d8a98);
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.buscar {
  gap: 16px;
  width: 100%;
  height: 40px;
  display: flex;
  max-width: 316px;
  align-items: center;
  padding-top: 8px;
  border-color: #DFE4E7;
  border-width: 1px;
  padding-left: 24px;
  border-radius: 8px;
  padding-right: 16px;
  padding-bottom: 8px;
}
.textinput {
  color: #2d3e50;
  width: 100%;
  padding: 0px;
  font-family: "Poppins";
}
.iconbusca {
  width: 19px;
  height: 19px;
  object-fit: cover;
}
.filtros-especificos {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  padding-bottom: 16px;
  justify-content: flex-end;
}
.dropdown-plano {
  gap: 10px;
  width: 100%;
  height: 40px;
  display: flex;
  max-width: 261px;
  align-items: center;
  padding-top: 10px;
  border-color: #D8DFE8;
  border-width: 1px;
  padding-left: 16px;
  border-radius: 8px;
  padding-right: 16px;
  padding-bottom: 10px;
  justify-content: flex-start;
}
.text01 {
  color: rgb(125, 138, 152);
  width: 100%;
  font-size: 14px;
  font-family: "Poppins";
}
.arrowicon {
  width: 24px;
}
.dropdown-status-perfil {
  gap: 10px;
  width: 100%;
  height: 40px;
  display: flex;
  max-width: 261px;
  align-items: center;
  padding-top: 10px;
  border-color: #D8DFE8;
  border-width: 1px;
  padding-left: 16px;
  border-radius: 8px;
  padding-right: 16px;
  padding-bottom: 10px;
  justify-content: flex-start;
}
.text02 {
  color: rgb(125, 138, 152);
  width: 100%;
  font-size: 14px;
  font-family: "Poppins";
}
.arrowicon1 {
  width: 24px;
}
.dropdown-status-anuncio {
  gap: 10px;
  width: 100%;
  height: 40px;
  display: flex;
  max-width: 261px;
  align-items: center;
  padding-top: 10px;
  border-color: #D8DFE8;
  border-width: 1px;
  padding-left: 16px;
  border-radius: 8px;
  padding-right: 16px;
  padding-bottom: 10px;
  justify-content: flex-start;
}
.text03 {
  color: #7D8A98;
  width: 100%;
  font-size: 14px;
  font-family: "Poppins";
}
.arrowicon2 {
  width: 24px;
}
.anunciantes-lista {
  gap: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.anunciante {
  gap: 24px;
  width: 100%;
  display: flex;
  padding: 24px;
  height: 100%;
  flex-wrap: wrap;
  align-self: stretch;
  box-shadow: 4px 8px 30px 0px rgba(0, 0, 0, 0.09);
  align-items: center;
  flex-shrink: 0;
  border-color: #DFE4E7;
  border-width: 1px;
  border-radius: 16px;
}
.foto-plano {
  gap: 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.image {
  width: 124px;
  height: 124px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.plano {
  gap: 8px;
  width: 100%;
  height: 31px;
  display: flex;
  padding: 8px 16px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(232, 139, 0, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-d8ffe6);
}
.pesoeliteicon {
  width: 27px;
}
.text04 {
  color: var(--dl-color-default-07a741);
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  font-stretch: normal;
  text-decoration: none;
}
.infos-usuario {
  gap: 24px;
  width: 100%;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  max-width: 377px;
  align-self: stretch;
  align-items: flex-start;
  border-color: #F0F4F6;
  border-width: 1px;
  padding-right: 24px;
  flex-direction: column;
  justify-content: space-between;
  border-top-width: 0px;
  border-left-width: 0px;
  border-bottom-width: 0px;
}
.container03 {
  gap: 14px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.text-nome {
  color: rgba(45, 62, 80, 1);
  width: fit-content;
  font-size: 20px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: 1;
}
.email {
  gap: 8px;
  width: 100%;
  display: flex;
  align-items: center;
}
.emailicon {
  width: 15px;
}
.text07 {
  color: rgb(125, 138, 152);
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: 1;
  font-stretch: normal;
  overflow-wrap: break-word;
}
.telefone {
  gap: 8px;
  width: 100%;
  display: flex;
  align-items: center;
}
.phoneicon {
  width: 13px;
}
.text08 {
  color: rgb(125, 138, 152);
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1;
  font-stretch: normal;
  overflow-wrap: break-word;
}
.text-data-entrada {
  color: #7D8A98;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: 1;
  font-stretch: normal;
  text-decoration: none;
}
.container04 {
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.status-usuario {
  gap: 8px;
  width: 100%;
  display: flex;
  min-width: 180px;
  align-self: stretch;
  align-items: center;
  padding-top: 8px;
  border-color: #DFE4E7;
  border-width: 1px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 8px;
  border-top-left-radius: 8px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 0;
}
.iconchecked {
  width: 16px;
  object-fit: cover;
}
.text12 {
  color: #2D3E50;
  font-size: 14px;
  font-family: "Poppins";
}
.botao-ver-perfil {
  gap: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 12px;
  padding-left: 24px;
  padding-right: 20px;
  padding-bottom: 12px;
  justify-content: center;
  background-color: #F0F4F6;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.text13 {
  color: #2D3E50;
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 500;
}
.iconarrow {
  width: 16px;
  object-fit: cover;
}
.infos-anuncio {
  gap: 24px;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  align-self: stretch;
  align-items: stretch;
  flex-direction: column;
  justify-content: flex-start;
}
.text-nome1 {
  color: #7d8a98;
  width: fit-content;
  font-size: 16px;
  font-style: SemiBold;
  text-align: left;
  font-family: "Poppins";
  font-weight: 600;
  line-height: 1;
}
.container05 {
  gap: 16px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.impressoes {
  gap: 16px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  max-width: 98px;
  align-items: flex-start;
  flex-direction: column;
}
.text14 {
  color: #7D8A98;
  font-size: 16px;
  font-family: "Poppins";
  line-height: 1;
}
.qntd {
  color: #2D3E50;
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 600;
  line-height: 1;
}
.divisor {
  width: 1px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  background-color: #F0F4F6;
}
.cliques {
  gap: 16px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  max-width: 98px;
  align-items: flex-start;
  flex-direction: column;
}
.text15 {
  color: rgb(125, 138, 152);
  font-size: 16px;
  font-family: "Poppins";
  line-height: 1;
}
.qntd01 {
  color: #2D3E50;
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 600;
  line-height: 1;
}
.divisor1 {
  width: 1px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  background-color: #F0F4F6;
}
.conversoes {
  gap: 16px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  max-width: 98px;
  align-items: flex-start;
  flex-direction: column;
}
.text16 {
  color: rgb(125, 138, 152);
  font-size: 16px;
  font-family: "Poppins";
  line-height: 1;
}
.qntds {
  gap: 8px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.qntd02 {
  color: #2D3E50;
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 600;
  line-height: 1;
}
.qntd03 {
  color: #2D3E50;
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 600;
  line-height: 1;
}
.container06 {
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.status-anuncio {
  gap: 8px;
  width: 100%;
  display: flex;
  max-width: 180px;
  align-self: stretch;
  align-items: center;
  padding-top: 8px;
  border-color: #DFE4E7;
  border-width: 1px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 8px;
  justify-content: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 0;
}
.iconchecked1 {
  width: 16px;
  object-fit: cover;
}
.text17 {
  color: rgb(45, 62, 80);
  font-size: 14px;
  font-family: "Poppins";
  white-space: nowrap;
}
.botao-ver-anuncio {
  gap: 8px;
  width: 100%;
  display: flex;
  max-width: 242px;
  align-items: center;
  padding-top: 12px;
  padding-left: 24px;
  padding-right: 20px;
  padding-bottom: 12px;
  justify-content: center;
  background-color: #FCA600;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.text18 {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 500;
}
.iconarrow1 {
  width: 16px;
  object-fit: cover;
}
.anunciante1 {
  gap: 24px;
  width: 100%;
  display: flex;
  padding: 24px;
  flex-wrap: wrap;
  align-self: stretch;
  box-shadow: 4px 8px 30px 0px rgba(0, 0, 0, 0.09);
  align-items: center;
  flex-shrink: 0;
  border-color: #DFE4E7;
  border-width: 1px;
  border-radius: 16px;
}
.foto-plano1 {
  gap: 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.image1 {
  width: 124px;
  height: 124px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.plano1 {
  gap: 8px;
  width: 100%;
  height: 31px;
  display: flex;
  padding: 8px 16px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(232, 139, 0, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-d8ffe6);
}
.pesoaqueciicon {
  height: 16px;
}
.text21 {
  color: var(--dl-color-default-07a741);
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: "Poppins";
  font-weight: 400;
  font-stretch: normal;
  text-decoration: none;
}
.infos-usuario1 {
  gap: 24px;
  width: 100%;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  max-width: 377px;
  align-self: stretch;
  align-items: flex-start;
  border-color: #F0F4F6;
  border-width: 1px;
  padding-right: 24px;
  flex-direction: column;
  justify-content: space-between;
  border-top-width: 0px;
  border-left-width: 0px;
  border-bottom-width: 0px;
}
.container07 {
  gap: 14px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.text-nome2 {
  color: rgba(45, 62, 80, 1);
  width: fit-content;
  font-size: 20px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: 1;
}
.email1 {
  gap: 8px;
  width: 100%;
  display: flex;
  align-items: center;
}
.emailicon1 {
  width: 15px;
}
.text23 {
  color: rgb(125, 138, 152);
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: 1;
  font-stretch: normal;
  overflow-wrap: break-word;
}
.telefone1 {
  gap: 8px;
  width: 100%;
  display: flex;
  align-items: center;
}
.phoneicon1 {
  width: 13px;
}
.text24 {
  color: rgb(125, 138, 152);
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1;
  font-stretch: normal;
  overflow-wrap: break-word;
}
.text-data-entrada1 {
  color: #7D8A98;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: 1;
  font-stretch: normal;
  text-decoration: none;
}
.container08 {
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.status-usuario1 {
  gap: 8px;
  width: 100%;
  display: flex;
  min-width: 180px;
  align-self: stretch;
  align-items: center;
  padding-top: 8px;
  border-color: #DFE4E7;
  border-width: 1px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 8px;
  border-top-left-radius: 8px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 0;
}
.iconinativo {
  width: 16px;
  object-fit: cover;
}
.text28 {
  color: rgb(45, 62, 80);
  font-size: 14px;
  font-family: "Poppins";
}
.botao-ver-perfil1 {
  gap: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 12px;
  padding-left: 24px;
  padding-right: 20px;
  padding-bottom: 12px;
  justify-content: center;
  background-color: #F0F4F6;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.text31 {
  color: #2D3E50;
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 500;
}
.iconarrow2 {
  width: 16px;
  object-fit: cover;
}
.infos-anuncio1 {
  gap: 24px;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  align-self: stretch;
  align-items: stretch;
  flex-direction: column;
  justify-content: flex-start;
}
.text-nome3 {
  color: #7d8a98;
  width: fit-content;
  font-size: 16px;
  font-style: SemiBold;
  text-align: left;
  font-family: "Poppins";
  font-weight: 600;
  line-height: 1;
}
.container09 {
  gap: 16px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.impressoes1 {
  gap: 16px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  max-width: 98px;
  align-items: flex-start;
  flex-direction: column;
}
.text32 {
  color: #7D8A98;
  font-size: 16px;
  font-family: "Poppins";
  line-height: 1;
}
.qntd04 {
  color: #2D3E50;
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 600;
  line-height: 1;
}
.divisor2 {
  width: 1px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  background-color: #F0F4F6;
}
.cliques1 {
  gap: 16px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  max-width: 98px;
  align-items: flex-start;
  flex-direction: column;
}
.text33 {
  color: rgb(125, 138, 152);
  font-size: 16px;
  font-family: "Poppins";
  line-height: 1;
}
.qntd05 {
  color: #2D3E50;
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 600;
  line-height: 1;
}
.divisor3 {
  width: 1px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  background-color: #F0F4F6;
}
.conversoes1 {
  gap: 16px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  max-width: 98px;
  align-items: flex-start;
  flex-direction: column;
}
.text34 {
  color: rgb(125, 138, 152);
  font-size: 16px;
  font-family: "Poppins";
  line-height: 1;
}
.qntds1 {
  gap: 8px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.qntd06 {
  color: #2D3E50;
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 600;
  line-height: 1;
}
.qntd07 {
  color: #2D3E50;
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 600;
  line-height: 1;
}
.container10 {
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.status-anuncio1 {
  gap: 8px;
  width: 100%;
  display: flex;
  max-width: 180px;
  align-self: stretch;
  align-items: center;
  padding-top: 8px;
  border-color: #DFE4E7;
  border-width: 1px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 8px;
  justify-content: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 0;
}
.iconreprovado {
  width: 16px;
  object-fit: cover;
}
.text35 {
  color: rgb(45, 62, 80);
  font-size: 14px;
  font-family: "Poppins";
  white-space: nowrap;
}
.botao-ver-anuncio1 {
  gap: 8px;
  width: 100%;
  display: flex;
  max-width: 242px;
  align-items: center;
  padding-top: 12px;
  padding-left: 24px;
  padding-right: 20px;
  padding-bottom: 12px;
  justify-content: center;
  background-color: #FCA600;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.text36 {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 500;
}
.iconarrow3 {
  width: 16px;
  object-fit: cover;
}
.anunciante2 {
  gap: 24px;
  width: 100%;
  display: flex;
  padding: 24px;
  flex-wrap: wrap;
  align-self: stretch;
  box-shadow: 4px 8px 30px 0px rgba(0, 0, 0, 0.09);
  align-items: center;
  flex-shrink: 0;
  border-color: #DFE4E7;
  border-width: 1px;
  border-radius: 16px;
}
.foto-plano2 {
  gap: 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.image2 {
  width: 124px;
  height: 124px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.plano2 {
  gap: 8px;
  width: 100%;
  height: 31px;
  display: flex;
  padding: 8px 16px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-color: #7D8A98;
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  justify-content: center;
  background-color: #F0F4F6;
}
.icon-free {
  width: 28px;
  object-fit: cover;
}
.text39 {
  color: #2d3e50;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: "Poppins";
  font-weight: 400;
  font-stretch: normal;
  text-decoration: none;
}
.infos-usuario2 {
  gap: 24px;
  width: 100%;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  max-width: 377px;
  align-self: stretch;
  align-items: flex-start;
  border-color: #F0F4F6;
  border-width: 1px;
  padding-right: 24px;
  flex-direction: column;
  justify-content: space-between;
  border-top-width: 0px;
  border-left-width: 0px;
  border-bottom-width: 0px;
}
.container11 {
  gap: 14px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.text-nome4 {
  color: rgba(45, 62, 80, 1);
  width: fit-content;
  font-size: 20px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: 1;
}
.email2 {
  gap: 8px;
  width: 100%;
  display: flex;
  align-items: center;
}
.emailicon2 {
  width: 15px;
}
.text41 {
  color: rgb(125, 138, 152);
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: 1;
  font-stretch: normal;
  overflow-wrap: break-word;
}
.telefone2 {
  gap: 8px;
  width: 100%;
  display: flex;
  align-items: center;
}
.phoneicon2 {
  width: 13px;
}
.text42 {
  color: rgb(125, 138, 152);
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1;
  font-stretch: normal;
  overflow-wrap: break-word;
}
.text-data-entrada2 {
  color: #7D8A98;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: 1;
  font-stretch: normal;
  text-decoration: none;
}
.container12 {
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.status-usuario2 {
  gap: 8px;
  width: 100%;
  display: flex;
  min-width: 180px;
  align-self: stretch;
  align-items: center;
  padding-top: 8px;
  border-color: #DFE4E7;
  border-width: 1px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 8px;
  border-top-left-radius: 8px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 0;
}
.iconcloqueado {
  width: 16px;
  object-fit: cover;
}
.text46 {
  color: rgb(45, 62, 80);
  font-size: 14px;
  font-family: "Poppins";
}
.botao-ver-perfil2 {
  gap: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 12px;
  padding-left: 24px;
  padding-right: 20px;
  padding-bottom: 12px;
  justify-content: center;
  background-color: #F0F4F6;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.text49 {
  color: #2D3E50;
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 500;
}
.iconarrow4 {
  width: 16px;
  object-fit: cover;
}
.infos-anuncio2 {
  gap: 24px;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  align-self: stretch;
  align-items: stretch;
  flex-direction: column;
  justify-content: flex-start;
}
.text-nome5 {
  color: rgb(125, 138, 152);
  width: fit-content;
  font-size: 16px;
  font-style: SemiBold;
  text-align: left;
  font-family: "Poppins";
  font-weight: 600;
  line-height: 1;
}
.container13 {
  gap: 16px;
  display: none;
  align-items: flex-start;
  flex-direction: row;
}
.impressoes2 {
  gap: 16px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  max-width: 98px;
  align-items: flex-start;
  flex-direction: column;
}
.text50 {
  color: #7D8A98;
  font-size: 16px;
  font-family: "Poppins";
  line-height: 1;
}
.qntd08 {
  color: #2D3E50;
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 600;
  line-height: 1;
}
.divisor4 {
  width: 1px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  background-color: #F0F4F6;
}
.cliques2 {
  gap: 16px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  max-width: 98px;
  align-items: flex-start;
  flex-direction: column;
}
.text51 {
  color: rgb(125, 138, 152);
  font-size: 16px;
  font-family: "Poppins";
  line-height: 1;
}
.qntd09 {
  color: #2D3E50;
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 600;
  line-height: 1;
}
.divisor5 {
  width: 1px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  background-color: #F0F4F6;
}
.conversoes2 {
  gap: 16px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  max-width: 98px;
  align-items: flex-start;
  flex-direction: column;
}
.text52 {
  color: rgb(125, 138, 152);
  font-size: 16px;
  font-family: "Poppins";
  line-height: 1;
}
.qntds2 {
  gap: 8px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.qntd10 {
  color: #2D3E50;
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 600;
  line-height: 1;
}
.qntd11 {
  color: #2D3E50;
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 600;
  line-height: 1;
}
.container14 {
  width: 100%;
  display: none;
  align-items: flex-start;
}
.status-anuncio2 {
  gap: 8px;
  width: 100%;
  display: flex;
  max-width: 180px;
  align-self: stretch;
  align-items: center;
  padding-top: 8px;
  border-color: #DFE4E7;
  border-width: 1px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 8px;
  justify-content: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 0;
}
.iconchecked2 {
  width: 16px;
  object-fit: cover;
}
.text53 {
  color: rgb(45, 62, 80);
  font-size: 14px;
  font-family: "Poppins";
  white-space: nowrap;
}
.botao-ver-anuncio2 {
  gap: 8px;
  width: 100%;
  display: flex;
  max-width: 242px;
  align-items: center;
  padding-top: 12px;
  padding-left: 24px;
  padding-right: 20px;
  padding-bottom: 12px;
  justify-content: center;
  background-color: #FCA600;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.text54 {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 500;
}
.iconarrow5 {
  width: 16px;
  object-fit: cover;
}
.paginacao {
  gap: 4px;
  width: 100%;
  display: flex;
  padding: 16px 0 0;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
}
.prev {
  height: 30px;
  display: none;
  padding: 10px;
  overflow: hidden;
  min-width: 30px;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(216, 223, 232, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.text57 {
  color: rgb(125, 138, 152);
  font-size: 16px;
  font-style: Regular;
  text-align: center;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.page-atual {
  height: 30px;
  display: flex;
  padding: 10px;
  overflow: hidden;
  min-width: 30px;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(251, 166, 0, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
  cursor: pointer;
}
.text58 {
  color: var(--dl-color-default-padro);
  font-size: 16px;
  font-style: Regular;
  text-align: center;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.page {
  height: 30px;
  display: flex;
  padding: 10px;
  overflow: hidden;
  min-width: 30px;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(216, 223, 232, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
  cursor: pointer;
}
.text59 {
  color: rgba(125, 138, 152, 1);
  font-size: 16px;
  font-style: Regular;
  text-align: center;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.page1 {
  height: 30px;
  display: flex;
  padding: 10px;
  overflow: hidden;
  min-width: 30px;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(216, 223, 232, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.text60 {
  color: rgb(125, 138, 152);
  font-size: 16px;
  font-style: Regular;
  text-align: center;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.page2 {
  height: 30px;
  display: flex;
  padding: 10px;
  overflow: hidden;
  min-width: 30px;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(216, 223, 232, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.text61 {
  color: rgb(125, 138, 152);
  font-size: 16px;
  font-style: Regular;
  text-align: center;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.next {
  height: 30px;
  display: flex;
  padding: 10px;
  overflow: hidden;
  min-width: 30px;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(216, 223, 232, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.text62 {
  color: rgb(125, 138, 152);
  font-size: 16px;
  font-style: Regular;
  text-align: center;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.perfil-usuario {
  gap: 16px;
  width: 100%;
  display: none;
  flex-grow: 1;
  max-width: 1052px;
  align-items: center;
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  flex-direction: column;
  padding-bottom: 92px;
  justify-content: center;
}
.cabecalho1 {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: flex-start;
}
.titulo1 {
  color: rgb(45, 62, 80);
  width: 100%;
  font-size: 24px;
  font-style: SemiBold;
  text-align: left;
  font-family: "Poppins";
  font-weight: 600;
  line-height: 1;
  font-stretch: normal;
  text-decoration: none;
}
.status-usuario3 {
  gap: 8px;
  width: 100%;
  display: flex;
  max-width: 180px;
  align-self: stretch;
  align-items: center;
  padding-top: 8px;
  border-color: #DFE4E7;
  border-width: 1px;
  padding-left: 16px;
  border-radius: 8px;
  padding-right: 16px;
  padding-bottom: 8px;
}
.iconinativo1 {
  width: 16px;
  display: none;
  object-fit: cover;
}
.iconcloqueado1 {
  width: 16px;
  display: none;
  object-fit: cover;
}
.iconchecked3 {
  width: 16px;
  object-fit: cover;
}
.text63 {
  color: #2D3E50;
  font-size: 14px;
  font-family: "Poppins";
}
.botao-bloquear {
  gap: 8px;
  width: 100%;
  display: flex;
  max-width: 140px;
  align-self: stretch;
  align-items: center;
  padding-top: 12px;
  padding-left: 24px;
  border-radius: 8px;
  padding-right: 20px;
  padding-bottom: 12px;
  justify-content: center;
  background-color: #F0F4F6;
}
.text64 {
  color: rgb(45, 62, 80);
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 500;
  line-height: 1;
}
.iconcloqueado2 {
  width: 16px;
  object-fit: cover;
}
.usuario {
  gap: 24px;
  width: 100%;
  display: flex;
  padding: 32px;
  align-items: flex-start;
  border-color: #D8DFE8;
  border-width: 1px;
  border-radius: 16px;
}
.foto-perfil {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.container15 {
  gap: 16px;
  flex: 1;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.plano3 {
  gap: 8px;
  width: 100%;
  height: 31px;
  display: flex;
  padding: 8px 16px;
  overflow: hidden;
  max-width: 150px;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(232, 139, 0, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-d8ffe6);
}
.icon-free1 {
  width: 28px;
  display: none;
  object-fit: cover;
}
.pesoaqueciicon1 {
  height: 16px;
  display: none;
}
.pesoeliteicon1 {
  width: 27px;
}
.text65 {
  color: var(--dl-color-default-07a741);
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  font-stretch: normal;
  text-decoration: none;
}
.text-data-inicio-contrato {
  color: rgb(125, 138, 152);
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1;
  font-stretch: normal;
  text-decoration: none;
}
.text-data-fim-contrato {
  color: rgb(125, 138, 152);
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1;
  font-stretch: normal;
  text-decoration: none;
}
.text-data-entrada3 {
  color: rgb(125, 138, 152);
  font-size: 14px;
  font-style: Regular;
  text-align: right;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1;
  font-stretch: normal;
  text-decoration: none;
}
.dados {
  gap: 24px;
  width: 100%;
  display: flex;
  padding: 32px;
  overflow: hidden;
  align-self: stretch;
  align-items: flex-start;
  border-color: rgba(216, 223, 232, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}
.inputs {
  gap: 24px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: row;
}
.coluna {
  gap: 24px;
  flex: 1;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.coluna1 {
  gap: 24px;
  flex: 1;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.endereco-numero {
  gap: 16px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
}
.cidade-uf {
  gap: 16px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
}
.botao-salvar {
  gap: 5px;
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: 12px 24px;
  max-width: 128px;
  align-self: flex-end;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-padro);
}
.text67 {
  color: var(--dl-color-default-white);
  height: auto;
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 100%;
  font-stretch: normal;
  text-decoration: none;
}
.verificacao {
  gap: 24px;
  display: flex;
  padding: 32px;
  overflow: hidden;
  align-self: stretch;
  align-items: flex-start;
  border-color: rgba(216, 223, 232, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}
.verificar-telefone {
  gap: 24px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.verificacao-telefone {
  gap: 8px;
  width: 100%;
  height: 40px;
  display: flex;
  max-width: 180px;
  align-self: flex-end;
  align-items: center;
  padding-top: 8px;
  border-color: #DFE4E7;
  border-width: 1px;
  padding-left: 16px;
  border-radius: 8px;
  padding-right: 16px;
  padding-bottom: 8px;
}
.iconinativo2 {
  width: 16px;
  display: none;
  object-fit: cover;
}
.iconcloqueado3 {
  width: 16px;
  display: none;
  object-fit: cover;
}
.iconchecked4 {
  width: 16px;
  object-fit: cover;
}
.text69 {
  color: #2D3E50;
  font-size: 14px;
  font-family: "Poppins";
}
.verificar-email {
  gap: 24px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.verificacao-email {
  gap: 8px;
  width: 100%;
  height: 40px;
  display: flex;
  max-width: 180px;
  align-self: flex-end;
  align-items: center;
  padding-top: 8px;
  border-color: #DFE4E7;
  border-width: 1px;
  padding-left: 16px;
  border-radius: 8px;
  padding-right: 16px;
  padding-bottom: 8px;
}
.iconinativo3 {
  width: 16px;
  display: none;
  object-fit: cover;
}
.iconcloqueado4 {
  width: 16px;
  display: none;
  object-fit: cover;
}
.iconchecked5 {
  width: 16px;
  object-fit: cover;
}
.text70 {
  color: #2D3E50;
  font-size: 14px;
  font-family: "Poppins";
}
.botao-alterar-senha {
  gap: 8px;
  cursor: pointer;
  display: flex;
  padding: 12px 20px 12px 24px;
  align-self: flex-start;
  align-items: center;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-colorgreybg);
}
.text71 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 100%;
  font-stretch: normal;
  text-decoration: none;
}
.chave {
  width: 16px;
  height: 16px;
}
@media(max-width: 1250px) {
  .anunciante {
    flex-direction: column;
  }
  .infos-usuario {
    max-width: 353px;
    align-self: center;
    border-width: 0px;
    padding-right: 0px;
  }
  .container03 {
    align-items: center;
  }
  .email {
    justify-content: center;
  }
  .telefone {
    justify-content: center;
  }
  .infos-anuncio {
    width: 100%;
    align-self: center;
    align-items: center;
  }
  .container05 {
    width: 100%;
    justify-content: center;
  }
  .impressoes {
    align-items: center;
  }
  .cliques {
    align-items: center;
  }
  .conversoes {
    align-items: center;
  }
  .container06 {
    justify-content: center;
  }
  .anunciante1 {
    flex-direction: column;
  }
  .infos-usuario1 {
    max-width: 353px;
    align-self: center;
    border-width: 0px;
    padding-right: 0px;
  }
  .container07 {
    align-items: center;
  }
  .email1 {
    justify-content: center;
  }
  .telefone1 {
    justify-content: center;
  }
  .infos-anuncio1 {
    width: 100%;
    align-self: center;
    align-items: center;
  }
  .container09 {
    width: 100%;
    justify-content: center;
  }
  .impressoes1 {
    align-items: center;
  }
  .cliques1 {
    align-items: center;
  }
  .conversoes1 {
    align-items: center;
  }
  .container10 {
    justify-content: center;
  }
  .anunciante2 {
    flex-direction: column;
  }
  .infos-usuario2 {
    max-width: 353px;
    align-self: center;
    border-width: 0px;
    padding-right: 0px;
  }
  .container11 {
    align-items: center;
  }
  .email2 {
    justify-content: center;
  }
  .telefone2 {
    justify-content: center;
  }
  .infos-anuncio2 {
    width: 100%;
    align-self: center;
    align-items: center;
  }
  .container13 {
    width: 100%;
    justify-content: center;
  }
  .impressoes2 {
    align-items: center;
  }
  .cliques2 {
    align-items: center;
  }
  .conversoes2 {
    align-items: center;
  }
  .container14 {
    justify-content: center;
  }
}
@media(max-width: 991px) {
  .anunciantes {
    padding-bottom: 108px;
  }
  .anunciantes-lista {
    gap: 24px;
  }
  .perfil-usuario {
    padding-bottom: 108px;
  }
  .usuario {
    padding-top: 24px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 24px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .dados {
    gap: 32px;
    padding: 0px;
    border-width: 0px;
    border-radius: 0px;
  }
  .botao-salvar {
    align-self: center;
  }
  .verificacao {
    padding-top: 24px;
    padding-left: 0px;
    border-radius: 0px;
    padding-right: 0px;
    padding-bottom: 24px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .verificar-telefone {
    gap: 12px;
  }
  .verificacao-telefone {
    width: auto;
    max-width: auto;
  }
  .text69 {
    display: none;
  }
  .verificar-email {
    gap: 12px;
  }
  .verificacao-email {
    width: auto;
    max-width: auto;
  }
  .text70 {
    display: none;
  }
}
@media(max-width: 767px) {
  .cabecalho {
    gap: 16px;
    flex-direction: column;
  }
  .titulo {
    width: 100%;
    padding-top: 0px;
  }
  .text {
    font-size: 22px;
  }
  .textqntd {
    font-size: 15px;
  }
  .buscar {
    max-width: 100%;
  }
  .textinput {
    font-size: 15px;
  }
  .iconbusca {
    width: 18px;
    height: 18px;
  }
  .filtros-especificos {
    flex-direction: column;
  }
  .dropdown-plano {
    max-width: 100%;
  }
  .dropdown-status-perfil {
    max-width: 100%;
  }
  .dropdown-status-anuncio {
    max-width: 100%;
  }
  .plano {
    align-self: center;
  }
  .text04 {
    font-size: 15px;
  }
  .infos-usuario {
    width: 100%;
  }
  .text-nome {
    font-size: 19px;
    text-align: center;
  }
  .email {
    align-self: center;
    justify-content: center;
  }
  .text07 {
    width: fit-content;
  }
  .telefone {
    align-self: center;
    justify-content: center;
  }
  .text08 {
    width: fit-content;
  }
  .text-data-entrada {
    align-self: stretch;
    text-align: center;
  }
  .text13 {
    font-size: 15px;
  }
  .infos-anuncio {
    width: 100%;
  }
  .text-nome1 {
    font-size: 15px;
    text-align: center;
  }
  .text18 {
    font-size: 15px;
  }
  .plano1 {
    align-self: center;
  }
  .text21 {
    font-size: 15px;
  }
  .infos-usuario1 {
    width: 100%;
  }
  .text-nome2 {
    font-size: 19px;
    text-align: center;
  }
  .email1 {
    align-self: center;
    justify-content: center;
  }
  .text23 {
    width: fit-content;
  }
  .telefone1 {
    align-self: center;
    justify-content: center;
  }
  .text24 {
    width: fit-content;
  }
  .text-data-entrada1 {
    align-self: stretch;
    text-align: center;
  }
  .text31 {
    font-size: 15px;
  }
  .infos-anuncio1 {
    width: 100%;
  }
  .text-nome3 {
    font-size: 15px;
    text-align: center;
  }
  .text36 {
    font-size: 15px;
  }
  .plano2 {
    align-self: center;
  }
  .text39 {
    font-size: 15px;
  }
  .infos-usuario2 {
    width: 100%;
  }
  .text-nome4 {
    font-size: 19px;
    text-align: center;
  }
  .email2 {
    align-self: center;
    justify-content: center;
  }
  .text41 {
    width: fit-content;
  }
  .telefone2 {
    align-self: center;
    justify-content: center;
  }
  .text42 {
    width: fit-content;
  }
  .text-data-entrada2 {
    align-self: stretch;
    text-align: center;
  }
  .text49 {
    font-size: 15px;
  }
  .infos-anuncio2 {
    width: 100%;
  }
  .text-nome5 {
    font-size: 15px;
    text-align: center;
  }
  .text54 {
    font-size: 15px;
  }
  .cabecalho1 {
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  .titulo1 {
    font-size: 22px;
  }
  .usuario {
    align-items: center;
    flex-direction: column;
  }
  .plano3 {
    align-self: center;
  }
  .text65 {
    font-size: 15px;
  }
  .text-data-inicio-contrato {
    align-self: stretch;
    text-align: center;
  }
  .text-data-fim-contrato {
    align-self: stretch;
    text-align: center;
  }
  .text-data-entrada3 {
    align-self: stretch;
    text-align: center;
  }
  .coluna {
    flex: 0 0 auto;
  }
  .coluna1 {
    flex: 0 0 auto;
  }
}
@media(max-width: 479px) {
  .text {
    font-size: 20px;
  }
  .textqntd {
    font-size: 14px;
  }
  .textinput {
    font-size: 14px;
  }
  .pesoeliteicon {
    width: 24px;
  }
  .infos-usuario {
    max-width: 100%;
  }
  .text-nome {
    font-size: 18px;
  }
  .status-usuario {
    width: auto;
    min-width: auto;
  }
  .text12 {
    display: none;
  }
  .text13 {
    font-size: 14px;
  }
  .container05 {
    flex-wrap: wrap;
    justify-content: center;
  }
  .status-anuncio {
    width: auto;
    max-width: auto;
  }
  .text17 {
    display: none;
  }
  .botao-ver-anuncio {
    max-width: 100%;
  }
  .text18 {
    font-size: 14px;
  }
  .pesoaqueciicon {
    height: 14px;
  }
  .infos-usuario1 {
    max-width: 100%;
  }
  .text-nome2 {
    font-size: 18px;
  }
  .status-usuario1 {
    width: auto;
    min-width: auto;
  }
  .text28 {
    display: none;
  }
  .text29 {
    display: none;
  }
  .text31 {
    font-size: 14px;
  }
  .container09 {
    flex-wrap: wrap;
    justify-content: center;
  }
  .status-anuncio1 {
    width: auto;
    max-width: auto;
  }
  .text35 {
    display: none;
  }
  .botao-ver-anuncio1 {
    max-width: 100%;
  }
  .text36 {
    font-size: 14px;
  }
  .icon-free {
    width: 26px;
  }
  .infos-usuario2 {
    max-width: 100%;
  }
  .text-nome4 {
    font-size: 18px;
  }
  .status-usuario2 {
    width: auto;
    min-width: auto;
  }
  .text46 {
    display: none;
  }
  .text47 {
    display: none;
  }
  .text49 {
    font-size: 14px;
  }
  .container13 {
    flex-wrap: wrap;
    justify-content: center;
  }
  .status-anuncio2 {
    width: auto;
    max-width: auto;
  }
  .text53 {
    display: none;
  }
  .botao-ver-anuncio2 {
    max-width: 100%;
  }
  .text54 {
    font-size: 14px;
  }
  .titulo1 {
    font-size: 20px;
  }
  .status-usuario3 {
    max-width: 100%;
    justify-content: center;
  }
  .botao-bloquear {
    max-width: 100%;
  }
  .icon-free1 {
    width: 26px;
  }
  .pesoaqueciicon1 {
    height: 14px;
  }
  .pesoeliteicon1 {
    width: 24px;
  }
  .verificacao-telefone {
    max-width: 100%;
    justify-content: center;
  }
  .verificacao-email {
    max-width: 100%;
    justify-content: center;
  }
}
