.plano {
    gap: 8px;
    width: 180px;
    height: 100%;
    display: flex;
    padding: 8px 16px;
    overflow: hidden;
    align-items: center;
    flex-shrink: 0;
    background-color: rgb(125, 138, 152, 0.2);
    border-style: solid;
    border: 1px solid #7d8a98;
    border-width: 1px;
    border-radius: 8px;
    justify-content: center;
}

.plano-elite {
    gap: 8px;
    width: 180px;
    height: 31px;
    display: flex;
    padding: 8px 16px;
    overflow: hidden;
    align-items: center;
    flex-shrink: 0;
    border-style: solid;
    border-width: 1px;
    border-radius: 8px;
    justify-content: center;
    border-color: rgba(232, 139, 0, 1);
    background-color: var(--dl-color-default-d8ffe6);
}

.icon-free {
    width: 28px;
    object-fit: cover;
}

.pesoaqueciicon {
    height: 16px;
}

.pesoeliteicon {
    width: 27px;
}

.text1 {
    font-size: 16px;
    font-style: Regular;
    text-align: left;
    font-family: Poppins;
    font-weight: 400;
    font-stretch: normal;
    text-decoration: none;
    color: var(--dl-color-default-2d3e50);
}

.text {
    color: var(--dl-color-default-07a741);
    font-size: 16px;
    font-style: Regular;
    text-align: left;
    font-family: Poppins;
    font-weight: 400;
    font-stretch: normal;
    text-decoration: none;
}

@media(max-width: 767px) {
    .plano {
        align-self: center;
    }
    .text {
        font-size: 15px;
    }
    .text1 {
        font-size: 15px;
    }
}

@media(max-width: 479px) {
    .icon-free {
        width: 26px;
    }
    .pesoaqueciicon {
        height: 14px;
    }
    .pesoeliteicon {
        width: 24px;
    }
}