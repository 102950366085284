.container {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
  background-color: rgba(240, 244, 246, 1);
}
.container1 {
  gap: 24px;
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: 24px;
  padding-left: 16px;
  padding-right: 16px;
  flex-direction: column;
  padding-bottom: 108px;
  justify-content: center;
}
.logo {
  width: 228px;
  height: 38px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
}
.sv-gcombr {
  top: 26.3306941986084px;
  left: 171.3148193359375px;
  width: 56px;
  height: 12px;
  position: absolute;
  box-sizing: content-box;
}
.imgs {
  top: 0px;
  left: 0px;
  width: 228.2868194580078px;
  height: 29.203147888183594px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.personaltrainer {
  top: 1.7234597206115723px;
  left: 0px;
  width: 130px;
  height: 27px;
  position: absolute;
  box-sizing: content-box;
}
.personaltrainer1 {
  top: 0px;
  left: 130.62344360351562px;
  width: 98px;
  height: 23px;
  position: absolute;
  box-sizing: content-box;
}
.entrar {
  width: 100%;
  display: flex;
  overflow: hidden;
  max-width: 500px;
  box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.07999999821186066);
  align-items: center;
  flex-shrink: 0;
  border-radius: 16px;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.formulario {
  gap: 24px;
  width: 100%;
  display: flex;
  padding: 48px 24px 40px;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.text {
  color: rgba(45, 62, 80, 1);
  width: 100%;
  height: auto;
  font-size: 24px;
  font-style: SemiBold;
  text-align: center;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.inputs {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.botao-entrar {
  gap: 5px;
  width: 180px;
  cursor: pointer;
  display: flex;
  padding: 12px 24px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-padro);
}
.text02 {
  color: var(--dl-color-default-white);
  height: auto;
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 100%;
  font-stretch: normal;
  text-decoration: none;
}
.text-login {
  color: rgba(148, 158, 167, 1);
  width: 171px;
  cursor: pointer;
  height: auto;
  font-size: 14px;
  font-style: Regular;
  text-align: center;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text-cadastro {
  color: rgb(148, 158, 167);
  cursor: pointer;
  font-size: 14px;
  max-width: 341px;
  font-style: Regular;
  text-align: center;
  font-family: "Poppins";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text06 {
  color: #5171e4;
}
.text08 {
  color: #5171e4;
}
.linhas-ou {
  width: 303px;
  height: 21px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
}
.group561 {
  top: 11px;
  left: 0px;
  width: 303px;
  height: 0px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
  border-bottom: 1px solid #f0f4f6;
}
.caminho44 {
  top: 0px;
  left: 0px;
  width: 135px;
  height: 1px;
  position: absolute;
}
.caminho45 {
  top: -5.6843418860808015e-14px;
  left: 167.87832641601562px;
  width: 135px;
  height: 1px;
  position: absolute;
}
.text10 {
  left: 129px;
  color: rgba(148, 158, 167, 1);
  width: 45px;
  height: auto;
  position: absolute;
  font-size: 14px;
  font-style: Regular;
  text-align: center;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
  background-color: white;
}
.botoes-redes {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.google {
  gap: 10px;
  width: 100%;
  cursor: pointer;
  height: 40px;
  display: flex;
  padding: 10px 16px;
  overflow: hidden;
  max-width: 300px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background-color: var(--dl-color-default-f0f4f6);
}
.googleimg {
  width: 22px;
  height: 22px;
}
.text11 {
  color: rgba(125, 138, 152, 1);
  width: 100%;
  height: auto;
  flex-grow: 1;
  font-size: 14px;
  font-style: Regular;
  text-align: center;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.espacobranco {
  width: 20px;
  height: 20px;
}
.face {
  gap: 10px;
  width: 100%;
  cursor: pointer;
  height: 40px;
  display: flex;
  padding: 10px 16px;
  overflow: hidden;
  max-width: 300px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background-color: var(--dl-color-default-f0f4f6);
}
.faceimg {
  width: 24px;
  height: 24px;
}
.text13 {
  color: rgba(125, 138, 152, 1);
  height: auto;
  flex-grow: 1;
  font-size: 14px;
  font-style: Regular;
  text-align: center;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.espacobranco1 {
  width: 20px;
  height: 20px;
}
.cadastrar {
  gap: 8px;
  width: 100%;
  display: flex;
  padding: 24px;
  align-self: stretch;
  align-items: center;
  border-radius: 0 0 8px 8px;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-default-f0f4f6);
}
.text15 {
  color: rgba(148, 158, 167, 1);
  width: 100%;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: center;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.botao-cadastrar {
  gap: 8px;
  width: 100%;
  display: flex;
  padding: 12px 20px 12px 24px;
  align-items: center;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-colorgreybg);
}
.text17 {
  color: var(--dl-color-default-2d3e50);
  cursor: pointer;
  height: auto;
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 100%;
  font-stretch: normal;
  text-decoration: none;
}
.arrowicon {
  width: 16px;
  cursor: pointer;
  height: 16px;
}
@media (max-width: 767px) {
  .text {
    font-size: 22px;
  }
  .text-login {
    font-size: 13px;
  }
  .text-cadastro {
    font-size: 13px;
  }
  .text10 {
    color: rgb(148, 158, 167);
    font-size: 14px;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
  }
  .text15 {
    font-size: 15px;
  }
}
@media (max-width: 479px) {
  .text {
    font-size: 20px;
  }
  .text-login {
    font-size: 12px;
  }
  .text-cadastro {
    font-size: 12px;
  }
  .text15 {
    font-size: 14px;
  }
}

.password-hints {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  width: 100%;
  padding: 0px 16px;
  font-weight: 600;
  font-size: small;
}
.password-hints-hide {
  display: none;
}
.password-ok {
  color: green;
}
.password-not-ok {
  color: red;
}
