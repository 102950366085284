
.container {
  display:flex;
  flex-direction:column;
}
.opcoes {
    gap: 16px;
    /* width: 100%; */
    height: auto;
    max-width:1320px;
    display: flex;
    position: relative;
    align-items: flex-start;
    grid-row-gap: 24px;
    justify-content: center;
  }
  .plano-card {
    flex: 1;
    /* width: 100%; */
    width: 82vw;
    display: flex;
    max-width: 364px;
    align-self: stretch;
    box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.07999999821186066) ;
    box-sizing: content-box;
    align-items: center;
    flex-shrink: 0;
    border-color: #D8DFE8;
    border-style: solid;
    border-width: 1px;
    border-radius: 16px;
    flex-direction: column;
    justify-content: flex-start;
  }
  .cabecalho {
    gap: 16px;
    width: 100%;
    display: flex;
    padding: 16px 32px;
    align-self: stretch;
    min-height: 110px;
    align-items: center;
    flex-shrink: 0;
    border-radius: 16px 16px 0 0;
    background-color: var(--dl-color-default-f0f4f6);
  }
  .pesoicon {
    width: 28px;
    height: 10px;
  }
  .page-title {
    color: rgb(45, 62, 80);
    height: auto;
    font-size: 24px;
    font-style: SemiBold;
    text-align: left;
    font-family: Poppins;
    font-weight: 600;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .pacote {
    gap: 16px;
    width: 100%;
    height: 100%;
    display: flex;
    padding: 32px 24px 40px;
    overflow: hidden;
    align-self: stretch;
    align-items: center;
    border-radius: 0 0 16px 16px;
    flex-direction: column;
    justify-content: space-between;
    background-color: rgba(255, 255, 255, 1);
  }
  .container {
    gap: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
  }
  .topico {
    gap: 16px;
    width: 100%;
    display: flex;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
    border-color: #F0F4F6;
    border-width: 1px;
    padding-bottom: 16px;
    justify-content: center;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .aprovado {
    width: 16px;
    height: 16px;
  }
  .text001 {
    color: rgba(45, 62, 80, 1);
    height: auto;
    flex-grow: 1;
    font-size: 16px;
    font-style: Regular;
    text-align: left;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .text002 {
    font-weight: 400;
  }
  .topico01 {
    gap: 16px;
    width: 100%;
    display: flex;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
    border-color: #F0F4F6;
    border-width: 1px;
    padding-bottom: 16px;
    justify-content: center;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .recusado {
    width: 16px;
    height: 16px;
  }
  .text004 {
    color: var(--dl-color-default-7d8a98);
    height: auto;
    flex-grow: 1;
    font-size: 16px;
    font-style: Regular;
    text-align: left;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .topico02 {
    gap: 16px;
    width: 100%;
    display: flex;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
    border-color: #F0F4F6;
    border-width: 1px;
    padding-bottom: 16px;
    justify-content: center;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .aprovado01 {
    width: 16px;
    height: 16px;
  }
  .text006 {
    color: rgba(45, 62, 80, 1);
    height: auto;
    flex-grow: 1;
    font-size: 16px;
    font-style: Regular;
    text-align: left;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .topico03 {
    gap: 16px;
    width: 100%;
    display: flex;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
    border-color: #F0F4F6;
    border-width: 1px;
    padding-bottom: 16px;
    justify-content: center;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .recusado1 {
    width: 16px;
    height: 16px;
  }
  .text008 {
    color: var(--dl-color-default-7d8a98);
    height: auto;
    flex-grow: 1;
    font-size: 16px;
    font-style: Regular;
    text-align: left;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .topico04 {
    gap: 16px;
    width: 100%;
    display: flex;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
    border-color: #F0F4F6;
    border-width: 1px;
    padding-bottom: 16px;
    justify-content: center;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .aprovado02 {
    width: 16px;
    height: 16px;
  }
  .text010 {
    color: rgba(45, 62, 80, 1);
    height: auto;
    flex-grow: 1;
    font-size: 16px;
    font-style: Regular;
    text-align: left;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .text011 {
    font-weight: 400;
  }
  .text012 {
    font-weight: 600;
  }
  .topico05 {
    gap: 16px;
    width: 100%;
    display: flex;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
    border-color: #F0F4F6;
    border-width: 1px;
    padding-bottom: 16px;
    justify-content: center;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .aprovado03 {
    width: 16px;
    height: 16px;
  }
  .text014 {
    color: rgba(45, 62, 80, 1);
    height: auto;
    flex-grow: 1;
    font-size: 16px;
    font-style: SemiBold;
    text-align: left;
    font-family: Poppins;
    font-weight: 600;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .text015 {
    font-weight: 600;
  }
  .topico06 {
    gap: 16px;
    width: 100%;
    display: flex;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
    border-color: #F0F4F6;
    border-width: 1px;
    padding-bottom: 16px;
    justify-content: center;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .recusado2 {
    width: 16px;
    height: 16px;
  }
  .text017 {
    color: var(--dl-color-default-7d8a98);
    height: auto;
    flex-grow: 1;
    font-size: 16px;
    font-style: Regular;
    text-align: left;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .topico07 {
    gap: 16px;
    width: 100%;
    display: flex;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
    border-color: #F0F4F6;
    border-width: 1px;
    padding-bottom: 16px;
    justify-content: center;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .aprovado04 {
    width: 16px;
    height: 16px;
  }
  .text019 {
    color: rgba(45, 62, 80, 1);
    height: auto;
    flex-grow: 1;
    font-size: 16px;
    font-style: Regular;
    text-align: left;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .text020 {
    font-weight: 400;
  }
  .text021 {
    font-weight: 600;
  }
  .topico08 {
    gap: 16px;
    width: 100%;
    display: flex;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
    border-color: #F0F4F6;
    border-width: 1px;
    padding-bottom: 16px;
    justify-content: center;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .recusado3 {
    width: 16px;
    height: 16px;
  }
  .text023 {
    color: var(--dl-color-default-7d8a98);
    height: auto;
    flex-grow: 1;
    font-size: 16px;
    font-style: Regular;
    text-align: left;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .topico09 {
    gap: 16px;
    width: 100%;
    display: flex;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
    border-color: #F0F4F6;
    border-width: 1px;
    padding-bottom: 16px;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .recusado4 {
    width: 16px;
    height: 16px;
  }
  .text025 {
    color: var(--dl-color-default-7d8a98);
    height: auto;
    font-size: 16px;
    font-style: Regular;
    text-align: left;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .appleicon {
    width: 20px;
    height: 20px;
  }
  .valor {
    gap: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
  }
  .text027 {
    color: rgba(45, 62, 80, 1);
    width: 100%;
    height: auto;
    font-size: 32px;
    font-style: SemiBold;
    text-align: left;
    font-family: Poppins;
    font-weight: 600;
    line-height: normal;
    font-stretch: normal;
    padding-left: 16px;
    padding-right: 16px;
    text-decoration: none;
  }
  .plano-aquecimento {
    flex: 1;
    width: 100%;
    display: flex;
    max-width: 364px;
    align-self: stretch;
    box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.07999999821186066) ;
    box-sizing: content-box;
    align-items: center;
    flex-shrink: 0;
    border-color: rgba(216, 223, 232, 1);
    border-style: solid;
    border-width: 1px;
    border-radius: 16px;
    flex-direction: column;
  }
  .cabecalho1 {
    gap: 16px;
    width: 100%;
    display: flex;
    padding: 16px 32px;
    align-self: stretch;
    min-height: 110px;
    align-items: center;
    flex-shrink: 0;
    border-radius: 16px 16px 0 0;
    padding-right: 16px 32px;
    background-color: var(--dl-color-default-f0f4f6);
  }
  .pesoicon1 {
    width: 24px;
    height: 20px;
  }
  .text029 {
    color: rgba(45, 62, 80, 1);
    font-size: 24px;
    font-style: SemiBold;
    text-align: left;
    font-family: Poppins;
    font-weight: 600;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .pacote1 {
    gap: 16px;
    width: 100%;
    height: 100%;
    display: flex;
    padding: 32px 24px 40px;
    overflow: hidden;
    align-self: stretch;
    align-items: flex-start;
    border-radius: 0 0 16px 16px;
    flex-direction: column;
    justify-content: space-between;
    background-color: rgba(255, 255, 255, 1);
  }
  .container1 {
    gap: 16px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .topico10 {
    gap: 16px;
    width: 100%;
    display: flex;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
    border-color: #F0F4F6;
    border-width: 1px;
    padding-bottom: 16px;
    justify-content: center;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .aprovado05 {
    width: 16px;
    height: 16px;
  }
  .text031 {
    color: rgba(45, 62, 80, 1);
    height: auto;
    flex-grow: 1;
    font-size: 16px;
    font-style: Regular;
    text-align: left;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .text032 {
    font-weight: 400;
  }
  .topico11 {
    gap: 16px;
    width: 100%;
    display: flex;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
    border-color: #F0F4F6;
    border-width: 1px;
    padding-bottom: 16px;
    justify-content: center;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .aprovado06 {
    width: 16px;
    height: 16px;
  }
  .text034 {
    color: rgba(45, 62, 80, 1);
    height: auto;
    flex-grow: 1;
    font-size: 16px;
    font-style: Regular;
    text-align: left;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .topico12 {
    gap: 16px;
    width: 100%;
    display: flex;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
    border-color: #F0F4F6;
    border-width: 1px;
    padding-bottom: 16px;
    justify-content: center;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .aprovado07 {
    width: 16px;
    height: 16px;
  }
  .text036 {
    color: rgba(45, 62, 80, 1);
    height: auto;
    flex-grow: 1;
    font-size: 16px;
    font-style: Regular;
    text-align: left;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .topico13 {
    gap: 16px;
    width: 100%;
    display: flex;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
    border-color: #F0F4F6;
    border-width: 1px;
    padding-bottom: 16px;
    justify-content: center;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .aprovado08 {
    width: 16px;
    height: 16px;
  }
  .text038 {
    color: rgba(45, 62, 80, 1);
    height: auto;
    flex-grow: 1;
    font-size: 16px;
    font-style: Regular;
    text-align: left;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .topico14 {
    gap: 16px;
    width: 100%;
    display: flex;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
    border-color: #F0F4F6;
    border-width: 1px;
    padding-bottom: 16px;
    justify-content: center;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .aprovado09 {
    width: 16px;
    height: 16px;
  }
  .text040 {
    color: rgba(45, 62, 80, 1);
    height: auto;
    flex-grow: 1;
    font-size: 16px;
    font-style: Regular;
    text-align: left;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .text041 {
    font-weight: 400;
  }
  .text042 {
    font-weight: 600;
  }
  .topico15 {
    gap: 16px;
    width: 100%;
    display: flex;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
    border-color: #F0F4F6;
    border-width: 1px;
    padding-bottom: 16px;
    justify-content: center;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .aprovado10 {
    width: 16px;
    height: 16px;
  }
  .text044 {
    color: rgba(45, 62, 80, 1);
    height: auto;
    flex-grow: 1;
    font-size: 16px;
    font-style: Regular;
    text-align: left;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .text045 {
    font-weight: 400;
  }
  .text046 {
    font-weight: 600;
  }
  .topico16 {
    gap: 16px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
    border-color: #F0F4F6;
    border-width: 1px;
    padding-bottom: 16px;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .vector {
    width: 16px;
    height: 16px;
  }
  .text048 {
    color: rgba(45, 62, 80, 1);
    height: auto;
    font-size: 16px;
    font-style: Regular;
    text-align: left;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .redes {
    gap: 8px;
    display: flex;
    align-items: flex-start;
  }
  .tiktok {
    width: 24px;
    height: 24px;
  }
  .linkedin {
    width: 24px;
    height: 24px;
    border-radius: 35px;
  }
  .rede {
    width: 24px;
    height: 24px;
  }
  .topico17 {
    gap: 16px;
    width: 100%;
    display: flex;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
    border-color: #F0F4F6;
    border-width: 1px;
    padding-bottom: 16px;
    justify-content: center;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .aprovado11 {
    width: 16px;
    height: 16px;
  }
  .text050 {
    color: rgba(45, 62, 80, 1);
    height: auto;
    flex-grow: 1;
    font-size: 16px;
    font-style: Regular;
    text-align: left;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .text051 {
    font-weight: 400;
  }
  .text052 {
    font-weight: 600;
  }
  .text053 {
    font-weight: 400;
  }
  .text054 {
    font-weight: 600;
  }
  .topico18 {
    gap: 16px;
    width: 100%;
    display: flex;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
    border-color: #F0F4F6;
    border-width: 1px;
    padding-bottom: 16px;
    justify-content: center;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .recusado5 {
    width: 16px;
    height: 16px;
  }
  .text056 {
    color: var(--dl-color-default-7d8a98);
    height: auto;
    flex-grow: 1;
    font-size: 16px;
    font-style: Regular;
    text-align: left;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .topico19 {
    gap: 16px;
    width: 100%;
    display: flex;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
    border-color: #F0F4F6;
    border-width: 1px;
    padding-bottom: 16px;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .recusado6 {
    width: 16px;
    height: 16px;
  }
  .text058 {
    color: var(--dl-color-default-7d8a98);
    height: auto;
    font-size: 16px;
    font-style: Regular;
    text-align: left;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .appleicon1 {
    width: 20px;
    height: 20px;
  }
  .valor1 {
    gap: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .preco {
    gap: 8px;
    display: flex;
    padding: 0 16px;
    position: relative;
    align-self: stretch;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
  }
  .valor-anterior {
    width: 100%;
    display: flex;
    position: relative;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
  }
  .text060 {
    color: var(--dl-color-default-7d8a98);
    height: auto;
    font-style: Regular;
    text-align: left;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .text061 {
    text-decoration: line-through;
    color: #FCA600;
  }
  .risco {
    top: 0px;
    left: 4px;
    width: 60px;
    bottom: 0px;
    height: 1px;
    margin: auto;
    display: flex;
    position: absolute;
    align-items: flex-start;
    border-color: var(--dl-color-default-7d8a98);
    border-style: solid;
    border-width: 1px;
    flex-direction: column;
    justify-content: center;
  }
  .text063 {
    color: rgb(45, 62, 80);
    height: auto;
    font-size: 32px;
    font-style: SemiBold;
    text-align: left;
    font-family: Poppins;
    font-weight: 600;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .text064 {
    color: #2d3e50;
    font-weight: 600;
  }
  .text065 {
    color: var(--dl-color-default-7d8a98);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
  .plano-elite {
    flex: 1;
    width: 100%;
    display: flex;
    max-width: 364px;
    align-self: stretch;
    box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.07999999821186066) ;
    box-sizing: content-box;
    align-items: center;
    flex-shrink: 0;
    border-color: rgba(216, 223, 232, 1);
    border-style: solid;
    border-width: 1px;
    border-radius: 16px;
    flex-direction: column;
  }
  .cabecalho2 {
    gap: 16px;
    width: 100%;
    display: flex;
    padding: 16px 32px;
    align-self: stretch;
    min-height: 110px;
    align-items: center;
    flex-shrink: 0;
    border-radius: 16px 16px 0 0;
    background-color: var(--dl-color-default-f0f4f6);
  }
  .pesoicon2 {
    width: 30px;
    height: 20px;
  }
  .text066 {
    color: rgba(45, 62, 80, 1);
    height: auto;
    font-size: 24px;
    font-style: SemiBold;
    text-align: left;
    font-family: Poppins;
    font-weight: 600;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .pacote2 {
    gap: 16px;
    width: 100%;
    height: 100%;
    display: flex;
    padding: 32px 24px 40px;
    overflow: hidden;
    align-self: stretch;
    align-items: flex-start;
    border-radius: 0 0 16px 16px;
    flex-direction: column;
    justify-content: space-between;
    background-color: rgba(255, 255, 255, 1);
  }
  .container2 {
    gap: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .topico20 {
    gap: 16px;
    width: 100%;
    display: flex;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
    border-color: #F0F4F6;
    border-width: 1px;
    padding-bottom: 16px;
    justify-content: center;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .aprovado12 {
    width: 16px;
    height: 16px;
  }
  .text068 {
    color: rgba(45, 62, 80, 1);
    height: auto;
    flex-grow: 1;
    font-size: 16px;
    font-style: Regular;
    text-align: left;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .text069 {
    font-weight: 400;
  }
  .topico21 {
    gap: 16px;
    width: 100%;
    display: flex;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
    border-color: #F0F4F6;
    border-width: 1px;
    padding-bottom: 16px;
    justify-content: center;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .aprovado13 {
    width: 16px;
    height: 16px;
  }
  .text071 {
    color: rgba(45, 62, 80, 1);
    height: auto;
    flex-grow: 1;
    font-size: 16px;
    font-style: Regular;
    text-align: left;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .topico22 {
    gap: 16px;
    width: 100%;
    display: flex;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
    border-color: #F0F4F6;
    border-width: 1px;
    padding-bottom: 16px;
    justify-content: center;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .aprovado14 {
    width: 16px;
    height: 16px;
  }
  .text073 {
    color: rgba(45, 62, 80, 1);
    height: auto;
    flex-grow: 1;
    font-size: 16px;
    font-style: Regular;
    text-align: left;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .topico23 {
    gap: 16px;
    width: 100%;
    display: flex;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
    border-color: #F0F4F6;
    border-width: 1px;
    padding-bottom: 16px;
    justify-content: center;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .aprovado15 {
    width: 16px;
    height: 16px;
  }
  .text075 {
    color: rgba(45, 62, 80, 1);
    height: auto;
    flex-grow: 1;
    font-size: 16px;
    font-style: Regular;
    text-align: left;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .topico24 {
    gap: 16px;
    width: 100%;
    display: flex;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
    border-color: #F0F4F6;
    border-width: 1px;
    padding-bottom: 16px;
    justify-content: center;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .aprovado16 {
    width: 16px;
    height: 16px;
  }
  .text077 {
    color: rgba(45, 62, 80, 1);
    height: auto;
    flex-grow: 1;
    font-size: 16px;
    font-style: Regular;
    text-align: left;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .text078 {
    font-weight: 400;
  }
  .text079 {
    font-weight: 600;
  }
  .topico25 {
    gap: 16px;
    width: 100%;
    display: flex;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
    border-color: #F0F4F6;
    border-width: 1px;
    padding-bottom: 16px;
    justify-content: center;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .aprovado17 {
    width: 16px;
    height: 16px;
  }
  .text081 {
    color: rgba(45, 62, 80, 1);
    height: auto;
    flex-grow: 1;
    font-size: 16px;
    font-style: Regular;
    text-align: left;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .text082 {
    font-weight: 400;
  }
  .text083 {
    font-weight: 600;
  }
  .topico26 {
    gap: 16px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
    border-color: #F0F4F6;
    border-width: 1px;
    padding-bottom: 16px;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .vector1 {
    width: 16px;
    height: 16px;
  }
  .text085 {
    color: rgba(45, 62, 80, 1);
    height: auto;
    font-size: 16px;
    font-style: Regular;
    text-align: left;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .redes1 {
    gap: 8px;
    display: flex;
    align-items: flex-start;
  }
  .insta {
    width: 24px;
    height: 24px;
  }
  .face {
    width: 24px;
    height: 24px;
  }
  .tiktok1 {
    width: 24px;
    height: 24px;
  }
  .linkedin1 {
    width: 24px;
    height: 24px;
    border-radius: 35px;
  }
  .rede1 {
    width: 24px;
    height: 24px;
  }
  .topico27 {
    gap: 16px;
    width: 100%;
    display: flex;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
    border-color: #F0F4F6;
    border-width: 1px;
    padding-bottom: 16px;
    justify-content: center;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .aprovado18 {
    width: 16px;
    height: 16px;
  }
  .text087 {
    color: rgba(45, 62, 80, 1);
    height: auto;
    flex-grow: 1;
    font-size: 16px;
    font-style: Regular;
    text-align: left;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .text088 {
    font-weight: 400;
  }
  .text089 {
    font-weight: 600;
  }
  .text090 {
    font-weight: 400;
  }
  .text091 {
    font-weight: 600;
  }
  .topico28 {
    gap: 16px;
    width: 100%;
    display: flex;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
    border-color: #F0F4F6;
    border-width: 1px;
    padding-bottom: 16px;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .aprovado19 {
    width: 16px;
    height: 16px;
  }
  .frame1171277085 {
    gap: 8px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .text093 {
    color: rgba(45, 62, 80, 1);
    height: auto;
    font-size: 16px;
    font-style: Regular;
    text-align: left;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .frame1171277054 {
    gap: 4px;
    height: 24px;
    display: flex;
    padding: 8px 10px;
    overflow: hidden;
    align-items: center;
    flex-shrink: 0;
    border-radius: 6px;
    justify-content: center;
    background-color: var(--dl-color-default-padro);
  }
  .text095 {
    color: var(--dl-color-default-ffffff);
    height: auto;
    font-size: 14px;
    font-style: SemiBold;
    text-align: center;
    font-family: Poppins;
    font-weight: 600;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .topico29 {
    gap: 16px;
    width: 100%;
    display: flex;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
    border-color: #F0F4F6;
    border-width: 1px;
    padding-bottom: 16px;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .aprovado20 {
    width: 16px;
    height: 16px;
  }
  .text097 {
    color: rgba(45, 62, 80, 1);
    height: auto;
    font-size: 16px;
    font-style: Regular;
    text-align: left;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .appleicon2 {
    width: 20px;
    height: 20px;
  }
  .valor2 {
    gap: 16px;
    width: 100%;
    display: flex;
    align-self: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .preco1 {
    gap: 8px;
    display: flex;
    padding: 0 16px;
    align-self: stretch;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
  }
  .valor-anterior1 {
    width: 100%;
    display: flex;
    position: relative;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
  }
  .text099 {
    color: var(--dl-color-default-7d8a98);
    height: auto;
    font-style: Regular;
    text-align: left;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .risco1 {
    top: 0px;
    left: 4px;
    width: 60px;
    bottom: 0px;
    height: 1px;
    margin: auto;
    display: flex;
    position: absolute;
    align-items: flex-start;
    border-color: var(--dl-color-default-7d8a98);
    border-style: solid;
    border-width: 1px;
    flex-direction: column;
    justify-content: center;
  }
  .text102 {
    color: rgb(45, 62, 80);
    height: auto;
    font-size: 32px;
    font-style: SemiBold;
    text-align: left;
    font-family: Poppins;
    font-weight: 600;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .text103 {
    color: #2d3e50;
    font-weight: 600;
  }
  .text104 {
    color: var(--dl-color-default-7d8a98);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
  @media(max-width: 991px) {
    .opcoes {
      flex-wrap: nowrap;
      align-self: center;
      justify-content: center;
    }
    .plano-free {
      flex: 0 0 auto;
      max-width: 94%;
    }
    .plano-aquecimento {
      flex: 0 0 auto;
      max-width: 94%;
    }
    .plano-elite {
      flex: 0 0 auto;
      max-width: 94%;
    }
  }
  @media(max-width: 767px) {
    .page-title {
      font-size: 22px;
    }
    .aprovado {
      width: 15px;
      height: 15px;
    }
    .text001 {
      font-size: 15px;
    }
    .recusado {
      width: 15px;
      height: 15px;
    }
    .text004 {
      font-size: 15px;
    }
    .aprovado01 {
      width: 15px;
      height: 15px;
    }
    .text006 {
      font-size: 15px;
    }
    .recusado1 {
      width: 15px;
      height: 15px;
    }
    .text008 {
      font-size: 15px;
    }
    .aprovado02 {
      width: 15px;
      height: 15px;
    }
    .text010 {
      font-size: 15px;
    }
    .aprovado03 {
      width: 15px;
      height: 15px;
    }
    .text014 {
      font-size: 15px;
    }
    .recusado2 {
      width: 15px;
      height: 15px;
    }
    .text017 {
      font-size: 15px;
    }
    .aprovado04 {
      width: 15px;
      height: 15px;
    }
    .text019 {
      font-size: 15px;
    }
    .recusado3 {
      width: 15px;
      height: 15px;
    }
    .text023 {
      font-size: 15px;
    }
    .recusado4 {
      width: 15px;
      height: 15px;
    }
    .text025 {
      font-size: 15px;
    }
    .text029 {
      font-size: 22px;
    }
    .aprovado05 {
      width: 15px;
      height: 15px;
    }
    .text031 {
      font-size: 15px;
    }
    .aprovado06 {
      width: 15px;
      height: 15px;
    }
    .text034 {
      font-size: 15px;
    }
    .aprovado07 {
      width: 15px;
      height: 15px;
    }
    .text036 {
      font-size: 15px;
    }
    .aprovado08 {
      width: 15px;
      height: 15px;
    }
    .text038 {
      font-size: 15px;
    }
    .aprovado09 {
      width: 15px;
      height: 15px;
    }
    .text040 {
      font-size: 15px;
    }
    .aprovado10 {
      width: 15px;
      height: 15px;
    }
    .text044 {
      font-size: 15px;
    }
    .text048 {
      font-size: 15px;
    }
    .tiktok {
      width: 22px;
      height: 22px;
    }
    .linkedin {
      width: 22px;
      height: 22px;
    }
    .rede {
      width: 22px;
      height: 22px;
    }
    .aprovado11 {
      width: 15px;
      height: 15px;
    }
    .text050 {
      font-size: 15px;
    }
    .recusado5 {
      width: 15px;
      height: 15px;
    }
    .text056 {
      font-size: 15px;
    }
    .recusado6 {
      width: 15px;
      height: 15px;
    }
    .text058 {
      font-size: 15px;
    }
    .text060 {
      font-size: 15px;
    }
    .text066 {
      font-size: 22px;
    }
    .aprovado12 {
      width: 15px;
      height: 15px;
    }
    .text068 {
      font-size: 15px;
    }
    .aprovado13 {
      width: 15px;
      height: 15px;
    }
    .text071 {
      font-size: 15px;
    }
    .aprovado14 {
      width: 15px;
      height: 15px;
    }
    .text073 {
      font-size: 15px;
    }
    .aprovado15 {
      width: 15px;
      height: 15px;
    }
    .text075 {
      font-size: 15px;
    }
    .aprovado16 {
      width: 15px;
      height: 15px;
    }
    .text077 {
      font-size: 15px;
    }
    .aprovado17 {
      width: 15px;
      height: 15px;
    }
    .text081 {
      font-size: 15px;
    }
    .vector1 {
      width: 15px;
      height: 15px;
    }
    .text085 {
      font-size: 15px;
    }
    .insta {
      width: 22px;
      height: 22px;
    }
    .face {
      width: 22px;
      height: 22px;
    }
    .tiktok1 {
      width: 22px;
      height: 22px;
    }
    .linkedin1 {
      width: 22px;
      height: 22px;
    }
    .rede1 {
      width: 22px;
      height: 22px;
    }
    .aprovado18 {
      width: 15px;
      height: 15px;
    }
    .text087 {
      font-size: 15px;
    }
    .aprovado19 {
      width: 15px;
      height: 15px;
    }
    .text093 {
      font-size: 15px;
    }
    .aprovado20 {
      width: 15px;
      height: 15px;
    }
    .text097 {
      font-size: 15px;
    }
    .text099 {
      font-size: 15px;
    }
  }
  @media(max-width: 479px) {
    .pesoicon {
      display: none;
    }
    .page-title {
      font-size: 20px;
    }
    .aprovado {
      width: 14px;
      height: 14px;
    }
    .text001 {
      font-size: 14px;
    }
    .recusado {
      width: 14px;
      height: 14px;
    }
    .text004 {
      font-size: 14px;
    }
    .aprovado01 {
      width: 14px;
      height: 14px;
    }
    .text006 {
      font-size: 14px;
    }
    .recusado1 {
      width: 14px;
      height: 14px;
    }
    .text008 {
      font-size: 14px;
    }
    .aprovado02 {
      width: 14px;
      height: 14px;
    }
    .text010 {
      font-size: 14px;
    }
    .aprovado03 {
      width: 14px;
      height: 14px;
    }
    .text014 {
      font-size: 14px;
    }
    .recusado2 {
      width: 14px;
      height: 14px;
    }
    .text017 {
      font-size: 14px;
    }
    .aprovado04 {
      width: 14px;
      height: 14px;
    }
    .text019 {
      font-size: 14px;
    }
    .recusado3 {
      width: 14px;
      height: 14px;
    }
    .text023 {
      font-size: 14px;
    }
    .recusado4 {
      width: 14px;
      height: 14px;
    }
    .text025 {
      font-size: 14px;
    }
    .appleicon {
      display: none;
    }
    .pesoicon1 {
      display: none;
    }
    .text029 {
      font-size: 20px;
    }
    .aprovado05 {
      width: 14px;
      height: 14px;
    }
    .text031 {
      font-size: 14px;
    }
    .aprovado06 {
      width: 14px;
      height: 14px;
    }
    .text034 {
      font-size: 14px;
    }
    .aprovado07 {
      width: 14px;
      height: 14px;
    }
    .text036 {
      font-size: 14px;
    }
    .aprovado08 {
      width: 14px;
      height: 14px;
    }
    .text038 {
      font-size: 14px;
    }
    .aprovado09 {
      width: 14px;
      height: 14px;
    }
    .text040 {
      font-size: 14px;
    }
    .aprovado10 {
      width: 14px;
      height: 14px;
    }
    .text044 {
      font-size: 14px;
    }
    .text048 {
      font-size: 14px;
    }
    .tiktok {
      width: 20px;
      height: 20px;
    }
    .linkedin {
      width: 20px;
      height: 20px;
    }
    .rede {
      width: 20px;
      height: 20px;
    }
    .aprovado11 {
      width: 14px;
      height: 14px;
    }
    .text050 {
      font-size: 14px;
    }
    .recusado5 {
      width: 14px;
      height: 14px;
    }
    .text056 {
      font-size: 14px;
    }
    .recusado6 {
      width: 14px;
      height: 14px;
    }
    .text058 {
      font-size: 14px;
    }
    .appleicon1 {
      display: none;
    }
    .text060 {
      font-size: 14px;
    }
    .text063 {
      color: rgb(45, 62, 80);
      font-size: 32px;
      font-family: Poppins;
      font-weight: 600;
      line-height: normal;
      text-decoration: none;
    }
    .text064 {
      color: #2d3e50;
      font-weight: 600;
    }
    .text065 {
      font-size: 14px;
    }
    .pesoicon2 {
      display: none;
    }
    .text066 {
      font-size: 20px;
    }
    .aprovado12 {
      width: 14px;
      height: 14px;
    }
    .text068 {
      font-size: 14px;
    }
    .aprovado13 {
      width: 14px;
      height: 14px;
    }
    .text071 {
      font-size: 14px;
    }
    .aprovado14 {
      width: 14px;
      height: 14px;
    }
    .text073 {
      font-size: 14px;
    }
    .aprovado15 {
      width: 14px;
      height: 14px;
    }
    .text075 {
      font-size: 14px;
    }
    .aprovado16 {
      width: 14px;
      height: 14px;
    }
    .text077 {
      font-size: 14px;
    }
    .aprovado17 {
      width: 14px;
      height: 14px;
    }
    .text081 {
      font-size: 14px;
    }
    .vector1 {
      width: 14px;
      height: 14px;
    }
    .text085 {
      font-size: 14px;
    }
    .insta {
      width: 20px;
      height: 20px;
    }
    .face {
      width: 20px;
      height: 20px;
    }
    .tiktok1 {
      width: 20px;
      height: 20px;
    }
    .linkedin1 {
      width: 20px;
      height: 20px;
    }
    .rede1 {
      width: 20px;
      height: 20px;
    }
    .aprovado18 {
      width: 14px;
      height: 14px;
    }
    .text087 {
      font-size: 14px;
    }
    .aprovado19 {
      width: 14px;
      height: 14px;
    }
    .text093 {
      font-size: 14px;
    }
    .text095 {
      font-size: 13px;
    }
    .aprovado20 {
      width: 14px;
      height: 14px;
    }
    .text097 {
      font-size: 14px;
    }
    .appleicon2 {
      display: none;
    }
    .text099 {
      font-size: 14px;
    }
  }
  
  .page-title {
    padding-top:24px;
  }

  .page-title {
    color: var(--dl-color-default-2d3e50);
    width: 100%;
    flex-grow: 1;
    font-size: 24px;
    font-style: SemiBold;
    text-align: left;
    font-family: Poppins;
    font-weight: 600;
    line-height: normal;
    font-stretch: normal;
    padding-left: 16px;
    padding-right: 16px;
    text-decoration: none;
    text-align:center;  
  }

  @media screen and (max-width:991px) {
    .page-title {
      text-align: center;
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  @media screen and (max-width:767px) {
    .page-title {
      font-size: 22px;
    }
  }

  @media screen and (max-width:450px) {
    .page-title {
      font-size: 20px;
    }
  }

  .btn-cta {
    background:#FCA600;
    border-radius:8px;
    height:52px;
    padding: 0 16px;
    display:flex;
    justify-content: center;
    align-items:center;
    margin-top:16px;
    font-weight:500;
    font-family: 'Poppins',sans-serif;
    font-size:20px;
    color:white;
    margin-bottom:24px;
  }

  .plans-container {
    max-width: 100%;
    overflow-x: auto;
    overflow-y: visible;
    padding: 0px 16px 16px 16px;
    /* scroll-snap-type: x mandatory; */
    display: flex;
    padding-left: 16px;
  }
  /* .plans-container > div {
    scroll-snap-align: start;
    scroll-snap-stop: always;
  } */