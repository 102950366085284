.container {
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
  background-color: rgba(240, 244, 246, 1);
}
.avaliacao {
  gap: 8px;
  display: flex;
  padding: 16px;
  align-self: stretch;
  align-items: center;
  padding-top: 16px;
  cursor:pointer;
  border-radius: 8px;
  flex-direction: column;
  background-color: var(--dl-color-default-f0f4f6);
}
.nota {
  gap: 8px;
  display: flex;
  align-items: center;
}
.vector {
  width: 22px;
  height: 21px;
}
.text004 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 18px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.textquantidade {
  color: var(--dl-color-default-7d8a98);
  height: auto;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.container1 {
  gap: 24px;
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-self: center;
  align-items: center;
  padding-top: 24px;
  padding-left: 16px;
  padding-right: 16px;
  flex-direction: column;
  padding-bottom: 108px;
}
.title-ordenar-desk {
  gap: 10px;
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  max-width: 1320px;
  align-self: center;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
}
.text {
  color: rgba(45, 62, 80, 1);
  height: auto;
  flex-grow: 1;
  font-size: 24px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  width:100%;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.conteudo {
  gap: 16px;
  width: 100%;
  display: flex;
  max-width: 1320px;
  align-self: center;
  align-items: flex-start;
  flex-shrink: 0;
}
.lista {
  gap: 10px;
  flex: 1;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.trainers {
  gap: 16px;
  display: flex;
  flex-wrap: wrap;
  align-self: stretch;
}
.trainer {
  gap: 24px;
  width: 100%;
  display: flex;
  padding: 24px;
  overflow: hidden;
  position: relative;
  max-width: 32.2%;
  align-items: center;
  flex-shrink: 0;
  border-color:#DFE4E7;
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
  transition:.35s ease-in-out;
}
.trainer:hover {
  box-shadow:0 0px 16px rgba(0,0,0,0.16);
}
.destaque {
  top: 12px;
  left: 12px;
  display: flex;
  padding: 4px 10px;
  z-index: 1;
  overflow: hidden;
  position: absolute;
  align-items: center;
  border-radius: 6px;
  justify-content: center;
  background-color: var(--dl-color-default-padro);
}
.text02 {
  color: var(--dl-color-default-ffffff);
  font-size: 14px;
  font-style: SemiBold;
  text-align: center;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.verificado {
  gap: 4px;
  top: 12px;
  right: 012px;
  height: 25px;
  display: flex;
  z-index: 4;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  justify-content: center;
}
.text04 {
  color: rgba(39, 204, 99, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.registros {
  width: 17px;
  height: 17px;
}
.foto {
  width: 160px;
  height: 160px;
  border-radius: var(--dl-radius-radius-round);
  object-fit: cover;
}
.nome {
  color: var(--dl-color-default-2d3e50);
  width: 100%;
  height: auto;
  z-index: 1;
  font-size: 20px;
  align-self: center;
  font-style: SemiBold;
  text-align: center;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.descricao {
  color: var(--dl-color-default-7d8a98);
  height: auto;
  z-index: 2;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: center;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.extras {
  gap: 8px;
  display: flex;
  z-index: 3;
  align-self: center;
  align-items: center;
  flex-direction: column;
}
.infos {
  gap: 8px;
  display: flex;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: center;
}
.avaliacao2 {
  gap: 4px;
  flex: 1;
  width: 100%;
  height: 32px;.avaliacao2 {
    gap: 4px;
    flex: 1;
    width: 100%;
    height: 32px;
    display: flex;
    padding: 8px 10px;
    flex-grow: 1;
    align-items: center;
    flex-shrink: 0;
    border-radius: 8px;
    justify-content: center;
    background-color: var(--dl-color-default-f0f4f6);
  }
  display: flex;
  padding: 8px 10px;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-f0f4f6);
}
.estrelaicon {
  width: 14px;
  height: 13px;
}
.textnota {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 16px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.textqnt {
  color: var(--dl-color-default-7d8a98);
  height: auto;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.apoio-nutricional {
  gap: 8px;
  flex: 3;
  width: 100%;
  height: 32px;
  display: flex;
  padding: 8px 10px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-f0f4f6);
}
.appleicon {
  width: 18px;
  height: 18px;
}
.text08 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.valor {
  height: 32px;
  display: flex;
  padding: 0 16px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(255, 193, 75, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 24px;
  justify-content: center;
  background-color: var(--dl-color-default-d8ffe6);
}
.text10 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: center;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text12 {
  font-style: normal;
}
.text13 {
  font-style: normal;
  font-weight: 600;
}
.paginacao {
  gap: 4px;
  width: 100%;
  display: flex;
  padding: 32px 0 0;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
}
.prev {
  height: 30px;
  display: none;
  padding: 10px;
  overflow: hidden;
  min-width: 30px;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(216, 223, 232, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.text14 {
  color: rgb(125, 138, 152);
  font-size: 16px;
  font-style: Regular;
  text-align: center;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.page-atual {
  height: 30px;
  display: flex;
  padding: 10px;
  overflow: hidden;
  min-width: 30px;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(251, 166, 0, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.text15 {
  color: var(--dl-color-default-padro);
  font-size: 16px;
  font-style: Regular;
  text-align: center;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.page {
  height: 30px;
  display: flex;
  padding: 10px;
  overflow: hidden;
  min-width: 30px;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(216, 223, 232, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.text16 {
  color: rgba(125, 138, 152, 1);
  font-size: 16px;
  font-style: Regular;
  text-align: center;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.page1 {
  height: 30px;
  display: flex;
  padding: 10px;
  overflow: hidden;
  min-width: 30px;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(216, 223, 232, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.text17 {
  color: rgb(125, 138, 152);
  font-size: 16px;
  font-style: Regular;
  text-align: center;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.page2 {
  height: 30px;
  display: flex;
  padding: 10px;
  overflow: hidden;
  min-width: 30px;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(216, 223, 232, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.text18 {
  color: rgb(125, 138, 152);
  font-size: 16px;
  font-style: Regular;
  text-align: center;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.next {
  height: 30px;
  display: flex;
  padding: 10px;
  overflow: hidden;
  min-width: 30px;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(216, 223, 232, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.text19 {
  color: rgb(125, 138, 152);
  font-size: 16px;
  font-style: Regular;
  text-align: center;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
@media(max-width: 1450px) {
  .trainer {
    padding-left: 24px;
  }
}
@media(max-width: 1250px) {
  .trainers {
    gap: 0;
    row-gap: 15px;
    justify-content: space-between;
  }
  .trainer {
    max-width: 45%;
    min-width: 49%;
  }
}
@media(max-width: 991px) {
  .container1 {
    padding-bottom: 80px;
  }
  .title-ordenar-desk {
    display: none;
  }
  .conteudo {
    flex-direction: column;
  }
  .lista {
    align-items: center;
  }
}
@media(max-width: 767px) {
  .text02 {
    font-size: 13px;
  }
  .foto {
    width: 150px;
    height: 150px;
  }
  .nome {
    font-size: 19px;
  }
  .descricao {
    font-size: 15px;
  }
  .textnota {
    font-size: 15px;
  }
  .textqnt {
    font-size: 13px;
  }
  .text10 {
    color: var(--dl-color-default-2d3e50);
    font-size: 15px;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
  }
  .text11 {
    font-weight: 400;
  }
  .text12 {
    font-style: normal;
    font-weight: 700;
  }
  .text13 {
    font-style: normal;
    font-weight: 700;
  }
}
@media(max-width: 479px) {
  .trainer {
    max-width: auto;
    min-width: 100%;
  }
  .text02 {
    font-size: 12px;
  }
  .foto {
    width: 140px;
    height: 140px;
  }
  .nome {
    font-size: 18px;
  }
  .descricao {
    font-size: 14px;
  }
  .textnota {
    font-size: 14px;
  }
  .textqnt {
    font-size: 12px;
  }
  .text10 {
    font-size: 14px;
  }
}
.botao-entrar {
  gap: 8px;
  cursor: pointer;
  display: flex;
  padding: 12px 20px 12px 24px;
  align-items: center;
  border-radius: 8px;
  justify-content: center;
  background-color: rgba(125, 138, 152, 1);
  margin:0 auto;
  margin-top:2rem;
}
.text07 {
  color: var(--dl-color-default-white);
  height: auto;
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 100%;
  font-stretch: normal;
  text-decoration: none;
}
.perfilicon {
  width: 16px;
  height: 16px;
}