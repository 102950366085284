.container {
  display: flex;
  position: relative;
}
.unselected {
  width: 20px;
  cursor: pointer;
  height: 20px;
  /* display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(125, 138, 152, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1); */
}
.selected {
  width: 20px;
  cursor: pointer;
  height: 20px;
}
