.container {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
  align-items: center;
  padding-top: 8px;
  padding-left: 16px;
  padding-right: 16px;
  flex-direction: row;
  padding-bottom: 8px;
  justify-content: center;
  z-index:999999;
  background-color: white;
  position:sticky;
  top:0;
}
@media screen and (max-width:500px) {
  .container {
    height:65px;
  }
}
.container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  max-width: 1320px;
  align-self: center;
  align-items: center;
  margin: 0 auto;
  flex-direction: row;
  justify-content: space-between;
}
.logo {
  display: flex;
  align-items: center;
  justify-content: center;
}
.text-logo {
  color: rgb(45, 62, 80);
  height: auto;
  font-size: 28px;
  font-style: italic;
  text-align: left;
  font-family: Inter;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text {
  color: var(--dl-color-default-2d3e50);
}
.text01 {
  color: var(--dl-color-default-padro);
  font-weight: 700;
}
.text02 {
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
}
.container2 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  gap:5px;
}
.menu-desktop {
  gap: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.text03 {
  color: rgba(125, 138, 152, 1);
  cursor: pointer;
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: center;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text05 {
  color: rgba(125, 138, 152, 1);
  cursor: pointer;
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: center;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.botao-entrar {
  gap: 8px;
  cursor: pointer;
  display: flex;
  padding: 12px 20px 12px 24px;
  align-items: center;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-padro);
}
.text07 {
  color: var(--dl-color-default-white);
  height: auto;
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 100%;
  font-stretch: normal;
  text-decoration: none;
}
.perfilicon {
  width: 16px;
  height: 16px;
}
.botao-sair-conta {
  gap: 8px;
  width: fit-content;
  display: flex;
  padding: 12px 20px 12px 24px;
  align-items: center;
  border-width: 0px;
  border-radius: 8px;
  justify-content: center;
  cursor:pointer;
  background-color: var(--dl-color-default-colorgreybg);
}
.text09 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 100%;
  font-stretch: normal;
  text-decoration: none;
}
.vector {
  width: 16px;
  height: 16px;
}
.menu-mobile {
  width: 34px;
  height: auto;
  gap: 10px;
  display: none;
  align-items: center;
  justify-content: flex-end;
}
.icon {
  fill: #FCA600;
  width: 100%;
  height: 100%;
}
.menu-aberto-logado {
  gap: 16px;
  height:100vh;
  top: 100%;
  z-index:99999999;
  right: 0px;
  width: 100%;
  display: flex;
  padding: 14px 16px 16px;
  position: absolute;
  box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.07999999821186066) ;
  align-items: flex-end;
  flex-shrink: 0;
  border-radius: 0 0 0 8px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}
.closeicon {
  width: 24px;
  cursor: pointer;
  height: auto;
}
.estatisticas {
  gap: 10px;
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: 10px;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
}
.estatisticasicon {
  width: 19px;
  height: 19px;
}
.text11 {
  color: rgba(45, 62, 80, 1);
  width: 100%;
  flex-grow: 1;
  font-size: 18px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.meu-anuncio {
  gap: 10px;
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: 10px;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
}
.anuncioicon {
  width: 19px;
  height: 19px;
}
.text13 {
  color: rgba(45, 62, 80, 1);
  width: 100%;
  flex-grow: 1;
  font-size: 18px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.avaliacoes {
  gap: 10px;
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: 10px;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
}
.staricon {
  width: 19px;
  height: 19px;
}
.text15 {
  color: rgb(45, 62, 80);
  width: 100%;
  flex-grow: 1;
  font-size: 18px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.minha-conta {
  gap: 10px;
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: 10px;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
}
.perfilicon1 {
  width: 19px;
  height: 19px;
}
.text16 {
  color: rgba(45, 62, 80, 1);
  width: 100%;
  flex-grow: 1;
  font-size: 18px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.planos {
  gap: 10px;
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: 10px;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
}
.crownicon {
  width: 19px;
  height: 19px;
}
.text18 {
  color: rgba(45, 62, 80, 1);
  width: 100%;
  flex-grow: 1;
  font-size: 18px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.suporte {
  gap: 10px;
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: 10px;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
}
.suporteicon {
  width: 19px;
  height: 19px;
}
.text20 {
  color: rgba(45, 62, 80, 1);
  width: 100%;
  flex-grow: 1;
  font-size: 18px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.sair {
  gap: 10px;
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: 10px;
  align-self: stretch;
  margin-top: 24px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background-color: var(--dl-color-default-f0f4f6);
}
.sairicon {
  width: 19px;
  height: 19px;
}
.text22 {
  color: rgba(45, 62, 80, 1);
  width: 100%;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.menu-aberto-mobile {
  gap: 16px;
  top: 100%;
  right: 0px;
  width: auto;
  height: auto;
  display: flex;
  position: absolute;
  box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.07999999821186066) ;
  align-items: flex-end;
  flex-shrink: 0;
  padding: 14px 24px 32px 24px;
  border-radius: 0 0 0 8px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}
.fechar {
  width: 24px;
  cursor: pointer;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  flex-shrink: 1;
  justify-content: center;
  background-color: rgba(196, 196, 196, 0);
}
.fecharicon {
  width: 22px;
  height: 22px;
  align-self: center;
}
.entrar {
  gap: 8px;
  cursor: pointer;
  display: flex;
  padding: 12px 20px 12px 24px;
  align-items: center;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-padro);
}
.text24 {
  color: var(--dl-color-default-white);
  height: auto;
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 100%;
  font-stretch: normal;
  text-decoration: none;
}
.perfilicon2 {
  width: 16px;
  height: 16px;
}
.text-criar-anuncio {
  color: rgba(45, 62, 80, 1);
  cursor: pointer;
  height: auto;
  padding: 10px;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: right;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text27 {
  color: rgba(45, 62, 80, 1);
  cursor: pointer;
  height: auto;
  padding: 10px;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: right;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.menu-aberto-admin {
  gap: 16px;
  top: 100%;
  left: 0px;
  right: 0px;
  width: 100%;
  margin: auto;
  padding: 14px 16px 16px;
  position: absolute;
  max-width: 288px;
  min-width: 100vw;
  box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.07999999821186066) ;
  align-items: flex-end;
  flex-shrink: 0;
  border-radius: 0 0 0 8px;
  display:flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}
.closeicon1 {
  width: 35px;
  cursor: pointer;
  height: 35px;
}
.novos-anuncios {
  gap: 10px;
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: 10px;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
}
.plusicon {
  fill: #2D3E50;
  width: 24px;
  height: 24px;
}
.text29 {
  color: rgb(45, 62, 80);
  width: 100%;
  flex-grow: 1;
  font-size: 18px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.anuncios {
  gap: 10px;
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: 10px;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
}
.anuncioicon1 {
  width: 19px;
  height: 19px;
}
.text30 {
  color: rgb(45, 62, 80);
  width: 100%;
  flex-grow: 1;
  font-size: 18px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.usuarios {
  gap: 10px;
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: 10px;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
}
.perfilicon3 {
  width: 19px;
  height: 19px;
}
.text31 {
  color: rgb(45, 62, 80);
  width: 100%;
  flex-grow: 1;
  font-size: 18px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.verificacao {
  gap: 10px;
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: 10px;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
}
.checkicon {
  width: 19px;
  height: 19px;
}
.text32 {
  color: rgb(45, 62, 80);
  width: 100%;
  flex-grow: 1;
  font-size: 18px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.denuncias {
  gap: 10px;
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: 10px;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
}
.attentionicon {
  width: 19px;
  height: 19px;
}
.text33 {
  color: rgb(45, 62, 80);
  width: 100%;
  flex-grow: 1;
  font-size: 18px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.sair1 {
  gap: 10px;
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: 10px;
  align-self: stretch;
  margin-top: 24px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background-color: var(--dl-color-default-f0f4f6);
}
.sairicon1 {
  width: 19px;
  height: 19px;
}
.text34 {
  color: rgba(45, 62, 80, 1);
  width: 100%;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
@media(max-width: 1250px) {
  .container1 {
    gap: 10px;
    align-items: center;
  }
  .menu-desktop {
    display: none;
  }
  .menu-mobile {
    display: flex;
  }
}
@media(max-width: 991px) {
  .menu-desktop {
    display: none;
  }
  .botao-sair-conta {
    display: none;
  }
}
@media(max-width: 479px) {
  .text-logo {
    font-size: 24px;
  }
}

@media(max-width: 350px) {
  .text-logo {
    font-size: 20px;
  }
}
