.container {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
  background-color: rgba(240, 244, 246, 1);
}
.container1 {
  width: 100%;
  height: auto;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: 24px;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: column;
  padding-bottom: 108px;
}
.conteudo {
  gap: 40px;
  width: 100%;
  display: flex;
  overflow: hidden;
  max-width: 1320px;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.sobre-nos {
  gap: 52px;
  width: 100%;
  display: flex;
  padding: 52px 32px;
  overflow: hidden;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: center;
}
.espaco-mobile {
  background-image: url("/sobrenos.png");
  background-size: cover;
  width: 100%;
  height: 227px;
  display: none;
  padding: 52px 0;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-radius: 16px;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-default-dfe4e7);
}
.texto {
  gap: 24px;
  width: 50%;
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.text {
  color: rgba(45, 62, 80, 1);
  height: auto;
  font-size: 32px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text01 {
  color: rgba(125, 138, 152, 1);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.espaco-desk {
  background-image: url("/sobrenos.png");
  background-size: cover;
  width: 50%;
  display: flex;
  padding: 52px 0;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-radius: 16px;
  flex-direction: column;
  justify-content: center;
}
.missao-visao-valores {
  gap: 24px;
  width: 100%;
  display: flex;
  padding: 32px;
  overflow: hidden;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: center;
  border-radius: 8px;
  flex-direction: row;
  background-color: rgba(255, 255, 255, 1);
}
.missao-visao {
  gap: 24px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.missao {
  gap: 16px;
  flex: 1;
  display: flex;
  padding: 24px;
  overflow: hidden;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(222, 228, 230, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
  background-color: var(--dl-color-default-ffffff);
}
.text03 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 20px;
  align-self: stretch;
  font-style: Bold;
  text-align: left;
  font-family: Poppins;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text05 {
  color: rgba(125, 138, 152, 1);
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.visao {
  gap: 16px;
  flex: 1;
  display: flex;
  padding: 24px;
  overflow: hidden;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  border-color: rgba(222, 228, 230, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
  background-color: var(--dl-color-default-ffffff);
}
.text06 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 20px;
  align-self: stretch;
  font-style: Bold;
  text-align: left;
  font-family: Poppins;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text08 {
  color: rgba(125, 138, 152, 1);
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.valores {
  gap: 16px;
  width: 100%;
  display: flex;
  padding: 24px;
  overflow: hidden;
  align-self: stretch;
  align-items: flex-start;
  border-color: rgba(222, 228, 230, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  flex-direction: column;
  background-color: var(--dl-color-default-ffffff);
}
.text10 {
  color: var(--dl-color-default-2d3e50);
  height: auto;
  font-size: 20px;
  align-self: stretch;
  font-style: Bold;
  text-align: left;
  font-family: Poppins;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.text12 {
  color: rgb(125, 138, 152);
  font-size: 16px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
@media(max-width: 991px) {
  .container1 {
    padding-top: 24px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 80px;
  }
  .conteudo {
    gap: 24px;
  }
  .sobre-nos {
    gap: 24px;
    padding-top: 0px;
    padding-left: 16px;
    padding-right: 16px;
    flex-direction: column;
    padding-bottom: 24px;
  }
  .espaco-mobile {
    display: flex;
  }
  .texto {
    width: 100%;
  }
  .text {
    font-size: 28px;
  }
  .espaco-desk {
    width: 100%;
    display: none;
  }
  .missao-visao-valores {
    gap: 16px;
    padding-top: 48px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 48px;
  }
  .missao-visao {
    gap: 16px;
    flex-direction: column;
  }
}
@media(max-width: 767px) {
  .text {
    font-size: 24px;
  }
  .text01 {
    font-size: 15px;
  }
  .text03 {
    font-size: 18px;
  }
  .text05 {
    font-size: 15px;
  }
  .text06 {
    font-size: 18px;
  }
  .text08 {
    font-size: 15px;
  }
  .text10 {
    font-size: 18px;
  }
  .text12 {
    font-size: 15px;
  }
}
@media(max-width: 479px) {
  .text {
    color: rgb(45, 62, 80);
    font-size: 20px;
    font-family: Poppins;
    font-weight: 600;
    line-height: normal;
    text-decoration: none;
  }
  .text01 {
    font-size: 14px;
  }
  .text03 {
    font-size: 16px;
  }
  .text05 {
    font-size: 14px;
  }
  .text06 {
    font-size: 16px;
  }
  .text08 {
    font-size: 14px;
  }
  .text10 {
    font-size: 16px;
  }
  .text12 {
    font-size: 14px;
  }
}
