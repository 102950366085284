.pagarcomcarto {
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 16px;
  overflow: hidden;
  position: fixed;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.61);
}
.container {
  gap: 24px;
  width: 100%;
  display: flex;
  padding: 16px 16px 24px;
  overflow: hidden;
  position: relative;
  max-width: 328px;
  box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.07999999821186066) ;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.titulo {
  gap: 14px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.text {
  color: rgba(45, 62, 80, 1);
  width: 100%;
  flex-grow: 1;
  font-size: 16px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  font-stretch: normal;
  text-decoration: none;
}
.closeicon {
  width: 15px;
  cursor: pointer;
  height: 15px;
  align-self: center;
}
.text-valor {
  color: rgb(45, 62, 80);
  width: 100%;
  font-size: 16px;
  align-self: stretch;
  font-style: SemiBold;
  text-align: center;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.cartao {
  gap: 24px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.inputs {
  gap: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.cvv-validade {
  gap: 8px;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  height: fit-content;

}
.dropdown-parcelas {
  width: 100%;
  height: 40px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: #D8DFE8;
  border-width: 1px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
  padding: 0px 16px;
}

.checkbox {
  width: 100%;
}
.checkbox span label {
  font-size: 14px;
  font-family:Poppins;
  color: rgba(125, 138, 152, 1);
}

.text02 {
  top: 10px;
  left: 16px;
  color: rgba(125, 138, 152, 1);
  width: 240px;
  height: auto;
  /* position: absolute; */
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.arrowicon {
  top: 14.6611328125px;
  left: 254.5px;
  width: 20px;
  height: 12px;
  /* position: absolute; */
}
.numero-rua {
  gap: 8px;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.inputNumero {
  max-width: 110px;
}
.cidade-uf {
  gap: 8px;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.botao-comprar {
  gap: 5px;
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: 12px 24px;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-radius: 40px;
  justify-content: center;
  background-color: rgba(0, 204, 102, 1);
}
.text04 {
  color: var(--dl-color-default-white);
  height: auto;
  font-size: 16px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: 100%;
  font-stretch: normal;
  text-decoration: none;
}
.pix {
  gap: 24px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.qr-code-gerado {
  width: 144px;
  height: 144px;
}
.text-chave-pix {
  color: rgb(125, 138, 152);
  width: 100%;
  font-size: 12px;
  text-align: center;
  font-family: Poppins;
  overflow-wrap: break-word;
}
.text07 {
  color: var(--dl-color-default-2d3e50);
  font-style: normal;
  font-weight: 700;
}
.botoes {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.botao-copiar {
  gap: 5px;
  cursor: pointer;
  display: flex;
  padding: 12px 24px;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-radius: 40px;
  justify-content: center;
  background-color: rgba(0, 204, 102, 1);
}
.text10 {
  color: var(--dl-color-default-white);
  height: auto;
  font-size: 16px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: 100%;
  font-stretch: normal;
  text-decoration: none;
}
.botao-compartilhar {
  gap: 5px;
  cursor: pointer;
  display: flex;
  padding: 12px 24px;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-radius: 40px;
  justify-content: center;
  background-color: rgba(240, 244, 246, 1);
}
.text12 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  font-size: 16px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: 100%;
  font-stretch: normal;
  text-decoration: none;
}
.text14 {
  color: rgba(125, 138, 152, 1);
  width: 100%;
  font-size: 12px;
  align-self: stretch;
  font-style: Regular;
  text-align: center;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.ambiente-seguro {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
}
.seloicon {
  width: 12px;
  height: 14px;
}
.text16 {
  color: rgba(0, 204, 102, 1);
  height: auto;
  font-size: 12px;
  font-style: SemiBold;
  text-align: center;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.root-class-name {
  top: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  display: none;
  position: absolute;
}
@media(max-width: 991px) {
  .dropdown-parcelas {
    padding-left: 16px;
    padding-right: 16px;
  }
  .text02 {
    width: 100%;
    position: static;
    align-self: center;
  }
  .arrowicon {
    position: static;
    align-self: center;
  }
}
