.busca {
  display: flex;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
}
.busca-home {
  padding: 0px 10px;
  gap: 4px;
  width: 100%;
  height: 56px;
  display: flex;
  box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.09000000357627869);
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(222, 228, 230, 1);
  border-style: solid;
  border-width: 1px;
  grid-row-gap: 8px;
  border-radius: 16px;
  justify-content: center;
  background-color: var(--dl-color-default-ffffff);
}
.opcoes {
  gap: 12px;
  width: 100%;
  display: flex;
  overflow: hidden;
  max-width: 332px;
  align-self: stretch;
  align-items: center;
  padding-top: 4px;
  padding-left: 12px;
  border-radius: 8px 0 0 8px;
  padding-right: 16px;
  padding-bottom: 4px;
  justify-content: center;
}
.online {
  gap: 16px;
  width: 100%;
  height: 40px;
  display: flex;
  padding: 4px 8px 4px 16px;
  max-width: 148px;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(222, 228, 230, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
}
.text {
  color: rgba(45, 62, 80, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.presencial {
  gap: 16px;
  height: 40px;
  display: flex;
  padding: 4px 8px 4px 16px;
  align-items: center;
  flex-shrink: 0;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(222, 228, 230, 1);
  border-radius: 8px;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
}
.ativo {
  background-color: var(--dl-color-default-d8ffe6);
  border-color: rgba(255, 193, 75, 1);
}
.text02 {
  color: rgb(45, 62, 80);
  width: 88px;
  height: auto;
  font-size: 16px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.ativo .text02,
.ativo .text {
  font-weight: 600;
}
.cidades {
  gap: 16px;
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  padding-top: 8px;
  border-color: rgba(222, 228, 230, 1);
  border-width: 1px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 8px;
  border-top-width: 0px;
  border-bottom-width: 0px;
}
.input-presencial {
  color: rgba(125, 138, 152, 1);
  width: 100%;
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.online1 {
  width: 100%;
  cursor: pointer;
  display: none;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.dropdown-toggle {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding-top: 0px;
  padding-left: 0px;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 0px;
  padding-bottom: 0px;
  justify-content: space-between;
}
.text03 {
  color: rgb(125, 138, 152);
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.group1171276758 {
  width: 24px;
  height: 24px;
}
.dropdown-list {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-color: #d9d9d9;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-gray-white);
  list-style-position: inside;
}
.dropdown {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.dropdown-toggle01 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.dropdown-toggle01:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.text06 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.dropdown1 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.dropdown-toggle02 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.dropdown-toggle02:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.text07 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.dropdown2 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.dropdown-toggle03 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.dropdown-toggle03:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.text08 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.frame1171276991 {
  gap: 16px;
  display: flex;
  overflow: hidden;
  align-self: stretch;
  align-items: center;
  padding-top: 8px;
  padding-left: 8px;
  border-radius: 0 8px 8px 0;
  padding-right: 8px;
  padding-bottom: 8px;
  justify-content: center;
}
.buttons {
  gap: 8px;
  display: flex;
  padding: 12px 20px 12px 24px;
  align-items: center;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-padro);
  cursor: pointer;
}
.text09 {
  color: var(--dl-color-default-white);
  height: auto;
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 100%;
  font-stretch: normal;
  text-decoration: none;
}
.lupa {
  width: 16px;
  height: 16px;
}
.busca-header {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-self: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-default-ffffff);
}
.busca-desk {
  gap: 12px;
  width: 100%;
  height: 40px;
  display: flex;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(222, 228, 230, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  padding-right: 6px;
  justify-content: center;
  background-color: var(--dl-color-default-ffffff);
}
.opcoes1 {
  gap: 16px;
  display: flex;
  position: relative;
  align-self: center;
  align-items: center;
  flex-shrink: 0;
  padding-top: 8px;
  border-color: rgba(222, 228, 230, 1);
  border-width: 1px;
  padding-left: 16px;
  padding-right: 12px;
  padding-bottom: 8px;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-bottom-width: 0px;
  cursor: pointer;
}
.text11 {
  color: rgba(125, 138, 152, 1);
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.arrowicon {
  width: 24px;
  cursor: pointer;
  height: 24px;
}
.arrowicon-up {
  width: 24px;
  cursor: pointer;
  height: 24px;
  transform: rotate(180deg);
}
.container {
  width: 100%;
  display: flex;
  background: white;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 0 0 8px 8px;
  border-top-width: 0;
}
.drop-presencial-online {
  top: 40px;
  position: absolute !important;
}
.text13 {
  color: rgba(125, 138, 152, 1);
  width: 100%;
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  align-self: flex-start;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  padding-top: 4px;
  font-stretch: normal;
  padding-left: 16px;
  padding-right: 12px;
  padding-bottom: 4px;
  text-decoration: none;
}
.text14 {
  color: rgb(125, 138, 152);
  width: 100%;
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  align-self: flex-start;
  font-style: Regular;
  text-align: left;
  font-family: "Poppins";
  font-weight: 400;
  line-height: normal;
  padding-top: 4px;
  font-stretch: normal;
  padding-left: 16px;
  padding-right: 8px;
  padding-bottom: 4px;
  text-decoration: none;
}
.cidades1 {
  width: 100%;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  padding-top: 8px;
  padding-left: 0;
  border-radius: 8px 0 0 8px;
  padding-right: 8px;
  padding-bottom: 8px;
}
.presencial1 {
  gap: 16px;
  width: 100%;
  display: flex;
  align-items: center;
}
.textinput {
  color: rgba(125, 138, 152, 1);
  width: 100%;
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.localicon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.online2 {
  width: 100%;
  cursor: pointer;
  display: none;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.dropdown-toggle04 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding-top: 0px;
  padding-left: 0px;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 0px;
  padding-bottom: 0px;
  justify-content: space-between;
}
.text15 {
  color: rgb(125, 138, 152);
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.group11712767581 {
  width: 24px;
  height: 24px;
}
.dropdown-list1 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-color: #d9d9d9;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-gray-white);
  list-style-position: inside;
}
.dropdown3 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.dropdown-toggle05 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.dropdown-toggle05:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.text18 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.dropdown4 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.dropdown-toggle06 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.dropdown-toggle06:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.text19 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.dropdown5 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.dropdown-toggle07 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.dropdown-toggle07:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.text20 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.botao-busca {
  gap: 5px;
  width: 30px;
  cursor: pointer;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-padro);
}
.lupa1 {
  width: 14px;
  height: 14px;
}
.botao-busca-mobile {
  gap: 5px;
  width: 40px;
  height: auto;
  display: none;
  padding: 0px;
  align-items: center;
  flex-shrink: 0;
  border-width: 0px;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-colorgreybg);
}
.lupa2 {
  width: 30px;
  height: 30px;
}
.busca-mobile {
  gap: 24px;
  width: 100%;
  display: none;
  box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.09000000357627869);
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(222, 228, 230, 1);
  border-style: solid;
  border-width: 1px;
  grid-row-gap: 8px;
  border-radius: 16px;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-default-ffffff);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
.container1 {
  gap: 8px;
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: center;
  grid-row-gap: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-ffffff);
}
.opcoes2 {
  gap: 8px;
  width: 100%;
  display: flex;
  padding: 0px;
  overflow: hidden;
  align-self: center;
  align-items: center;
  border-radius: 8px 0 0 8px;
  flex-direction: row;
  justify-content: center;
}
.online3 {
  gap: 16px;
  flex: 1;
  width: 100%;
  height: 40px;
  display: flex;
  padding: 4px 8px 4px 16px;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(222, 228, 230, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  flex-direction: row;
  justify-content: center;
}
.text21 {
  color: rgba(45, 62, 80, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.presencial2 {
  gap: 16px;
  flex: 1;
  width: 100%;
  height: 40px;
  display: flex;
  padding: 4px 8px 4px 16px;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(255, 193, 75, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-default-d8ffe6);
}
.text23 {
  color: rgb(45, 62, 80);
  width: 88px;
  height: auto;
  font-size: 16px;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.cidades2 {
  gap: 16px;
  display: flex;
  padding: 8px;
  overflow: hidden;
  flex-grow: 1;
  align-items: center;
  border-color: rgba(222, 228, 230, 1);
  border-width: 1px;
  border-top-width: 1px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.input-presencial1 {
  color: rgba(125, 138, 152, 1);
  width: 100%;
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.online4 {
  width: 100%;
  cursor: pointer;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.dropdown-toggle08 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding-top: 0px;
  padding-left: 0px;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 0px;
  padding-bottom: 0px;
  justify-content: space-between;
}
.text24 {
  color: rgb(125, 138, 152);
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.group11712767582 {
  width: 24px;
  height: 24px;
}
.dropdown-list2 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-color: #d9d9d9;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-gray-white);
  list-style-position: inside;
}
.dropdown6 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.dropdown-toggle09 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.dropdown-toggle09:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.text27 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.dropdown7 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.dropdown-toggle10 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.dropdown-toggle10:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.text28 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.dropdown8 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.dropdown-toggle11 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.dropdown-toggle11:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.text29 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.buttons1 {
  gap: 8px;
  display: flex;
  padding: 12px 20px 12px 24px;
  align-items: center;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-padro);
}
.text30 {
  color: var(--dl-color-default-white);
  height: auto;
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  line-height: 100%;
  font-stretch: normal;
  text-decoration: none;
}
.lupa3 {
  width: 16px;
  height: 16px;
}
.root-class-name {
  max-width: 480px;
}
.root-class-name1 {
  max-width: 960px;
}
.root-class-name2 {
  max-width: 480px;
}
@media (max-width: 1250px) {
  .busca-header {
    background: transparent;
  }
  .busca-desk {
    display: none;
  }
  .botao-busca-mobile {
    display: flex;
  }
  .busca-mobile {
    display: flex;
  }
  .container1 {
    display: flex;
  }
  .cidades2 {
    width: 100%;
  }
  .root-class-name2 {
    max-width: fit-content;
    margin-left: auto;
  }
}
@media (max-width: 991px) {
  .text {
    font-size: 16px;
  }
  .cidades {
    border-left-width: 1px !important;
    border-right-width: 1px !important;
  }
  .text21 {
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  .buscaHome {
    width: 100%;
  }
  .busca-home {
    gap: 8px;
    height: auto;
    padding: 16px;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .opcoes {
    gap: 8px;
    width: 100%;
    max-width: 100%;
    align-self: center;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 4px;
  }
  .online {
    flex: 1;
    max-width: 100%;
  }
  .text {
    font-size: 15px;
  }
  .presencial {
    flex: 1;
    width: 100%;
  }
  .text02 {
    font-size: 15px;
  }
  .cidades {
    border-radius: 8px;
    padding-top: 12px;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 12px;
    border-top-width: 1px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .input-presencial {
    font-size: 15px;
  }
  .frame1171276991 {
    padding-top: 4px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
  }
  .busca-mobile {
    gap: 16px;
  }
  .opcoes2 {
    display: none;
  }
  .text21 {
    font-size: 15px;
  }
  .text23 {
    font-size: 15px;
  }
  .cidades2 {
    border-top-width: 1px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .input-presencial1 {
    font-size: 15px;
  }
}
@media (max-width: 479px) {
  .online {
    padding-top: 2px;
    padding-left: 14px;
    padding-right: 6px;
    padding-bottom: 2px;
  }
  .text {
    font-size: 14px;
  }
  .presencial {
    padding-top: 2px;
    padding-left: 14px;
    padding-right: 6px;
    padding-bottom: 2px;
  }
  .text02 {
    font-size: 14px;
  }
  .input-presencial {
    font-size: 14px;
  }
  .text21 {
    font-size: 14px;
  }
  .text23 {
    font-size: 14px;
  }
  .input-presencial1 {
    font-size: 14px;
  }
}

@media screen and (max-width: 380px) {
  .text-21 {
    font-size: 13px;
  }
}

.lista-cidades {
  position: absolute;
  top: 100%;
  list-style: none;
  overflow: auto;
  background: white;
  width: 100%;
  left: 0;
  padding: 16px;
  border-radius: 0 0 8px 8px;
  border: 1px solid rgba(222, 228, 230, 1);
  border-top-width: 0px;
}

.lista-cidades li {
  color: var(--dl-color-default-7d8a98);
  padding: 4px 0;
  cursor: pointer;
}

.lista-cidades li:hover {
  filter: brightness(1.2);
}

@media screen and (max-width: 375px) {
  .opcoes {
    flex-direction: column;
  }

  .opcoes .online,
  .opcoes .presencial {
    min-height: 40px;
  }
}

.container {
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.dropdown {
  min-width: 200px;
  height: auto;
  display: flex;
  flex-direction: column;
  background-color: var(--dl-color-default-ffffff);
}
@media (max-width: 767px) {
  .dropdown {
    min-width: 100%;
  }
}
.option-padro {
  gap: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.todo-brasil {
  color: rgba(125, 138, 152, 1);
  height: auto;
  width: auto;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.chevron {
  width: 24px;
  height: 24px;
}
.lista {
  gap: 16px;
  width: 100%;
  display: flex;
  padding: 8px 8px 24px 24px;
  align-items: flex-start;
  flex-direction: column;
  position: absolute;
  top: 30px;
  background: white;
  height: 240px;
  overflow-y: scroll;
  border: 1px solid #d9d9d9;
  border-radius: 0px 0px 8px 8px;
}
.text1 {
  color: rgba(125, 138, 152, 1);
  width: 100%;
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Poppins;
  font-weight: 400;
  line-height: 168%;
  font-stretch: normal;
  text-decoration: none;
}
